import React, { useLayoutEffect } from "react";
import {
  Column,
  Table,
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
  RowData,
} from "@tanstack/react-table";

import "./EditableTable.scss";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faCircleExclamation, faPlus } from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";
import { useVirtualizer } from '@tanstack/react-virtual'
import { InvoiceFields } from "../services/API/InvoiceReports";
import { ProjectInvoice } from "../pages/KickbackProjectReportPage";
//ref: https://tanstack.com/table/v8/docs/examples/react/editable-data

export interface GroupedCol<T> {
  id: string;
  header: string;
  cell(item:T, index: number):JSX.Element; 
  minSize: string;
  maxSize?: number;
  footer?(col:GroupedCol<T>, groupId: number):JSX.Element;
  meta?: "skipPaddingOnFooter"
  
}

type Props<T> = {
  columns: ColumnDef<any>[];
  grouped?:{
    collapsedGroups: number[];
    onCollapseGroup(groupId:number):void;
    onCollapseAll():void;
    groupedColumns: GroupedCol<T>[];
  }
  data: T[];
  onChange: (data: T[]) => void;
  noItemsText?:string;
  // onAddRow:() => T;
};

declare module "@tanstack/react-table" {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

const _pageSize = 100;

const DefaultCell = (props: {
  getValue: any;
  row: { index: number };
  column: { id: string };
  table: any;
}) => {
  const initialValue = props.getValue();
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  // When the input is blurred, we'll call our table meta's updateData function
  const onBlur = () => {
    props.table.options.meta?.updateData(
      props.row.index,
      props.column.id,
      value
    );
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      value={value as string}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
    />
  );
};

const defaultColumn: Partial<ColumnDef<any>> = {
  cell: DefaultCell
};

function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

const EditableTable = <T,>(props: Props<T>) => {
  // const rerender = React.useReducer(() => ({}), {})[1]
  const { t, i18n } = useTranslation();

  const columns = React.useMemo<ColumnDef<any>[]>(() => props.columns, []);
  // const [data, setData] = React.useState(() => [])
  const [focus, setFocus] = React.useState<
    { x: number; y: number } | undefined
  >(() => undefined);
  // const refreshData = () => setData(() => [])

  function onCellFocus(x: number, y: number) {
    setFocus({ x, y });
  }

  function onTableBlur() {
    setFocus(undefined);
  }

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  const table = useReactTable({
    data: props.data,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex,
    pageCount: undefined,
    initialState: { pagination: { pageSize: 999999 } },
    // Provide our updateData function to our table meta
    
    meta: {
      updateData: (rowIndex, columnId, value) => {
        // Skip age index reset until after next rerender
        skipAutoResetPageIndex();
        props.onChange(
          props.data.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...props.data[rowIndex]!,
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    }
  });
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement : () => tableContainerRef.current,
    estimateSize: (i) => {
      
      if(!props.grouped)
        return 41; //non-grouped row
      const item = props.data[i];
      //@ts-ignore groupId
      if(props.grouped.collapsedGroups.includes(typeof item.groupId == 'undefined' ? item.uniqueRowId : item.groupId))
        return 50; // groupheader
      //@ts-ignore item.items
      
      const groupHeight = 50+31+31+(item.items.length * 42); // groupheader + header + footer + items
      //@ts-ignore
      return groupHeight;
    },
    overscan: 2,
  });
  useLayoutEffect(() => {
    if(props.grouped)
      rowVirtualizer.measure();
  }, [props.grouped?.collapsedGroups, props.data]);
  const virtualRows = rowVirtualizer.getVirtualItems();

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? rowVirtualizer.getTotalSize() - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;
  return (
    <div className="table-wrapper table-responsive-text" ref={tableContainerRef}>
      <table className="editable-table-root">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {
                props.grouped &&
                <th style={{width: "2%", padding:0}} onClick={() => props.grouped?.onCollapseAll()}>
                  <Button style={{borderRadius:0}} className="button-cancel"><FontAwesomeIcon icon={props.grouped?.collapsedGroups.length == props.data.length ? faArrowDown : faArrowUp} /></Button>
                </th>
              }
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan} style={{width: `${header.getSize()}px`, maxWidth: header.column.columnDef.maxSize, minWidth: header.column.columnDef.minSize}}>
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody onBlur={onTableBlur}>
          {paddingTop > 0 && (
            <tr>
              <td colSpan={8} style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
          {virtualRows.map((virtualRow, x) => {
            const row = rows[virtualRow.index];
            return (
              <>
                <tr
                  key={row.id}
                  className={props.grouped ? "grouped" : ""}
                  aria-selected={props.grouped?.collapsedGroups.includes(row.original.groupId) == false}
                >
                  {props.grouped && (
                    <td>
                      <Button
                        onClick={() => props.grouped?.onCollapseGroup(row.original.groupId)}
                        style={{ borderRadius: "0px 0px 5px 0px" }}
                      >
                        <FontAwesomeIcon
                          icon={
                            props.grouped?.collapsedGroups.includes(row.original.groupId)
                            ? faArrowDown
                            : faArrowUp
                          }
                        />
                      </Button>
                    </td>
                  )}
                  {row.getVisibleCells().map((cell, y) => {
                    return (
                      <td
                        key={cell.id}
                        id={`${x}_${y}`}
                        onFocus={() => onCellFocus(x, y)}
                        style={{
                          width: `${cell.column.getSize()}px`,
                          maxWidth: cell.column.columnDef.maxSize,
                          minWidth: cell.column.columnDef.minSize,
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
                {props.grouped?.collapsedGroups.includes(row.original.groupId) == false && (
                  <tr>
                    <td colSpan={props.columns.length+1}>
                      <div className="table-responsive-text">
                      <table
                        style={{ width: "calc(100% - 60px)", marginLeft: 60 }}
                      >
                        <thead>
                          <tr style={{ height: 25, fontSize: ".9em" }}>
                            {props.grouped?.groupedColumns.map((val) => {
                              return (
                                <th style={{ width: val.minSize }}>
                                  {val?.header?.toString()}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {row.original.items.map((item: T,index:number) => {
                            return (
                              <tr style={{ height: 25, fontSize: ".9em" }}>
                                {props.grouped?.groupedColumns.map((col) => {
                                  return (
                                    <td
                                      key={`${index}_${col.id}`}
                                      style={{
                                        minWidth: col.minSize,
                                        maxWidth: col?.maxSize,
                                        width: col?.maxSize,
                                      }}
                                    >
                                      {col.cell(item, row.index)}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr
                            key={`${row.id}_footer`}
                            style={{ height: "unset" }}
                          >
                            {props.grouped?.groupedColumns.map((col) => {
                              return (
                                <td
                                  key={`${row.id}_footer_${col.id}`}
                                  style={
                                    col.meta == "skipPaddingOnFooter"
                                      ? { padding: 0 }
                                      : {}
                                  }
                                >
                                  {col.footer
                                    ? col.footer(col, row.original.groupId)
                                    : null}
                                </td>
                              );
                            })}
                          </tr>
                        </tfoot>
                      </table>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            );
          })}
          {props.data.length == 0 && (
            <tr className="editable-table-empty-tr">
              <td colSpan={100}>
                <FontAwesomeIcon icon={faCircleExclamation} />
                {props.noItemsText ??t("invoiceReport.table.noRowsToShow")}
              </td>
            </tr>
          )}
          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((group) => (
            <tr key={group.id}>
              {
                props.grouped &&
                <td></td>
              }
              {group.headers.map((header) => (
                <td key={header.id} style={header.column.columnDef.meta == "skipPaddingOnFooter" ? {padding: 0}:{}}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
};

export default EditableTable;
