import * as React from 'react'
import { KickbackChanged } from '../modal/SubmitKickbackProject'
import Datalist, { FooterOptions, ListColumn } from '../Datalist/Datalist'
import i18n from '../../i18n'
import { ReadFriendlyNumber } from '../../lib/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
 
import StyledAccordion from '../Accordion/StyledAccordion'
import Accordion from '../Accordion/StyledAccordion';
import { TKickbackReportById } from '../../services/API/Kickback'
interface Props{
    changes:KickbackChanged[]
    kickbacReport:Required<TKickbackReportById>;
}
export  const ProjectChangesView = ({changes,kickbacReport}:Props) => {
    const okIcon = faCheckCircle;
    const notOkIcon = faExclamationCircle;
    const okColor = "#07a235"
    const tooMuchColor = "#f5a32d"
    const notEnoughColor = "#b30404"
    const [ columns, setColumns ] = React.useState<ListColumn<KickbackChanged>[]>([
        {
          fieldName: "contractId",
          isActive: true,
          key: "contractId",
          minWidth: 100,
          sorting: true,
          isSorted: true,
          name: i18n.t('general.contract'),
          onRender(item, col) {
            return `${item.contractId}`
          },
          footer: FooterOptions.COUNT
        },
        {
          fieldName: "reportedSum",
          isActive: true,
          key: "sumTotal",
          minWidth: 60,
          sorting: true,
          name: i18n.t('kickback.table.header.sumExclMomsReported'),
          onRender: (item, col) => {
            let icon = okIcon;
            let color = okColor;
            if(item.sumTotal != item.changedSum){
              color = notEnoughColor;
              icon = notOkIcon;
            }
            if(item.sumTotal > item.changedSum){
              color = tooMuchColor;
            }
            return <div style={{ height: "100%", display:"flex", alignItems: "center"}}>
              
              
                <FontAwesomeIcon icon={icon} style={{marginRight: "8px", height: "18px", width: "18px"}} color={color}/>
              
              {ReadFriendlyNumber(item.sumTotal)}
            </div>
          },
          footer: (items) => {
            const totalSum = items.reduce((prev, curr) => {
              return prev + curr.sumTotal;
            }, 0)
            return ReadFriendlyNumber(totalSum)
          }
        },
        {
            fieldName: "invoicedSum",
           isActive: /*props.type != "Project"*/true,
            sorting: true,
            key: "changedSum",
            minWidth: 60,
            name: i18n.t('kickback.table.header.invoiceSumExclMoms'),
            onRender: (item, col) => {
              return  ReadFriendlyNumber(item.changedSum);
            },
            footer: (items) => {
              const totalSum = items.reduce((prev, curr) => {
                return prev + curr.changedSum;
              }, 0)
              return ReadFriendlyNumber(totalSum)
            }
          },
          {
            fieldName: "kickbackProcent",
            isActive: true,
            sorting: true,
            key: "kickbackProcent",
            minWidth: 60,
            name: i18n.t('kickback.table.header.kickbackProcent'),
            onRender: (item,col) => {
              return kickbacReport.contracts.find(x => x.contractId == item.contractId)?.kickbackProcent +"%"
            }
          },
        {
            fieldName: "totalKickbackReported",
            isActive: true,
            key: "totalKickbackReported",
            minWidth: 100,
            sorting: true,
            isSorted: true,
            name: i18n.t('kickback.table.header.calculatedKickback'),
            onRender:(item,col) => {
                return ReadFriendlyNumber(item.totalKickbackReported)
            },
            footer:(items) => {
                const totalSum = items.reduce((prev, curr) => {
                    return prev + curr.totalKickbackReported;
                  }, 0)
                  return ReadFriendlyNumber(totalSum)
            }
          },
        {
            fieldName: "changesComment",
            isActive: true,
            key: "changesComment",
            minWidth: 100,
            sorting: true,
            isSorted: true,
            name: /*i18n.t('general.contract')*/"Ev.ändring kommentar",
           
          },
       
       
        
      ]);
    return(
        <>
       <Accordion title={'Ändringar'}  titleStyle={{fontSize: "1.1rem", margin: 0}} style={{padding: "0px 10px 0px 10px"}}>
       <Datalist
                items={changes ?? []}
                uniqueKey="contractId"
                columns={columns}
                noItemsText={i18n.t("kickbackReportModal.table.empty")}
                initialSortKey="contractId"
                setColumns={(cols) => setColumns(cols)}
            />    
       </Accordion>
        
       
        
        </>
    )
}