/* eslint-disable @typescript-eslint/no-useless-constructor */
import * as React from "react";
import { Button, Spinner } from "react-bootstrap";
import { InvoiceForPrint } from "../../services/API/Invoices";
import CsvDownloader from "react-csv-downloader";
import { ReadFriendlyNumber } from "../../lib/utils";
import {
  withTranslation
} from "react-i18next";
import i18n from "../../i18n";
interface ICsvProps {
  data?: InvoiceForPrint[] | any;
  format: string;
  
}
interface ICsvState {
  preparedData: any;
  loading: boolean;
  formatData: any[];
}

class CsvExport extends React.Component<ICsvProps, ICsvState> {
    private date = new Date()
    constructor(props: ICsvProps) {
    super(props);
    this.state = {
      preparedData: [],
      loading: true,
      formatData: [],
    };
  }
  async componentDidMount() {
    const da = await this.buildData(this.props.data);
    this.setState({formatData: da, loading: false });
  }
  private async buildData(data: InvoiceForPrint[]): Promise<any[]> {
    const res: any[] = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const bDate = element.billingDate.toString().split("T");
      const dDate = element.dueDate.toString().split("T");
      const buff = {
        customerName: element.customerName,
        customerOrgId: element.customerOrgId,
        invoiceNumber: element.invoiceNumber,
        billingDate: bDate[0],
        dueDate: dDate[0],
        priceExcMoms: ReadFriendlyNumber(element.priceExcMoms),
        priceIncMoms: ReadFriendlyNumber(element.priceIncMoms),
        type: element.type === 1 ? "Debit" : i18n.t("print.credit"),
      };
      res.push(buff);
    }
    return res;
  }
  render() {
    return (
      <div>
        {this.state.loading && 
        <Button className='primary'><Spinner/></Button>  
        }
        {this.state.loading === false && (
          <div>
            <CsvDownloader
              datas={this.state.formatData}
              extension=".csv"
              filename={`${this.props.data[0].levName}-${this.date.toLocaleDateString()}-${i18n.t("print.csvFileName")}`}
              separator=";"
              wrapColumnChar='"'
            >
             <Button className='primary'>{i18n.t("print.exportButton")}</Button>   
            </CsvDownloader>
          </div>
        )}
      </div>
    );
  }
}
export default withTranslation()(CsvExport)
