import Urls from "../../lib/Urls";
import { Code, ReturnFetchObj, apiFetch } from "../../lib/utils";
import { t } from "i18next";
import { NewsArticle } from "../../pages/NewsArticlePage";

export interface TNews {
  id: number;
  title: string;
  ingress: string;
  body?: string;
  // isPublished: boolean;
  // publishedDate: Date;
  // publishedBy?: string;
  publishedFrom: Date;
  publishedUntil: Date;
  created: Date;
  createdBy?: string;
  updated: Date;
  updatedBy?: string;
  lang?: string;
  newsArticleId?: string;
}
const formatJsonResponse = (json: any): NewsArticle => {
  const news = json.reduce((acc: NewsArticle, item: any) => {
    if (item.id > 0) {
      acc[item.lang] = {
        article: {
          created: new Date(item.created),
          id: item.id,
          publishedFrom: new Date(item.publishedFrom),
          publishedUntil: new Date(item.publishedUntil) /*yearsInFuture(100)*/,
          title: item.title,
          updated: new Date(item.updated),
          body: item.body,
          ingress: item.ingress,
          lang: item.lang,
          newsArticleId: item.newsArticleId,
          createdBy: item.createdBy,
        } as TNews,
        active: false,
      };
    }
    return acc;
  }, {});
  const keys = Object.keys(news);
  for (let i = 0; i < AppSettings.languages.length; i++) {
    for (let j = 0; j < keys.length; j++) {
      if (AppSettings.languages[i].locale !== keys[j]) {
        if (!news[AppSettings.languages[i].locale]) {
          news[AppSettings.languages[i].locale] = {
            article: {
              created: new Date(),

              publishedFrom: new Date(news[keys[j]].article.publishedFrom),
              publishedUntil: new Date(news[keys[j]].article.publishedUntil),
              title: "",
              updated: new Date(),
              body: "",
              ingress: "",
              lang: AppSettings.languages[i].locale,
              createdBy: news[keys[j]].article.createdBy,
              newsArticleId: news[keys[j]].article.newsArticleId,
            } as TNews,
            active: false,
          };
        }
      }
    }
  }
  return news;
};
const Get = async (): Promise<TNews[]> => {
  const response = await apiFetch(Urls.News);
  const json: any[] = await response.json();

  const news = json.map((item) => {
    return {
      ...item,
      created: new Date(item.created),
      updated: new Date(item.updated),
      publishedFrom: new Date(item.publishedFrom),
      publishedUntil: new Date(item.publishedUntil),
    };
  });
  return news;
};

const GetById = async (id: string): Promise<NewsArticle> => {
  const response = await apiFetch(Urls.News + "/" + id);
  const json = await response.json();
  const news = formatJsonResponse(json);
  return news;
};

const Post = async (news: TNews[]) => {
  try {
    const response = await apiFetch(Urls.News, "POST", JSON.stringify(news), {
      "Content-Type": "application/json",
    });
    const json  = await response.json();
    const res = formatJsonResponse(json);
    return ReturnFetchObj(t("api.news.postSuccess"), Code.OK, res);
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t("api.news.postFail"), Code.ADD_ERR, { ...news });
  }
};

const Put = async (news: TNews[]) => {
  try {
    const response = await apiFetch(
      `${Urls.News}/${news[0].id}`,
      "PUT",
      JSON.stringify(news),
      { "Content-Type": "application/json" }
    );
    if (!response.ok) {
      throw `Error News PUT: ${response.status}`;
    }
    const json = await response.json();
    const res = formatJsonResponse(json);
    return ReturnFetchObj(t("api.news.putSuccess"), Code.OK, res);
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t("api.news.putFail"), Code.UPDATE_ERR, { ...news });
  }
};

const Delete = async (id: string) => {
  try {
    const response = await apiFetch(`${Urls.News}/${id}`, "DELETE");
    if (!response.ok) {
      throw `Error News DELETE: ${response.status}`;
    }
    return ReturnFetchObj(t("api.news.deleteSuccess"), Code.OK, { id });
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t("api.news.deleteFail"), Code.DELETE_ERR, { id });
  }
};

export { Get, GetById, Post, Put, Delete };
