/* eslint-disable eqeqeq */
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { Button, Spinner, Stack } from "react-bootstrap";
import { DataService } from "../services/DataService";
import Datalist, {
  FooterOptions,
  ListColumn,
} from "../components/Datalist/Datalist";
import { IsUserSinfra, ReadFriendlyNumber } from "../lib/utils";
import {
  TKickbackProjectReport,
  TKickbackReport,
  TKickbackReportType,
  TKickbackStatusUpdate,
} from "../services/API/Kickback";
import { TSupplier } from "../services/API/Suppliers";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconLookup,
  faExclamationCircle,
  faFileDownload,
  faFilter,
  faFilterCircleXmark,
  faWarning,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { KickbackReportModal } from "../components/modal/KickbackReportModal";
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";
import KickBackReportFileModal from "../components/modal/KickBackReportFileModal";
import ToasterMsg from "../components/Toaster/ToasterMsg";
import { SearchBar } from "../components/SearchBar/SearchBar";
import ReportsFilter from "../components/ReportsFilter/ReportsFilter";

interface Props extends WithTranslation {}

interface State {
  error?: Error;
  isLoading: boolean;
  kickbackReports: TKickbackReport[];
  kickbackColumns: ListColumn<TKickbackReport>[];
  suppliers: TSupplier[];
  searchedSupplier: TSupplier | undefined;
  searchText: string;
  selectedReportId: string | undefined;
  loadingIndexes: number[];
  showFileModal: boolean;
  selectedFile: TKickbackReport | undefined;
  toasterMessage: any;
  toasterErrorShow: boolean;
  selectedFileRowIndex: number;
  selectedKickbackPeriod: string;
  supplierOrgId: string;
  filteredReports: TKickbackReport[];
  isFilterActive: boolean;
  showFilter: boolean;
  filters: {
    periodBegin: Date | null;
    periodEnd: Date | null;
    reportDateBegin: Date | null;
    reportDateEnd: Date | null;
    selectedSuppliers: string[];
    selectedStatuses: string[];
  };
  type:TKickbackReportType;
}

class KickbackListPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    if (IsUserSinfra() == false) window.location.href = "/";
    this.state = {
      isLoading: true,
      kickbackReports: [],
      searchText: "",
      suppliers: [],
      kickbackColumns: this.kickbackReportColumns,
      searchedSupplier: undefined,
      selectedReportId: undefined,
      loadingIndexes: [],
      showFileModal: false,
      selectedFile: undefined,
      toasterMessage: undefined,
      toasterErrorShow: false,
      selectedFileRowIndex: -1,
      selectedKickbackPeriod: "",
      supplierOrgId: "",
      filteredReports: [],
      isFilterActive: false,
      filters: {
        periodBegin: null,
        periodEnd: null,
        reportDateBegin: null,
        reportDateEnd: null,
        selectedSuppliers: [],
        selectedStatuses: [],
      },
      showFilter: false,
      type: "Standard"
    };
  }

  private supplierDictionary: { [orgNr: string]: TSupplier } = {};
  async componentDidMount() {
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    const [kickbackReports, suppliers] = await Promise.all([
      DataService.Kickback.GetReports(false),
      DataService.Suppliers.Get()
    ]);
    suppliers.forEach((supp) => {
      this.supplierDictionary[supp.supplierOrgNr] = supp;
    });

    this.setState({
      isLoading: false,
      kickbackReports,
      // kickbackReports:res,
      suppliers,
      filteredReports: kickbackReports,
    });
  }

  private handleUpdatedStatusSaveSuccess = async (
    dissmiss: boolean,
    updated: TKickbackStatusUpdate,
    info: any,
    status?: number
  ) => {
    if (status === 0) {
      this.setState({ toasterErrorShow: true, toasterMessage: info });
      const updatedKickBackReports = this.state.kickbackReports.map((x) => {
        if (x.reportId === updated.reportId) {
          x.status = updated.status;
          return x;
        } else {
          return x;
        }
      });
      this.setState({ kickbackReports: updatedKickBackReports });
    } else {
      this.setState({ toasterErrorShow: true, toasterMessage: info });
    }
  };

  private kickbackReportColumns: ListColumn<TKickbackReport>[] = [
    {
      fieldName: "reportDate",
      isActive: true,
      key: "reportDate",
      minWidth: 40,
      maxWidth: 120,
      name: this.props.t("kickback.list.reportDate").toString(),
      onRender(item, col) {
        return item.reportDate.toLocaleDateString();
      },
      footer: FooterOptions.COUNT,
      sorting: true,
    },
    {
      fieldName: "period",
      isActive: true,
      key: "period",
      minWidth: 40,
      maxWidth: 80,
      sorting: true,
      name: this.props.t("kickback.list.period"),
    },
    {
      fieldName: "isLink",
      isActive: true,
      key: "isLink",
      minWidth: 40,
      maxWidth: 40,
      name: "",
      onRender: (item, col, i) => {
        if (this.state.loadingIndexes.includes(i))
          return (
            <Spinner style={{ width: 20, height: 20, color: "#00cdff" }} />
          );
        return DataService.Kickback.IsFileReport(item) ? (
          <div style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faFileDownload} />
          </div>
        ) : (
          item.type == 'Project' ? (<div style={{ textAlign: "center" }}>
          {/* <FontAwesomeIcon icon='user-tie' /> */}
          <svg
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="18px"
      viewBox="0 0 204.504 204.504"
      xmlSpace="preserve"
      // {...props}
    >
      <path d="M99.542 43.211c10.875 0 19.704-12.623 19.704-23.501C119.246 8.823 110.417 0 99.542 0 88.654 0 79.825 8.829 79.825 19.71c0 10.879 8.829 23.501 19.717 23.501zM147.067 126.411h-5.59v-5.262c2.181-1.602 3.544-4.189 3.228-7.057l-6.235-56.118c-.049-.381-.243-.679-.341-1.041-.141-.572-.268-1.136-.536-1.659-.207-.393-.499-.697-.761-1.042-.348-.459-.664-.917-1.114-1.297-.354-.292-.768-.472-1.163-.703-.402-.237-.73-.554-1.182-.718-.706-.252-16.392-5.879-30.808-6.622l-1.982 3.967h-.049l5.249 40.879-6.235 10.89-6.235-10.89 5.252-40.879H98.5l-1.982-3.974c-14.416.743-30.099 6.366-30.802 6.622-.457.165-.792.493-1.19.731-.387.231-.801.399-1.142.691-.462.387-.792.862-1.154 1.337-.25.335-.53.63-.73 1.001-.274.53-.393 1.105-.548 1.69-.094.35-.286.648-.332 1.017l-6.235 56.118a7.793 7.793 0 007.758 8.653 7.793 7.793 0 007.733-6.93l5.678-51.118a125.82 125.82 0 012.165-.63v53.198l-6.205 77.138c-.411 5.151 3.425 9.657 8.573 10.071.256.019.505.03.761.03 4.82 0 8.917-3.708 9.31-8.604l5.605-69.722a9.315 9.315 0 003.772.805 9.285 9.285 0 003.771-.805l5.605 69.722c.39 4.896 4.487 8.604 9.311 8.604.256 0 .505-.006.761-.03 5.146-.414 8.981-4.926 8.573-10.071l-6.204-77.138V64.067c.773.219 1.491.429 2.161.63l5.682 51.118c.42 3.751 3.452 6.491 7.069 6.808v3.788h-5.584a3.123 3.123 0 00-3.117 3.117v47.276c0 1.717 1.4 3.117 3.117 3.117h16.368c1.711 0 3.117-1.4 3.117-3.117v-47.276c.021-1.711-1.393-3.117-3.098-3.117z" />
    </svg>
        </div>) : ''
        );
      },
    },
    {
      fieldName: "supplierOrgId",
      isActive: true,
      key: "supplierOrgId",
      minWidth: 130,
      name: this.props.t("general.supplier"),
      sorting: true,
      onRender: (item, col) => {
        const supplier = this.supplierDictionary[item.supplierOrgId];
        return `${item.supplierOrgId} - ${supplier?.supplierName}`;
      },
    },
    {
      fieldName: "reporter",
      isActive: true,
      sorting: true,
      key: "reporter",
      minWidth: 100,
      name: this.props.t("kickback.list.reporter"),
      onRender: (item, col) => {
        return item.reporter.includes("@") ? (
          <a href={`mailto:${item.reporter}`}>{item.reporter}</a>
        ) : (
          item.reporter
        );
      },
    },
    // {
    //   fieldName: "noOfContracts",
    //   isActive: true,
    //   key: "noOfContracts",
    //   minWidth: 10,
    //   sorting: true,
    //   name: this.props.t("kickback.list.noOfContracts"),
    //   onRender(item, col) {
    //     return DataService.Kickback.IsFileReport(item)
    //       ? ""
    //       : item.noOfContracts.toString();
    //   },
    // },
    {
      fieldName: "totalSum",
      isActive: true,
      key: "totalSum",
      minWidth: 50,
      sorting: true,
      footer: FooterOptions.SUM,
      name: this.props.t("kickback.list.totalSum"),
      onRender(item, col) {
        return DataService.Kickback.IsFileReport(item)
          ? ""
          : <>
          {ReadFriendlyNumber(item.totalSum)}
          {item.invoicedMatchReported == false && <FontAwesomeIcon icon={faExclamationCircle} style={{color: "orange", marginLeft:4}}/>}
          </>
      },
    },
    {
      fieldName: "totalKickback",
      isActive: true,
      key: "totalKickback",
      minWidth: 50,
      sorting: true,
      name: this.props.t("kickback.list.totalKickback"),
      footer: FooterOptions.SUM,
      onRender(item, col) {
        return DataService.Kickback.IsFileReport(item)
          ? ""
          : ReadFriendlyNumber(item.totalKickback);
      },
    },
    {
      fieldName: "status",
      isActive: true,
      key: "status",
      minWidth: 10,
      sorting: true,
      name: "Status" /*this.props.t("kickback.list.noOfContracts")*/,
      onRender(item, col) {
        let icon: any = {};
        for (
          let index = 0;
          index < AppSettings.kickbackReportsStatusIcons.length;
          index++
        ) {
          const element = AppSettings.kickbackReportsStatusIcons[index];
          if (element.key === item.status) {
            icon = element;
          }
        }
        const name: any = icon.icon;
        const lookup: IconLookup = { prefix: "fas", iconName: name };
        return typeof item.status !== "undefined" ? (
          <>
            {" "}
            <FontAwesomeIcon
              style={{ color: `${icon.color}` }}
              width={15}
              icon={findIconDefinition(lookup)}
            />{" "}
          </> /*item.status.toString()*/
        ) : (
          ""
        );
      },
    },
  ];
  private handleFileModalClose = (
    dissmiss: boolean,
    info?: any,
    status?: number,
    file?: any
  ) => {
    this.setState({ showFileModal: dissmiss });

    if (typeof info !== "undefined" && info.Code === 0) {
      this.setState({ toasterErrorShow: true, toasterMessage: info });

      if (status == 9) {
        this.setState((prevState) => ({
          kickbackReports: prevState.kickbackReports.filter((x) => {
            return x !== (this.state.selectedFile as TKickbackReport);
          }),
        }));
      } else {
        this.setState((prevState) => ({
          kickbackReports: prevState.kickbackReports.map((x) => {
            if (x === file) {
              x.status = status;
              return x;
            } else {
              return x;
            }
          }),
        }));
      }
    } else if (typeof info !== "undefined" && info.Code === 4) {
      this.setState({ toasterErrorShow: true, toasterMessage: info });
    }
  };
  private handleToasterClose = (close: boolean) => {
    this.setState({ toasterErrorShow: close });
  };

  private isFilterActive = () => {
    const { filters } = this.state;
    const supplierActive = filters.selectedSuppliers.length > 0;
    const statusActive = filters.selectedStatuses.length > 0;
    const reportDateBeginActive = filters.reportDateBegin != null;
    const reportDateEndActive = filters.reportDateEnd != null;
    const periodBeginActive = filters.periodBegin != null;
    const periodEndActive = filters.periodEnd != null;
    return (
      statusActive ||
      supplierActive ||
      reportDateBeginActive ||
      reportDateEndActive ||
      periodBeginActive ||
      periodEndActive
    );
  };

  private onUpdateFilter = () => {
    const { filters, searchText } = this.state;
    const isFilterActive = this.isFilterActive();
    const nullReports = this.state.kickbackReports.filter((report) => {
      const supplier = this.supplierDictionary[report.supplierOrgId];
      const onSearchText =
        searchText.length > 0
          ? `${report.supplierOrgId} - ${supplier?.supplierName}`
              .toLowerCase()
              .includes(searchText.toLocaleLowerCase()) ||
            report.period.includes(searchText.toLocaleLowerCase()) ||
            report.reporter
              .toLocaleLowerCase()
              .includes(searchText.toLocaleLowerCase())
          : true;
      const onSupplier =
        filters.selectedSuppliers.length > 0
          ? filters.selectedSuppliers.includes(report.supplierOrgId)
          : true;
      const onStatus =
        filters.selectedStatuses.length > 0
          ? filters.selectedStatuses.includes(report.status?.toString() || "")
          : true;
      const onReportDateBegin = filters.reportDateBegin != null ?
        report.reportDate.getTime() >= filters.reportDateBegin.getTime()
        : true;
      const onReportDateEnd = filters.reportDateEnd != null ?
        report.reportDate.getTime() <= filters.reportDateEnd.getTime()
        : true;
      const onPeriodBegin = filters.periodBegin != null ?
        report.period >= filters.periodBegin.toLocaleDateString("sv-SE").substring(0,7)
        : true;
      const onPeriodEnd = filters.periodEnd != null ?
        report.period <= filters.periodEnd.toLocaleDateString("sv-SE").substring(0,7)
        : true;

      return (
        onReportDateBegin &&
        onReportDateEnd &&
        onSupplier &&
        onPeriodBegin &&
        onPeriodEnd &&
        onStatus &&
        onSearchText
      );
    });
    this.setState({
      isFilterActive,
      filteredReports: nullReports,
    });
  };

  render() {
    const { error, filters } = this.state;

    if (error !== undefined) {
      return <div>{error.message}</div>;
    }
    return (
      <Stack gap={4}>
        <div>
          <h2>{this.props.t("kickbackList.title")}</h2>
          {this.props.t("kickbackList.description")?.length > 0 && (
            <p>{this.props.t("kickbackList.description")}</p>
          )}
        </div>
        {this.state.isLoading && <Spinner />}
        {!this.state.isLoading && (
          <>
            <div>
              <Stack
                direction="horizontal"
                gap={3}
                style={{ flexWrap: "wrap" }}
              >
                <SearchBar
                  value={this.state.searchText}
                  onChange={(newVal) => {
                    this.setState({ searchText: newVal }, () =>
                      this.onUpdateFilter()
                    );
                  }}
                  key={"searchKickback"}
                  placeholder={this.props
                    .t("kickbackList.searchPlaceholder")
                    .toString()}
                />
                <Button
                  className="button-cancel"
                  onClick={() => {
                    this.setState({ showFilter: !this.state.showFilter });
                  }}
                >
                  <FontAwesomeIcon
                    icon={
                      this.state.isFilterActive ? faFilterCircleXmark : faFilter
                    }
                  />
                </Button>
              </Stack>
              <ReportsFilter
                title={this.props.t("kickbackList.filterTitle").toString()}
                periodBegin={filters.periodBegin}
                setPeriodBegin={(date) =>
                  this.setState(
                    { filters: { ...filters, periodBegin: date } },
                    () => this.onUpdateFilter()
                  )
                }
                periodEnd={filters.periodEnd}
                setPeriodEnd={(date) =>
                  this.setState(
                    { filters: { ...filters, periodEnd: date } },
                    () => this.onUpdateFilter()
                  )
                }
                reportDateBegin={filters.reportDateBegin}
                setReportDateBegin={(date) =>
                  this.setState(
                    { filters: { ...filters, reportDateBegin: date } },
                    () => this.onUpdateFilter()
                  )
                }
                reportDateEnd={filters.reportDateEnd}
                setReportDateEnd={(date) =>
                  this.setState(
                    { filters: { ...filters, reportDateEnd: date } },
                    () => this.onUpdateFilter()
                  )
                }
                allSuppliers={this.state.suppliers}
                selectedSuppliers={filters.selectedSuppliers}
                setSelectedSupplier={(supplierOrgs) =>
                  this.setState(
                    {
                      filters: { ...filters, selectedSuppliers: supplierOrgs },
                    },
                    () => this.onUpdateFilter()
                  )
                }
                allStatuses={AppSettings.kickbackReportsStatusIcons.map(
                  (s) => ({
                    key: s.key.toString(),
                    text: this.props.t(`kickback.status.${s.key}`),
                  })
                )}
                selectedStatuses={filters.selectedStatuses}
                setSelectedStatuses={(selected) =>
                  this.setState(
                    { filters: { ...filters, selectedStatuses: selected } },
                    () => this.onUpdateFilter()
                  )
                }
                isOpen={this.state.showFilter}
              />
            </div>

            <div style={{ maxHeight: "60vh" }}>
              <Datalist
                items={this.state.filteredReports}
                uniqueKey="reportId"
                columns={this.state.kickbackColumns}
                noItemsText={this.props.t("kickback.table.empty")}
                initialSortKey="reportDate"
                descendingSort={false}
                setColumns={(cols) => {
                  this.setState({ kickbackColumns: cols });
                }}
                onClick={async (item, i) => {
                  if (!DataService.Kickback.IsFileReport(item))
                    this.setState({
                      selectedReportId: item.reportId,
                      selectedKickbackPeriod: item.period,
                      supplierOrgId: item.supplierOrgId,
                      type:item.type
                    });
                  else {
                    this.setState({
                      loadingIndexes: this.state.loadingIndexes.concat(i),
                    });
                    this.setState({
                      showFileModal: true,
                      selectedFile: item,
                      selectedFileRowIndex: i,
                    });

                    // await DataService.Kickback.DownloadFile(item.fileName as string, item.supplierOrgId);
                    this.setState({
                      loadingIndexes: this.state.loadingIndexes.filter(
                        (n) => n != i
                      ),
                    });
                  }
                }}
              />
            </div>
            {this.state.selectedReportId != undefined && (
              <KickbackReportModal
                kickbackReportId={this.state.selectedReportId}
                onDismiss={() => {
                  this.setState({ selectedReportId: undefined });
                }}
                handleUpdatedStatusSaveSuccess={
                  this.handleUpdatedStatusSaveSuccess
                }
                selectedPeriod={this.state.selectedKickbackPeriod}
                supplier={this.state.supplierOrgId}
                type={this.state.type}
              />
            )}
            {this.state.showFileModal && (
              <KickBackReportFileModal
                data={this.state.selectedFile}
                handleClose={this.handleFileModalClose}
                supplier={this.state.suppliers}
              />
            )}
            {this.state.toasterErrorShow && (
              <ToasterMsg
                show={this.state.toasterErrorShow}
                handleToastClose={this.handleToasterClose}
                message={this.state.toasterMessage}
                autoClose={true}
                closeDelayMs={2000}
                //customIcon="hashtag"
              />
            )}
          </>
        )}
      </Stack>
    );
  }
}

export default withTranslation()(KickbackListPage);
