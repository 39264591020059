import Urls from "../../lib/Urls";
import { Code, ImpersonationKEY, ReturnFetchObj, apiFetch, kcFetch } from "../../lib/utils";
import { t } from 'i18next'
import { TSupplier } from "./Suppliers";
import UserService from "../AuthService";

export interface TUser {
  id:string;
  SupplierGroups?:TSupplier[];
  firstName:string;
  lastName:string;
  enabled:boolean;
  created:Date;
  email:string;
  isSinfraAccount?:boolean;
  roles?:{
    id:string;
    name:string;
  }[],
  attributes:{
    locale: string;
    reporting_contracts:string[];
    departments:number[];
  }
}

export interface TUserValidation {
  SupplierGroups:string;
  firstName:string;
  lastName:string;
  email:string;
}

export type NewUser = Required<Pick<TUser, "firstName" | "lastName" | "email" | "SupplierGroups" | "isSinfraAccount" | "roles" | "enabled" | "attributes">>

interface UserBody {
  email?:string;
  enabled?:boolean;
  firstName?:string;
  lastName?:string;
  attributes?: {
    locale:string;
  }
}

const Get = async () : Promise<TUser[]> => {
  const response = await kcFetch(Urls.KcUsers+"?max=10000");
  const json : any[] = await response.json();
  return json.map(user => {
    return {
      id: user.id,
      created: new Date(user.createdTimestamp),
      enabled: user.enabled,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      attributes: {
        ...user.attributes,
        reporting_contracts: user.attributes?.reporting_contracts ?? [],
        departments: user.attributes?.departments ?? []
      }
    }
  });
}

const GetById = async (id:string) : Promise<TUser> => {
  const response = await kcFetch(Urls.KcUsers+"/"+id)
  const json = await response.json();
  return {
    id: json.id,
    created: new Date(json.createdTimestamp),
    enabled: json.enabled,
    firstName: json.firstName,
    lastName: json.lastName,
    email: json.email,
    attributes: {
      ...json.attributes,
      reporting_contracts: json.attributes?.reporting_contracts ?? [],
      departments: json.attributes?.departments ?? []
    }
  }
}

const GetByEmail = async (email:string) : Promise<TUser> => {
  const response = await kcFetch(Urls.KcUsers+"?exact=true&email="+email);
  const json : any[] = await response.json();
  const user = json[0];
  return user;
}

const Put = async (id:string, body:UserBody) => {
  
  try {
    const response = await apiFetch(Urls.User+"/"+id, "PUT", JSON.stringify(body), {"Content-Type": "application/json"});
    return ReturnFetchObj(t('api.users.putSuccess'), Code.OK, {id});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.users.putFail'), Code.UPDATE_ERR, {id});
  }
}

const SendActionEmail = async (id:string, actions:string[]) => {
  try {
    const response = await kcFetch(Urls.UserEmailAction.replace("{id}", id), "PUT", JSON.stringify(actions), {"Content-Type": "application/json"});
    return ReturnFetchObj(t('api.users.actionMailSuccess'), Code.OK, {id});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.users.actionMailFail'), Code.UPDATE_ERR, {id});
  }
}

const Post = async (user: TUser) => {
  try {
    const supplier = user.SupplierGroups?.find(s => s);
    const supplierNr = supplier ? supplier.supplierOrgNr : "";
    const response = await kcFetch(Urls.KcUsers, "POST", JSON.stringify({
      groups: user.isSinfraAccount ? [AppSettings.sinfraBaseGroupName] : [supplierNr],
      firstName:user.firstName,
      lastName: user.lastName,
      email: user.email,
      enabled: true,
      attributes: user.attributes,
      requiredActions: ["UPDATE_PASSWORD"]
    }), {"Content-Type": "application/json"});
    return ReturnFetchObj(t('api.users.postSuccess'), Code.OK, {user});
  } catch (error:any) {
    const statusText = error.status == 409 ?
      t('api.users.postDuplicate')
      :
      t('api.users.postFail')
    console.error(error);
    return ReturnFetchObj(statusText, Code.ADD_ERR, {user});
  }
}

const Impersonate = async (userId : string) => {
  try {
    const body = `client_id=frontend-client&grant_type=urn:ietf:params:oauth:grant-type:token-exchange&subject_token=${UserService.getToken()}&requested_token_type=urn:ietf:params:oauth:token-type:refresh_token&requested_subject=${userId}`;
    const tokenRes = await fetch(window.AppSettings.KCTokenUrl, 
      {
        method: "POST",
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        },
        body: body
      });
      const tokenInfo = await tokenRes.json();
      window.sessionStorage.setItem(ImpersonationKEY, JSON.stringify(tokenInfo));
      return ReturnFetchObj(t('api.users.impersonateSuccess'), Code.OK, {userId});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.users.impersonateFail'), Code.NOT_FOUND, {userId});
  }

}

const RefreshAccessToken = async (refreshToken: string) => {
  const tokenRes = await fetch(window.AppSettings.KCTokenUrl, {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded"
    },
    body: `client_id=frontend-client&grant_type=refresh_token&refresh_token=${refreshToken}`
  });
  if(!tokenRes.ok){
    window.sessionStorage.removeItem(ImpersonationKEY);
    window.location.reload();
  }
    
  const refreshTokenInfo = await tokenRes.json();
  return refreshTokenInfo;
  // window.sessionStorage.setItem(ImpersonationKEY, JSON.stringify(tokenInfo));
}

const RefreshImpersonation = async () => {
  const tokenInfoString = window.sessionStorage.getItem(ImpersonationKEY);
  if(tokenInfoString != null){
    const tokenInfo = JSON.parse(tokenInfoString);
    const newTokenInfo = await RefreshAccessToken(tokenInfo.refresh_token);
    window.sessionStorage.setItem(ImpersonationKEY, JSON.stringify(newTokenInfo));
  }
    

}


export {
  Get,
  GetById,
  Put,
  SendActionEmail,
  Post,
  GetByEmail,
  Impersonate,
  RefreshAccessToken,
  RefreshImpersonation
}