import { faCircleExclamation, faCircleInfo, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Form, Overlay, Tooltip } from 'react-bootstrap';

export interface IAppProps {
}



export function TextInput (props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) {
  
  // const [show, setShow] = React.useState(false);
  // const target = React.useRef(null);
  return (
    // <div className='textInputWrapper' aria-invalid={props['aria-invalid']}>
      <input 
        {...props}
      />
    //   {
    //     props['aria-invalid'] &&
    //     <div tabIndex={0} onClick={(ev) => {setShow(!show);}} onBlur={(ev) => setShow(false)}>
    //       <FontAwesomeIcon ref={target} icon={faExclamation} />
    //     </div>
    //   }
    //   <Overlay  target={target.current} show={show} placement="bottom">
    //     {(tooltipProps) => (
    //       <Tooltip id="overlay-example" {...tooltipProps} >
    //         {props['aria-errormessage']}
    //       </Tooltip>
    //     )}
    //   </Overlay>
    // </div>
  );
}
