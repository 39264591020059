import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Button, Form, Spinner, Stack } from 'react-bootstrap'
import { TUser } from '../../../services/API/Users'
import { Department, DepartmentDictionary } from '../../../services/API/Departments';
import { StyledModal } from '../../modal/StyledModal';
import { t } from 'i18next';



interface UserDepartmentListProps{
    user:TUser;
    departments:DepartmentDictionary;
    loading:boolean;
    updateUser:(val:ActiveStateOfDepartments) => void;
    userAssignedDepartments:number[];
}
export type ActiveStateOfDepartments = {
  [key:string]:string;
}
export const UserDepartmentList = ({user,departments,loading,updateUser,userAssignedDepartments}:UserDepartmentListProps) => {
    const [showModal,setShowModal] = React.useState<boolean>(false)
    const [selectedDepartments,setSelectedDepartment] = React.useState<Department[]>([])
    const [currentDepartmen,setCurrentDepartment] = React.useState<DepartmentKeyValuePair>()
    const [activeStateOfDepartments,setActiveStateOfDepartments] = React.useState<ActiveStateOfDepartments>({})
    const buff:string[] = user.attributes.departments.map(x => x.toString())

  React.useEffect(() =>{
    const currentDepartmentStateOfUser:{[key:string]:string} = {}
   Object.entries(departments).forEach(([key,value]) => {
    const tempRes =  userAssignedDepartments.filter(x => value.values.filter(q => q.id == x).length > 0)
      currentDepartmentStateOfUser[key] = tempRes.length == 0 ? '-1' : tempRes[0].toString()
   })
   setActiveStateOfDepartments(currentDepartmentStateOfUser)
  },[departments,userAssignedDepartments])
    if(Object.keys(departments).length == 0 && !loading ){
      return <></>
    }
    else if(Object.keys(departments).length > 0){
      return <>
      <table className='datalistTable' style={{minWidth:'100%',borderRadius:'5px'}}>
     <thead className='datalistTableHead' style={{borderRadius:'5px'}}>
       <tr>
       <th>{t('general.supplier')}</th>
       <th>{t('departmentList.title')}</th>
       <th></th>
       </tr>
     </thead>
    <tbody>
      {Object.entries(departments).map(([key,value]) => {
        return <tr className="datalistTableRow datalistTableRowHover">
          <td className='datalistTableCell' style={{width:100}}>
            {user.SupplierGroups?.find(x => x.supplierOrgNr == key)?.supplierName}
          </td>
          <td className='datalistTableCell' style={{width:'90%'}}>
           {value.values?.find(x => buff.includes(x.id.toString()))?.department} 
          </td>
          <td className='datalistTableCell' style={{width:30,padding:0}}>
          <Button 
             className='tool-button'
             onClick={() => {
               setSelectedDepartment(value.values);
               const searchedValue = value.values?.find(x => buff.includes(x.id.toString())) as Department
               const departmentBuff:DepartmentKeyValuePair = {
                supplierOrgId:key,
                department:searchedValue?.department.toString(),
                departmentId:searchedValue?.id.toString()
               }
               setCurrentDepartment(departmentBuff)
               setShowModal(true)
             }}
           >
             <FontAwesomeIcon  icon={"edit"}/>
           </Button>
          </td>
        </tr>
      })}
    </tbody>
  </table>
   
{showModal && 
    <UserDepartmentModal  deps={departments}userAssignedDepartments={userAssignedDepartments} activeState={activeStateOfDepartments} updateUser={updateUser} currentDepartment={currentDepartmen} departments={selectedDepartments} onDissmiss={(val) => setShowModal(val)}/>
}
      </>
    }
    else{
      return <Spinner/>
    }

}
interface UserDepartmentModalProps{
    departments:Department[];
    onDissmiss:(val:boolean)=>void;
    currentDepartment:DepartmentKeyValuePair | undefined;
    updateUser:(val:ActiveStateOfDepartments) => void;
    activeState:ActiveStateOfDepartments;
    userAssignedDepartments:number[];
    deps:DepartmentDictionary
    
}
const UserDepartmentModal = ({deps,departments,onDissmiss,currentDepartment,updateUser,activeState,userAssignedDepartments}:UserDepartmentModalProps) => {
   const {getCurrentValue,onSelect,onSave} =  useDepartmentHook(deps,userAssignedDepartments,activeState,updateUser,currentDepartment)
    return <StyledModal 
    title={t('departmentList.modal.updateTitle').toString()} 
    onDismiss={() => { onDissmiss(false) }}
    body={
      <Stack>
        <Form.Group>
          <Form.Label>{t("departmentList.modal.departmentname")}</Form.Label>
          <Form.Control
          as='select' 
          value={getCurrentValue()}
          onChange={onSelect}
          >
            {<option id={`${ departments[0].supplierOrgId},${t('departmentList.noDepartments')},-1`}>{t('departmentList.noDepartments')}</option>}
            {departments.map(x => <option id={`${ x.supplierOrgId},${x.department},${x.id.toString()}`} >{x.department}</option>)}

          </Form.Control>
        </Form.Group>
      </Stack>
    }
    footer={
      <Stack direction='horizontal' style={{gap:10}}>
        <Button onClick={() => { 
         onSave()
         onDissmiss(false)}}>Spara</Button>
        <Button 
          className='button-cancel'
          onClick={() => {onDissmiss(false)}}
        >{t("general.cancel")}</Button>
      </Stack>
    }
    />
    
}
type DepartmentKeyValuePair ={
  supplierOrgId:string;
  department:string;
  departmentId:string;
}
type DepartmentKeyArray = Record<(keyof DepartmentKeyValuePair),string>
export function useDepartmentHook(departments:DepartmentDictionary,userAssignedDepartments:number[],activeState:ActiveStateOfDepartments,updateUser:Function,currentDepartment?:DepartmentKeyValuePair){
    const [arrOfKeys,setArrOfKeys] = React.useState<(keyof DepartmentKeyValuePair)[]>([])
    const [selectedDepartment,setSelectedDepartment] = React.useState<DepartmentKeyValuePair|undefined>(currentDepartment)
    const [activeStateOfDepartments,setActiveStateOfDepartments] = React.useState<ActiveStateOfDepartments>({})
    React.useEffect(() =>{
      const currentDepartmentStateOfUser:{[key:string]:string} = {}
     Object.entries(departments).forEach(([key,value]) => {
      const tempRes =  userAssignedDepartments.filter(x => value.values.filter(q => q.id == x).length > 0)
        currentDepartmentStateOfUser[key] = tempRes.length == 0 ? '-1' : tempRes[0].toString()
     })
     setActiveStateOfDepartments(currentDepartmentStateOfUser)
    },[departments,userAssignedDepartments])
    React.useEffect(() => {
      const properties:DepartmentKeyArray = {supplierOrgId:'',department:'',departmentId:''}
      const arrOfKeys = Object.keys(properties) as (keyof DepartmentKeyArray)[]
      setArrOfKeys(arrOfKeys)
    },[])

    const getCurrentValue = () =>{
        return selectedDepartment?.department
    }
    const onSelect = (value?:React.BaseSyntheticEvent) => {
      const buff:HTMLCollection = value?.currentTarget.selectedOptions
      const res =  buff[0].id.split(',')
      const finalobj:DepartmentKeyValuePair = {supplierOrgId:'',department:'',departmentId:''}
     for (let index = 0; index < res.length; index++) {
      const element = res[index];
      finalobj[arrOfKeys[index]] = element
     }
      const buff2 = {...activeState}
      buff2[`${finalobj.supplierOrgId}`] = finalobj.departmentId
      setActiveStateOfDepartments(buff2)
      setSelectedDepartment(finalobj)
    }
    const onSave = () => {
        updateUser(activeStateOfDepartments)
    }
    
    return{
        getCurrentValue,
        onSelect,
        onSave
    }
}