import * as React from 'react';
import { FieldProperty } from '../../services/API/InvoiceReports';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FloatingLabel, Overlay, Tooltip } from 'react-bootstrap';
import { TextInput } from './TextInput';
import { ChoiceInput } from './ChoiceInput';

export interface IFieldProps {
  property: FieldProperty;
  onChange(newVal:string):void;
  label?:string;
}

interface IFieldState {
  show: boolean;
}

export default class Field extends React.Component<IFieldProps, IFieldState> {

  /**
   *
   */
  constructor(props: IFieldProps) {
    super(props);
    this.state = {
      show: false
    }
  }

  private target : SVGSVGElement | null = null;

  private setShow = (newVal : boolean) => { this.setState({ show: newVal }) };

  private renderField = () => {
    
    const { property } = this.props;
    switch(property.type){
      case "Text":
        return <TextInput 
          // aria-invalid={!property.isValid}
          aria-invalid={property.validation == "Error"}
          aria-errormessage={property.message}
          value={property.value} 
          placeholder={property.format}
          type={property.type}
          onChange={e => this.props.onChange(e.target.value)} 
        />
      case "Choice":
        return <ChoiceInput 
          id={property.key}
          options={property.options?.map(str => str) as string[]}
          selected={[property.value]}
          placeholder={property.format}
          onChange={(selected) => {
            if(selected.length > 0)
              this.props.onChange(selected.toString());
          }}
          onInputChange={(text) => {this.props.onChange(text);}}
        />
    }
      
  }

  private getValidationClass = () => {
    if(this.props.property.validation == "Error")
      return "fieldWrapper-error";
    if(this.props.property.validation == "Warning")
      return "fieldWrapper-warn";
    return "";
  }

  public render() {
    return (
      <div className={`fieldWrapper ${this.getValidationClass()}`}>
        {
          this.props.label &&
          <FloatingLabel label={this.props.label}/>
        }
        {
          this.renderField()
        }
        {
          this.props.property.validation != "Good" &&
          <div tabIndex={0} onClick={(ev) => {this.setShow(!this.state.show);}} onBlur={(ev) => this.setShow(false)}>
            <FontAwesomeIcon ref={(ref) => {this.target = ref}} icon={faExclamation} />
          </div>
        }
        <Overlay  target={this.target} show={this.state.show} placement="bottom">
          {(tooltipProps) => (
            <Tooltip id="overlay-example" {...tooltipProps} >
              {this.props.property.message}
            </Tooltip>
          )}
        </Overlay>
      </div>
    );
  }
}
