/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-useless-constructor */
import * as React from "react";
import { InvoiceForPrint } from "../../services/API/Invoices";
import logo from "./Printloggo.png";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";
import { NumberFormatter } from "../../lib/utils";
import i18n from "../../i18n";

interface IPdfFormatter {
  data: InvoiceForPrint[] | any[];
  options: {
    from: string | undefined;
    to: string | undefined;
    contractIds: string[] 
  };
  formattedDataForbarChart:any[];
  rows:any[]
  
}
interface IPdfFormatterstate {
  loading: boolean;
  headers: any;
}
class PdfFormatter extends React.Component<IPdfFormatter, IPdfFormatterstate> {
  private date = new Date()
  constructor(props: IPdfFormatter) {
    super(props);
    this.state = ({loading:true,headers:[]})
  }
   componentDidMount() {
    this.setState({ loading: true });
    
 if( this.props.data.length > 0 ){
  const headers =  this.buildHeaders();
    this.setState({
      loading: false,
      headers: headers,
    });
 }

    
   
  }
    
  private  buildHeaders(): any {
   const r = Object.keys(this.props.data[0]).map((x) => {
    const width = 100 / 7
    if(x !== 'supplierOrgId' && x !== 'levName' && x !== 'priceIncMoms' && x !== 'customerName')  
    return <th className='printTableTh' style={{border:'1px solid #ddd',width:`${width}px`}}>{i18n.t(`print.${x}`)}</th>;
    });
    return <tr style={{width:'100%'}}>{r}</tr>
  }
  
 public render(){
  
  return (      
      <div>
       
        {this.state?.loading === false && (
          <div style={{}}>
             <div className='pageBreak'/>

            <div style={{marginBottom:'35px'}}>
              <img src={logo} width='200px' height='100px'  alt="logo" />
              <p style={{paddingTop:'10px'}}>{this.props.data[0].levName}</p>
            </div>

            <div
              style={{
                lineHeight: "normal",
                wordSpacing: "2px",
                textAlign: "left",
              }}
            >
              <table style={{width:'800px',border:'1px solid #ddd',marginBottom:'15px'}}>
                <thead >
                  <tr>
                    <td style={{width:'12%'}}>{i18n.t("print.created")}</td>
                    <td style={{width:'12%'}}>{i18n.t("print.supplier")}</td>
                    <td style={{width:'12%'}}>{i18n.t("print.from")}</td>
                    <td style={{width:'12%'}}>{i18n.t("print.to")}</td>
                    <td style={{width:'52%'}}>{i18n.t("print.contractId")}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className='tableRow'>
                    <td>{ this.date.toLocaleDateString()}</td>
                    <td>{this.props.data[0].supplierOrgId}</td>
                    <td>{this.props.options.from}</td>
                    <td>{this.props.options.to}</td>
                    <td>{this.props.options.contractIds.toString()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
            >
              <BarChart
                width={800}
                height={300}
                data={this.props.formattedDataForbarChart}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis
                width={50}               
                dataKey="month"
                tickFormatter={(val: any, index: number) => {
                  return NumberFormatter(val, 2);
                }}
              />                           
                <Bar dataKey="month" fill="#00cdff" />                
              </BarChart>
            </div>           
            <table style={{width:'800px',border:'1px solid #ddd',marginTop:'25px',marginBottom:'25px'}}>           
             <thead >
                {this.state.headers}
                </thead>           
                <tbody className="tableRow" style={{border:'1px solid #ddd'}}>
                {this.props.rows.map((x: any) => {
                  return x
                })}            
             </tbody>          
            </table> 
            </div>
        )}
       
      </div>
    );
  }
}
export default PdfFormatter;
