import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DataService } from '../services/DataService';
import Datalist, { FooterOptions, ListColumn } from '../components/Datalist/Datalist';
import { Button, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faCheck, faFilter, faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { SearchBar } from '../components/SearchBar/SearchBar';
import { Contract } from '../services/API/Contracts';
import ContractFilter from '../components/ContractFilter/ContractFilter';
import { TSupplier } from '../services/API/Suppliers';
import { IsUserSinfra } from '../lib/utils';

interface Props extends WithTranslation {

}

interface State {
  isLoading: boolean;
  contracts: Contract[];
  columns: ListColumn<Contract>[];
  suppliers: TSupplier[];
  searchText:string;
  showFilterModal: boolean;
  filters: {
    supplierOrgNr: string;
    category:string;
    isArchived:boolean;
    startDateBegin:Date|null;
    startDateEnd:Date|null;
    terminateDateBegin:Date|null;
    terminateDateEnd:Date|null;
    startReportDateBegin:Date|null;
    startReportDateEnd:Date|null;
    endReportDateBegin:Date|null;
    endReportDateEnd:Date|null;
  }
}

class ContractListPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    
    if(!IsUserSinfra())
      window.location.href = "/"

    this.state = {
      isLoading: true,
      contracts:[],
      suppliers: [],
      columns: this.columns,
      searchText: "",
      showFilterModal: false,
      filters: {
        supplierOrgNr: "",
        category: "",
        isArchived: false,
        startDateBegin: null,
        startDateEnd: null,
        terminateDateBegin: null,
        terminateDateEnd: null,
        startReportDateBegin: null,
        startReportDateEnd: null,
        endReportDateBegin: null,
        endReportDateEnd: null
      }
    }

  }


  async componentDidMount(): Promise<void> {
    const [contracts, suppliers] = await Promise.all([
      DataService.Contracts.Get(),
      DataService.Suppliers.Get(true)
    ])
    this.setState({
      isLoading: false,
      contracts,
      suppliers
    });
  }

  private columns : ListColumn<Contract>[] = [
    {
      name: this.props.t("contractListPage.list.contractId"),
      fieldName: "contractId",
      key: "contractId",
      minWidth: 50,
      isActive: true,
      sorting: true,
      footer: FooterOptions.COUNT
    },
    {
      name: this.props.t("contractListPage.list.type"),
      fieldName: "contractType",
      key: "contractType",
      minWidth: 100,
      isActive: true,
      sorting: true
    },
    {
      name: this.props.t("contractListPage.list.name"),
      fieldName: "contractName",
      key: "contractName",
      minWidth: 100,
      maxWidth: 300,
      isActive: true,
      sorting: true,
      onRender(item, col) {
        return <div style={{textOverflow: "ellipsis", overflow: "hidden"}} title={item.contractName}>{item.contractName}</div>
      }
    },
    {
      name: this.props.t("contractListPage.list.supplierOrgNr"),
      fieldName: "supplierOrgNr",
      key: "supplierOrgNr",
      minWidth: 100,
      isActive: true,
      sorting: true
    },
    {
      name: this.props.t("contractListPage.list.supplierName"),
      fieldName: "supplierName",
      key: "supplierName",
      minWidth: 100,
      maxWidth: 300,
      isActive: true,
      sorting: true,
      onRender(item, col) {
        return <div style={{textOverflow: "ellipsis", overflow: "hidden"}} title={item.supplierName}>{item.supplierName}</div>
      }
    },
    {
      name: this.props.t("contractListPage.list.kickbackProcent"),
      fieldName: "kickbackProcent",
      key: "kickbackProcent",
      minWidth: 100,
      isActive: true,
      sorting: true,
      onRender(item, col) {
        return item.kickbackProcent ? `${item.kickbackProcent}%` : ""
      }
    },
    {
      name: this.props.t("contractListPage.list.startDate"),
      fieldName: "startDate",
      key: "startDate",
      minWidth: 100,
      isActive: true,
      sorting: true,
      onRender(item, col) {
        return item.startDate?.toLocaleDateString() ?? ""
      }
    },
    {
      name: this.props.t("contractListPage.list.reportingFromDate"),
      fieldName: "reportingFromDate",
      key: "reportingFromDate",
      minWidth: 100,
      isActive: true,
      sorting: true,
      onRender(item, col) {
        return item.reportingFromDate?.toLocaleDateString() ?? ""
      }
    },
    {
      name: this.props.t("contractListPage.list.reportingToDate"),
      fieldName: "reportingToDate",
      key: "reportingToDate",
      minWidth: 100,
      isActive: true,
      sorting: true,
      onRender(item, col) {
        return item.reportingToDate?.toLocaleDateString() ?? ""
      }
    },
    {
      name: this.props.t("contractListPage.list.terminationDate"),
      fieldName: "terminationDate",
      key: "terminationDate",
      minWidth: 100,
      isActive: true,
      sorting: true,
      onRender(item, col) {
        return item.terminationDate?.toLocaleDateString() ?? ""
      }
    },
    {
      name: this.props.t("general.archived"),
      fieldName: "archived",
      key: "archived",
      minWidth: 20,
      isActive: true,
      sorting: true,
      onRender: (item, col) => {
        return <div style={{textAlign: "center"}}>
          {
            item.archived &&
            <FontAwesomeIcon icon={faCheck} />
          }
        </div>
      }
    }
  ]

  private isFilterActive = () => {
    const categoryActive = this.state.filters.category != "";
    const supplierActive = this.state.filters.supplierOrgNr != "";
    const archivedActive = this.state.filters.isArchived == true;
    const startDateBeginActive = this.state.filters.startDateBegin != null;
    const startDateEndActive = this.state.filters.startDateEnd != null;
    const startReportDateBeginActive = this.state.filters.startReportDateBegin != null;
    const startReportDateEndActive = this.state.filters.startReportDateEnd != null;
    const terminateDateBeginActive = this.state.filters.terminateDateBegin != null;
    const terminateDateEndActive = this.state.filters.terminateDateEnd != null;
    const endReportDateBeginActive = this.state.filters.endReportDateBegin != null;
    const endReportDateEndActive = this.state.filters.endReportDateEnd != null;
    return categoryActive || supplierActive || archivedActive || startDateBeginActive
      || startDateEndActive || startReportDateBeginActive || startReportDateEndActive || terminateDateBeginActive
      || terminateDateEndActive || endReportDateBeginActive || endReportDateEndActive;
  };

  render() {
    
    const { searchText, filters } = this.state;
    const contracts = this.state.contracts.filter(contract => {
      const onSearchText = searchText.length > 0 ?
        (contract.contractId.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) 
        || contract.supplierName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) 
        || contract.supplierOrgNr.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
        : true;
      const onSupplier = filters.supplierOrgNr.length > 0 ?
        contract.supplierOrgNr.toLocaleLowerCase() == filters.supplierOrgNr.toLocaleLowerCase()
        : true;
      const onCategory = filters.category.length > 0 ?
        contract.contractType.toLocaleLowerCase() == filters.category.toLocaleLowerCase()
        : true;
      const onArchived = contract.archived == filters.isArchived;
      const contractStart = contract.startDate?.getTime() ?? null;
      const onStartBegin = filters.startDateBegin != null && contractStart != null ?
        contractStart >= filters.startDateBegin.getTime()
        : true;
      const onStartEnd = filters.startDateEnd != null && contractStart != null ?
        contractStart <= filters.startDateEnd.getTime()
        : true;
      const contractTermination = contract.terminationDate?.getTime() ?? null;
      const onTerminateBegin = filters.terminateDateBegin != null && contractTermination != null ?
        contractTermination >= filters.terminateDateBegin.getTime()
        : true;
      const onTerminateEnd = filters.terminateDateEnd != null && contractTermination != null ?
        contractTermination <= filters.terminateDateEnd.getTime()
        : true;
      const contractReportStart = contract.reportingFromDate?.getTime() ?? null;
      const onReportStartBegin = filters.startReportDateBegin != null && contractReportStart != null ?
        contractReportStart >= filters.startReportDateBegin.getTime()
        : true;
      const onReportStartEnd = filters.startReportDateEnd != null && contractReportStart != null ?
        contractReportStart <= filters.startReportDateEnd.getTime()
        : true;
      const contractReportEnd = contract.reportingToDate?.getTime() ?? null;
      const onReportEndBegin = filters.endReportDateBegin != null && contractReportEnd != null ?
        contractReportEnd >= filters.endReportDateBegin.getTime()
        : true;
      const onReportEndEnd = filters.endReportDateEnd != null ?
        contractReportEnd && contractReportEnd <= filters.endReportDateEnd.getTime()
        : true;
          
      return onSearchText && 
        onSupplier && 
        onCategory && 
        onArchived && 
        onStartBegin && onStartEnd &&
        onTerminateBegin && onTerminateEnd &&
        onReportStartBegin && onReportStartEnd &&
        onReportEndBegin && onReportEndEnd
    });
    const isFilterActive = this.isFilterActive();
    return (
      <Stack gap={4}>
        <div>
          <h2>{this.props.t('contractListPage.title')}</h2>
        </div>
        <div>
          <div className='list-toolbar' style={{marginBottom: 12}}>
            {/* <div className='list-toolbar'> */}
              <SearchBar
                onChange={(newVal) => { this.setState({ searchText: newVal }) }}
                value={searchText}
                placeholder={this.props.t("contractListPage.searchPlaceholder").toString()}
              />
              <Button className='button-cancel' onClick={() => {this.setState({showFilterModal:!this.state.showFilterModal})}}>
                <FontAwesomeIcon icon={isFilterActive ? faFilterCircleXmark : faFilter} />
              </Button>
            {/* </div> */}
            <Button onClick={() => {window.location.href = "/editcontract"}}>
              <FontAwesomeIcon icon={faAdd} style={{marginRight:"4px"}}/>
              {this.props.t("general.addContract")}
            </Button>
          </div>
          <ContractFilter 
            isOpen={this.state.showFilterModal}
            suppliers={this.state.suppliers} 
            filterOptions={{
              supplier: {
                currentFilter: filters.supplierOrgNr,
                OnChange: (newVal) => {
                  this.setState({filters: {...filters, supplierOrgNr: newVal}})
                },
              },
              category: {
                currentFilter: filters.category,
                options: contracts.map(cont => cont.contractType).filter((cont, i, self) => self.indexOf(cont) == i),
                OnChange: (newVal) => {
                  this.setState({filters: {...filters, category: newVal}})
                }
              },
              startDateBegin: {
                currentFilter: filters.startDateBegin,
                OnChange: (date) => { this.setState({filters: {...filters, startDateBegin: date}}) }
              },
              startDateEnd: {
                currentFilter: filters.startDateEnd,
                OnChange: (date) => { this.setState({filters: {...filters, startDateEnd: date}}) }
              },
              startReportDateBegin: {
                currentFilter: filters.startReportDateBegin,
                OnChange: (date) => { this.setState({filters: {...filters, startReportDateBegin: date}}) }
              },
              startReportDateEnd: {
                currentFilter: filters.startReportDateEnd,
                OnChange: (date) => { this.setState({filters: {...filters, startReportDateEnd: date}}) }
              },
              endReportDateBegin: {
                currentFilter: filters.endReportDateBegin,
                OnChange: (date) => { this.setState({filters: {...filters, endReportDateBegin: date}}) }
              },
              endReportDateEnd: {
                currentFilter: filters.endReportDateEnd,
                OnChange: (date) => { this.setState({filters: {...filters, endReportDateEnd: date}}) }
              },
              terminateDateBegin: {
                currentFilter: filters.terminateDateBegin,
                OnChange: (date) => { this.setState({filters: {...filters, terminateDateBegin: date}}) }
              },
              terminateDateEnd: {
                currentFilter: filters.terminateDateEnd,
                OnChange: (date) => { this.setState({filters: {...filters, terminateDateEnd: date}}) }
              },
              archived: {
                currentFilter: filters.isArchived,
                OnChange: (newVal) => this.setState({ filters: { ...filters, isArchived: newVal} })
              }
            }}
          />
        </div>
        <div style={{height: "50vh"}}>
          <Datalist 
            uniqueKey='id'
            items={contracts} 
            columns={this.state.columns} 
            setColumns={(cols) => this.setState({columns: cols})} 
            noItemsText={this.props.t("contractListPage.noItemsText")} 
            initialSortKey='contractId'
            onClick={(contract) => { window.location.href = `/editcontract/${contract.id}` }}
            isLoading={this.state.isLoading}
          />
        </div>
      </Stack>
   );
  }
 
};

export default withTranslation()(ContractListPage);