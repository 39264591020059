import * as React from 'react';
import { Code, getActiveSupplier, getImpersonationUser } from '../../lib/utils';
import { DataService } from '../../services/DataService';
import { CompressedDepartmentReported, Department, DepartmentFunctionArgs } from '../../services/API/Departments';
import i18n from '../../i18n';

export interface ISupplierBarProps {
}

export default function SupplierBar (props: ISupplierBarProps) {
  const [supplier, setSupplier] = React.useState("");
  const {currentDepartment} = useCurrentDepartmentHook();
  
  React.useEffect(() => {
    const activeSupplier = getActiveSupplier();
    if(activeSupplier){
      DataService.Suppliers.GetByOrgNr(activeSupplier)
      .then(sup => {
        setSupplier(`${sup.supplierName} - ${sup.supplierOrgNr}`)
      })
    }
  }, []);

  return (
    <>
    {
      supplier &&
      <div className='supplier-bar'>{currentDepartment() && currentDepartment()?.department + ' - '}{supplier}</div>
    }
    </>
  );
}

export const useCurrentDepartmentHook = () => {
  const [department,setDepartment] = React.useState<Department>()
  const [reportedPeriods,setReportedPeriods] = React.useState<{[key:string]:CompressedDepartmentReported}>({})
  const supplier = getActiveSupplier()
  
  React.useEffect(() => {
    
    async function fetchDepartment(){
      try {
          const res = await DataService.Departments.GetUserAssignedDepartment();
        if(res.Content.response){
          setDepartment(res.Content.response)
          
        }
      } catch (error) {
        console.log(`Fetch failed..${error}`)
      }
    }
    fetchDepartment()
  },[supplier])
  React.useEffect(() => {
    if (!department) return;
    async function fetchMissedPeriods(){
      try {
        const da:DepartmentFunctionArgs = {reportCheck:{departmentId:department?.id,report:4}}
        const res = await DataService.Departments.CheckAlreadyReportedDepartment(da)
        setReportedPeriods(res)  
      } catch (error) {
        console.log(`Fetch failed..${error}`)
      }
      
    }
    fetchMissedPeriods()
  },[department])
 
  const getMissedPeriodsAsString = async () =>{
    const res = await getMissedPeriods(false)
    const missedText:{kickbackText:string,statisticText:string} = {kickbackText:'',statisticText:''}
    if (typeof res !== 'undefined' &&  Object.keys(res).length > 0){
      if(typeof res['kickbackReport'] !== 'undefined'){ 
       missedText.kickbackText += res['kickbackReport']
      }
      if(typeof res['statisticReport'] !== 'undefined'){
        missedText.statisticText += res['statisticReport']
      }
    }
    return missedText
  }
  const currentDepartment = (): Department | undefined => department;
  const getMissedPeriods = async (original:boolean) => {
    if(original == true ){
      return reportedPeriods
    }
    else{
      const returnResult:{[key:string]:string} = {} 
      Object.entries(reportedPeriods).forEach(([key,value]) => {
        if(value.kickbackReport){
          returnResult['kickbackReport'] = typeof returnResult['kickbackReport'] == 'undefined' ? new Date(key).toLocaleDateString(i18n.language,{month:'long',year:'numeric'}) : returnResult['kickbackReport'] +','+ new Date(key).toLocaleDateString(i18n.language,{month:'long',year:'numeric'})
        }
        if(value.statisticReport){
          returnResult['statisticReport'] = typeof returnResult['statisticReport'] == 'undefined' ? new Date(key).toLocaleDateString(i18n.language,{month:'long',year:'numeric'}) : returnResult['statisticReport'] +','+ new Date(key).toLocaleDateString(i18n.language,{month:'long',year:'numeric'})
        }
      })

     return returnResult
    } 
  }

  return{
    department,
    reportedPeriods,
    currentDepartment,
    getMissedPeriods,
    getMissedPeriodsAsString
  }
}