/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import * as React from "react";
import { withTranslation } from "react-i18next";
import { StyledModal } from "./StyledModal";
import {
  ContractDict,
  Period,
  ProjectInvoice
} from "../../pages/KickbackProjectReportPage";
import i18n from "../../i18n";

import /*Datalist,*/ { ListColumn } from "../Datalist/Datalist";
import StyledAccordion from "../Accordion/StyledAccordion";
import { Button, Col, Form, FormGroup, OverlayTrigger, Row, Spinner, Stack, Tooltip } from "react-bootstrap";
import { faExclamation, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { DataService } from "../../services/DataService";
//import { InvoiceData } from "../../services/API/Invoices";
import { FieldProperty, InvoiceFields } from "../../services/API/InvoiceReports";
import { Code, IsUserSinfra, ReadFriendlyNumber } from "../../lib/utils";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Department } from "../../services/API/Departments";
import { _helpers } from "../../lib/ProjectReportValidation";
//import { ProjectInputField } from "../fields/ProjectReportTypeAhead";
import { SupplierChanges } from "../Reports/SupplierProjectChangesRowRender";


export type ProjectReport = {
  id:number;
  reportId: string;
  contractId: string;
  projectName: string;
  invoiceReference: string;
  invoiceIdentifier: string;
  kickbackSum:number;
  sumExclMoms: number;
  sumExclMomsChange:number;
  sumExclMomsChangeField:JSX.Element;
  comment: string;
  period: string;
  reportDate: Date;
  reporter: string;
  supplierOrgId: string;
  status: number;
  projectEndDate: Date | string | null;
  customerId:string;
  commentRowChangeField: JSX.Element;
  commentRowChange:string;
  //kick:number;
};
export type ProjectReportPolished ={
  id:number;
  reportId: string;
  contractId: string;
  projectName: string;
  invoiceReference: string;
  invoiceIdentifier: string;
  kickbackSum:number;
  sumExclMoms:number;
  comment: string;
  period: string;
  reportDate: Date;
  reporter: string;
  supplierOrgId: string;
  status: number;
  projectEndDate: Date | string | null;
  customerId:string;
  commentRowChange:string;
}
type CheckBox = {
  [key: string]: boolean;
};
export type StatusObj = {
  errors:number;
  warnings:number;
  duplicates:number;
  totalSum:number;
  totalRows:number;
  comment:string;
}
export type KickbackChangeState ={
  [key:string]:KickbackChanged;
}
export type KickbackChanged = {
  sumTotal:number;
  changedSum:number;
  contractId:string;
  changesComment:string;
  totalKickbackReported:number;
  contractChanged:boolean;
}
type Test = Pick<KickbackChanged, 'changesComment' | 'changedSum'>
type BLa = Pick<ProjectReport,'commentRowChange' | 'sumExclMomsChange'> 
//type ChangeProps = Pick<ProjectReport,'commentRowChange'|'sumExclMomsChange'>
//type ChangeFields = Pick<ProjectReport,"commentRowChangeField"|"sumExclMomsChangeField">
interface SubmitKickbackProjectProps {
  submitHandler: (period: string, status: number, message: string,data?:any) => void;
  closeModal: () => void;
  //data: PeriodPerContract;
  data: Period;
  articleReporter:boolean;
  //data: any;
  contractDict:ContractDict
  period: string;
  onClickDataCard(key:"warnings"|"duplicates"|"errors"):void;
  rowEdit?:boolean;
}
interface SubmitKickbackProjectState {
  //reports: ProjectReport[];
  invoiceReport:InvoiceFields[]
  reports: any;
  loading: boolean;
  contractConfirmation: CheckBox;
  showHelp: boolean;
  saving: boolean;
  statusObj:StatusObj;
  comment:string;
  department?:Department;
  kickbackChangesReport:KickbackChangeState;
  editMode:boolean;
  csrdRows?:any[]
  //compressed:boolean;
}
class SubmitKickbackProject extends React.Component<
  SubmitKickbackProjectProps,
  SubmitKickbackProjectState
> {
 
  sectionRef: React.RefObject<HTMLDivElement>;
  
  constructor(props: SubmitKickbackProjectProps) {
    super(props);
    this.state = {
      reports: [],
      loading: true,
      contractConfirmation: {},
      showHelp: true,
      saving: false,
      invoiceReport: [],
      statusObj: {} as StatusObj,
      comment: "",
      department:undefined,
      kickbackChangesReport:{} as KickbackChangeState,
      editMode:false
      //compressed:false
    };
    this.sectionRef = React.createRef();
  }

  async componentDidMount(): Promise<void> {
    this.parseStructureAndCalculate();
    document.addEventListener("mousedown", this.HandleClickOutside);
    const department = await DataService.Departments.GetUserAssignedDepartment();
    if(department.Code == Code.OK){

      this.setState({department:department.Content.response})
    }
  }
 componentDidUpdate(prevProps: Readonly<SubmitKickbackProjectProps>, prevState: Readonly<SubmitKickbackProjectState>, snapshot?: any): void {
  if (this.state.contractConfirmation[`${i18n.t("kickbackProject.invoiceReport")}`] && !prevState.contractConfirmation[`${i18n.t("kickbackProject.invoiceReport")}`] && this.sectionRef.current) {
    this.sectionRef.current.scrollIntoView({ behavior: 'smooth' });
   }
 }
  componentWillUnmount(): void {
    document.removeEventListener("mousedown", this.HandleClickOutside);
  }
  private myRef: any = React.createRef();
  private innerMenuRef: any = React.createRef();
  invoiceColumns: ListColumn<InvoiceFields>[] = [
    {
      fieldName: "customer",
      isActive: true,
      key: "customer",
      minWidth: 40,
      maxWidth: 120,
      name: i18n.t("kickbackProject.customerName"),
      onRender(item, col) {
        return item.customer.value;
      },
    },
    {
      fieldName: "customerOrgId",
      isActive: true,
      key: "customerOrgId",
      minWidth: 40,
      maxWidth: 120,
      name: i18n.t("kickbackProject.customerOrgId"),
      onRender(item, col) {
        return item.customerOrgId.value;
      },
    },
    {
      fieldName: "contractId",
      isActive: true,
      key: "contractId",
      minWidth: 40,
      maxWidth: 120,
      name: i18n.t("kickbackProject.contractId"),
      onRender(item, col) {
        return item.contractId.value;
      },
    },
    {
      fieldName: "invoiceNumber",
      isActive: true,
      key: "invoiceNumber",
      minWidth: 40,
      maxWidth: 120,
      name: i18n.t("kickbackProject.invoiceLabel"),
      onRender(item, col) {
        return item.invoiceNumber.value;
      },
    },
    {
      fieldName: "billingDate",
      isActive: true,
      key: "billingDate",
      minWidth: 40,
      maxWidth: 120,
      name: `${i18n.t("kickbackProject.billingDate")}`,
      onRender(item, col) {
        return item.billingDate.value;
      },
    },
    {
      fieldName: "sumExclMoms",
      isActive: true,
      key: "sumExclMoms",
      minWidth: 40,
      maxWidth: 120,
      name: "sumExclMoms",
      onRender(item, col) {
        return item.rowSumExclMoms.value;
      },
    },
  ];
  columns: ListColumn<ProjectReport>[] = [
    {
      fieldName: "projectName",
      isActive: true,
      key: "projectName",
      minWidth: 40,
      maxWidth: 120,
      name: i18n.t("kickbackProject.projectName"),
    },
    {
      fieldName: "invoiceReference",
      isActive: true,
      key: "invoiceReference",
      minWidth: 40,
      maxWidth: 120,
      name: i18n.t("kickbackProject.invoiceReference"),
    },
    {
      fieldName: "invoiceIdentifier",
      isActive: true,
      key: "invoiceIdentifier",
      minWidth: 40,
      maxWidth: 120,
      name: i18n.t("kickbackProject.invoiceLabel"),
    },

    {
      fieldName: "comment",
      isActive: true,
      key: "comment",
      minWidth: 40,
      maxWidth: 120,
      name: i18n.t("kickbackProject.comment"),
    },
    {
      fieldName: "sumExclMoms",
      key: "sumExclMoms",
      minWidth: 40,
      maxWidth: 120,
      isActive: true,
      name: `${i18n.t("kickbackProject.sum")}`,
      onRender(item,col){
        return item.sumExclMoms.toFixed(2)
      }
    },
    // {
    //   fieldName: "sumExclMomsChangeField",
    //                 isActive:typeof this.state.compressed == 'undefined' ? false :this.state.compressed,
    //                 key: "sumExclMomsChangeField",
    //                 minWidth: 60,
    //                 customCellPadding: "1px",
    //                 name: i18n.t("kickback.table.header.sumExclMoms"),
    //   onRender(item,col){
    //     return item.sumExclMomsChangeField
    //   }              
    // },
    // {
    //   fieldName: "sum",
    //                 isActive:typeof this.state.compressed == 'undefined' ? false :this.state.compressed,
    //                 key: "commentRowChangeField",
    //                 minWidth: 60,
    //                 customCellPadding: "1px",
    //                 name:i18n.t("kickback.table.header.CommentChanges"),
                    
    //   onRender(item,col){
        
    //     return item.commentRowChangeField
    //   },
         
    // },
    {
      fieldName: "kickbackSum",
      isActive: false,
      key: "kickbackSum",
      minWidth: 40,
      maxWidth: 120,
      name: i18n.t("kickbackProject.kickBackToBill"),
      
    },

    {
      fieldName: "projectEndDate",
      isActive: true,
      key: "projectEndDate",
      minWidth: 40,
      maxWidth: 120,
      name: i18n.t(
        "kickbackProject.endDate"
      ) /*this.props.t("invoiceReportList.reportDate").toString()*/,
      onRender(item, col) {
        // const date = new Date()
        return item.projectEndDate == null
          ? ""
          : new Date(item.projectEndDate).toLocaleDateString();
      },
    },
  ];
  private showValidationInfo = (props: any, key: string): JSX.Element => {
    const validationText = {
      "kommentarÄndringar":i18n.t('kickbackProject.EditInfo')
    }[key];
    return(
      <Tooltip id="button-tooltip" {...props}>
        <span>{validationText}</span>
      </Tooltip>
    )
  };
  HandleClickOutside = (e: any) => {
    // if (!this.myRef.current.contains(e.target)) {
    //   this.setState({ showHelp: false });
    // }
  };
  HandleClickInside = (e: any) => {
    if (!this.myRef.current.contains(e.target)) {
      this.setState({ showHelp: false });
    }
  };
  private renderKickbackChangesField = (item:KickbackChanged,keyForProp:keyof Test) => {

    if(keyForProp == 'changesComment'){
      const boolllllll = item.sumTotal !== item.changedSum && item.changesComment.length == 0 
      return( 
      
      <div className={boolllllll? 'fieldWrapper  fieldWrapper-error' : ''} >
      <input
      spellCheck={false}
        type={'text'}
        value={item[keyForProp]}
        onChange={(event) => this.handleKickbackFieldChangeTest(item,keyForProp,event.target.value)}
      />
        <OverlayTrigger
      placement='auto-start'
    delay={{ show: 250, hide: 400 }}
    overlay={(props) => this.showValidationInfo(props, 'kommentarÄndringar')}
    >
    {boolllllll ? (
            <FontAwesomeIcon
            className="exclamationIconStyle"
              icon={faExclamation}
            />
          ):<>
          </>}
            
    </OverlayTrigger>
    </div>
      )
    }
    else{
    return (
      <input
      style={{MozAppearance:'textfield',margin:0,WebkitAppearance:'none',height:'100%'}}
        type={'number'}
        value={item[keyForProp]}
        onChange={(event) => this.handleKickbackFieldChangeTest(item,keyForProp,+event.target.value)}
      />          
      )
    }

}
private handleKickbackFieldChangeTest = (item:KickbackChanged,key:keyof Test,event:string | number) => {
  const curr:KickbackChangeState = {...this.state.kickbackChangesReport}
  if(typeof curr !== 'undefined'){
    let buff:KickbackChanged = curr[`${item.contractId}`] 
    const kickBackPercent:number = _helpers.convertToPercentage(this.props.contractDict[item.contractId].kickbackPercent) 
        if(key === 'changedSum' && typeof event === 'number'){
          buff[key] = event;
          buff['totalKickbackReported'] = buff[key] *kickBackPercent
          buff['contractChanged'] = item['sumTotal'] !== item[key] 
        }else if (key === 'changesComment' && typeof event === 'string') {
          buff[key] = event;
          buff['contractChanged'] = item['sumTotal'] !== item['changedSum'] 
        }
  }
  
  this.setState({kickbackChangesReport:curr})
}

  private handleCheckBox = (contractId: string): void => {
    let update = JSON.parse(JSON.stringify(this.state.contractConfirmation));
    update[contractId] = !update[contractId];
    this.setState({ contractConfirmation: update });
  };
  private parseInvoiceReport = (): void => {
    if(this.props.articleReporter){
      let statusObj: StatusObj = {
        warnings: 0,
        errors: 0,
        duplicates: 0,
        totalSum: 0,
        totalRows: 0,
        comment: "",
      };
      const groupedAndFormattedInvoices: InvoiceFields[] = [];
      const csrdRows:any[] = []
    const res =   this.props.data[this.props.period].reduce((acc:{[groupdId:number]:InvoiceFields},item) => {
        if(!acc[item.groupId]){
          acc[item.groupId] = {
                  customer:{value:item.customerName} as FieldProperty,
                  customerOrgId:{value:item.customerOrgId} as FieldProperty,
                  contractId:{value:item.contractId} as FieldProperty,
                  invoiceNumber:{value:item.invoiceNumber} as FieldProperty,
                  billingDate:{value:item.billingDate} as FieldProperty,
                  rowSumExclMoms:{value:item.rowSumExclMoms} as FieldProperty
              } as InvoiceFields
             const totalErrors = this.countEmptyValues(item,false);
              if (totalErrors > 0) {
                statusObj.errors = totalErrors + statusObj.errors;
              }
              if(item.projectEndDate.length == 0)
                statusObj.warnings++;
              if(item.reference.length == 0)
                statusObj.warnings++;
              if(item.tag.length == 0)
                statusObj.warnings++;
              if (item.isDuplicate.value) {
                statusObj.duplicates++;
              }
              //lägg till csrdRad
              const csrdRow = {
                customer:item.customerName,
                articleNumber:item.articleNumber,
                articlePricePerUnit:item.articlePricePerUnit,
                articleQuantity:item.articleQuantity,
                articleQuantityType:item.articleQuantityType,
                articleText:item.articleText,
                invoiceNumber:item.invoiceNumber,
                customerOrgId:item.customerOrgId,
                rowSumExclMoms:item.rowSumExclMoms,
                billingDate:item.billingDate,
                contractId:item.contractId
              }
              csrdRows.push(csrdRow);
              return acc
        }else{
          acc[item.groupId].rowSumExclMoms.value = (+acc[item.groupId].rowSumExclMoms.value + (+item.rowSumExclMoms)).toString() 
          const totalErrors = this.countEmptyValues(item,true);
              if (totalErrors > 0) {
                statusObj.errors = totalErrors + statusObj.errors;
              }
              //lägg till csrdRad
              const csrdRow = {
                customer:item.customerName,
                articleNumber:item.articleNumber,
                articlePricePerUnit:item.articlePricePerUnit,
                articleQuantity:item.articleQuantity,
                articleQuantityType:item.articleQuantityType,
                articleText:item.articleText,
                invoiceNumber:acc[item.groupId].invoiceNumber.value,
                customerOrgId:acc[item.groupId].customerOrgId.value,
                rowSumExclMoms:item.rowSumExclMoms,
                billingDate:acc[item.groupId].billingDate.value,
                contractId:acc[item.groupId].contractId.value
              }
              csrdRows.push(csrdRow);
          return acc
        }
      },{})

      let totalSum = 0;
      Object.values(res).forEach(x => {
        groupedAndFormattedInvoices.push(x)
        totalSum = totalSum + (+x.rowSumExclMoms.value)
      });

      statusObj.totalRows = groupedAndFormattedInvoices.length
      statusObj.totalSum = totalSum
      this.setState({invoiceReport:groupedAndFormattedInvoices,csrdRows,statusObj})

    }else{
      const res: InvoiceFields[] = [];
      let statusObj: StatusObj = {
        warnings: 0,
        errors: 0,
        duplicates: 0,
        totalSum: 0,
        totalRows: 0,
        comment: "",
      };
      let groupId = this.props.data[this.props.period][0].groupId
      this.props.data[this.props.period].forEach((x, index) => {
        const invoice: InvoiceFields = {
          id: index,
          groupId: 0,
          billingDate: { value: x.billingDate } as FieldProperty,
          contractId: { value: x.contractId } as FieldProperty,
          customer: { value: x.customerName } as FieldProperty,
          customerOrgId: { value: x.customerOrgId } as FieldProperty,
          invoiceNumber: { value: x.invoiceNumber } as FieldProperty,
          rowSumExclMoms: {
            key: "",
            validation: "Good",
            format: "",
            message: "",
            type: "Number",
            value: this.props.articleReporter ? x.rowSumExclMoms : x.totalExclMoms.value.toString(),
          },
          articleQuantity: { value: x.articleQuantity } as FieldProperty,
          articlePricePerUnit: { value: x.articlePricePerUnit } as FieldProperty,
          articleNumber: { value: x.articleNumber } as FieldProperty,
          articleText: { value: x.articleText } as FieldProperty,
          articleQuantityType: { value: x.articleQuantityType } as FieldProperty
        };
       let prevPoint = false
       if(index !== 0){
         prevPoint =  x.groupId == groupId
        if(!prevPoint){
          groupId = x.groupId
        }
       }
        let totalErrors = this.countEmptyValues(x,prevPoint);
  
        if (totalErrors > 0) {
          statusObj.errors = totalErrors + statusObj.errors;
        }
        if(x.projectEndDate.length == 0)
          statusObj.warnings++;
        if(x.reference.length == 0)
          statusObj.warnings++;
        if(x.tag.length == 0)
          statusObj.warnings++;
        if (x.isDuplicate.value) {
          statusObj.duplicates++;
        }
        if (!isNaN(x.totalExclMoms.value)) {
          statusObj.totalSum = this.props.articleReporter ? +x.rowSumExclMoms + statusObj.totalSum : x.totalExclMoms.value + statusObj.totalSum;
        }
  
        res.push(invoice);
      });
  
      statusObj.totalRows = res.length;
      this.setState({ invoiceReport: res, statusObj });
    }
    
  };
  private countEmptyValues = (val: ProjectInvoice,prevPoint:boolean): number => {
    const keys: string[] = this.props.articleReporter ? prevPoint == false ? [
      "billingDate",
      "contractId",
      "customerName",
      "customerOrgId",
      "invoiceNumber",
      "rowSumExclMoms",
      "projectName",
      //"articleNumber",
      "articlePricePerUnit",
      "articleQuantity",
      "articleQuantityType",
      "articleText"
    ]:["rowSumExclMoms","articlePricePerUnit",
    "articleQuantity",
    "articleQuantityType",
    "articleText"] : [
      "billingDate",
      "contractId",
      "customerName",
      "customerOrgId",
      "invoiceNumber",
      "totalExclMoms",
      "projectName"
    ];
    let errors = 0;
    Object.entries(val).forEach(([key, value]) => {
      if (key == "totalExclMoms" && this.props.articleReporter == false) {
        if (val[key].display.length == 0 || val[key].valid == false) {
          errors++;
        }
      } 
      else if (keys.includes(key)) {
        let buff = value as string;
        if (buff.length == 0) {
          errors++;
        }
      }
     
    });
    return errors;
  };
  private parseStructureAndCalculate = (): void => {
    const projectKickbacks: { [contractId: string]: ProjectReport[] } = {};
    this.parseInvoiceReport();
    if(this.props.articleReporter){
    const grouped =   this.props.data[this.props.period].reduce((acc:{[groupId:string]:ProjectReport},item:ProjectInvoice) => {

      if(this.props.contractDict[item.contractId] !== undefined){
        const checkLength = item.rowSumExclMoms.length > 0
        let kickbackValue = (checkLength ? +item.rowSumExclMoms * _helpers.convertToPercentage(this.props.contractDict[item.contractId]?.kickbackPercent) : 0 * _helpers.convertToPercentage(this.props.contractDict[item.contractId].kickbackPercent)).toFixed(
          2
        );
        if(!acc[`${item.contractId}-${item.projectName}-${item.reference}-${item.tag}`]){
          const date = new Date();
          const endDate = this.checkDate(item.projectEndDate);
          const buff:ProjectReport = {
                  id: item.uniqueRowId,
                  reportId: "",
                  contractId: item.contractId,
                  projectName: item.projectName,
                  invoiceReference: item.reference,
                  invoiceIdentifier: item.tag,
                  kickbackSum: +kickbackValue,
                  sumExclMoms: +item.rowSumExclMoms,
                  sumExclMomsChange : +item.rowSumExclMoms,
                  sumExclMomsChangeField: <></>,
                  comment: item.comment,
                  period: this.props.period,
                  reportDate: date,
                  reporter: "",
                  supplierOrgId: item.supplierOrgId,
                  projectEndDate: endDate,
                  status: 1,
                  customerId: item.customerId,
                  commentRowChange:"",
                  commentRowChangeField:<></>
                };
                //buff.commentRowChangeField = this.renderKickbackChangesField(buff,'commentRowChange','commentRowChangeField')
                //buff.sumExclMomsChangeField = this.renderKickbackChangesField(buff,'sumExclMomsChange','sumExclMomsChangeField')
                acc[`${item.contractId}-${item.projectName}-${item.reference}-${item.tag}`] = buff
            return acc
          }
          else{
            acc[`${item.contractId}-${item.projectName}-${item.reference}-${item.tag}`].sumExclMoms += +item.rowSumExclMoms 
            acc[`${item.contractId}-${item.projectName}-${item.reference}-${item.tag}`].kickbackSum += +kickbackValue
            acc[`${item.contractId}-${item.projectName}-${item.reference}-${item.tag}`].sumExclMomsChange += +item.rowSumExclMoms 
            //buff.commentRowChangeField = this.renderKickbackChangesField(buff,'commentRowChange','commentRowChangeField')
            //acc[`${item.contractId}-${item.projectName}-${item.reference}-${item.tag}`].sumExclMomsChangeField = this.renderKickbackChangesField(acc[`${item.contractId}-${item.projectName}-${item.reference}-${item.tag}`],'sumExclMomsChange','sumExclMomsChangeField')
            return acc
          }
      }
     return acc
      },{})
      Object.values(grouped).forEach((x: ProjectReport) => {
       if(!projectKickbacks[x.contractId]){
        projectKickbacks[x.contractId] = []
        projectKickbacks[x.contractId].push(x);
       }
       else{
        projectKickbacks[x.contractId].push(x);
       }
      });
    } 
    if (this.props.data[this.props.period].length !== 0 && this.props.articleReporter == false) {
      const bufferObjToSumIdenticalRows: { [key: string]: ProjectReport } = {};
      this.props.data[this.props.period].reduce(
        (
          prev: { [contractId: string]: ProjectReport[] },
          item: ProjectInvoice
        ) => {
          const key = `${item.contractId}-${item.projectName}-${item.reference}-${item.tag}`;
          if (item.contractId.length > 0) {
            if (!prev[item.contractId]) {
              const arr: ProjectReport[] = [];
              prev[item.contractId] = arr;
            }
            let kickbackValue = (item.totalExclMoms.value * item.kick).toFixed(
              2
            );
            if (bufferObjToSumIdenticalRows[key]) {
              bufferObjToSumIdenticalRows[key].kickbackSum += +kickbackValue;
              bufferObjToSumIdenticalRows[key].sumExclMoms += item.totalExclMoms.value;
              bufferObjToSumIdenticalRows[key].sumExclMomsChange += +item.totalExclMoms.value 
              //buff.commentRowChangeField = this.renderKickbackChangesField(buff,'commentRowChange','commentRowChangeField')
              //bufferObjToSumIdenticalRows[key].sumExclMomsChangeField = this.renderKickbackChangesField(bufferObjToSumIdenticalRows[key],'sumExclMomsChange','sumExclMomsChangeField')
                
            } else {
              const date = new Date();
              const endDate = this.checkDate(item.projectEndDate);
              const buff = {
                id: item.uniqueRowId,
                reportId: "",
                contractId: item.contractId,
                projectName: item.projectName,
                invoiceReference: item.reference,
                invoiceIdentifier: item.tag,
                kickbackSum: +kickbackValue,
                sumExclMoms: item.totalExclMoms.value,
                sumExclMomsChange : +item.totalExclMoms.value,
                sumExclMomsChangeField: <></>,
                comment: item.comment,
                period: this.props.period,
                reportDate: date,
                reporter: "",
                supplierOrgId: item.supplierOrgId,
                projectEndDate: endDate,
                status: 1,
                customerId: item.customerId,
                commentRowChange:"",
                  commentRowChangeField:<></>
              } as ProjectReport;
             // buff.commentRowChangeField = this.renderKickbackChangesField(buff,'commentRowChange','commentRowChangeField')
               // buff.sumExclMomsChangeField = this.renderKickbackChangesField(buff,'sumExclMomsChange','sumExclMomsChangeField')
              bufferObjToSumIdenticalRows[key] = buff;
            }
          }

          return prev;
        },
        projectKickbacks
      );
      Object.values(bufferObjToSumIdenticalRows).forEach((x: ProjectReport) => {
        projectKickbacks[x.contractId].push(x);
      });
    }
    this.setState({ reports: projectKickbacks }, () => this.initCheckBoxes());
  };

  private initCheckBoxes = (): void => {
    const checkBoxKeys = Object.keys(this.state.reports);
    let checkBoxObj: CheckBox = {};
    checkBoxKeys.reduce((acc: CheckBox, item) => {
      if (!checkBoxObj[item]) {
        checkBoxObj[item] = false;
      }
      return checkBoxObj;
    }, checkBoxObj);
    checkBoxObj[`${i18n.t("kickbackProject.invoiceReport")}`] = false;
    this.setState({ contractConfirmation: checkBoxObj }, () =>
      this.initChangesObj()/* this.setState({ loading: false })*/
    );
  };
  private checkBoxMenu = (contractId: string): JSX.Element => {
    return (
      <>
        <div
          className="checkYeah"
          //style={{ display: "flex", flexDirection: "row" }}
        >
          {this.state.contractConfirmation[contractId] ?  
        <FontAwesomeIcon onClick={(e) => this.handleCheckBox(contractId)} icon='square-check' style={{color: /*'rgb(245, 156, 47)'*/'rgb(146, 192, 31)',
          height:28
          ,width:26}}/>
        : <FontAwesomeIcon onClick={(e) => this.handleCheckBox(contractId)} icon='square-check' style={{color: 'rgba(179, 4, 4, 0.8)',
          height:28
          ,width:26}}/>
          
         
        }
        </div>
      </>
    );
  };

  private checkDate = (date: string): Date | null => {
    return typeof date == "undefined"
      ? null
      : date.length > 0
      ? new Date(date)
      : null;
  };
  private renderInvoiceReport = (): JSX.Element => {
    if (typeof this.state.invoiceReport !== "undefined") {
      return (
        <StyledAccordion
          alwaysOpen={true}
          title={
            <>
              {i18n.t("kickbackProject.invoiceReport")}{" "}
              {this.state.contractConfirmation[
                `${i18n.t("kickbackProject.invoiceReport")}`
              ] == true ? (
                <FontAwesomeIcon
                  style={{ color: "rgb(146, 192, 31)" }}
                  icon="check-circle"
                />
              ) : (
                <FontAwesomeIcon
                  style={{ color: "rgba(179, 4, 4, 0.8)" }}
                  icon="circle-xmark"
                />
              )}
            </>
          }
          style={{
            marginTop: "25px",
            borderTop: "5px solid var(--statistic-color)",
          }}
        >
          <Stack
            gap={3}
            style={{
              maxHeight: "700px",
              overflowY: "scroll",
              overflowX: "hidden",
              padding: "0px 10px",
              marginBottom: "12px"
            }}
          >
            <div style={{marginLeft: "-5px"}}>
              {this.checkBoxMenu(i18n.t("kickbackProject.invoiceReport"))}
            </div>
            <Row className="gy-3">
              <Col xs={12} md={6} lg={4} >
                {this.renderDataCard(i18n.t("kickbackProject.warning"), this.state.statusObj.warnings.toString(), "triangle-exclamation","rgb(245, 156, 47,0.8)", () => { this.props.onClickDataCard("warnings"); } )}
              </Col>
              <Col xs={12} md={6} lg={4} >
                {this.renderDataCard(i18n.t("kickbackProject.errors"), this.state.statusObj.errors.toString(), "circle-exclamation","rgb(179, 4, 4, 0.8)", () => { this.props.onClickDataCard("errors"); } )}
              </Col>
              <Col xs={12} md={6} lg={4} >
                {this.renderDataCard(i18n.t("kickbackProject.duplicates"), this.state.statusObj.duplicates.toString(), "copy","rgb(11, 93, 151,0.8)", () => { this.props.onClickDataCard("duplicates"); } )}
              </Col>
              <Col xs={12} md={6} lg={6} >
                {this.renderDataCard(i18n.t("kickbackProject.totalRows"), this.state.statusObj.totalRows.toString(), "list","rgba(86, 89, 89, 0.95)" )}
              </Col>
              <Col xs={12} d={12} lg={6} >
                {this.renderDataCard(i18n.t("kickbackProject.total"), ReadFriendlyNumber(this.state.statusObj.totalSum), "sack-dollar","rgb(86, 181, 58,0.8)" )}
              </Col>
            </Row>
            <FormGroup style={{marginBottom:10}}>
              <Form.Label>{i18n.t("kickbackProject.cleanComment")}</Form.Label>
              <Form.Control 
                type="text" 
                as="textarea"
                onChange={(value) =>
                  this.setState((prevState) => ({
                    ...prevState,
                    statusObj: {
                      ...prevState.statusObj,
                      comment: value.target.value,
                    },
                  }))
                }
                autoCorrect="off"
                spellCheck="false"
              />
            </FormGroup>
          </Stack>
          
        </StyledAccordion>
      );
    } else {
      return <></>;
    }
  };
  // private totalBilling = (key: string): JSX.Element => {
  //   const sum: string = this.state.reports[key]
  //     .reduce((acc: number, item: any) => {
  //       return acc + item.kickbackSum;
  //     }, 0)
  //     .toFixed(2);
  //     const totalExclMoms:string = this.state.reports[key]
  //     .reduce((acc:number,item:any) => {
       
  //       return acc + item.sumExclMoms
  //     },0).toFixed(2)
  //   return (
  //     <>
  //       <span>
  //         <i>{i18n.t("kickbackProject.Total")}</i>
  //         <i> </i>
  //         <b style={{ marginLeft: 4 }}>{sum}</b>
  //       </span>
  //       {/* <div>
  //       <i>{i18n.t("kickbackProject.Total")}</i>
  //       </div> */}
        
  //     </>
  //   );
  // };
  private toggleEditMode = () => {
    this.setState({editMode:!this.state.editMode})
  }
  private renderTable = (): JSX.Element/*JSX.Element[]*/ => {
    // const tableElements: JSX.Element[] = [];
    // Object.keys(this.state.reports).forEach((w) =>
    //   tableElements.push(
    //     <div style={{ marginBottom: 15, marginTop: 15 }}>
    //       <StyledAccordion
    //         alwaysOpen={true}
    //         title={`${i18n.t("kickbackProject.contract")} ${w} `}
    //       >
    //         <>
    //           <div
    //             style={{ display: "flex", flexWrap: "wrap", marginRight: "4%" }}
    //           >
    //             {/* {this.checkBoxMenu(w)} */}
    //           </div>

    //           <div style={{ maxHeight: "30vh" }}>
    //             <Datalist
    //               isLoading={this.state.loading}
    //               uniqueKey="contractId"
    //               items={this.state.reports[w]}
    //               columns={this.columns}
    //               noItemsText={i18n.t("kickback.table.empty")}
    //               initialSortKey="contractId"
    //               setColumns={(cols) => {}}
    //             />
    //           </div>
    //           <Stack
    //             style={{ marginTop: 5, marginBottom: 5 }}
    //             direction="horizontal"
    //           >
    //             {/* {this.totalBilling(w)} */}
    //           </Stack>
    //         </>
    //       </StyledAccordion>
    //     </div>
    //   )
    // );
    // //ska var i den nya sen 
    // //tableElements.push(this.renderChangesTable())
    // tableElements.push(<SupplierChanges 
    //   sectionRef={this.sectionRef} 
    //   kickbackChangesReport={this.state.kickbackChangesReport} 
    //   renderKickbackChangesField={this.renderKickbackChangesField}
    //   contractConfirmation={this.state.contractConfirmation}
    //   checkBoxMenu={this.checkBoxMenu}
    //   compressed={this.state.compressed}
    //   reports={this.state.reports}
    //   />)
    // return tableElements;
    const res = SupplierChanges({sectionRef:this.sectionRef,
      kickbackChangesReport:this.state.kickbackChangesReport,
      renderKickbackChangesField:this.renderKickbackChangesField,
      contractConfirmation:this.state.contractConfirmation,
      checkBoxMenu:this.checkBoxMenu,
      //compressed:this.state.compressed,
      reports:this.state.reports,
      loading:this.state.loading,
      chageForChangeChange:this.chageForChangeChange,
      showValidationInfo:this.showValidationInfo,
      rowEdit:this.state.editMode//this.props.rowEdit
      //editModeToggleHandler:this.toggleEditMode
    })
    return res
    // return <SupplierChanges 
    //   sectionRef={this.sectionRef} 
    //   kickbackChangesReport={this.state.kickbackChangesReport} 
    //   renderKickbackChangesField={this.renderKickbackChangesField}
    //   contractConfirmation={this.state.contractConfirmation}
    //   checkBoxMenu={this.checkBoxMenu}
    //   compressed={this.state.compressed}
    //   reports={this.state.reports}
    //    />
  };
  private chageForChangeChange = (event:any,item:ProjectReport,propKey:keyof BLa) => {
    const kickBackPercent:number = _helpers.convertToPercentage(this.props.contractDict[item.contractId].kickbackPercent) 
    const buff = this.state.reports
   const i =  buff[item.contractId].find((x:any) => x.id == item.id)
   i[propKey] = propKey == 'sumExclMomsChange' ?  +event.target.value : event.target.value
   if(propKey == 'sumExclMomsChange'){
     i['kickbackSum'] = i[propKey] * kickBackPercent 
   }
   this.setState({reports:buff}) 
   //console.log(event.target.value,item[propKey],this.state.reports)
    //item[propKey] = event.target.value
  }
  private handleSubmit = async (): Promise<void> => {
    this.setState({ saving: true });
    const keys = Object.keys(this.state.reports);
    // const polishedProjectReports: ProjectReport[] = [];
    // for (let index = 0; index < keys.length; index++) {
    //   const element: ProjectReport[] = this.state.reports[keys[index]];
    //   element.forEach((e: ProjectReport) => {
    //     polishedProjectReports.push(e);
    //   });
    // }
    const polishedProjectReports: ProjectReportPolished[] = []
    for (let index = 0; index < keys.length; index++) {
        const element: ProjectReport[] = this.state.reports[keys[index]];
        element.forEach((e: ProjectReport) => {
          const omitedProps:ProjectReportPolished = {
         id:e.id,   
        reportId: e.reportId,
        contractId: e.contractId,
        projectName: e.projectName,
        invoiceReference: e.invoiceReference,
        invoiceIdentifier: e.invoiceIdentifier,
        kickbackSum:e.kickbackSum,
        sumExclMoms:e.sumExclMomsChange,
        comment: e.comment,
        period: e.period,
        reportDate:e.reportDate,
        reporter:e.reporter,
        supplierOrgId:e.supplierOrgId,
        status:e.status,
        projectEndDate: e.projectEndDate,
        customerId:e.customerId,
        commentRowChange:e.commentRowChange
          } 
          polishedProjectReports.push(omitedProps);
        });
      }
  
      let res: any = {}
      //const anythingChanged = Object.values(this.state.kickbackChangesReport).find(x => x.contractChanged == true)
   
   
   
      if(/*typeof anythingChanged !== 'undefined'*/!this.state.editMode){
      res = await DataService.Kickback.PostProjectReport(
        polishedProjectReports,
        this.state.invoiceReport,
        this.state.statusObj,
        i18n.language,
        this.state.department?.id,
        this.state.kickbackChangesReport,
        this.state.csrdRows
      );
    }
    else{
      res = await DataService.Kickback.PostProjectReport(
        polishedProjectReports,
        this.state.invoiceReport,
        this.state.statusObj,
        i18n.language,
        this.state.department?.id,
        undefined,
        this.state.csrdRows
        
      );
    }
      

    if (res.Content.parsedRes.statusCode == 200) {
      this.setState({ saving: false });
      let apitranslatedMessage = `${i18n.t("kickbackProject.apiSuccess")}`;
      this.props.submitHandler(
        this.props.period,
        res.Content.parsedRes.statusCode,
        apitranslatedMessage
      );
    } else if (res.Content.parsedRes.statusCode == 304) {
      this.setState({ saving: false });
      let rows = res.Content.parsedRes.message.split(",");
      let apiErrorMessage = `${i18n.t("kickbackProject.apiError")} ${
        rows[0]
      } ${i18n.t("kickbackProject.off")}  ${rows[1]} ${i18n.t(
        "kickbackProject.row"
      )}`;
      this.props.submitHandler(
        this.props.period,
        res.Content.parsedRes.statusCode,
        apiErrorMessage
      );
    } else {
      this.setState({ saving: false });
      this.props.submitHandler(
        this.props.period,
        res.Content.parsedRes.statusCode,
        res.Content.parsedRes.message,
        JSON.stringify(res.Content.parsedRes.duplicates)
      );
    }
  };
  private handleHelpToggle = (): void => {
    this.setState((prevState) => ({ showHelp: !prevState.showHelp }));
  };
  private renderDataCard = (title:string, number: string, icon: IconProp, color: string, onClick?:Function) => {
    const shouldOnClick = onClick// && number > "0";
    const classNames = !shouldOnClick ? "submit-kickback-project-datacard"
      : "submit-kickback-project-datacard submit-kickback-project-datacard-clickable"
    
    return <Stack
        className={classNames}
        direction="horizontal"
        onClick={() => { if(shouldOnClick) onClick(); }}
        gap={2}
        style={{
          borderLeft: "5px solid "+color,
          position: "relative"
        }}
    >
      <FontAwesomeIcon
        style={{ color: color, fontSize: "2rem" }}
        icon={icon}
      />
      <div>
        <span className="value" style={{ color: color, fontSize: "1.3rem", marginRight: 8 }}>
          {number}
        </span>
        <span className="textTitle" style={{fontSize: "1.2rem"}}>{title}</span>
      </div>
      {
        shouldOnClick &&
        <div>
          <FontAwesomeIcon icon={"square-up-right"} style={{ color: "#8786867a", position: "absolute", right:6, top: 6, fontSize: "1.3rem" }}/>
        </div>
      }
    </Stack>
  }

  private renderInfoCard = (): JSX.Element => {

    return (
      <>
        <div
          ref={this.myRef}
          className="submitModalInfoCardContainer"
        >
          <FontAwesomeIcon className="infoIcon" icon="info-circle" />

          <div
            ref={this.innerMenuRef}
            style={this.state.showHelp ? { maxHeight: 500 } : { maxHeight: 0 }}
            onClick={this.HandleClickInside}
            className="submitModalInfoCardContentActive"
          >
            <p className="paragraph">
              <li className="infoLielement">
                {i18n.t("kickbackProject.confirm")}
              </li>
              <li className="infoLielement">
                {i18n.t("kickbackProject.RowsIdentical")}
              </li>
              <li className="infoLielement">
                {i18n.t("kickbackProject.errorsDuplicates")}
              </li>
              <li className="infoLielement">
                {i18n.t("kickbackProject.confirmInfo")}
              </li>
            </p>
          </div>
        </div>
      </>
    );
  };
  private initChangesObj = () => {
    const values:{[key:string]:KickbackChanged} = {}
     Object.entries(this.state.reports).forEach(([key,value]) => {   
      const buffArr = [...value as any[]]
      const holder = buffArr.reduce((acc:KickbackChanged,item) => {
          if(isNaN(acc.sumTotal)){
            acc.sumTotal =  item.sumExclMoms
            acc.changedSum = item.sumExclMoms
            acc.totalKickbackReported = item.kickbackSum
            acc.contractChanged = false
       
          }
          else{
       
            const sum = acc.sumTotal + item.sumExclMoms
            const kick = acc.totalKickbackReported + item.kickbackSum
            acc.sumTotal = sum
            acc.changedSum = sum
            acc.totalKickbackReported = kick
          }
          acc.changesComment = ''
          acc.contractId = item.contractId
       return acc
      },{})
      return values[`${key}`] = holder
    })
    this.setState({kickbackChangesReport:values},() =>  this.setState({ loading: false }))
  }
  // private renderChangesTable = ():JSX.Element => {
    
  //   if(typeof this.state.kickbackChangesReport !== 'undefined'){
  //     //- ${this.props.contractDict[x.contractId].title} ${this.props.contractDict[x.contractId].name}
  //     return <StyledAccordion title="Bekräfta rapport" alwaysOpen> 
  //     <div ref={this.sectionRef} className="datalist" style={{borderTop:'5px solid rgb(245, 156, 47)',borderBottom:'2px solid rgb(245, 156, 47)',marginBottom:'30px'}} >
  //    <table  className="datalistTable">
  //        <thead className="datalistTableHead">
  //          <tr>
            
  //            <th className="datalistTableHeader">
  //                Avtal
  //            </th>
  //          <th className="datalistTableHeader">Totalt belopp exkl. moms</th>
  //            <th className="datalistTableHeader">Ev.ändringar</th>
  //            <th className="datalistTableHeader">Ev.ändringar Kommentar</th>
  //            <th className="datalistTableHeader">Total kickback rapporterat</th>
  //          </tr>
  //        </thead>
  //      <tbody>
  //        {Object.values(this.state.kickbackChangesReport).map((x,i) => {
  //            return <tr className="datalistTableRow datalistTableRowHover">
  //            <td className="datalistTableCell" style={{height:'100%'}} aria-disabled>{`${x.contractId} `}</td>  
  //            <td className="datalistTableCell" style={{height:'100%'}} aria-disabled> {ReadFriendlyNumber(x.sumTotal)}</td>
  //            <td className="datalistTableCell" style={{height:'100%'}}>{/*x.changedSum*/this.renderKickbackChangesField(x ,'changedSum')}</td>
  //            <td className="datalistTableCell" style={{height:'100%'}}>{/*x.changesComment*/this.renderKickbackChangesField(x,'changesComment')}</td>
  //            <td className="datalistTableCell" style={{height:'100%'}} aria-disabled>{ReadFriendlyNumber(x.totalKickbackReported)}</td>
  //             <td className={`datalistTableCell ${this.state.contractConfirmation[i18n.t("kickbackProject.invoiceReport")] ? `animate animate-pop delay-${i}`: ''}`} style={{height:'100%',width:30,cursor:'pointer'}}>{this.checkBoxMenu(x.contractId)}</td>
  //          </tr>
  //        })}
         
  //      </tbody>
  //      <tfoot className="datalistTableFoot"></tfoot>
  //    </table>
  //    </div>
  //    </StyledAccordion>
  //   }
  //   else{
  //     return <></>
  //   }
    
   
  // }
  
  render() {
    let currentValues = Object.values(this.state.kickbackChangesReport).find((item:KickbackChanged) =>  item.sumTotal !== item.changedSum && item.changesComment.length == 0 )
    let expandedValues = Object.keys(this.state.reports).find(x => this.state.reports[x].find((item:any) => item.sumExclMoms !== item.sumExclMomsChange &&
    item.commentRowChange.length == 0))
    let disableSendButton =
      typeof Object.values(this.state.contractConfirmation)
        .find((x) => x !== true)
        ?.valueOf() !== "undefined" ||
      this.state.statusObj.errors > 0 ||
      this.state.statusObj.duplicates > 0 || typeof currentValues !== 'undefined' || typeof expandedValues !== 'undefined';

    let alContractsValid = Object.entries(this.state.contractConfirmation)
      .filter((x) => {
        if (x[0] !== "Statistik-rapport") {
          return x[1] !== true;
        }
      })
      .find(([x, q]) => q !== true)?.[1]
      .valueOf(); 
    return (
      <div>
        <StyledModal
          className="full-width"
          title={`${i18n.t("kickbackProject.review")}`}
          onDismiss={this.props.closeModal}
          body={
            <>
              {this.state.loading && <Spinner />}

              {!this.state.loading && (
                <div>
                  {this.renderInfoCard()}
                  <p style={{ fontSize: "18px" }}>
                    {" "}
                    {`${i18n.t("kickbackProject.report")}` +
                      " period " +
                      this.props.period[0].toUpperCase()}
                    {this.props.period.substring(1, this.props.period.length)}
                  </p>
                  <div>{this.renderInvoiceReport()}</div>

                  <StyledAccordion
                    alwaysOpen={true}
                    title={
                      <>
                        {i18n.t("kickbackProject.projectReport")}{" "}
                        {typeof alContractsValid == "undefined" ? (
                          <FontAwesomeIcon
                            style={{
                              marginLeft: "8px",
                              color: "rgb(146, 192, 31)",
                            }}
                            icon="check-circle"
                          />
                        ) : (
                          <FontAwesomeIcon
                            style={{
                              marginLeft: "8px",
                              color: "rgba(179, 4, 4, 0.8)",
                            }}
                            icon="circle-xmark"
                          />
                        )}
                      </>
                    }
                    style={{
                      marginTop: "25px",
                      borderTop: "5px solid rgb(86, 181, 58)",
                    }}
                  >
                    <>
                    <Form.Check className="customSwitch" type="switch" label="Aktivera radeditering" reverse onClick={this.toggleEditMode}/>
                    <div style={{height:'60vh',overflowY:'scroll'}}>{this.renderTable()}</div>
                    
                
            </>
                  </StyledAccordion>
                </div>
              )}
            </>
          }
          footer={
            <Stack direction="horizontal" gap={2}>
              <Button
                disabled={disableSendButton || IsUserSinfra()}
                className="button button-submit"
                onClick={() => this.handleSubmit()}
              >
                {this.state.saving ? (
                  <Spinner animation="border" size={"sm"} />
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      style={{ marginRight: 4 }}
                    />
                    {t("general.send")}
                  </>
                )}
              </Button>
            </Stack>
          }
        />
      </div>
    );
  }
}
export default withTranslation()(SubmitKickbackProject);
