/* eslint-disable @typescript-eslint/no-useless-constructor */

import * as React from "react";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import CardChart from "./CardChart";
interface ICardProps {
  svg: JSX.Element;
  backgroundColor: string;
  color: string;
  cardTitle: string;
  statProcent: string;
  week: string;
  dataPointsTrendline: any;
}
interface ICardState {}
class Card extends React.Component<ICardProps, ICardState> {
  constructor(props: ICardProps) {
    super(props);
  }
  render() {
    return (
      <>
        <div className="dashboardCard"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "5px",
            backgroundColor: this.props.backgroundColor,
            display: "flex",
            flex: " auto",
            width: "33%",
          }}
        >
          <div style={{ marginLeft: "5px" }}>
            {this.props.svg}
            <div style={{ marginTop: "25px", float: "right", flex: "auto" }}>
              <CardChart
                color={this.props.color}
                dataPointsTrendline={this.props.dataPointsTrendline}
              />
            </div>

            <div style={{marginLeft:'5px'}}>
              <b style={{whiteSpace:'nowrap'}}>{this.props.cardTitle}</b>
              <p>
                <b>{this.props.statProcent}</b>
                <i>st</i><i>{' '}{i18n.t("dashboard.since")}</i> <i>{this.props.week}</i>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(Card);
