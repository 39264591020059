import * as React from 'react'
import { CompressedDepartmentReported} from '../../../services/API/Departments'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from '../../../i18n';

interface DepartmentReportedViewProps{
    period:string;
    departmentReported:{[key:string]:CompressedDepartmentReported}

}

const DepartmentReportedView = ({period,departmentReported}:DepartmentReportedViewProps) =>{  
  const currMonthText = () => {
      const currDate = new Date().toLocaleDateString(i18n.language,{month:'long',year:'numeric'}).toLowerCase()
      return  currDate == period.replace('-',' ') ? `${i18n.t('department.nothingReported')} ${period}` : `${i18n.t('department.missingReport')} ${period}`
    }
   const renderCheck = () => {
    return typeof departmentReported[period] == 'undefined' ?
    <></>:     
    <>
    {departmentReported[period].reported && 
      <div className='departmentReportViewContainer'>
        {i18n.t('department.messages')} {period}
          <FontAwesomeIcon
            className='departmentReportViewIcon'
            icon='triangle-exclamation'
          />
      </div>                
    }
    {(!departmentReported[period].reported && !departmentReported[period].cantReport) && 
        <div className='departmentReportViewContainer'>
          {currMonthText()}
            <FontAwesomeIcon
              className='departmentReportViewIcon'
              icon='triangle-exclamation'
            />
        </div>
    }
    {departmentReported[period].cantReport && 
      <div className='departmentReportViewContainer'>                     
        {i18n.t('department.noRights')} {period}
          <FontAwesomeIcon
            className='departmentReportViewIcon'
            icon='triangle-exclamation'
          />
      </div>
    }
    </>
   } 
    return(
      <>
      {renderCheck()}
      </>
    )
}




export{
    DepartmentReportedView
}