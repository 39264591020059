/* eslint-disable eqeqeq */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import i18n from "../../i18n"
import { EditInvoiceReportTable } from "./EditInvoiceReportTable"
import { InfoBar } from "./Info"
import Accordion from '../Accordion/StyledAccordion';
import { Logs } from "../../services/API/Logs"
import Datalist, { ListColumn } from "../Datalist/Datalist"
import { /*DeleteModalState, HandleDeleteModal,*/ ActionModalState, HandleActionModal, InternalInvoiceReport, InternalKeysForEIRT/*, InvoicesWithCsrdAndGroupId*/ } from "./editTypes"
import { Contract } from '../../services/API/Contracts';
import { Customer } from "../../services/API/Customer"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import { Button, Spinner } from "react-bootstrap";
import { IsUserSinfra } from "../../lib/utils";
import { Department } from "../../services/API/Departments";
import { ActionModal } from "./ActionModal";
interface StandardReportViewProps{
    data:InternalInvoiceReport;
    invoiceReportOnChange:(e:React.ChangeEvent<HTMLTextAreaElement> | number,key:keyof Pick<InternalInvoiceReport,'departmentId' | 'comment'>,item?:InternalInvoiceReport)=>void;
    callBack:<T extends object>(item:T,index?:number,key?:keyof T)=>void;
    reportType:InternalKeysForEIRT;
    addRow:()=>void;
    deleteRow:(id:number)=>void;
    handleDeleteModal:HandleActionModal;
    deleteModal:ActionModalState;
    copyRow:<T extends object>(item:T,index:number) => void;
    contract:Contract[];
    customers:Customer[];
    submit:() =>void;
    submitActive:boolean;
    editHistory:any;
    departments?:Department[];
    department?:Department;
    logs?:Logs[]
}
export const StandardInvoiceReportView = ({data,invoiceReportOnChange,callBack,reportType,addRow,deleteRow,handleDeleteModal,deleteModal,copyRow,contract,customers,submit,submitActive,editHistory,departments,department,logs}:StandardReportViewProps) => {
  
  const logColumns:ListColumn<Logs>[] = [
        {
            fieldName: "timeReported",
            isActive: true,
            key: "timeReported",
            minWidth: '',
            maxWidth: 150,
            sorting: true,
            name: 'timeReported',
            onRender:(item,col) => {
              return <p style={{width:'50px',margin:'0'}}>{new Date(item.timeReported).toLocaleDateString()}</p>                     
            }
            
          },
          {
            fieldName: "logLevel",
            isActive: true,
            key: "logLevel",
            minWidth: '',
            maxWidth: 100,
            //width:'35px !important',
            sorting: true,
            name: 'logLevel',
            
          },
          {
            fieldName: "logText",
            isActive: true,
            key: "logText",
            minWidth: '',
            //maxWidth: undefined,
            //width:'400px !important',
            sorting: true,
            name:'logText',
            onRender:(item,col) => {
              return <p style={{margin:'0'}}>{item.logText}</p>
            }
            
          }
        
    ]
    return(
      <>
           {
            deleteModal.show &&
            ActionModal(deleteModal,addRow,deleteRow,copyRow,handleDeleteModal,invoiceReportOnChange,submit)
          }
           <InfoBar customers={customers} editHistory={editHistory} handleActionModal={handleDeleteModal} data={data} handleChange={invoiceReportOnChange} departments={departments} department={department}/>
           {logs !== undefined && logs.length > 0 &&
           <Accordion open title={i18n.t("invoiceReportList.invoiceReportModal.logs").toString()} titleStyle={{fontSize: "1.1rem", margin: 0}} style={{padding: "0px 10px 0px"}} contentStyle={{paddingLeft: 4}}>
            <div style={{maxHeight: "48vh",marginBottom:'10px'}}>
            <Datalist
                items={logs ?? []}
                uniqueKey="invoiceNumber"
                columns={logColumns}
                noItemsText={i18n.t("invoiceReportList.invoiceReportModal.table.empty")}
                initialSortKey="timeReported"
                descendingSort={false}
                setColumns={(cols) => /*this.setState({ logColumns: cols })*/console.log()}
              />
              </div>
              </Accordion>
           }
         <Accordion open={true} title={i18n.t("invoiceReportList.invoiceReportModal.invoices").toString()} titleStyle={{fontSize: "1.1rem", margin: 0}} style={{padding: "0px 10px 0px 10px"}}>   
             <>
             {/* 
             HÄR ÄR EN PLACEHOLDER FÖR SÖKFUNKTION
             <span>
             {" "}
             <input
               placeholder={`${i18n.t("dashboard.search")}`}
               type="text"
               onChange={(e) => SearchData(e,this.props.reportType,this.state.data.report)}
             />
             <FontAwesomeIcon
               style={{ paddingLeft: "5px" }}
               icon="magnifying-glass"
             />
             <i style={{ paddingLeft: "5px" }}>
               {" "}
               {this.state.searchedData.length > 0
                 ? `${this.state.searchedData.length} ${i18n.t(
                     "dashboard.hits"
                   )}`
                 : (this.state.searched ? i18n.t('dashboard.noHits'):'')}
             </i>
           </span> */}
             <EditInvoiceReportTable customers={customers} contract={contract} copyRow={copyRow} handleDeleteModal={handleDeleteModal} deleteModal={deleteModal} deleteRow={deleteRow} addRow={addRow} reportType={reportType} invoiceKey={ reportType === 'Invoicereport' || reportType === 'Projectreport' 
    ? 'invoices' 
    : reportType === 'InvoicereportWithCSRD' || reportType === 'ProjectreportWithCSRD' 
      ? 'csrdGroup' 
      : 'invoices'} data={{...data}} callBack={callBack}/>
             </>
         </Accordion>  
         <div style={{display:'flex',flexDirection:'row-reverse'}}>
         <Button
                style={{width:'fit-content'}}
                disabled={ !IsUserSinfra()}
                className="button button-submit"
                onClick={() => handleDeleteModal('SUBMIT',true,-1)}
              >
                {submitActive ? (
                  <Spinner animation="border" size={"sm"} />
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      style={{ marginRight: 4 }}
                    />
                    {t("general.send")}
                  </>
                )}
              </Button>
              </div>
         </>
    )
  
  }
