/* eslint-disable @typescript-eslint/no-useless-constructor */
//i render så jag jag en property som stry diplay none eller inte,
//prop för data som ska visas
//intert state för när den stängs
//timer för hur länge den är öppen innan den stängs?
//prop för bakgrund?
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";
import { IconLookup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { Code, ReturnFetchObj } from "../../lib/utils";
import * as React from "react";
import { withTranslation } from "react-i18next";

type FetchReturn<T> = {
  Content:T;
  Message: string;
  Code:Code
}
interface Props {
 /** pass function from parent to handle toast message onClick 
  *  conditional render the toaster component with the parents boolean state
 */
  handleToastClose: (close:boolean) => void;
  /** Message needs to be a FetchReturn*/
  message:FetchReturn<typeof ReturnFetchObj>;
  /**boolean to hide or show the toaster */
  show:boolean;
  /**set time in ms for the autoclose animation to start, if not passed default value = 2000ms */
  closeDelayMs?:number;
  /** if the toaster should close automaticly or onClick, default value true */
  autoClose?:boolean;
  /**pass fontawsome iconname if you want to render a different icon then check | triangle-exclamation */
  customIcon?:string;
  /*Inital positioning*/
  initial?:boolean; 
}
interface State {
  startCloseAnimation:boolean;
  icon:JSX.Element
}
//**TOOOOOOOS */
class Toaster extends React.Component<Props, State> {

    constructor(props: Props) {
    super(props);
    this.state = {startCloseAnimation:false, icon:<></>};
  }
  componentDidMount(): void {
    this.onInit();
  }
  private autoCloseToaster = () => {
   if(typeof this.props.autoClose !== 'undefined' && this.props.autoClose){
    this.setState({startCloseAnimation : true})
    setTimeout(this.callParentClose,
      typeof this.props.closeDelayMs === 'undefined' ? 2000 : this.props.closeDelayMs)
   }
   else{
    this.setState({startCloseAnimation : false})
   }
   
  }
  private callParentClose = () => {
    this.props.handleToastClose(false)
  }
 private onInit =() =>{

    const name:any = this.props.message.Code === 0 ? 'check' : 'triangle-exclamation'
    const lookup:IconLookup = {prefix:'fas', iconName:typeof this.props.customIcon !== 'undefined' ? this.props.customIcon :name }
    const icon =
      <> 
      <FontAwesomeIcon 
      size="lg" 
      style={{color:'white'}} 
      width={20} 
      icon={findIconDefinition( lookup )}/>  
      </>
    this.setState({icon:icon})
     
    setTimeout(this.autoCloseToaster,4000);
  }
  render() {
    console.log(this.props.initial)
    return (  
           
        <div
          className={ "toasterContainer"}
          style={this.props.show ?  {position:this.props.initial == true ? 'initial':"relative"} : { display: "none" } }
          onClick={() => this.props.handleToastClose(false)}
          
        >
          <div className={this.state.startCloseAnimation ?"toasterCloseContainer":"toasterContentContainer"} style={this.props.message.Code === 0 ?{backgroundColor:'#38b03a'} :{backgroundColor:'#cc423b'}}>
          {/* {!this.state.jaja ? 
          (<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrWwxSLmg5wGQR0yfsFjZ8ffAuUnjcApYcKw&usqp=CAU"  alt="h" width={100} height={100}></img>)
          :(<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSLRQeBanQX_8Zmm3jlyeL9GtxgC-rzmccAA&usqp=CAU" alt="h" width={100} height={100}></img>)
          } */}
          
         
           <div className="marginWrapper" /*style={{margin:'5px 25px 10px 15px'}}*/>   
           <span className="toasterMsg">{this.state.icon}{this.props.message.Message} </span>
           </div>
          </div>
          
        </div>
        
      
    );
  }
}
export default withTranslation()(Toaster);
