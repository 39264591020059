/* eslint-disable array-callback-return */

import * as React from "react";
import { withTranslation } from "react-i18next";
import { Form, Spinner, Stack } from "react-bootstrap";
import { SvgForExportView } from "./SvgIcons";
import { DataService } from "../../services/DataService";
import ViewModal from "./ViewModal";
import i18n from "../../i18n";
interface IViewCardProps {}
interface IViewCardState {
  selectedView: string;
  selectedViewData: any;
  showViewModal: boolean;
  loading: boolean;
  viewsList: string[];
  viewTyping: ViewTypings;
}
interface ViewTypings {
  [key: string]: { [columnName: string]: any };
}

class ViewCard extends React.Component<IViewCardProps, IViewCardState> {
  constructor(props: IViewCardProps) {
    super(props);
    this.state = {
      selectedView: "",
      selectedViewData: [],
      showViewModal: false,
      loading: false,
      viewsList: [],
      viewTyping: {},
    };
  }
  async componentDidMount(): Promise<void> {
    this.setState({ loading: true });
    const available = await DataService.Dashboard.GetListOfAvailableLEV_views();
    const set = new Set<string>();
    available.map((x) => set.add(x.ViewName));
    const result: string[] = [];
    result.push(i18n.t("dashboard.view"));
    set.forEach((x) => result.push(x));
    const typed = await this.BuildTypings(available);
    this.setState({ viewsList: result, viewTyping: typed }, () =>
      this.setState({ loading: false }, () => console.log())
    );
  }
  BuildTypings = async (available: any[]): Promise<ViewTypings> => {
    
    const res: ViewTypings = available.reduce((result: ViewTypings, item) => {
      const key1 = `${item.ViewName}`;

        if (!result[key1]) {
          result[key1] = { [item.ColumnName]: item.Datatype };
        } else {
          result[key1][item.ColumnName] = item.Datatype;
        }
      return result;
    }, {});
    return res;

  };
  GenerateViews = () => {
    return this.state.viewsList.map((x) => {
      return (
        <option
          style={{ cursor: "pointer" }}
          key={x}
          // onClick={(e) => this.OnSelectedViewHandle(e.currentTarget.value)}
        >
          {x}
        </option>
      );
    });
  };
  OnSelectedViewHandle = async (view: string) => {
    if (view !== i18n.t("dashboard.view")) {
      this.setState({ loading: true });
      const selectedViewData = await this.fetchSelectedViewData(view);
      this.setState({
        selectedView: view,
        selectedViewData,
        showViewModal: true,
        loading: false,
      });
    } else {
      this.setState({ selectedView: view });
    }
  };
  fetchSelectedViewData = async (view: string) => {
    return await DataService.Dashboard.Get_LEV_Views(view);
  };
  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <Stack>
            <div
              style={{
                borderRadius: "5px",

                backgroundColor: "white",
                display: "flex",
                marginTop: "10px",
              }}
            >
              <Form.Label>{SvgForExportView()}</Form.Label>

              <Form.Control
                value={
                  this.state.selectedView.length > 0
                    ? this.state.selectedView
                    : " "
                }
                as="select"
                onChange={(e) => this.OnSelectedViewHandle(e.target.value)}
              >
                {/* <Form.Select  defaultValue={this.state.selectedView} style={{ textTransform: "capitalize" }}> */}
                {this.GenerateViews()}
                {/* </Form.Select> */}
              </Form.Control>
              <div style={{ marginLeft: "5px" }}>
                <div
                  style={{
                    marginTop: "5px",
                    marginRight: "10px",
                    marginBottom: "5px",
                  }}
                ></div>
              </div>
            </div>
            {this.state.showViewModal && this.state.selectedViewData.length > 0 && (
              <ViewModal
                loading={this.state.loading}
                viewName={this.state.selectedView}
                data={this.state.selectedViewData}
                typing={this.state.viewTyping[this.state.selectedView]}
                onDissmissHandler={() =>
                  this.setState({ showViewModal: false })
                }
              />
            )}
          </Stack>
        )}
      </div>
    );
  }
}

export default withTranslation()(ViewCard);
