import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IsUserUserManager, apiFetch } from '../lib/utils';
import { DataService } from '../services/DataService';
import Datalist, { FooterOptions, ListColumn } from '../components/Datalist/Datalist';
import { TUser } from '../services/API/Users';
import { Button, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faSearch } from '@fortawesome/free-solid-svg-icons';
import { SearchBar } from '../components/SearchBar/SearchBar';
import StyledCheckbox from '../components/StyledCheckbox/StyledCheckbox';

interface Props extends WithTranslation {

}

interface State {
  isLoading: boolean;
  users: TUser[];
  columns: ListColumn<TUser>[];
  searchText:string;
  onlyShowActive: boolean;
}

class UserManagementPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    
    this.state = {
      isLoading: true,
      users:[],
      columns: this.columns,
      searchText: "",
      onlyShowActive: true
    }

  }

  async componentDidMount(): Promise<void> {
    
    if(!IsUserUserManager())
      window.location.href = "/"
    const users = await DataService.Users.Get();
    
    this.setState({
      isLoading: false,
      users: users
    });
  }

  private columns : ListColumn<TUser>[] = [
    {
      name: this.props.t("general.user"),
      fieldName: "email",
      key: "email",
      minWidth: 100,
      isActive: true,
      sorting: true,
      onRender:(item,col) => {
        return item.email.includes('@') ? <a href={`mailto:${item.email}`}>{item.email}</a> : item.email
      }
    },
    {
      name: this.props.t("general.firstname"),
      fieldName: "firstName",
      key: "firstName",
      minWidth: 100,
      isActive: true,
      sorting: true
    },
    {
      name: this.props.t("general.lastname"),
      fieldName: "lastName",
      key: "lastName",
      minWidth: 100,
      isActive: true,
      sorting: true
    },
    {
      name: this.props.t("general.status"),
      fieldName: "enabled",
      key: "enabled",
      minWidth: 40,
      isActive: true,
      sorting: true,
      onRender: (item, col) => <div>{item.enabled ? this.props.t("general.active") : this.props.t("general.inactive")}</div>
    }
  ]

  render() {
    const users = this.state.searchText.length > 0 ?
      this.state.users.filter(user => {
        return user.firstName?.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase()) 
        || user.lastName?.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase()) 
        || user.email?.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase());
      })
      : this.state.users;
    const filteredByActive = this.state.onlyShowActive == true ?
      users.filter(user => {
        return user.enabled
      })
      : users;
    return (
      <Stack gap={4}>
        <div>
          <h2>{this.props.t('userManagement.title')}</h2>
          {/* <p>{this.props.t('userManagement.description')}</p> */}
        </div>
        <div className='list-toolbar'>
          <SearchBar
            onChange={(newVal) => { this.setState({ searchText: newVal }) }}
            value={this.state.searchText}
            placeholder={this.props.t("userManagement.searchPlaceholder").toString()}
          />
          <StyledCheckbox isChecked={this.state.onlyShowActive} label={this.props.t("userManagement.showOnlyActive").toString()} onChange={(newval) => this.setState({onlyShowActive: newval})}/>
          <Button style={{marginLeft: 'auto'}} onClick={() => {window.location.href = "/newuser"}}>
            <FontAwesomeIcon icon={faAdd} style={{marginRight:"4px"}}/>
            {this.props.t("general.addUser")}
          </Button>
        </div>
        <div style={{height: "50vh"}}>
          <Datalist 
            uniqueKey='id'
            items={filteredByActive} 
            columns={this.state.columns} 
            setColumns={(cols) => this.setState({columns: cols})} 
            noItemsText={this.props.t('userManagement.noUsersToShow')} 
            initialSortKey='username'
            onClick={(user) => { window.location.href = `/edituser/${user.id}` }}
            isLoading={this.state.isLoading}
          />
        </div>
      </Stack>
   );
  }
 
};

export default withTranslation()(UserManagementPage);