import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Form, Nav, Navbar, NavDropdown, NavLink } from 'react-bootstrap';
import { longLogoSrc, longLightLogoSrc } from "../../constants/constants";
import { Theme } from "../../lib/types";
import UserNavProfile from './UserNavProfile';

import './navigationbar.scss';
import { getImpersonationUser, CanProjectReport, IsImpersonating, IsUserSinfra, IsUserUserManager } from "../../lib/utils";
import LangSelect from "./LangSelect";
import AuthService from "../../services/AuthService";
import { DataService } from "../../services/DataService";
import { TSupplier } from "../../services/API/Suppliers";
import SupplierSelect from "./SupplierSelect";

type Props = {
  theme?: Theme
}

const Navigationbar = (props: Props) => {
  const { t, i18n } = useTranslation();
  const [user, setUser] = useState<any>();
  const [userSuppliers, setUserSuppliers] = useState<TSupplier[]>([]);
  const [grantedPermissionForProjectReport,setGrantedPermissionForProjectReport] = useState(false)
  const { theme } = props;

  useEffect(() => {
    const _user = IsImpersonating() ? getImpersonationUser() : AuthService.getUser();

    const promises = [];
    for (let i = 0; i < _user.groups.length; i++) {
      const supplierOrgNr = _user.groups[i];
      if(supplierOrgNr != AppSettings.sinfraBaseGroupName)
        promises.push(DataService.Suppliers.GetByOrgNr(supplierOrgNr).then(supp => supp))
    }
    Promise.all(promises).then((suppliers) => {
      setUser(_user);
      setUserSuppliers(suppliers)
    })

  }, [])
  useEffect ( () => {
    const perm = async () => {
    const res =  await CanProjectReport()
    const haha =  res == false ? IsUserSinfra() : res
    setGrantedPermissionForProjectReport(res);
     
    }
     perm()
  },[user])
  
  return (
    <div className={`navigationbar-root ${theme}`}>
      <Navbar expand="xl" variant={theme === 'dark-theme' ? 'dark' : undefined}>
        <Navbar.Toggle className="navigationbar-item" />
        <Navbar.Brand href="/">
          <img className="navigationbar-logo" src={theme === 'dark-theme' ? longLightLogoSrc : longLogoSrc} />
        </Navbar.Brand>
        <Nav className="justify-content-end show-on-mobile">
          <UserNavProfile user={user} userSuppliers={userSuppliers}/>
        </Nav>
        <Navbar.Collapse className="navigationbar-item ml-auto">
          <Nav className="mr-auto" style={{paddingBottom: 14}}>
            <NavLink href="/" >
              {t('navigationLinks.home')}
            </NavLink>
            {
              IsUserSinfra() &&
              <>
                <NavDropdown title={t('navigationLinks.report')} id="nav-stat-dd" >
                  <NavDropdown.Item href="/report">
                    {t('navigationLinks.standardReport')}
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/kickback-project">
                    {t('navigationLinks.projectReport')}
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            }
            {
              !IsUserSinfra() &&
              <>
                <NavLink href={grantedPermissionForProjectReport ? "/kickback-project" : "/report"}>
                  {t('navigationLinks.report')}
                </NavLink>
              </>
            }
            {
              !IsUserSinfra() &&
              <>
                <NavDropdown title={t('navigationLinks.reports')} id="nav-stat-dd" >
                  <NavDropdown.Item href="/invoice-report-list">
                    {t('navigationLinks.invoiceReportList')}
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            }
            <NavLink href="https://sinfra.se/upphandlingskalender/" target="_blank">
            {t('navigationLinks.procurementCalendar')}
            </NavLink>
            <NavDropdown title={t('navigationLinks.sinfra.se')} id="nav-stat-dd" >
              <NavDropdown.Item href="https://sinfra.se/" target="_blank">
              {t('navigationLinks.sinfraHomePage')}
              </NavDropdown.Item>
              <NavDropdown.Item href="https://sinfra.se/nyheter/" target="_blank">
              {t('navigationLinks.sinfraNews')}
              </NavDropdown.Item>
              <NavDropdown.Item href="https://sinfra.se/leverantorsstampel/" target="_blank">
              {t('navigationLinks.sinfraSupplierStamp')}
              </NavDropdown.Item>
              <NavDropdown.Item href="https://sinfra.se/medlemmar/" target="_blank">
              {t('navigationLinks.sinfraMembers')}
              </NavDropdown.Item>
            </NavDropdown>
            {
              IsUserSinfra() &&
              <>
                <NavDropdown title={t('navigationLinks.administration')} id="nav-stat-dd" >
                  {
                    IsUserUserManager() &&
                    <>
                      <NavDropdown.Item href="/usermanagement">
                        {t('navigationLinks.users')}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/suppliermanagement">
                        {t('navigationLinks.suppliers')}
                      </NavDropdown.Item>
                    </>
                  }
                <NavDropdown.Item href="/kickback-list">
                  {t('navigationLinks.kickbackReportAdmin')}
                </NavDropdown.Item>
                <NavDropdown.Item href="/invoice-report-list">
                  {t('navigationLinks.invoiceReportList')}
                </NavDropdown.Item>
                <NavDropdown.Item href="/nullreport-list-page">
                  {t('dashboard.nullReports')}
                </NavDropdown.Item>
                <NavDropdown.Item href="/contractManagement">
                  {t('navigationLinks.contracts')}
                </NavDropdown.Item>
                
                <NavDropdown.Item href="/dashboard">
                  Dashboard{/* {t('navigationLinks.contracts')} */}
                </NavDropdown.Item>
                
              </NavDropdown>
              </>
            }
          <LangSelect/>
            {/* <SupplierSelect suppliers={userSuppliers} /> */}
          </Nav>
        </Navbar.Collapse>
        <Nav className="justify-content-end hide-on-mobile">
          <UserNavProfile user={user} userSuppliers={userSuppliers}/>
        </Nav>
      </Navbar>
    </div>
  );
};

export default Navigationbar;