import AuthService from '../services/AuthService';

import { HttpMethod, userNameObject, userProfileObject } from "./types";
import { userColorArray } from "../constants/constants";
import { API } from '../services/API';
import { t } from 'i18next';
import { InvoiceImportData } from '../services/API/InvoiceReports';
import { DataService } from '../services/DataService';

const stringToNumericalValue = (str: string): number => {
    return Array.from(str).reduce((acc, _, i) => {
        return acc + str.charCodeAt(i);
    }, 0);
}

export const getInitials = (user: userNameObject) => {
    if(user.family_name && user.given_name) {
        return user.given_name.charAt(0).toUpperCase() + user.family_name.charAt(0).toUpperCase()
    }
    else {
        return user.preferred_username.charAt(0).toUpperCase()
    }
}

export const getProfileObject = (user: userNameObject): userProfileObject => {
    const initials = getInitials(user);
    const cIndex = stringToNumericalValue(user.preferred_username);
    const cObj = userColorArray[cIndex % userColorArray.length];
    
    return {
        initials: initials,
        bgColor: cObj.bg,
        txtColor: cObj.txt
    }
}

export function getJwtToken() {
    if(!IsImpersonating())
        return AuthService.getToken();
    const tokenInfoString = window.sessionStorage.getItem(ImpersonationKEY);
    const tokenInfo = JSON.parse(tokenInfoString as string);
    if(tokenInfo.access_token)
        return tokenInfo.access_token;
    window.sessionStorage.removeItem(ImpersonationKEY);
    window.location.reload();
}

async function baseFetch(url: string, method: HttpMethod = 'GET', body: any = {}, headers: any = {}, redo: boolean = false): Promise<Response> {
  await AuthService.ensureToken();
  let jwt = getJwtToken();
  return fetch(url, {
      method: method,
      body: method != 'GET' ? body : undefined,
      headers: {
          Authorization: `Bearer ${jwt}`,
          ...headers
      }
  })
  .then(async response => {
      if(response.status == 401){
          const reason = await response.text();
          if(redo == false){
              await API.Users.RefreshImpersonation();
              return baseFetch(url, method, body, headers, true);
          }
      }
      if(!response.ok)
          throw response;
      return response;
  })
  .catch((e) => {
      throw e;
  });
}

export const apiFetch = (url: string, method: HttpMethod = 'GET', body: any = {}, headers: any = {}): Promise<Response> =>
    baseFetch(AppSettings.APIprefix + url, method, body, {...headers, ActiveSupplier: getActiveSupplier()});


export const kcFetch = (url: string, method: HttpMethod = 'GET', body: any = "", headers: any = {}): Promise<Response> => 
    baseFetch(AppSettings.KCprefix + url, method, body, headers);


export enum Code {
	OK = 0,
	ADD_ERR = 1,
	UPDATE_ERR = 2,
	DUPLICATE_ERR = 3,
	DELETE_ERR = 4,
	GET_ERR = 5,
    NOT_FOUND = 6
}

export type FetchReturn<T> = {
    Content:T;
    Message: string;
    Code:Code
}

export const ImpersonationKEY = "lev_impersonation_token";
export function IsImpersonating() : boolean {
    const maybeToken = window.sessionStorage.getItem(ImpersonationKEY);
    return maybeToken != undefined;
}

export function ReturnFetchObj <T,>(msg:string, code:Code, content:T) : FetchReturn<T>  {
    return {Message:msg, Code: code, Content:content };
}

export function isInteger(value: any): boolean {
    return !isNaN(parseInt(value)) && (parseFloat(value) == parseInt(value));
}

export function IsUserNewsEditor() : boolean {
    const user = AuthService.getUser();
    return (user?.realm_access?.roles.includes(AppSettings.newsEditorRole) && !IsImpersonating()) ?? false;
}

export function IsUserUserManager() : boolean {
    const user = AuthService.getUser();
    return (user?.realm_access?.roles.includes(AppSettings.userManagerRole) && !IsImpersonating()) ?? false;
}

export function IsUserSinfra() : boolean {
    const user = AuthService.getUser();
    return (user?.groups.includes(AppSettings.sinfraBaseGroupName) && !IsImpersonating()) ?? false;
}

export function RoundTo(value:number, precision: number) : number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export function FloorTo(value:number, precision: number) : number {
  const multiplier = Math.pow(10, precision || 0);
  return Math.floor(value * multiplier) / multiplier;
}

export function NumberFormatter(num: number, digits: number) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "t" },
      { value: 1e6, symbol: "M" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

  export function StringToNumber(str:string) {
    const value = str.replaceAll(" ", "").replaceAll(',',".");
    return parseFloat(value)
  }

  export function ReadFriendlyNumber(val:number) : string {
    return val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ').replace(".", ",")
  }
  export function ReadPercentageForProjectReport(val:number) : string {
    //console.log(val)
    const perc = val  * 100
    
    //console.log(perc.toFixed(1))
    return perc.toFixed(1)
  }
export function parseJwt (token:string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function convertToQuerystring(opt:any) {
    return Object.keys(opt ?? {}).reduce((prev, currKey, i, self) => {
        //@ts-ignore
        const potentialValue = opt[currKey];
        if(potentialValue){
          return prev.concat(`${currKey}=${potentialValue}`);
        }
        return prev;
      }, [] as string[]).join("&");
}

export async function downloadFile(fileName:string, fileData: Blob) {
    const aElement = document.createElement('a');
    aElement.setAttribute('download', fileName);
    const href = URL.createObjectURL(fileData);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);
}

export function getImpersonationUser() {
    const item = window.sessionStorage.getItem(ImpersonationKEY);
    const jwt = JSON.parse(item as string);
    const accessToken = parseJwt(jwt.access_token);
    return accessToken;
}

export function getSupplierOrgNr() {
    const token = IsImpersonating() ? getImpersonationUser() : AuthService.getUser();
    //@ts-ignore
    const groups:string[] = token.groups;
    const potentialOrgNr = groups.find(group => group != window.AppSettings.sinfraBaseGroupName);
    if(potentialOrgNr)
        return potentialOrgNr;
    // throw "No valid supplierOrgNr";
    return "";
}

export function IsReportingDivided() {
    //@ts-ignore
    const canReport = AuthService.getUser().divided_reporting;
    if(canReport)
        return canReport;
    return false;
}

const timerObj : {[key:string]: number} = {}
export function delayedAction(id:string, delayMs: number, callback:Function) {
    if(timerObj[id])
        clearTimeout(timerObj[id]);
    const timerId = setTimeout(callback, delayMs);
    timerObj[id] = timerId;
}

export function parseDate_ddMMyyyy(
  date: string,
  separator: " " | "." | "-" | "/"
) {
  let newDateString = "";
  let dateSplitted = date.split(separator);
  let elementCounter = 0;
  for (let index = dateSplitted.length - 1; index >= 0; index--) {
    const element: number = +dateSplitted[index];
    if (element < 10 && elementCounter < dateSplitted.length) {
      newDateString +=
        elementCounter < dateSplitted.length - 1
          ? "0" + element + "-"
          : "0" + element;
    } else {
      newDateString +=
        elementCounter < dateSplitted.length - 1 ? element + "-" : element;
    }
    elementCounter++;
  }
  return newDateString;
}

export function parseDate_yyyyMMdd(
  date: string,
  separator: " " | "." | "-" | "/"
) {
  let newDateString = "";
  let dateSplitted = date.split(separator);
  let elementCounter = 0;
  for (let index = 0; index < dateSplitted.length; index++) {
    const element: number = +dateSplitted[index];
    if (element < 10 && index < dateSplitted.length - 1) {
      newDateString +=
        elementCounter < dateSplitted.length - 1
          ? "0" + element + "-"
          : "0" + element;
    } else if (element < 10 && elementCounter === dateSplitted.length - 1) {
      newDateString += "0" + element;
    } else if (element > 10 && elementCounter === dateSplitted.length - 1) {
      newDateString += element;
    } else {
      newDateString += elementCounter === 0 ? element + "-" : element;
    }
    elementCounter++;
  }
  return newDateString;
}

export function parseDate_dMyyyy(
  date: string,
  separator: " " | "." | "-" | "/"
) {
  let newDateString = "";
  let dateSplitted = date.split(separator);
  let elementCounter = 0;
  for (let index = dateSplitted.length - 1; index >= 0; index--) {
    const element: number = +dateSplitted[index];
    if (element < 10 && elementCounter < dateSplitted.length) {
      newDateString +=
        elementCounter < dateSplitted.length - 1
          ? "0" + element + "-"
          : "0" + element;
    } else {
      newDateString +=
        elementCounter < dateSplitted.length - 1 ? element + "-" : element;
    }
    elementCounter++;
  }
  return newDateString;
}

function getActiveSupplierStorageKey() {
  const isImpersonating = IsImpersonating();
  const user = isImpersonating ? getImpersonationUser() : AuthService.getUser();
  //@ts-ignore
  return `activeSupplier_${user.preferred_username}`;
}

export function getActiveSupplier(){
  const isImpersonating = IsImpersonating();
  const user = isImpersonating ? getImpersonationUser() : AuthService.getUser();
  //@ts-ignore
  const sessionStorageKey = getActiveSupplierStorageKey();
  const potentiallyActiveSupplier = sessionStorage.getItem(sessionStorageKey);
  if(potentiallyActiveSupplier){
    return potentiallyActiveSupplier;
  }
  //@ts-ignore
  const shouldBeActive =  user.groups.find(group => group != window.AppSettings.sinfraBaseGroupName) ?? "";
  if(shouldBeActive){
    sessionStorage.setItem(sessionStorageKey, shouldBeActive);
    return shouldBeActive;
  }
  return ""
}

export function setActiveSupplier(supplierOrgNr: string) {
  const sessionStorageKey = getActiveSupplierStorageKey();
  sessionStorage.setItem(sessionStorageKey, supplierOrgNr)
}

export const CanProjectReport =  async ():Promise<boolean> => {
  const supp =  getActiveSupplier()
    const projectReport = await DataService.Suppliers.GetByOrgNr(supp)
    return typeof projectReport.project_report !== 'undefined' ? projectReport.project_report : false
}
export const CheckReportPermission = async (url:string):Promise<void> => {
  const[grantedPermissionToProjectReport,sinfraUser] = [await CanProjectReport(),IsUserSinfra()]
   if(grantedPermissionToProjectReport && !sinfraUser && !url.includes('kickback-project')){
      window.location.href = "/kickback-project";
    }
    else if(!grantedPermissionToProjectReport && !sinfraUser && url.includes('kickback-project')){
      window.location.href = "/report";
    }
}