/* eslint-disable array-callback-return */

import * as React from "react";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { StyledModal } from "../modal/StyledModal";
import Datalist, { ListColumn } from "../Datalist/Datalist";
import {  IInvoiceDataForPieChart } from "../../services/API/DashBoard";
import { Button, Stack } from "react-bootstrap";
import CsvDownloader from "react-csv-downloader";
import { ReadFriendlyNumber } from "../../lib/utils";

interface ICategoryModalProps {
  onDissmissHandler: () => void;
  data: IInvoiceDataForPieChart[];
  period: string;
  footerSum:number;
  selectedCategory:string;
}
interface ICategoryModalState {
  loading: boolean;
  columns: ListColumn<IInvoiceDataForPieChart> | any;
}

class CategoryModal extends React.Component<
  ICategoryModalProps,
  ICategoryModalState
> {
  date = new Date().toLocaleDateString("sv-SE");
  columns: ListColumn<IInvoiceDataForPieChart>[] = [
    {
      fieldName: "contractId",
      isActive: true,
      key: "contractId",
      minWidth: 50,
      maxWidth: 100,
      sorting: true,
      name: i18n.t('dashboard.contractId'),
     
    },
    {
      fieldName: "contractName",
      isActive: true,
      key: "contractName",
      minWidth: 100,
      maxWidth: 140,
      sorting: true,
      name: i18n.t('dashboard.contractName')
     

    },
    {
      fieldName: "supplierOrgId",
      isActive: true,
      key: "supplierOrgId",
      minWidth: 100,
      maxWidth: 140,
      sorting: true,
      name: i18n.t("dashboard.supplierOrgId"),
    },
    {
      fieldName: "supplierName",
      isActive: true,
      key: "supplierName",
      minWidth: 100,
      maxWidth: 140,
      sorting: true,
      name: i18n.t("dashboard.supplierName") ,
    },
    {
      fieldName: "noInvoices",
      isActive: true,
      key: "noInvoices",
      minWidth: 100,
      maxWidth: 140,
      sorting: true,
      name:i18n.t("dashboard.noInvoices") ,
    },
    {
      fieldName: "priceExcMoms",
      isActive: true,
      key: "priceExcMoms",
      minWidth: 100,
      maxWidth: 140,
      sorting: true,
      name:i18n.t("dashboard.priceExcMoms"),
      footer: (items) => {
        
        return ReadFriendlyNumber(this.props.footerSum);
      },
    },
  
    
  ];
  constructor(props: ICategoryModalProps) {
    super(props);
    this.state = { loading: true, columns: this.columns };
  }
  componentDidMount(): void {
    this.setState({ loading: false });
  }

  render() {
    
    return (
      <div>
        {!this.state.loading && (
          <StyledModal
            title={i18n.t(`dashboard.category.${this.props.selectedCategory}`) ?? ''}
            className="full-width"
            onDismiss={
              () => this.props.onDissmissHandler() 
            }
            body={
              <div style={{ maxHeight: "26vh" }}>
                <Datalist
                  items={this.props.data ?? []}
                  uniqueKey="invoiceNumber"
                  columns={this.state.columns}
                  noItemsText={
                    i18n.t('dashboard.noCategories') 
                  }
                  initialSortKey="billingDate"
                  descendingSort={false}
                  setColumns={(cols) => this.setState({ columns: cols })}
                  
                />
               
              </div>
            }
            footer={
              <Stack direction="horizontal" gap={2}>
                <CsvDownloader
                  datas={this.props.data as any}
                  extension=".csv"
                  filename={`${
                    this.props.period
                  }-${this.props.selectedCategory}-${i18n.t("print.csvFileName")}`}
                  separator=";"
                >
                  <Button className="primary">
                    {i18n.t("print.exportButton")}
                  </Button>
                </CsvDownloader>

                <Button
                  className="button button-cancel"
                  onClick={this.props.onDissmissHandler}
                >
                  {i18n.t("dashboard.closeButton")}
                </Button>
              </Stack>
            }
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(CategoryModal);
