import * as React from 'react';
import { InvoiceFields } from '../../services/API/InvoiceReports';
import { StyledModal } from './StyledModal';
import { DataService } from '../../services/DataService';
import { Button, Form, Spinner, Stack } from 'react-bootstrap';
import { Code, IsUserSinfra, ReadFriendlyNumber } from '../../lib/utils';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useCurrentDepartmentHook } from '../SupplierBar/SupplierBar';
import { Department } from '../../services/API/Departments';

export interface SubmitInvoiceReportModalProps {
  invoices: InvoiceFields[];
  onDismiss():void;
  disabled:boolean;
  onSubmitted():void;
  reportingArticles:boolean;
}

export function SubmitInvoiceReportModal (props: SubmitInvoiceReportModalProps) {
  const [isSaving, setSaving] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const { t, i18n } = useTranslation();
 const {currentDepartment} =  useCurrentDepartmentHook();
 const departMent:Department | undefined = currentDepartment()
  
  const onDismiss = () => {
    if(isSaving)
      return;
    props.onDismiss();
  }

  const onClickSubmit = async () => {
    if((comment.trim().length < 1 && statusObj.warnings > 0) || statusObj.errors > 0){
      return;
    }
    setErrorMessage("");
    setSaving(true);
    
    const result = await DataService.InvoiceReports.Post(props.invoices, comment, props.reportingArticles,departMent?.id);
    console.log(result)
    if(result.Code == Code.OK){
      props.onSubmitted();
      return;
    }
    setErrorMessage(result.Message);
    setSaving(false);
  }

  const statusObj = DataService.InvoiceReports.Validations.GetValidationStatus(props.invoices, props.reportingArticles);
  const sum = props.invoices.reduce((prev, curr, i, self) => {
    const value = curr.rowSumExclMoms.value.replaceAll(" ", "").replaceAll(',',".");
    return prev + parseFloat(value)
  }, 0.0);
  const formattedSum = ReadFriendlyNumber(sum);
  return (
    <StyledModal 
      onDismiss={onDismiss}
      title={t('invoiceReport.previewModal.title').toString()}
      body={
        <Stack className='submit-modal-body' gap={3}>
          <table >
            <tr>
              <td>{t('invoiceReport.previewModal.noOfRows')}:</td>
              <td>{props.invoices.length}</td>
            </tr>
            <tr>
              <td>{t('invoiceReport.previewModal.warnings')}:</td>
              <td className='warning'>{statusObj.warnings}</td>
            </tr>
            <tr>
              <td>{t('invoiceReport.previewModal.errors')}:</td>
              <td className='error'>{statusObj.errors}</td>
            </tr>
            <tr>
              <td>{t('invoiceReport.previewModal.totSum')}:</td>
              <td>{formattedSum.toString() + " Kr"}</td>
            </tr>
          </table>
          {
            statusObj.errors == 0 &&
            <Form.Group>
              <Form.Label >{t('general.comment')}:</Form.Label>
              <Form.Control isInvalid={comment.trim().length < 1 && statusObj.warnings > 0} as="textarea" rows={3} value={comment} onChange={(ev) => setComment(ev.target.value)}/>
              <Form.Control.Feedback type="invalid">
                {t("invoiceReport.previewModal.reqComment").toString()}
              </Form.Control.Feedback>
            </Form.Group>
          }
          {
            statusObj.errors > 0 &&
            <div className='error-message'>
              <span>{t('general.errorMsg')}:</span>
              {t("invoiceReport.previewModal.cannotSendWithErrors")}
            </div>
          }
          {
            errorMessage &&
            <div className='error-message'>
              <span>{t('general.errorMsg')}:</span>
              {errorMessage}
            </div>
          }
        </Stack>
      }
      footer={
      <Stack direction="horizontal" gap={2}>
        <Button disabled={props.disabled || isSaving || props.invoices.length == 0 || IsUserSinfra() || statusObj.errors > 0 || (comment.trim().length < 1 && statusObj.warnings > 0)} className="button button-submit" onClick={onClickSubmit}>
          {
            isSaving ?
            <Spinner animation="border" size={'sm'}/>
            :
            <><FontAwesomeIcon icon={faPaperPlane} style={{marginRight: 4}}/>{t('general.send')}</>
          }
        </Button>
        <Button disabled={isSaving} className="button button-cancel" onClick={onDismiss}>{t('general.close')}</Button>
      </Stack>}
    />
  );
}
