import { t } from "i18next";
import Urls from "../../lib/Urls";
import { Code, ReturnFetchObj, apiFetch, convertToQuerystring } from "../../lib/utils";
import { FieldProperty } from "./InvoiceReports";

export interface Contract {
  id:number;
  contractId:string;
  supplierOrgNr: string;
  contractName: string;
  contractTypeId: number;
  contractType: string;
  monthlySum?:string;
  monthlySumProp?: FieldProperty;
  kickbackProcent:string;
  supplierName:string;
  archived: boolean;
  startDate?: Date | null;
  terminationDate?: Date | null;
  reportingFromDate?: Date | null;
  reportingToDate?: Date | null;
  detached:boolean;
  procurementValue?:number;
}

export interface ContractValidation {
  contractTypeId: string;
  supplierName: string;
  contractName: string;
  contractId: string;
  kickbackProcent:string;
  procurementValue:string;
}

export interface ContractField {
  id:number;
  customer: FieldProperty;
  customerOrgId:FieldProperty;
  contractId: FieldProperty;
  invoiceNumber:FieldProperty;
  billingDate:FieldProperty;
  sumExclMoms:FieldProperty;
}

type GetOpt = {
  onlyactive?: boolean;
  supplierOrgId?:string;
}

const mapToContract = (apiContract:any) : Contract => {
  return {
    id: apiContract.id,
    contractId: apiContract.contractId,
    supplierOrgNr:apiContract.orgNr,
    contractName: apiContract.contractName,
    contractType: apiContract.contractType,
    contractTypeId: apiContract.contractTypeId,
    kickbackProcent: apiContract.kickbackProcent,
    supplierName: apiContract.orgName?.trim(),
    archived: apiContract.archived,
    startDate: apiContract.startDate ? new Date(apiContract.startDate) : null,
    terminationDate: apiContract.terminationDate ? new Date(apiContract.terminationDate) : null,
    reportingFromDate: apiContract.reportingFromDate ? new Date(apiContract.reportingFromDate) : null,
    reportingToDate: apiContract.reportingToDate ? new Date(apiContract.reportingToDate) : null,
    detached: apiContract.detached,
    procurementValue: apiContract.procurementValue
  }
}

const mapToApiContract = (contract: Contract) : any => {
  return {
    id: contract.id,
    contractId: contract.contractId,
    orgNr:contract.supplierOrgNr,
    contractName: contract.contractName,
    contractType: contract.contractType,
    contractTypeId: contract.contractTypeId,
    kickbackProcent: contract.kickbackProcent,
    orgName: contract.supplierName?.trim(),
    archived: contract.archived,
    startDate: contract.startDate ? new Date(contract.startDate).toLocaleDateString("sv-SE") : null,
    terminationDate: contract.terminationDate ? new Date(contract.terminationDate).toLocaleDateString("sv-SE") : null,
    reportingFromDate: contract.reportingFromDate ? new Date(contract.reportingFromDate).toLocaleDateString("sv-SE") : null,
    reportingToDate: contract.reportingToDate ? new Date(contract.reportingToDate).toLocaleDateString("sv-SE") : null,
    detached: contract.detached,
    procurementValue: contract.procurementValue
  }
}

const Get = async (opt?: GetOpt) : Promise<Contract[]> => {
  const queryString = convertToQuerystring(opt);
  const url = queryString.length > 0 ? `${Urls.Contract}?${queryString}` : Urls.Contract;
  const response = await apiFetch(url);
  const contractsJson = await response.json();
  return contractsJson.map((contract:any) => {
    return mapToContract(contract);
  });
}

const GetById = async (id:number) : Promise<Contract> => {
  const response = await apiFetch(Urls.Contract+'/'+id);
  const contract = await response.json();
  return mapToContract(contract);
}

const Post = async (contract: Contract) => {
  try {
    const res = await apiFetch(Urls.Contract, "POST", JSON.stringify(mapToApiContract(contract)), {"Content-Type": "application/json"});
    return ReturnFetchObj(t('api.contract.postSuccess'), Code.OK, {contract});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.contract.postFail'), Code.ADD_ERR, {contract});
  }
}

const Put = async (contract: Contract) => {
  try {
    const res = await apiFetch(Urls.Contract+"/"+contract.id.toString(), "PUT", JSON.stringify(mapToApiContract(contract)), {"Content-Type": "application/json"});
    return ReturnFetchObj(t('api.contract.putSuccess'), Code.OK, {contract});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.contract.putFail'), Code.ADD_ERR, {contract});
  }
}

export {
  Get,
  GetById,
  Put,
  Post
}