import { BrowserRouter, Route, Routes } from "react-router-dom";

import Navigationbar from "./components/navigation/Navigationbar";
import WelcomePage from "./pages/Homepage";
import SecuredPage from "./pages/Securedpage";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
import "./App.scss";
import NewsArticlePage from "./pages/NewsArticlePage";
import NewsListPage from "./pages/NewsListPage";
import Footer from "./components/Footer/Footer";
import UserManagementPage from "./pages/UserManagementPage";
import UserPage from "./pages/UserPage";
import { EditUserPage } from "./pages/EditUserPage";
import SupplierManagementPage from "./pages/SupplierManagementPage";
import EditSupplierPage from "./pages/EditSupplierPage";
// import KickbackReportPage from "./pages/KickbackReportPage";
import KickbackListPage from "./pages/KickbackListPage";
import { ImpersonationKEY, IsImpersonating, getActiveSupplier,  parseJwt } from "./lib/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, fas } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import ContractListPage from "./pages/ContractListPage";
import EditContractPage from "./pages/EditContractPage";
import ErrorBoundary from "./lib/ErrorBoundary";
import _secretPage from "./pages/_pageForLaunchUsers";
import { changedLangStorageKey } from "./i18n";
import { ChangedLangPopup } from "./components/ChangedLangPopup/ChangedLangPopup";
import InvoiceReportListPage from "./pages/InvoiceReportListPage";
import { useEffect, useState } from "react";
import SupplierBar from "./components/SupplierBar/SupplierBar";
import { library } from "@fortawesome/fontawesome-svg-core";
import Dashboard from "./pages/DashboardPage";
import NullReportListPage from "./pages/NullReportListPage";
import ReportPage from "./pages/ReportPage";
import KickbackProjectReportPage from "./pages/KickbackProjectReportPage";



declare global {
  var AppSettings: {
    newsEditorRole: string;
    userManagerRole: string;
    sinfraBaseGroupId: string;
    sinfraBaseGroupName: string;
    APIprefix: string;
    KCprefix: string;
    KCTokenUrl: string;
    languages: { locale: string; label: string; dataSrc: string }[];
    invoiceReports: {
      // colors:string[];
      // icons:string[];
      status:{
        key:number;
        color:string;
        icon:string;
      }[]
      importedUrl:string;
      originalUrl:string;
    }
    kickbackReportsStatusIcons:{key:number,color:string, icon:string,status:string}[]
  };
}
library.add(fas)

function App() {
  const theme = undefined; //'dark-theme';//window.localStorage.getItem("dark-theme") === 'true' ? 'dark-theme' : undefined;
  const [isAppSettingsLoaded, setAppSettingsLoaded] = useState(false);
  const langChanged = window.sessionStorage.getItem(changedLangStorageKey);
  // const [grantedPermissionForProjectReport,setGrantedPermissionForProjectReport] = useState(false)
  useEffect(()=> {
    fetch("/appSettings.json")
      .then((a) => a.json())
      .then((settings) => {
        window.AppSettings = settings;
        getActiveSupplier()
        setAppSettingsLoaded(true);

      })
      .catch((e) => console.error(e));
  }, [])
// useEffect ( () => {
//   const perm = async () => {
//    const res =  await getProjectReport()
//    //const json = await res.valueOf()
//    console.log(res)
//    setGrantedPermissionForProjectReport(res)
   
//   }
//    perm()
// },[isAppSettingsLoaded])
  const renderImpersonationBar = () => {
    const tokenInfoString = window.sessionStorage.getItem(ImpersonationKEY);
    if (!tokenInfoString) return <></>;
    const tokenInfo = JSON.parse(tokenInfoString);
    const accessTokenInfo = parseJwt(tokenInfo.access_token);
    return (
      <div className="impersonation-bar">
        <div>
          {t("nav.impersonationBar.description")} ({accessTokenInfo.email})
        </div>
        <div
          className="impersonation-bar-button"
          onClick={() => {
            window.sessionStorage.removeItem(ImpersonationKEY);
            window.location.reload();
          }}
        >
          {t("nav.impersonationBar.buttonText")}
          <FontAwesomeIcon icon={faCancel} />
        </div>
      </div>
    );
  };
 
  return (
  
    <div className={`app-root ${theme}`}>
      {/* <meta name="viewport" content="width=device-width,initial-scale=1"/> */}
    {isAppSettingsLoaded && (
        <>
        <Navigationbar theme={theme} /> 
          <SupplierBar />
          {IsImpersonating() && renderImpersonationBar()}
          {langChanged && <ChangedLangPopup />}
          <div className="app-content-wrapper">
            <ErrorBoundary>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<WelcomePage />} />
                  <Route
                    path="/news/:paramMode/:paramId?"
                    element={<NewsArticlePage />}
                  />
                  <Route path="/newslist" element={<NewsListPage />} />
                  <Route path="/secured" element={<SecuredPage />} />
                  <Route path="/report" element={<ReportPage/>} />
                  <Route
                    path="/invoice-report-list"
                    element={<InvoiceReportListPage />}
                  />
                  <Route path="/kickback-list" element={<KickbackListPage />} />

                  <Route
                    path="/usermanagement"
                    element={<UserManagementPage />}
                  />
                  <Route path="/user/:id" element={<UserPage />} />
                  <Route
                    path="/newuser/:supplierId?"
                    element={<EditUserPage />}
                  />
                  <Route
                    path="/edituser/:paramUserId"
                    element={<EditUserPage />}
                  />

                  <Route
                    path="/suppliermanagement"
                    element={<SupplierManagementPage />}
                  />
                  <Route
                    path="/editsupplier/:orgNr"
                    element={<EditSupplierPage />}
                  />

                  <Route
                    path="/contractManagement"
                    element={<ContractListPage />}
                  />
                  <Route
                    path="/editcontract/:contractId?"
                    element={<EditContractPage />}
                  />
                  <Route
                    path="/dashboard"
                    element={<Dashboard />}
                  />
                  <Route
                    path="/nullreport-list-page"
                    element={<NullReportListPage />}
                  />
                  
                       <Route
                       path="/kickback-project"
                       element={<KickbackProjectReportPage />}
                     /> 
                  
                 

                  {/* <Route path="/simsimsim" element={<_secretPage />} /> */}
                </Routes>
              </BrowserRouter>
            </ErrorBoundary>
          </div>
        </>
      )}
      <Footer />
    </div>
   
  );
}

export default App;
