import * as React from 'react';
import { TNews } from '../../services/API/News';
import { Spinner, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { IsUserNewsEditor } from '../../lib/utils';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DataService } from '../../services/DataService';

export interface INewsListProps extends WithTranslation {
  news: TNews[];
  isLoading: boolean;
}

export interface INewsListState {
}

class NewsList extends React.Component<INewsListProps, INewsListState> {
  constructor(props: INewsListProps) {
    super(props);

    this.state = {
    }
  }


  public render() {
    let empty = [1,2,3].slice(this.props.news.length)
    if(window.matchMedia(`(max-width: 1200px)`).matches){
      empty = empty.slice(0,1);
    }
    return (
      <Stack className='news-list'>
        {
          this.props.isLoading &&
          <div className='news-list-loading'>
            <Spinner/>
          </div>
        }
        {
          !this.props.isLoading && this.props.news.map(news => {
            return <div key={`news-item-${news.id}`} className='news-listitem-wrapper news-active-listitem-wrapper' onClick={() => { window.location.href = `/news/view/${news.newsArticleId}` }}>
              <div className='news-listitem-titleWrapper'>
                <div className='news-listitem-title'>
                  {news.title}
                </div>
                <div className='news-listitem-tools'>
                  {
                    DataService.News.isPublished(news) != true &&
                    <FontAwesomeIcon icon={faEyeSlash} />
                  }
                  {/* {
                    isUserEditor() &&
                    <div onClick={() => { window.location.href = `/news/edit/${news.id}` }}><FontAwesomeIcon icon={faEdit} /></div>
                  } */}
                </div>
              </div>
              <div className='news-listitem-body' dangerouslySetInnerHTML={{__html: news.ingress ?? ""}}></div>
              {
                DataService.News.isPublished(news) && 
                <div className='news-listitem-published'>{news.publishedFrom.toLocaleDateString()}</div>
              }
              
            </div>
          })
        }
        {
          !this.props.isLoading && empty.map(empty => {
            return <div key={`news-empty-item-${empty}`} className='news-listitem-wrapper news-empty-listitem-wrapper'>
              {this.props.t("news.emptyItem")}
            </div>
          })
        }
      </Stack>
    );
  }
}

export default withTranslation()(NewsList)