import * as React from 'react';
import { InfoModal } from '../components/modal/InfoModal';
import { Button, Col, Form, Row, Spinner, Stack } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { NewUser, TUser, TUserValidation } from '../services/API/Users';
import { useTranslation } from 'react-i18next';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useParams } from 'react-router-dom';
import { DataService } from '../services/DataService';
import { Code, IsUserUserManager } from '../lib/utils';
import StyledCheckbox from '../components/StyledCheckbox/StyledCheckbox';
import { TRole } from '../services/API/Roles';
import { StyledModal } from '../components/modal/StyledModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { TSupplier } from '../services/API/Suppliers';
import DepartmentList from '../components/Supplier/DepartmentList/DepartmentList';
import { Department, DepartmentDictionary, DepartmentFunctionArgs } from '../services/API/Departments';
import Datalist, { ListColumn } from '../components/Datalist/Datalist';
import MemberModal from '../components/Supplier/MemberList/MemberModal';
import { ActiveStateOfDepartments, UserDepartmentList } from '../components/Supplier/UserDepartmentComps/UserDepartmentList';

export interface IEditUserPageProps {
}


export function EditUserPage (props: IEditUserPageProps) {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ successText, setSuccessText ] = useState("");
  const [ errorMsg, setErrorMsg ] = useState("");
  const [ suppliers, setSuppliers ] = useState<TSupplier[]>([]);
  const [ newsEditorRole, setNewsEditorRole ] = useState<TRole>();
  const [ userManagementRole, setUserManagementRole ] = useState<TRole>();
  const [statusConfirmModal, setStatusConfirmModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [ validations, setValidations ] = useState<TUserValidation>({
    firstName: "",
    lastName: "",
    email: "",
    SupplierGroups: ""
  })
  const userBeforeEdit = React.useRef<TUser>();
  const [ user, setUser ] = useState<TUser>({
    firstName: "",
    lastName: "",
    email: "",
    SupplierGroups: [],
    isSinfraAccount: false,
    roles: [],
    enabled: true,
    attributes: {
      departments: [],
      locale: "sv",
      reporting_contracts: []
    },
    created: new Date(),
    id: ""
  });
  const [ isEdit, setIsEdit ] = useState(false);

  const { t } = useTranslation();
  const { supplierId, paramUserId }  = useParams();
  const[departments,setDepartMents] = React.useState<DepartmentDictionary>({})
  const[userAssignedDepartments,setUserAssignedDepartments] = React.useState<number[]>([])
  useEffect(() => {
    Promise.all([
      initRoles(),
      initSuppliers(),
      initUser(),
      //initDepartments()
    ])
    .then(res => {
      initDepartments()
      //setIsLoading(false);
    }).then(r => setIsLoading(false))
  }, [])

  const initUser = async () => {
    
    if(!IsUserUserManager())
      window.location.href = "/"
    if(!paramUserId)
      return;
    const user = await DataService.Users.GetById(paramUserId);
    
    if(user){
      userBeforeEdit.current = user;
      setUser(user);
      setIsEdit(true);
    }
  }

  const initRoles = () => {
    return DataService.Roles.Get()
      .then(roles => {
        const newsRole = roles.find(role => role.name == window.AppSettings.newsEditorRole);
        const userManagementRole = roles.find(role => role.name == window.AppSettings.userManagerRole);
        if(newsRole){
          setNewsEditorRole(newsRole);
        }
        if(userManagementRole){
          setUserManagementRole(userManagementRole);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const initSuppliers = () => {
    return DataService.Suppliers.Get()
    .then(suppliers => {
      const initialSupplier = suppliers.find(supplier => supplier.supplierOrgNr == supplierId);
      if(initialSupplier){
        setUser({ ...user, SupplierGroups: [initialSupplier] });
      }
      setSuppliers(suppliers);
    })
    .catch(err => {
      console.log(err)
    })
  }
  const initDepartments = async() => {
    const user = await DataService.Users.GetById(paramUserId ?? '');
    const depArgs:DepartmentFunctionArgs = {}  
    depArgs.supplierOrgIds = user.SupplierGroups.map(x => x.supplierOrgNr)  
    const departments = await DataService.Departments.GetMultipleDepartments(depArgs)
    if(departments.Code == Code.OK)
    setDepartMents(departments.Content.response)
    setUserAssignedDepartments(user.attributes.departments)
  }
  const renderField = (label:string, value:string, prop: keyof TUserValidation) => {
    return <>
      <div className='user-page-field-label'>{label}</div>
      <Form.Control
        value={value}
        // className='user-page-field-value'
        type='text'
        onChange={(ev) => setUser({ ...user, [prop]: ev.target.value })}
        isInvalid={Boolean(validations[prop])}
      />
      <Form.Control.Feedback type="invalid">
        {validations[prop]}
      </Form.Control.Feedback>
    </>
  }

  const onClickSubmit = async () => {
    setIsSaving(true);
    setErrorMsg("");
    const validation = DataService.Users.ValidateUser(user);
    setValidations(validation);
    //@ts-ignore
    const isInvalid = Object.keys(validation).some((key) => validation[key]);
    if(!isInvalid){
      const response = await (isEdit && paramUserId ? DataService.Users.UpdateUser(userBeforeEdit.current as TUser, user) : DataService.Users.CreateUser(user));
      if(response.Code == Code.OK){
        setSuccessText(response.Message);
      }
      else{
        setErrorMsg(response.Message);
      }
    }
    setIsSaving(false);
  }

  const activeText = user?.enabled ? t('general.active') : t('general.inactive');
  const activateText = user?.enabled ? t('general.deactivate') : t('general.activate');
  const buttonCss = user?.enabled ? "button-danger" : "button-submit";
  const activeIcon = user?.enabled ? faCircleCheck : faCircleXmark;
  const modalButtonIcon = user?.enabled ? faCircleXmark : faCircleCheck;
  const iconCss = user?.enabled ? "user-page-active" : "user-page-inactive";
  const isSmallScreen = window.matchMedia("(max-width: 700px)").matches;

  const dismissResetPasswordModal = () => {
    setResetPasswordModal(false);
    setErrorMsg("");
  }

  const dismissToggleStatusModal = () => {
    setStatusConfirmModal(false);
    setErrorMsg("");
  }

  const submitStatusChange = async () => {
    if(user){
      setIsSaving(true);
      const userId = (userBeforeEdit.current as TUser).id;
      const result = await DataService.Users.ChangeUserStatus(userId, !user.enabled);
      if(result.Code == Code.OK){
        setUser({...user, enabled: !user.enabled});
        setStatusConfirmModal(false);
        setIsSaving(false);
      }
      else{
        setIsSaving(false);
        setErrorMsg(result.Message)
      }
    }
  }

  const submitResetPassword = async () => {
    if(user){
      setIsSaving(true);
      const userId = (userBeforeEdit.current as TUser).id;
      const result = await DataService.Users.ResetPassword(userId);
      if(result.Code == Code.OK){
        setResetPasswordModal(false);
        setIsSaving(false);
        setSuccessText(result.Message);
      }
      else{
        setIsSaving(false);
        setErrorMsg(result.Message);
      }
    }
  }
  const updateUserDepartment = (val:ActiveStateOfDepartments) => {
    const buffUser = {...user}
    const ids:number[] = [] 
    Object.values(val).forEach(x => {
      if(x !== '-1'){
        ids.push(+x)
      } 
  }) 
  buffUser.attributes.departments = ids
    setUser(buffUser)
    setUserAssignedDepartments(ids)
  }
  return (
    <Stack gap={4} className='user-page'>
      {
        isLoading &&
        <Spinner />
      }
      {
        !isLoading &&
        <div>
          <div className='user-page-titlewrapper'>
            <div className='user-page-title'>
            {
              isEdit ?
              t("newUserPage.titleEdit")
              :
              t("newUserPage.title")
            }
            </div> 
          </div>
          <Stack style={{marginTop:8}} gap={3}>
            <Row>
              <Col>
                {renderField(t("general.email"), user.email, "email")}
              </Col>
            </Row>
            <Row>
              <Col>
                {renderField(t("general.firstname"), user.firstName, "firstName")}
              </Col>
              <Col>
                {renderField(t("general.lastname"), user.lastName, "lastName")}
              </Col>
            </Row>
            {/* <Row> */}
              <StyledCheckbox 
                isChecked={user.isSinfraAccount ?? false}
                label={t("newUserPage.sinfraUser")}
                onChange={(newVal) => setUser({...user, isSinfraAccount: newVal, roles: []})}
                key={"sinfraAccount"}
              />
              {
                user.isSinfraAccount == true &&
                <>
                {
                  newsEditorRole &&
                  <div style={{marginLeft: "1rem"}}>
                    <StyledCheckbox 
                      isChecked={user.roles?.find(role => role.name == newsEditorRole.name) != undefined}
                      label={t("newUserPage.newsEditor")}
                      onChange={(newVal) => setUser({...user, roles: newVal ? user.roles?.concat(newsEditorRole) : user.roles?.filter(role => role.name != newsEditorRole.name)})}
                      key={"newsEditor"}
                    />
                  </div>
                }
                {
                  userManagementRole &&
                  <div style={{marginLeft: "1rem"}}>
                    <StyledCheckbox 
                      isChecked={user.roles?.find(role => role.name == userManagementRole.name) != undefined}
                      label={t("newUserPage.frontendAdmin")}
                      onChange={(newVal) => setUser({...user, roles: newVal ? user.roles?.concat(userManagementRole) : user.roles?.filter(role => role.name != userManagementRole.name)})}
                      key={"frontendAdmin"}
                    />
                  </div>
                }
                  
                  
                </>
              }
            {/* </Row> */}
            {
              user.isSinfraAccount == false &&
              <>
              <Row>
                <div className='user-page-field-label'>{t("general.supplier")}</div>
                <Typeahead 
                  isInvalid={Boolean(validations.SupplierGroups)}
                  // className='user-page-field-value' 
                  multiple
                  placeholder={t('newUserPage.supplierPlaceholder').toString()}
                  options={suppliers.map((supp) => (`${supp.supplierName} - ${supp.supplierOrgNr}`))}
                  // defaultInputValue={user.SupplierGroups.length > 0 ? 
                  //   user.SupplierGroups[0].supplierName + " - " + user.SupplierGroups[0].supplierOrgNr
                  //   : ""
                  // }
                  selected={user.SupplierGroups?.map(supp =>  `${supp.supplierName} - ${supp.supplierOrgNr}`) ?? []}
                  labelKey={'title'}
                  id={"supplier"}
                  onChange={(selected) => {
                    const newSupplier = suppliers.filter(supp => selected.includes(`${supp.supplierName} - ${supp.supplierOrgNr}`));
                    setUser({...user, SupplierGroups: newSupplier})
                  }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validations.SupplierGroups}
                  </Form.Control.Feedback>
              </Row>
                <Row style={{gap:10}}>
                  
                  <div className='department-list'>
                    {Object.keys(departments).length > 0 &&                
                    <div className="field-label">
                      {t("departmentList.title")}
                    </div>
                    }
                    
                    <UserDepartmentList userAssignedDepartments={userAssignedDepartments} user={user} departments={departments} loading={isLoading} updateUser={updateUserDepartment}/>
                    
                    
                  
                  </div>
                  
              </Row>
              </>
            }
            <div className='user-page-tools'>
              {
                errorMsg &&
                <div className='error-message'>
                  <span>{t('general.errorMsg')}:</span>
                  {errorMsg}
                </div>
              }

              {
                isEdit &&
                <>
                  <Button onClick={() => setResetPasswordModal(true)}>{t("general.resetPassword")}</Button>
                  <Button className={buttonCss} onClick={() => setStatusConfirmModal(true)}>{activateText}</Button>
                </>
              }  
              
              <Button disabled={isSaving} className='button-submit' onClick={() => onClickSubmit()}>
              {
                isSaving ?
                <Spinner animation="border" size={'sm'}/>
                :
                <>{isEdit ? t("general.update") : t("general.create")}</>
              }
              </Button>
              <Button disabled={isSaving} className='button-cancel' onClick={() => window.history.go(-1)}>{t("general.cancel")}</Button>
            </div>
          </Stack>
        </div>
      }
      {
        statusConfirmModal &&
        <StyledModal 
          title={t("userPage.toggleStatusModalTitle").toString()} 
          onDismiss={() => dismissToggleStatusModal()} 
          body={
            <div>
              <p>
                {
                  user?.enabled ?
                  t("userPage.toggleStatusModalBodyActive")
                  :
                  t("userPage.toggleStatusModalBodyInactive")
                }
              </p>
            {
              errorMsg &&
              <div className='error-message'>
                <span>{t('general.errorMsg')}:</span>
                {errorMsg}
              </div>
            }
            </div>
          }
          footer={
            <Stack direction="horizontal" gap={2}>
              <Button disabled={isSaving} className={buttonCss} onClick={submitStatusChange}>
                {
                  isSaving ?
                  <Spinner animation="border" size={'sm'}/>
                  :
                  <><FontAwesomeIcon icon={modalButtonIcon} style={{marginRight: 4}}/>{activateText}</>
                }
              </Button>
              <Button disabled={isSaving} className="button button-cancel" onClick={() => dismissToggleStatusModal()}>{t('general.close')}</Button>
            </Stack>
          }
        />
      }
      {
        resetPasswordModal &&
        <StyledModal
          onDismiss={() => dismissResetPasswordModal()}
          title={t("general.resetPassword").toString()}
          body={
            <div>
              <p>
                {
                  t("userPage.resetPasswordModalBody")
                }
              </p>
            {
              errorMsg &&
              <div className='error-message'>
                <span>{t('general.errorMsg')}:</span>
                {errorMsg}
              </div>
            }
            </div>
          }
          footer={
            <Stack direction="horizontal" gap={2}>
              <Button disabled={isSaving}  onClick={submitResetPassword}>
                {
                  isSaving ?
                  <Spinner animation="border" size={'sm'}/>
                  :
                  <>{t("general.send")}</>
                }
              </Button>
              <Button disabled={isSaving} className="button button-cancel" onClick={() => dismissResetPasswordModal()}>{t('general.close')}</Button>
            </Stack>
          }
         />
      }
      {
        successText &&
        <InfoModal 
          bodyText={successText}
          title={isEdit ? t("newUserPage.infoModalSuccessUpdated") : t("newUserPage.infoModalSuccessCreated")}
          onDismiss={() => {
            // window.history.back();
            window.history.go(-1);
          }}
        />
      }
     
    </Stack>
  );
}
