import * as React from "react";
import { Button, Spinner } from "react-bootstrap";
import i18n, { changedLangStorageKey } from "../../i18n";
import { useTranslation } from "react-i18next";
import { DataService } from "../../services/DataService";
import { Code } from "../../lib/utils";

export interface IChangedLangPopupProps {}

export function ChangedLangPopup(props: IChangedLangPopupProps) {
  const { t } = useTranslation();

  const [hide, setHide] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const onClickSubmit = async () => {
    setLoading(true);
    const res = await DataService.Users.UpdateCurrentUserLang(i18n.language);
    setLoading(false);
    if (res.Code != Code.OK) {
      setError(res.Message);
    } else {
      window.sessionStorage.removeItem(changedLangStorageKey);
      setHide(true);
    }
  };

  return (
    <>
      {!hide && (
        <div className="changedLangPopup">
          <div className="changedLangPopupContent">
            {error.length > 0 && (
              <div className="error-message">
                <span>{t("general.errorMsg")}:</span>
                {error}
              </div>
            )}
            {error.length == 0 && (
              <div className="changedLangPopupText">
                {t("changedLangNotice.title")}
              </div>
            )}
            <div>
              {error.length == 0 && (
                <Button
                  style={{ marginRight: 8 }}
                  disabled={loading}
                  onClick={onClickSubmit}
                >
                  {loading ? (
                    <Spinner size={"sm"} />
                  ) : (
                    t("changedLangNotice.setAsDefault")
                  )}
                </Button>
              )}

              <Button
                className="button-cancel"
                onClick={() => {
                  window.sessionStorage.removeItem(changedLangStorageKey);
                  setHide(true);
                }}
              >
                {t("general.close")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
