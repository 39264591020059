import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { DataService } from "../services/DataService";
import { TNews } from "../services/API/News";
import News from "../components/News/News";
import Reports from "../components/Reports/Reports";
import { InvoiceBarChart } from "../components/InvoiceBarChart/InvoiceBarChart";
import { Col, Container, Row } from "react-bootstrap";
import Excel from 'exceljs'
import { NewUser } from "../services/API/Users";
import { Code } from "../lib/utils";
import ReportNotice from "../components/ReportNotice/ReportNotice";

interface Props extends WithTranslation {}

interface State {
  news: TNews[];
}

class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      news: [],
    };
  }

  async componentDidMount(): Promise<void> {
    const news = await DataService.News.Get();
      
    this.setState({
      news,
    });
  }

  render() {
    return (
      <Container fluid className="home-content-container ">
        {/* <Row className="g-5"> */}
          {/* <Col className="g-5"> */}
            <ReportNotice withLinks/>
          {/* </Col> */}
        {/* </Row> */}
        {/* {
          ([1,2,{}] as any[]).map(k => k)
        } */}
        <Row className="g-5">
          <Col xs={12} sm={12} md={12} lg={6}>
            <div className="leftColumn">
              <div className="welcomeText">
                <div className="title">{this.props.t("welcomeText.title")}</div>
                <div className="description">
                  <Trans i18nKey={"welcomeText.description"}>a<a href={"mailto:fakturadirekt@sinfra.se"}></a></Trans>
                </div>
              </div>
              <div style={{ flexGrow: 1 }}>
                <News
                  numberOfNews={3}
                  sortBy="Published"
                  showAllNewsButton={true}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} className="g-5">
            <Row lg={12} className="g-5">
              <Col lg={12} className="g-5">
                <div style={{ height: "40vh", width: "maxContent", minHeight: "400px" }}>
                  <InvoiceBarChart />
                </div>
              </Col>
              <Col className="g-5">
                <div style={{ height: "40vh", minHeight: "400px"}}>
                  <Reports />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation()(Home);
