/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Urls from "../../lib/Urls";
import { Code, kcFetch, ReturnFetchObj } from "../../lib/utils";
import { t } from 'i18next'
import { TUser } from "./Users";

export interface TGroup {
  id:string;
  name: string;
  attributes: {
    divided_reporting:string[];
    prevent_invoice_report:string[];
    prevent_kickback_report:string[];
    project_report:string[];
    testing_report:string[];
    rowEdit:string[];
  }
}


const Add = async (name:string) => {
  try {
    const addGrpResp = await kcFetch(Urls.Groups, "POST", JSON.stringify({name}), {"Content-Type": "application/json"});
    return ReturnFetchObj(t('api.group.postSuccess'), Code.OK, {name , id: ""});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.group.postFail'), Code.ADD_ERR, {name, id: ""});
  }
}

const Update = async (grp:TGroup) => {
  try {
    const addGrpResp = await kcFetch(Urls.Groups+"/"+grp.id, "PUT", JSON.stringify({...grp}), {"Content-Type": "application/json"});
    return ReturnFetchObj(t('api.group.putSuccess'), Code.OK, {...grp} as TGroup);
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.group.putFail'), Code.ADD_ERR, {...grp} as TGroup);
  }
}

type GetOptions = {
  onlySuppliers?:boolean
}
const Get = async (opt: GetOptions) : Promise<TGroup[]> => {
  const { onlySuppliers } = opt
  const response = await kcFetch(Urls.Groups+"?briefRepresentation=false");
  const json : any[] = await response.json();
  return json
    .filter(grp => onlySuppliers ? grp.attributes?.SupplierID != undefined : true)
    .map(grp => {
      const supplierIds = grp.attributes?.SupplierID ?? []
      return {
        id: grp.id,
        name: grp.name,
        SupplierID: supplierIds.length > 0 ? supplierIds[0] : undefined,
        attributes: grp.attributes
      }
    });
}

const GetById = async (id:string) : Promise<TGroup> => {
  const response = await kcFetch(Urls.Groups+"/"+id)
  const grp = await response.json();
  return {
    id: grp.id,
    name: grp.name,
    attributes: grp.attributes
  }
}

const GetByName = async (name:string)=> {
  try {
    const getGrpRes = await kcFetch(Urls.Groups+"?briefRepresentation=false&search="+name);
    const grps = await getGrpRes.json();
    if(grps.length == 0)
      return ReturnFetchObj(t('api.getGroup.success'), Code.NOT_FOUND, {} as TGroup);
    const group : TGroup =  {
      id: grps[0].id,
      name: grps[0].name,
      attributes: grps[0].attributes
    }
    return ReturnFetchObj(t('api.getGroup.success'), Code.OK, group);
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.getGroup.fail'), Code.GET_ERR, {} as TGroup);
  }
}

const GetUserGroups = async (id:string) : Promise<TGroup[]> => {
  const response = await kcFetch(Urls.UserGroups.replace("{id}", id)+"?briefRepresentation=false");
  const json : any[] = await response.json();
  
  return json.map(grp => {
    const supplierIds = grp.attributes?.SupplierID ?? []
    return {
      id: grp.id,
      name: grp.name,
      SupplierID: supplierIds.length > 0 ? supplierIds[0] : undefined,
      attributes: grp.attributes
    }
  });
}

const AddUserToGroup = async (userId:string, groupId:string) => {
  if(!userId || !groupId){
    return ReturnFetchObj(t('api.group.addUserInvalidArguments'), Code.ADD_ERR, {userId, groupId});
  }
  try {
    const response = await kcFetch(Urls.UserGroupEdit.replace("{id}", userId).replace("{groupId}", groupId), "PUT", {}, {"Content-Type": "application/json"});
    return ReturnFetchObj(t('api.group.addUserSuccess'), Code.OK, {userId, groupId});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.group.addUserFail'), Code.ADD_ERR, {userId, groupId});
  }
}

const RemoveUserFromGroup = async (userId:string, groupId:string) => {
  if(!userId || !groupId){
    return ReturnFetchObj(t('api.group.remUserInvalidArguments'), Code.ADD_ERR, {userId, groupId});
  }
  try {
    const response = await kcFetch(Urls.UserGroupEdit.replace("{id}", userId).replace("{groupId}", groupId), "DELETE", {}, {"Content-Type": "application/json"});
    return ReturnFetchObj(t('api.group.remUserSuccess'), Code.OK, {userId, groupId});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.group.remUserFail'), Code.ADD_ERR, {userId, groupId});
  }
}

const GetGroupMembers = async (groupId:string) => {
  try {
    const response = await kcFetch(Urls.GroupMembers.replace("{id}", groupId), "GET");
    const json = await response.json();
    const members : TUser[] = json.map((jsonUser:any) => {
      return {
        id: jsonUser.id,
        created: new Date(jsonUser.createdTimestamp),
        enabled: jsonUser.enabled,
        firstName: jsonUser.firstName,
        lastName: jsonUser.lastName,
        email: jsonUser.email,
        attributes: {
          ...jsonUser.attributes,
          reporting_contracts: jsonUser.attributes?.reporting_contracts ?? [],
          departments: jsonUser.attributes?.departments ?? []
        }
      }
    });
    
    return ReturnFetchObj(t('api.groupMembers.success'), Code.OK, {members});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.groupMembers.fail'), Code.GET_ERR, {members:[]});
  }
}


export {
  Add,
  Get,
  GetById,
  GetByName,
  GetUserGroups,
  RemoveUserFromGroup,
  AddUserToGroup,
  GetGroupMembers,
  Update
}