import * as React from 'react';
import { StyledModal } from './StyledModal';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export interface IInfoModalProps {
  onDismiss():void;
  title:string;
  bodyText:string;
}

export function InfoModal (props: IInfoModalProps) {
  const { t } = useTranslation();

  return (
    <StyledModal
      onDismiss={() => props.onDismiss()}
      title={props.title}
      body={
        <div>
          <p>
            {
              props.bodyText
            }
          </p>
        </div>
      }
      footer={
        <Button onClick={() => props.onDismiss()}>
          {
            <>{t("general.close")}</>
          }
        </Button>
      }
      />
  );
}
