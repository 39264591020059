import Urls from "../../lib/Urls";
import { apiFetch } from "../../lib/utils";

export interface TContractCategory {
  id:number;
  title: string;
}

const Get = async () : Promise<TContractCategory[]> => {
  const response = await apiFetch(Urls.ContractCategory);
  const customers = await response.json();
  return customers;
}

export {
  Get
}