import * as InvoiceReports from './API/InvoiceReports'
import * as Customers from './API/Customer'
import * as Contracts from './API/Contracts'
import * as NullReports from './API/NullReports'
import * as News from './API/News'
import * as Invoices from './API/Invoices'
import * as Users from './API/Users'
import * as Groups from './API/Groups'
import * as Suppliers from './API/Suppliers'
import * as Roles from './API/Roles'
import * as Kickback from './API/Kickback'
import * as ContractCategories from './API/ContractCategories'
import * as Dashboard from './API/DashBoard'
import * as Logs from './API/Logs'
import * as Departments from './API/Departments'
import * as EditedReports from './API/EditedReports'

export const API = {
  InvoiceReports,
  Suppliers,
  Customers,
  Contracts,
  NullReports,
  News,
  Invoices,
  Users,
  Groups,
  Roles,
  Kickback,
  ContractCategories,
  Dashboard,
  Logs,
  EditedReports
}
