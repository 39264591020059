import * as React from 'react';
import { TUser } from '../../../services/API/Users';
import { StyledModal } from '../../modal/StyledModal';
import { t } from 'i18next';
import { Stack, Row, Col, Button, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Department } from '../../../services/API/Departments';
import { DataService } from '../../../services/DataService';

interface IMemberModalProps {
  onDismiss():void;
  departments:Department[];
  members:TUser[];
  member?:TUser;
  onSubmit(user: TUser):void;
}

const MemberModal: React.FunctionComponent<IMemberModalProps> = (props) => {
  const [allUsers, setUsers] = React.useState<TUser[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const initUsers = async () => {
    const fetchedUsers = await DataService.Users.Get();
    setUsers(fetchedUsers.filter(user => {
      return user.isSinfraAccount == false || props.members.find(u => u.id == user.id) == undefined
    }));
    setIsLoading(false);
  };
  React.useEffect(() => {
    initUsers();
  }, []);

  const modalTitle = React.useMemo(() => {
    return props.member?.id == "" ? 
      t("memberList.modal.addUser")
      :
      t("memberList.modal.updateTitle")
  }, [props.member]);

  const [ emailValidation, setEmailValidation ] = React.useState("");
  const [ addExistingUser, setAddExistingUser ] = React.useState(props.member?.id == "" ? false : true);
  const newUser : TUser = {
    attributes: { 
      locale: "sv", 
      reporting_contracts: [] ,
      departments: []
    },
    created: new Date(),
    email: "",
    enabled: true,
    firstName: "",
    id: "",
    lastName: "",
    isSinfraAccount: false,
    SupplierGroups: []
  }
  const [selectedUser, setSelectedUser] = React.useState<TUser>(props.member ?? {...newUser});
  
  return <StyledModal
  title={modalTitle}
  onDismiss={() => {props.onDismiss()}}
  body={
    <Stack style={{gap: 8}}>
      {
        props.member == undefined &&
        <Form.Group>
          <Form.Select 
            value={Number(addExistingUser)}
            onChange={(ev) => {
              setAddExistingUser(!!Number(ev.target.value));
              setSelectedUser({...newUser});
            }} 
            id='memberlist-users'
            >
            <option value={0}>{t("memberList.modal.newUser")}</option>
            <option value={1}>{t("memberList.modal.existingUser")}</option>
          </Form.Select>
        </Form.Group>
      }
      {
        addExistingUser &&
        <Form.Group>
          <Form.Label>{t("general.users")}</Form.Label>
          <Typeahead 
            id='memberlist-member-options'
            options={allUsers}
            disabled={props.member != undefined}
            key={"id"}
            labelKey={"email"}
            selected={selectedUser.id ? [selectedUser] : []}
            onChange={(selected) => { 
              if(selected.length > 0){
                const user = selected.reverse()[0] as TUser;
                setSelectedUser(user);
              }
              else{
                setSelectedUser({...newUser});
              }
            }}
            multiple
            isLoading={isLoading}
          />
        </Form.Group>
      }
      {
        !addExistingUser &&
        <>
          <Form.Group>
            <Form.Label>{t("general.email")}</Form.Label>
            <Form.Control 
              type='text'
              value={selectedUser.email}
              onChange={(ev) => {
                const newValue = ev.target.value;
                setSelectedUser({ ...selectedUser, email: newValue })
              }}
              isInvalid={emailValidation.length > 0}
            />
            <Form.Control.Feedback type="invalid">
              {emailValidation}
            </Form.Control.Feedback>
          </Form.Group>
          <Row>
            <Col>
          <Form.Group>
            <Form.Label>{t("general.firstname")}</Form.Label>
            <Form.Control
              type='text'
              value={selectedUser.firstName}
              onChange={(ev) => {
                const newValue = ev.target.value;
                setSelectedUser({ ...selectedUser, firstName: newValue })
              }}
            />
          </Form.Group>
            </Col>
            <Col>
          <Form.Group>
            <Form.Label>{t("general.lastname")}</Form.Label>
            <Form.Control 
              type='text'
              value={selectedUser.lastName}
              onChange={(ev) => {
                const newValue = ev.target.value;
                setSelectedUser({ ...selectedUser, lastName: newValue })
              }}
            />
          </Form.Group>
            </Col>
          </Row>
        </>
      }
      <Form.Group>
        <Form.Label>{t("general.department")}</Form.Label>
        <Form.Select 
          value={selectedUser.attributes.departments.find(dep => props.departments.find(d => d.id == dep)) ?? 0}
          onChange={(ev) => {
            const newValue = Number(ev.target.value);
            const supplierDepartments = props.departments;
            const withoutSupplierDepartments = selectedUser.attributes.departments.filter(dep => supplierDepartments.find(d => d.id == dep) == undefined);
            const newDepartments = newValue != 0 ? withoutSupplierDepartments.concat(newValue) : withoutSupplierDepartments;
            setSelectedUser({ ...selectedUser, attributes: { ...selectedUser.attributes, departments: newDepartments } });
          }}
        >
          <option key={0} value={0}>{t("general.none")}</option>
          {
            props.departments.map(dep => <option key={dep.id} value={dep.id}>{dep.department}</option>)
          }
        </Form.Select>
      </Form.Group>
    </Stack>
  }
  footer={
    <Stack direction='horizontal' style={{gap:10}}>
      <Button onClick={() => {
        const { email } = DataService.Users.ValidateUser(selectedUser);
        if(email.length > 0){
          setEmailValidation(email);
          return;
        }

        props.onSubmit(selectedUser);
        }}>Spara</Button>
      <Button 
        className='button-cancel'
        onClick={() => {props.onDismiss();}}
      >{t("general.cancel")}</Button>
    </Stack>
  }
/>;
};

export default MemberModal;
