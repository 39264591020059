/* eslint-disable eqeqeq */
import * as React from 'react'
import { StyledModal } from '../modal/StyledModal'
import { Stack, Button } from 'react-bootstrap'
import { Department } from '../../services/API/Departments'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
type Status = {
    key:number;
    value:string;
    element:JSX.Element;
  }
type Kickbackactions = 'CHANGEDEPARTMENT' | 'STATUS' | 'SUBMIT' | 'ERROR' | 'NONE' | 'OK'
type BaseKickbackAction ={
    action:Kickbackactions;
    dissmiss:()=>void;
}
export type IChangeDepartmentAction  = BaseKickbackAction &{
    action:'CHANGEDEPARTMENT';
    handleDepartmentChange:(newDepartment:Department) => void;
    item:Department;
    
}
export type IStatusmentAction  = BaseKickbackAction &{
    action:'STATUS';
    handleStatusChange:(status:Status) => void;
    item:Status;
}
export type ISubmitAction  = BaseKickbackAction &{
    action:'SUBMIT'
    handleSubmit:() => Promise<void>;
}
export type IErrorAction = BaseKickbackAction &{
    action:'ERROR';
}
export type INoneAction = BaseKickbackAction &{
    action:'NONE'
}
export type IOkAction = BaseKickbackAction &{
    action:'OK'
    parentDissmiss:()=>void;
}
interface IKickbackActionModal{
    action:IChangeDepartmentAction | IStatusmentAction | ISubmitAction | IErrorAction | INoneAction | IOkAction;
   
}
export const KickbackActionModal = ({action}:IKickbackActionModal) => {
    const actionText = {
        CHANGEDEPARTMENT:{title:'Ändra/Lägg till avdelning',body:'Är du säker på att du vill ändra/lägga till en ny avdelning på rapporten?'},
        STATUS:{title:'Ändra staus',body:'Är du säker på att du vill ändra status på rapporten?'},
        SUBMIT:{title:'Skicka in',body:'Är du säker på att du vill skicka in ändringar?'},
        ERROR:{title:'Fel',body:'Något gick fel, försök igen senare.'},
        NONE:{title:'',body:''},
        OK:{title:'Rapport uppdaterad',body:<div style={{display:'flex',flexDirection:'column'}}>
        <FontAwesomeIcon icon='check-circle' style={{height:40,color:'green'}}/>
        <div>
        <label style={{padding:5,border:'2px solid rgb(236, 236, 236)',borderRadius:5,marginTop:5,width:150}}>Inskickad</label>
        </div>
        </div>}
    }[action.action]
    const handleConfirmation = () => {
        if(action.action == 'CHANGEDEPARTMENT' && 'handleDepartmentChange' in action){
            action.handleDepartmentChange(action.item);
            
        }
        else if (action.action == 'STATUS' && 'handleStatusChange' in action){
            action.handleStatusChange(action.item);
            
        }
        else if(action.action == 'SUBMIT'){
            action.handleSubmit();
            
        }
        else if(action.action == 'OK'){
            action.parentDissmiss();
            window.location.href = "/kickback-list";
        }
        action.dissmiss();
    }
    return(
        <>
        <StyledModal 
        onDismiss={() => { action.dissmiss()}}
        title={<label>{actionText.title}</label>}
        body={
            <div style={{alignContent:'center',flexDirection:'column',textAlign:'center'}}>
            {actionText.body}
          <Stack direction='horizontal' gap={3} style={{justifyContent:"center",marginTop:'15px'}}>
            {(action.action !== 'ERROR' && action.action !== 'OK')&&
            <Button style={{width: 'fit-content'}} className="button button-danger" onClick={() => handleConfirmation()}>{actionText.title}</Button>
            }   
            <Button style={{width: 100}} className="button button-cancel" onClick={() => {
                if(action.action == 'OK'){
                    handleConfirmation()
                }else{
                    action.dissmiss()
                }
                
                }} >{'Stäng'}</Button>
          </Stack>
          </div>
          }
          />
        </>
    )
}