/* eslint-disable array-callback-return */
import { Option } from "react-multi-select-component";
import Urls from "../../lib/Urls";
import { apiFetch, convertToQuerystring } from "../../lib/utils";

export interface Invoice {
  id:number;
  invoiceNumber: string;
  supplierOrgId: string;
  customerId: string;
  customerOrgId: string;
  contractId:string;
  priceExcMoms: number;
  priceIncMoms: number;
  isRemoved: boolean;
  billingDate: Date;
  noOfInvoices:number;
  dueDate: Date;
  sendDate: Date;
  customerName?:string;
  type: number;
  filePath: string;
}

export interface InvoiceMonthly {
  contractId:string;
  priceExcMoms: number;
  billingDate: Date;
  noOfInvoices:number;
}

export interface InvoiceForPrint{
  supplierOrgId:string;
  customerOrgId:string;
  invoiceNumber:string;
  billingDate: Date;
  dueDate: Date;
  priceExcMoms: number;
  priceIncMoms : number;
  type: number;
  levName:string;
  customerName:string;
}
export interface InvoiceForProjectReport{
  period:string;
  contractData:ContractData[]
}
export type ContractData = {
  contractId:string;
  values:InvoiceData[]
}
export type InvoiceData = {
  customerName:string;
  customerOrgId:string;
  supplierId:string;
  supplierName:string;
  supplierOrgId:string;
  contractId:string;
  invoiceNumber:string;
  billingDate:string;
  priceExcMoms:number;
  kickbackPercent:string;
}
const Get = async (opt?: {year?:string, month?:string, supplier?: string}) : Promise<Invoice[]> =>{
  const queryString = convertToQuerystring(opt);
  
  const url =  `${Urls.Invoice}?${queryString}`;
  
  const response = await apiFetch(url);
  const invoices = await response.json();
  return invoices.map((inv:any) => {
    const invoice : Invoice = {
      ...inv,
      id: parseInt(inv.id),
      billingDate: new Date(inv.billingDate),
      dueDate: new Date(inv.dueDate),
      sendDate: new Date(inv.sendDate)
    }
    return invoice;
  })
}
const GetMonthly = async (opt?: {year?:string, month?:string, supplier?: string}) : Promise<InvoiceMonthly[]> => {
  const queryString = convertToQuerystring(opt);
  const url = queryString.length > 0 ? `${Urls.Invoice}/monthly?${queryString}` : Urls.Invoice+"/monthly";
  const response = await apiFetch(url);
  const invoices = await response.json();
  return invoices.map((inv:any) => {
    const invoice : Invoice = {
      ...inv,
      id: parseInt(inv.id),
      billingDate: new Date(inv.billingDate),
      dueDate: new Date(inv.dueDate),
      sendDate: new Date(inv.sendDate)
    }
    return invoice;
  });
}

const GetInvoicesToKickback = async (opt? : {year?:string, month?:string, select?:string}) : Promise<Invoice[]> => {
  const queryString = convertToQuerystring(opt);
  const url = queryString.length > 0 ? `${Urls.Invoice}/invoicetokickback?${queryString}` : Urls.Invoice+"/invoicetokickback";
  const response = await apiFetch(url);
  const invoices = await response.json();
  return invoices.map((inv:any) => {
    const invoice : Invoice = {
      ...inv,
      id: parseInt(inv.id),
      billingDate: new Date(inv.billingDate),
      dueDate: new Date(inv.dueDate),
      sendDate: new Date(inv.sendDate)
    };
    return invoice;
  })
}

const GetMissedPeriods = async () : Promise<string[]> => {
  try {
    const response = await apiFetch(Urls.Invoice+"/missed");
    const missedPeriods = await response.json();
    return missedPeriods;
  } catch (error) {
    console.log(error);
    return [];
  }
}

const GetDataForPrint = async (opt: {from?:string, to?:string, contractIds: string[]}) : Promise<InvoiceForPrint[]> => {
  try{
    const queryString = convertToQuerystring(opt?.from );
    
  const url = queryString.length > 0 ? `/forprint?from=${opt?.from}&to=${opt?.to}&filters=${opt.contractIds.toString()}` :   '/forprint';
  const response = await apiFetch(url);
  const invoices = await response.json();
  const res:InvoiceForPrint[] = []
  
  invoices.forEach((inv:any) => {
    inv.map((x:any) => {
      res.push({
        supplierOrgId: x.supplierOrgId,
        customerOrgId: x.customerOrgId,
        invoiceNumber: x.invoiceNumber,
        billingDate: x.billingDate,
        dueDate: x.dueDate,
        priceExcMoms: x.priceExcMoms,
        priceIncMoms: x.priceIncMoms,
        type: x.type,
        levName: x.levNamn,
        customerName: x.customerName
      }as InvoiceForPrint)
    })
  })
  return res.length> 0 ? res : []
  }
  catch(e){
    console.log(e)
    return []
  }
}
const GetProjectReportData = async (period:string,lang:string): Promise<Invoice[]> =>{
  const response = await apiFetch(Urls.Invoice + `/invoicesForKickbackProject?period=${period}&lang=${lang}`);

  const invoices:Invoice[] = await response.json();
  //console.log(invoices)
  return invoices
} 
export {
  Get,
  GetMonthly,
  GetMissedPeriods,
  GetDataForPrint,
  GetInvoicesToKickback,
  GetProjectReportData
}