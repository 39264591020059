import * as React from "react";
import { withTranslation } from "react-i18next";
import { DataService } from "../services/DataService";
import { NullReport } from "../services/API/NullReports";
import { Button, Form, FormGroup, Spinner, Stack } from "react-bootstrap";
import Datalist, {
  FooterOptions,
  ListColumn,
} from "../components/Datalist/Datalist";
import i18n from "../i18n";
import { IsUserSinfra } from "../lib/utils";
import { TSupplier } from "../services/API/Suppliers";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  IconLookup,
  faFilter,
  faFilterCircleXmark,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";
import NullReportHandleStatusModal from "../components/modal/NullReportHandleStatusModal";
import ToasterMsg from "../components/Toaster/ToasterMsg";
import ReportsFilter from "../components/ReportsFilter/ReportsFilter";
import { SearchBar } from "../components/SearchBar/SearchBar";
interface INullReportListpageProps {}
interface INullReportListpageState {
  loading: boolean;
  nullReports: NullReport[];
  columns: ListColumn<NullReport>[];
  suppliers: TSupplier[];
  searchText: string;
  selectedReportId: number | null;
  selectedReport: NullReport | null;
  toasterErrorShow: boolean;
  toasterMessage: any;
  filteredReports: NullReport[];
  isFilterActive: boolean;
  showFilter: boolean;
  filters: {
    periodBegin: Date | null;
    periodEnd: Date | null;
    reportDateBegin: Date | null;
    reportDateEnd: Date | null;
    selectedSuppliers: string[];
    selectedStatuses: string[];
  };
}
class NullReportListpage extends React.Component<
  INullReportListpageProps,
  INullReportListpageState
> {
  constructor(props: INullReportListpageProps) {
    super(props);
    this.state = {
      loading: false,
      nullReports: [],
      columns: this.nullReportColumns,
      suppliers: [],
      selectedReportId: null,
      searchText: "",
      selectedReport: null,
      toasterErrorShow: false,
      toasterMessage: "",
      filteredReports: [],
      isFilterActive: false,
      filters: {
        periodBegin: null,
        periodEnd: null,
        reportDateBegin: null,
        reportDateEnd: null,
        selectedSuppliers: [],
        selectedStatuses: [],
      },
      showFilter: false,
    };
  }

  private nullReportColumns: ListColumn<NullReport>[] = [
    {
      fieldName: "reportedTime",
      isActive: true,
      key: "reportedTime",
      minWidth: 40,
      maxWidth: 120,
      name: i18n.t("invoiceReportList.reportDate").toString(),
      onRender(item, col) {
        return item.reportedTime.toLocaleDateString("sv-SE");
      },
      footer: FooterOptions.COUNT,
      sorting: true,
    },
    {
      fieldName: "period",
      isActive: true,
      key: "period",
      minWidth: 130,
      name: i18n.t("general.period"),
      sorting: true,
      onRender: (item, col) => <>{item.period}</>,
    },
    {
      fieldName: "supplierOrgId",
      isActive: IsUserSinfra(),
      key: "supplierOrgId",
      minWidth: 130,
      // maxWidth: 130,
      name: i18n.t("general.supplier"),
      sorting: true,
      onRender: (item, col) => {
        const supplier = this.supplierDictionary[item.supplierOrgId];
        return `${item.supplierOrgId} - ${supplier?.supplierName}`;
      },
    },
    {
      fieldName: "reportedBy",
      isActive: true,
      sorting: true,
      key: "reportedBy",
      minWidth: 100,
      // maxWidth: 400,
      name: i18n.t("invoiceReportList.reporter"),
      onRender: (item, col) => {
        return item.reportedBy.includes("@") ? (
          <a href={`mailto:${item.reportedBy}`}>{item.reportedBy}</a>
        ) : (
          item.reportedBy
        );
      },
    },
    {
      fieldName: "status",
      isActive: true,
      key: "status",
      minWidth: 40,
      maxWidth: 70,
      name: i18n.t("invoiceReportList.status"),
      onRender: (item, col, i) => {
        let icon: any = {};
        for (
          let index = 0;
          index < AppSettings.kickbackReportsStatusIcons.length;
          index++
        ) {
          const element = AppSettings.kickbackReportsStatusIcons[index];
          if (element.key === item.status) {
            icon = element;
          }
        }
        const name: any = icon.icon;
        const lookup: IconLookup = { prefix: "fas", iconName: name };
        return typeof item.status !== "undefined" ? (
          <>
            {" "}
            <FontAwesomeIcon
              style={{ color: `${icon.color}` }}
              width={15}
              icon={findIconDefinition(lookup)}
            />{" "}
          </>
        ) : (
          ""
        );
      },
    },
  ];
  async componentDidMount(): Promise<void> {
    this.setState({ loading: true });
    await this.initSuppliers();
    const [nullReports] = await Promise.all([DataService.NullReports.Get()]);
    this.setState({
      loading: false,
      nullReports,
      filteredReports: nullReports,
    });
  }
  private initSuppliers = async () => {
    if (!IsUserSinfra()) {
      return [];
    }
    const suppliers = await DataService.Suppliers.Get();
    this.setState({ suppliers });
    suppliers.forEach((supp) => {
      this.supplierDictionary[supp.supplierOrgNr] = supp;
    });
    return suppliers;
  };
  private supplierDictionary: { [orgNr: string]: TSupplier } = {};
  private handleUpdatedStatusSaveSuccess = async (
    dissmiss: boolean,
    updated: NullReport,
    info: any,
    status?: number
  ) => {
    if (status === 0) {
      this.setState({ toasterErrorShow: true, toasterMessage: info });
      const updatedKickBackReports = this.state.nullReports.map((x) => {
        if (x.id === updated.id) {
          x.status = updated.status;
          return x;
        } else {
          return x;
        }
      });
      this.setState({ nullReports: updatedKickBackReports });
    } else {
      this.setState({ toasterErrorShow: true, toasterMessage: info });
    }
  };
  private handleToasterClose = () => {
    this.setState((prevState) => ({ toasterErrorShow: false }));
  };

  private isFilterActive = () => {
    const { filters } = this.state;
    const supplierActive = filters.selectedSuppliers.length > 0;
    const statusActive = filters.selectedStatuses.length > 0;
    const reportDateBeginActive = filters.reportDateBegin != null;
    const reportDateEndActive = filters.reportDateEnd != null;
    const periodBeginActive = filters.periodBegin != null;
    const periodEndActive = filters.periodEnd != null;
    return (
      statusActive ||
      supplierActive ||
      reportDateBeginActive ||
      reportDateEndActive ||
      periodBeginActive ||
      periodEndActive
    );
  };

  private onUpdateFilter = () => {
    const { filters, searchText } = this.state;
    const isFilterActive = this.isFilterActive();
    const nullReports = this.state.nullReports.filter((report) => {
      const supplier = this.supplierDictionary[report.supplierOrgId];
      const onSearchText =
        searchText.length > 0
          ? `${report.supplierOrgId} - ${supplier?.supplierName}`
              .toLowerCase()
              .includes(searchText.toLocaleLowerCase()) ||
            report.period.includes(searchText.toLocaleLowerCase()) ||
            report.reportedBy
              .toLocaleLowerCase()
              .includes(searchText.toLocaleLowerCase())
          : true;
      const onSupplier =
        filters.selectedSuppliers.length > 0
          ? filters.selectedSuppliers.includes(report.supplierOrgId)
          : true;
      const onStatus =
        filters.selectedStatuses.length > 0
          ? filters.selectedStatuses.includes(report.status.toString())
          : true;
      const onReportDateBegin = filters.reportDateBegin != null ?
        report.reportedTime.getTime() >= filters.reportDateBegin.getTime()
        : true;
      const onReportDateEnd = filters.reportDateEnd != null ?
        report.reportedTime.getTime() <= filters.reportDateEnd.getTime()
        : true;
      const onPeriodBegin = filters.periodBegin != null ?
        report.period >= filters.periodBegin.toLocaleDateString("sv-SE").substring(0,7)
        : true;
      const onPeriodEnd = filters.periodEnd != null ?
        report.period <= filters.periodEnd.toLocaleDateString("sv-SE").substring(0,7)
        : true;

      return (
        onReportDateBegin &&
        onReportDateEnd &&
        onSupplier &&
        onPeriodBegin &&
        onPeriodEnd &&
        onStatus &&
        onSearchText
      );
    });
    this.setState({
      isFilterActive,
      filteredReports: nullReports,
    });
  };

  render() {
    const { filters } = this.state;
    return (
      <>
        <Stack gap={4}>
          <div>
            <h2>{i18n.t("nullReportList.title")}</h2>
            <div style={{ position: "absolute", top: 0, left: 0, right: 0 }}>
              {this.state.toasterErrorShow && (
                <ToasterMsg
                  show={this.state.toasterErrorShow}
                  handleToastClose={this.handleToasterClose}
                  message={this.state.toasterMessage}
                  autoClose={true}
                  closeDelayMs={2000}
                  //customIcon="hashtag"
                />
              )}
            </div>
          </div>
          <div>
            {this.state.loading ? (
              <Spinner />
            ) : (
              <Stack
                direction="horizontal"
                gap={3}
                style={{ flexWrap: "wrap" }}
              >
                <SearchBar
                  value={this.state.searchText}
                  onChange={(newVal) => {
                    this.setState({ searchText: newVal }, () =>
                      this.onUpdateFilter()
                    );
                  }}
                  key={"searchNullReports"}
                  placeholder={i18n
                    .t("nullReportList.searchPlaceholder")
                    .toString()}
                />
                <Button
                  className="button-cancel"
                  onClick={() => {
                    this.setState({ showFilter: !this.state.showFilter });
                  }}
                >
                  <FontAwesomeIcon
                    icon={
                      this.state.isFilterActive ? faFilterCircleXmark : faFilter
                    }
                  />
                </Button>
              </Stack>
            )}
            <ReportsFilter
              title={i18n.t("nullReportList.filterTitle").toString()}
              periodBegin={filters.periodBegin}
              setPeriodBegin={(date) =>
                this.setState(
                  { filters: { ...filters, periodBegin: date } },
                  () => this.onUpdateFilter()
                )
              }
              periodEnd={filters.periodEnd}
              setPeriodEnd={(date) =>
                this.setState(
                  { filters: { ...filters, periodEnd: date } },
                  () => this.onUpdateFilter()
                )
              }
              reportDateBegin={filters.reportDateBegin}
              setReportDateBegin={(date) =>
                this.setState(
                  { filters: { ...filters, reportDateBegin: date } },
                  () => this.onUpdateFilter()
                )
              }
              reportDateEnd={filters.reportDateEnd}
              setReportDateEnd={(date) =>
                this.setState(
                  { filters: { ...filters, reportDateEnd: date } },
                  () => this.onUpdateFilter()
                )
              }
              allSuppliers={this.state.suppliers}
              selectedSuppliers={filters.selectedSuppliers}
              setSelectedSupplier={(supplierOrgs) =>
                this.setState(
                  { filters: { ...filters, selectedSuppliers: supplierOrgs } },
                  () => this.onUpdateFilter()
                )
              }
              allStatuses={AppSettings.kickbackReportsStatusIcons.map((s) => ({
                key: s.key.toString(),
                text: i18n.t(`kickback.status.${s.key}`),
              }))}
              selectedStatuses={filters.selectedStatuses}
              setSelectedStatuses={(selected) =>
                this.setState(
                  { filters: { ...filters, selectedStatuses: selected } },
                  () => this.onUpdateFilter()
                )
              }
              isOpen={this.state.showFilter}
            />
          </div>
          <div style={{ maxHeight: "60vh" }}>
            <Datalist
              items={this.state.filteredReports}
              uniqueKey="reportId"
              columns={this.state.columns /*this.state.invoiceReportColumns*/}
              noItemsText={i18n.t("invoiceReportList.empty")}
              initialSortKey="reportedTime"
              descendingSort={false}
              onClick={(item) => {
                this.setState((prevState) => ({ selectedReport: item }));
              }}
              setColumns={(cols) => {
                this.setState({ columns: cols /*invoiceReportColumns: cols*/ });
              }}
            />
          </div>
          {this.state.selectedReport != null && (
            <NullReportHandleStatusModal
              onDismiss={() => this.setState({ selectedReport: null })}
              selectedReport={this.state.selectedReport}
              handleUpdatedStatusSaveSuccess={
                this.handleUpdatedStatusSaveSuccess
              }
              supplierName={
                this.supplierDictionary[this.state.selectedReport.supplierOrgId]
              }
              //invoiceReportId={this.state.selectedReportId}
              // batchId={this.state.selectedReportBatchId}
            />
          )}
        </Stack>
      </>
    );
  }
}
export default withTranslation()(NullReportListpage);
