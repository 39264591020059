import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker_react from '../DatePicker/DatePicker_react';

interface FilterBase {
  label: string;
  type: "Date" | "Choice";
  key: string;
  placeholder?: string;
}

interface FilterDate extends FilterBase {
  type: "Date";
  variant: "date" | "month";
  value: Date | null;
  setValue(newVal:Date | null):void;
}

interface FilterChoiceOption { key:string; text:string }
interface FilterChoice extends FilterBase {
  type: "Choice";
  options: FilterChoiceOption[];
  value: string[];
  setValue(newValue:string[]):void;
}

type FilterOption = FilterDate | FilterChoice


export interface IFilterFormProps {
  filters: FilterOption[];
}

export interface IFilterFormState {
  
}

export default class FilterForm extends React.Component<IFilterFormProps, IFilterFormState> {
  constructor(props: IFilterFormProps) {
    super(props);

    this.state = {
    }
  }

  private getFilterRender = (filterOption:  FilterOption) => {
    switch(filterOption.type){
      case "Choice":
        return <Typeahead 
                placeholder={filterOption.placeholder}
                id={filterOption.key}
                selected={filterOption.options.filter(opt => filterOption.value.includes(opt.key))}
                multiple
                labelKey={"text"}
                options={filterOption.options}
                onChange={(selected) => {
                  //@ts-ignore
                  filterOption.setValue(selected.map(s => s.key))
                }}
                positionFixed
                onInputChange={(text) => {
                }}
              />
      case "Date":
        return <DatePicker_react
          showMonthYearPicker={filterOption.variant == "month"}
          dateFormat={filterOption.variant == "month" ? "yyyy-MMM" : undefined}
          onChange={(ev) => { 
            filterOption.setValue(ev);
          }} 
          selected={filterOption.value ? 
            filterOption.value : null}
          placeholderText={filterOption.placeholder}
        />
    }
  }

  private renderFilterOption = (filterOption: FilterOption) => {
    const filterRender = this.getFilterRender(filterOption);
    return <Col xs={12} sm={6} md={6} lg={6}>
      <div className='filter-field-label'>{filterOption.label}</div>
      {filterRender}
    </Col>
  }


  public render() {
    return (
        <Row className='filterForm'>
          {
            this.props.filters.map(filter => {
              return this.renderFilterOption(filter);
            })
          }
        </Row>
    );
  }
}
