/* eslint-disable @typescript-eslint/no-unused-expressions */

/* eslint-disable array-callback-return */
import * as React from "react";
import { withTranslation } from "react-i18next";
import i18n from "../i18n";
import { DataService } from "../services/DataService";
import ExpectedReportsBarChart from "../components/DashboardComps/ExpectedReportsBarChart";
import { Spinner, Stack } from "react-bootstrap";
import {
  ICategoryForPieChart,
  IDashboardCategorys,
  IDashboardExpectedReports,
  //IDashboardLEV_SinfraContractArea,
  IData_fordashboardCard,
  IInvoiceDataForPieChart,
} from "../services/API/DashBoard";
import PieChartCategorys from "../components/DashboardComps/PieChartCategorys";
import Card from "../components/DashboardComps/Card";
import {
  SvgForKickbackReports,
  SvgForNullReports,
  SvgForStatisticReports,
} from "../components/DashboardComps/SvgIcons";
import CategoryModal from "../components/DashboardComps/CategoryModal";
import ViewCard from "../components/DashboardComps/ViewCard";
import { FloorTo, RoundTo } from "../lib/utils";

interface IDashboardProps {}
interface IDashboardState {
  loading: boolean;
  loadingCards: boolean;
  expectedReports: IDashboardExpectedReports[];
  categorys: IDashboardCategorys;
  //perContract: IDashboardLEV_SinfraContractArea[];
  period: string;
  statistikRapporterCard: IData_fordashboardCard[];
  nollRapporterCard: IData_fordashboardCard[];
  kickbackRapporterCard: IData_fordashboardCard[];
  periodIndex: number;
  yearFilter: string[];
  showCategoryModal: boolean;
  dataForCategoryModal: any[];
  summedDataForPie: IInvoiceDataForPieChart[];
  dataForPie: ICategoryForPieChart[];
  selectedCategory: string;
  filterdByYear: any[];
}


class Dashboard extends React.Component<IDashboardProps, IDashboardState> {
  constructor(props: IDashboardProps) {
    super(props);
    this.state = {
      expectedReports: [],
      categorys: {} as IDashboardCategorys,
      period: "",
      //perContract: [] as IDashboardLEV_SinfraContractArea[],
      statistikRapporterCard: [] as IData_fordashboardCard[],
      nollRapporterCard: [] as IData_fordashboardCard[],
      kickbackRapporterCard: [] as IData_fordashboardCard[],
      loading: true,
      periodIndex: -1,
      yearFilter: [""],
      showCategoryModal: false,
      dataForCategoryModal: [],
      loadingCards: true,
      dataForPie: [] as ICategoryForPieChart[],
      summedDataForPie: [] as IInvoiceDataForPieChart[],
      selectedCategory: "",
      filterdByYear: [],
    };
  }
  async componentDidMount(): Promise<void> {
    const [
      categorys,
      //perContract,
      summedDataForPie,
      expectedReports
    ] = await Promise.all([
      DataService.Dashboard.GetCategorys(),
      //DataService.Dashboard.GetLEV_SinfraContractArea(),
      DataService.Dashboard.GetData_IInvoiceDataForPieChart(),
      DataService.Dashboard.GetExpectedReports()
    ]);
   
    await this.InitCards();
    await this.InitBarChart(expectedReports);
    const date = new Date();
    const backUpYeah = new Date(date.getFullYear(),date.getMonth()-1)
    const formatted = backUpYeah.toLocaleDateString('sv-SE').split('-')
    const period = `${formatted[0]}-${formatted[1]}`;

    this.setState({
      categorys,
      //perContract,
      period,
      summedDataForPie,
      expectedReports
    },() => this.InitBarChart(this.state.expectedReports));
  }
  InitBarChart = async (expectedReports:IDashboardExpectedReports[]) => {
   
    const yearFilter = this.CreateYearFilter(expectedReports);
    this.FilterByYear(yearFilter.length);
    this.setState({
      
      yearFilter,
      periodIndex: yearFilter.length,
    });
  };
  InitCards = async () => {
    const cardData = await DataService.Dashboard.GetData_fordashboardCard();
    this.setState({
      loadingCards: false,
      statistikRapporterCard: cardData[0],
      nollRapporterCard: cardData[1],
      kickbackRapporterCard: cardData[2],
    });
  };
  CreateYearFilter = (
    expectedReports: IDashboardExpectedReports[]
  ): string[] => {
    const periods: Set<string> = new Set();
    expectedReports.map((x) =>
      periods.add(new Date(x.period).getFullYear().toString())
    );
    const parsedPeriods: string[] = [];
    periods.forEach((x) => {
      parsedPeriods.push(x);
    });
    return parsedPeriods;
  };
  OnClickForward = () =>
    this.state.periodIndex + 1! > this.state.yearFilter.length
      ? ""
      : this.setState(
          (prevState) => ({
            periodIndex: prevState.periodIndex + 1,
          }),
          () => this.FilterByYear(this.state.periodIndex)
        );

  OnClickBackward = () =>
    this.state.periodIndex - 1 <= 0
      ? ""
      : this.setState(
          (prevState) => ({
            periodIndex: prevState.periodIndex - 1,
          }),
          () => this.FilterByYear(this.state.periodIndex)
        );

  OnClickHandlerForReportsExpectedBarChart = async (barPeriod: string) => {
    const dataForPie = await DataService.Dashboard.GetData_ICategoryForPieChart(
      `${barPeriod}-01`
    );
    this.setState({ period: barPeriod, dataForPie });
  };

  OnClickHandlerForSelectedSlicePieChart = async (category: any) => {
    const categorys: { [key: string]: number } = {
      "El och Fiber": 100,
      Sinfra: 200,
      "Tjänster och Konsulter": 300,
      "Vatten och Avlopp": 400,
      "Värme och kyla": 500,
      Övrigt: 900,
      Ekonomi: 910,
    };
    const res: IInvoiceDataForPieChart[] = [];
    for (let index = 0; index < this.state.summedDataForPie.length; index++) {
      if (
        this.state.summedDataForPie[index].categoryId ===
          categorys[category.name] &&
        this.state.summedDataForPie[index].period.includes(this.state.period)
      ) {
        res.push(this.state.summedDataForPie[index]);
      }
    }
    this.setState({
      showCategoryModal: true,
      dataForCategoryModal: res,
      selectedCategory: category.name,
    });
  };
  OnCloseHandlerForCategoryModal = () => {
    this.setState({ showCategoryModal: false });
  };
  FilterByYear(index: number) {
    const result: any[] = [];
    for (let index = 0; index < this.state.expectedReports.length; index++) {
      const invoiceReports = this.state.expectedReports[index].invoiceReports;
      const nullReports = this.state.expectedReports[index].nullReports;
      const totalExpected = this.state.expectedReports[index].reportsExpected;
      const csrdReports = this.state.expectedReports[index].csrdReports;
      if (
        this.state.expectedReports[index].period.includes(
          this.state.yearFilter[this.state.periodIndex - 1]
        )
      ) {
        const procentInvoiceReports = RoundTo(
          (invoiceReports /
            totalExpected) *
            100, 1
        );
        const procentNullReports = RoundTo(
          (nullReports /
            totalExpected) *
            100, 1
        );
        const procentCsrdReports = RoundTo((csrdReports / totalExpected) * 100,1)
       
        result.push({
          period: this.state.expectedReports[index].period,
          reportsExpected: 100,
          invoiceReports: procentInvoiceReports,
          nullReports: procentNullReports,
          csrdReports: procentCsrdReports,
          fullyReported: invoiceReports + nullReports >= totalExpected ? 1 : 0,
          toolTipData: this.state.expectedReports[index],
        });
      }
    }
    this.setState({ filterdByYear: result,loading:false });
    return result;
  }
  render() {
    const categorys: { [key: string]: string } = {
      "100": "El och Fiber",
      "200": "Sinfra",
      "300": "Tjänster och Konsulter",
      "400": "Vatten och Avlopp",
      "500": "Värme och kyla",
      "900": "Övrigt",
      "910": "Ekonomi",
    };
    const test: { [key: string]: number } =
      this.state.period.length > 0
        ? this.state.summedDataForPie.reduce((res: any, item) => {
            const cat = categorys[`${item.categoryId}`];
            const sumvalue = Math.round(item.priceExcMoms);
            if (
              !res[cat] &&
              sumvalue > 0 &&
              item.period.includes(this.state.period)
            ) {
              res[cat] = sumvalue;
            } else if (
              res[cat] &&
              sumvalue > 0 &&
              item.period.includes(this.state.period)
            ) {
              res[cat] += sumvalue;
            }
            return res;
          }, {})
        : {};
    const compStyles = getComputedStyle(document.documentElement);
    return (
      <div  >
        {this.state.loadingCards ? (
          <Spinner />
        ) : (
          <Stack
            className="dashboardCardscontainer"
            gap={3}
            style={{ marginBottom: "10px" }}
            direction="horizontal"
          >
            <Card
              backgroundColor={compStyles.getPropertyValue("--statistic-background")}
              color={compStyles.getPropertyValue("--statistic-color")}
              svg={SvgForStatisticReports()}
              cardTitle={i18n.t("dashboard.invoiceReports")}
              statProcent={this.state.statistikRapporterCard
                .reduce((acc, z) => {
                  return acc + z.totalReported;
                }, 0)
                .toString()}
              week={this.state.statistikRapporterCard[0].week}
              dataPointsTrendline={this.state.statistikRapporterCard}
            />

            <Card
              backgroundColor={compStyles.getPropertyValue("--zero-background")}
              color={compStyles.getPropertyValue("--zero-color")}
              svg={SvgForNullReports()}
              cardTitle={i18n.t("dashboard.nullReports")}
              statProcent={this.state.nollRapporterCard
                .reduce((acc, z) => {
                  return acc + z.totalReported;
                }, 0)
                .toString()}
              week={this.state.nollRapporterCard[0].week}
              dataPointsTrendline={this.state.nollRapporterCard}
            />

            <Card
              backgroundColor={compStyles.getPropertyValue("--kickback-background")}
              color={compStyles.getPropertyValue("--kickback-color")}
              svg={SvgForKickbackReports()}
              cardTitle={i18n.t("dashboard.kickbackReport")}
              statProcent={this.state.kickbackRapporterCard
                .reduce((acc, z) => {
                  return acc + z.totalReported;
                }, 0)
                .toString()}
              week={this.state.kickbackRapporterCard[0].week}
              dataPointsTrendline={this.state.kickbackRapporterCard}
            />
          </Stack>
        )}
        {this.state.loading ? (
          <div style={{ float: "right", marginLeft: "10px" }}>
            <Spinner />
          </div>
        ) : (
          
          // <Stack direction="horizontal">
          <div className="test" >
          <div className="dashboardChartsscontainer">
           {/* <Stack  > */}
           
             <ExpectedReportsBarChart
               data={this.state.filterdByYear}
               loading={this.state.loading}
               onClick={this.OnClickHandlerForReportsExpectedBarChart}
               onClickForward={this.OnClickForward}
               onClickBackward={this.OnClickBackward}
               yearFilter={this.state.yearFilter ?? []}
               periodIndex={this.state.periodIndex}
             />
           {/* </Stack> */}
             </div>
          <div className="dashboardPieContainer" >
            {/* <Stack gap={3}> */}
              <PieChartCategorys
                category={this.state.categorys}
                data={test}
                period={this.state.period}
                onSelectedSlice={this.OnClickHandlerForSelectedSlicePieChart}
              />
              
            {/* </Stack> */}
            <Stack>
              <div style={{alignSelf:'center',paddingTop:'5px'}}>
                <ViewCard />
            </div>
              </Stack>
          </div>
          
          </div>
            //  </Stack>
        )}

         
        {this.state.showCategoryModal && (
          <CategoryModal
            onDissmissHandler={this.OnCloseHandlerForCategoryModal}
            data={this.state.dataForCategoryModal}
            period={this.state.period}
            selectedCategory={this.state.selectedCategory}
            footerSum={test[`${this.state.selectedCategory}`]}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(Dashboard);
