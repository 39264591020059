/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { Button, Spinner, Stack } from "react-bootstrap";
import { DataService } from "../services/DataService";
import Datalist, {
  FooterOptions,
  ListColumn,
} from "../components/Datalist/Datalist";
import { IsUserSinfra, ReadFriendlyNumber } from "../lib/utils";
import { TSupplier } from "../services/API/Suppliers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faFilterCircleXmark,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { InvoiceReport } from "../services/API/InvoiceReports";
import InvoiceReportModal from "../components/modal/InvoiceReportModal";
import { SearchBar } from "../components/SearchBar/SearchBar";
import ReportsFilter from "../components/ReportsFilter/ReportsFilter";

interface Props extends WithTranslation {}

interface State {
  isLoading: boolean;
  suppliers: TSupplier[];
  searchText: string;
  selectedReportId: number | null;
  reportColumns: ListColumn<InvoiceReport>[];
  reportData: InvoiceReport[];
  filteredReports: InvoiceReport[];
  selectedReportBatchId?: string;
  showFilter: boolean;
  isFilterActive: boolean;
  filters: {
    periodBegin: Date | null;
    periodEnd: Date | null;
    reportDateBegin: Date | null;
    reportDateEnd: Date | null;
    selectedSuppliers: string[];
    selectedStatuses: string[];
  };
}

class InvoiceReportListPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      searchText: "",
      suppliers: [],
      selectedReportId: null,
      reportData: [],
      reportColumns: this.reportColumns,
      selectedReportBatchId: undefined,
      showFilter: false,
      filteredReports: [],
      isFilterActive: false,
      filters: {
        periodBegin: null,
        periodEnd: null,
        reportDateBegin: null,
        reportDateEnd: null,
        selectedSuppliers: [],
        selectedStatuses: [],
      },
    };
  }

  private initSuppliers = async () => {
    if (!IsUserSinfra()) {
      return [];
    }
    const suppliers = await DataService.Suppliers.Get();

    suppliers.forEach((supp) => {
      this.supplierDictionary[supp.supplierOrgNr] = supp;
    });
    return suppliers;
  };

  private supplierDictionary: { [orgNr: string]: TSupplier } = {};
  async componentDidMount() {
    const [invoiceReports, suppliers] = await Promise.all([
      DataService.InvoiceReports.Get(),
      this.initSuppliers(),
    ]);

    this.setState({
      isLoading: false,
      suppliers,
      reportData: invoiceReports,
      filteredReports: invoiceReports,
    });
  }

  private reportColumns: ListColumn<InvoiceReport>[] = [
    {
      fieldName: "reportDate",
      isActive: true,
      key: "reportDate",
      minWidth: 40,
      maxWidth: 120,
      name: this.props.t("invoiceReportList.reportDate").toString(),
      onRender(item, col) {
        return item.reportDate.toLocaleDateString();
      },
      footer: FooterOptions.COUNT,
      sorting: true,
    },
    {
      fieldName: "period",
      isActive: true,
      key: "period",
      minWidth: 130,
      name: this.props.t("general.period"),
      sorting: true,
      // onRender: (item,col) => <>{this.checkPeriod(item)}</>
    },
    {
      fieldName: "supplierOrgId",
      isActive: IsUserSinfra(),
      key: "supplierOrgId",
      minWidth: 130,
      // maxWidth: 130,
      name: this.props.t("general.supplier"),
      sorting: true,
      onRender: (item, col) => {
        const supplier = this.supplierDictionary[item.supplierOrgId];
        return `${item.supplierOrgId} - ${supplier?.supplierName}`;
      },
    },
    {
      fieldName: "reporter",
      isActive: true,
      sorting: true,
      key: "reporter",
      minWidth: 100,
      // maxWidth: 400,
      name: this.props.t("invoiceReportList.reporter"),
      onRender: (item, col) => {
        return item.reporter.includes("@") ? (
          <a href={`mailto:${item.reporter}`}>{item.reporter}</a>
        ) : (
          item.reporter
        );
      },
    },

    {
      fieldName: "noOfInvoicesImported",
      isActive: true,
      key: "noOfInvoicesImported",
      minWidth: 10,
      sorting: true,
      name: this.props.t("invoiceReportList.noOfInvoices"),
    },
    {
      fieldName: "totalSumExclMomsImported",
      isActive: true,
      key: "totalSumExclMomsImported",
      minWidth: 50,
      sorting: true,
      name: this.props.t("invoiceReportList.sumExclMoms"),
      footer: FooterOptions.SUM,
      onRender(item, col) {
        return ReadFriendlyNumber(item.totalSumExclMomsImported ?? 0);
      },
    },
    {
      fieldName: "status",
      isActive: true,
      key: "status",
      minWidth: 40,
      maxWidth: 70,
      name: this.props.t("invoiceReportList.status"),
      onRender: (item, col, i) => (
        <>
          <FontAwesomeIcon {...DataService.InvoiceReports.GetStatus(item)} />
        </>
      ),
    },
  ];

  private isFilterActive = () => {
    const { filters } = this.state;
    const supplierActive = filters.selectedSuppliers.length > 0;
    const statusActive = filters.selectedStatuses.length > 0;
    const reportDateBeginActive = filters.reportDateBegin != null;
    const reportDateEndActive = filters.reportDateEnd != null;
    const periodBeginActive = filters.periodBegin != null;
    const periodEndActive = filters.periodEnd != null;
    return (
      statusActive ||
      supplierActive ||
      reportDateBeginActive ||
      reportDateEndActive ||
      periodBeginActive ||
      periodEndActive
    );
  };

  private onUpdateFilter = () => {
    const { filters, searchText } = this.state;
    const isFilterActive = this.isFilterActive();
    const reports = this.state.reportData.filter((report) => {
      const supplier = this.supplierDictionary[report.supplierOrgId];
      const onSearchText =
        searchText.length > 0
          ? `${report.supplierOrgId} - ${supplier?.supplierName}`
              .toLowerCase()
              .includes(searchText.toLocaleLowerCase()) ||
            report.period?.includes(searchText.toLocaleLowerCase()) ||
            report.reporter
              .toLocaleLowerCase()
              .includes(searchText.toLocaleLowerCase())
          : true;
      const onSupplier =
        filters.selectedSuppliers.length > 0
          ? filters.selectedSuppliers.includes(report.supplierOrgId)
          : true;
      const onStatus =
        filters.selectedStatuses.length > 0
          ? filters.selectedStatuses.includes(report.status.toString())
          : true;
      const onReportDateBegin = filters.reportDateBegin != null ?
        report.reportDate.getTime() >= filters.reportDateBegin.getTime()
        : true;
      const onReportDateEnd = filters.reportDateEnd != null ?
        report.reportDate.getTime() <= filters.reportDateEnd.getTime()
        : true;
      const periods = report.period.split(" - ");
      const onPeriodBegin = filters.periodBegin != null ?
        //@ts-ignore
        periods.some((p) => p >= filters.periodBegin.toLocaleDateString("sv-SE").substring(0,7))
        : true;
      const onPeriodEnd = filters.periodEnd != null ?
        //@ts-ignore
        periods.some((p) => p <= filters.periodEnd.toLocaleDateString("sv-SE").substring(0,7))
        : true;

      return (
        onReportDateBegin &&
        onReportDateEnd &&
        onSupplier &&
        onPeriodBegin &&
        onPeriodEnd &&
        onStatus &&
        onSearchText
      );
    });
    this.setState({
      filteredReports: reports,
      isFilterActive,
    });
  };

  render() {
    const { filters } = this.state;
    return (
      <Stack gap={4}>
        <div>
          <h2>{this.props.t("invoiceReportList.title")}</h2>
          {this.props.t("invoiceReportList.description")?.length > 0 && (
            <p>{this.props.t("invoiceReportList.description")}</p>
          )}
        </div>
        {this.state.isLoading && <Spinner />}
        {!this.state.isLoading && (
          <>
            <div>
              <Stack
                direction="horizontal"
                gap={3}
                style={{ flexWrap: "wrap" }}
              >
                <SearchBar
                  value={this.state.searchText}
                  onChange={(newVal) => {
                    this.setState({ searchText: newVal }, () =>
                      this.onUpdateFilter()
                    );
                  }}
                  key={"searchStatisticReports"}
                  placeholder={this.props
                    .t("invoiceReportList.searchPlaceholder")
                    .toString()}
                />
                <Button
                  className="button-cancel"
                  onClick={() => {
                    this.setState({ showFilter: !this.state.showFilter });
                  }}
                >
                  <FontAwesomeIcon
                    icon={
                      this.state.isFilterActive ? faFilterCircleXmark : faFilter
                    }
                  />
                </Button>
              </Stack>
              <ReportsFilter
                title={this.props.t("invoiceReportList.filterTitle").toString()}
                periodBegin={filters.periodBegin}
                setPeriodBegin={(date) =>
                  this.setState(
                    { filters: { ...filters, periodBegin: date } },
                    () => this.onUpdateFilter()
                  )
                }
                periodEnd={filters.periodEnd}
                setPeriodEnd={(date) =>
                  this.setState(
                    { filters: { ...filters, periodEnd: date } },
                    () => this.onUpdateFilter()
                  )
                }
                reportDateBegin={filters.reportDateBegin}
                setReportDateBegin={(date) =>
                  this.setState(
                    { filters: { ...filters, reportDateBegin: date } },
                    () => this.onUpdateFilter()
                  )
                }
                reportDateEnd={filters.reportDateEnd}
                setReportDateEnd={(date) =>
                  this.setState(
                    { filters: { ...filters, reportDateEnd: date } },
                    () => this.onUpdateFilter()
                  )
                }
                allSuppliers={this.state.suppliers}
                selectedSuppliers={filters.selectedSuppliers}
                setSelectedSupplier={(supplierOrgs) =>
                  this.setState(
                    {
                      filters: { ...filters, selectedSuppliers: supplierOrgs },
                    },
                    () => this.onUpdateFilter()
                  )
                }
                allStatuses={AppSettings.invoiceReports.status.map((s) => ({
                  key: s.key.toString(),
                  text: this.props.t(`invoiceReportList.statusTexts.${s.key}`),
                }))}
                selectedStatuses={filters.selectedStatuses}
                setSelectedStatuses={(selected) =>
                  this.setState(
                    { filters: { ...filters, selectedStatuses: selected } },
                    () => this.onUpdateFilter()
                  )
                }
                isOpen={this.state.showFilter}
              />
            </div>

            <div style={{ maxHeight: "60vh" }}>
              <Datalist
                items={this.state.filteredReports}
                uniqueKey="reportId"
                columns={
                  this.state.reportColumns /*this.state.invoiceReportColumns*/
                }
                noItemsText={this.props.t("invoiceReportList.empty")}
                initialSortKey="reportDate"
                descendingSort={false}
                onClick={(item) => {
                  this.setState({
                    selectedReportId: item.id,
                    selectedReportBatchId: item.batchId,
                  });
                }}
                setColumns={(cols) => {
                  this.setState({
                    reportColumns: cols /*invoiceReportColumns: cols*/,
                  });
                }}
              />
            </div>
          </>
        )}

        {this.state.selectedReportId != null && (
          <InvoiceReportModal
            onDismiss={() => this.setState({ selectedReportId: null })}
            invoiceReportId={this.state.selectedReportId}
            batchId={this.state.selectedReportBatchId}
          />
        )}
      </Stack>
    );
  }
}

export default withTranslation()(InvoiceReportListPage);
