import { t } from 'i18next';
import * as React from 'react';
import { Stack, Row, Col, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { TSupplier } from '../../services/API/Suppliers';
import StyledCheckbox from '../StyledCheckbox/StyledCheckbox';
import DatePicker_react from '../DatePicker/DatePicker_react';

interface Filter<T> {
  OnChange(newFilter:T):void;
  currentFilter:T;
  options?:T[]
}

export interface FilterOptions {
  supplier:Filter<string>;
  category:Filter<string>;
  startDateBegin:Filter<Date|null>;
  startDateEnd:Filter<Date|null>;
  startReportDateBegin:Filter<Date|null>;
  startReportDateEnd:Filter<Date|null>;
  endReportDateBegin:Filter<Date|null>;
  endReportDateEnd:Filter<Date|null>;
  terminateDateBegin:Filter<Date|null>;
  terminateDateEnd:Filter<Date|null>;
  archived:Filter<boolean>;
}

export interface IContractFilterProps {
  suppliers:TSupplier[];
  filterOptions: FilterOptions;
  isOpen: boolean;
}

export interface IContractFilterState {
  
}

export default class ContractFilter extends React.Component<IContractFilterProps, IContractFilterState> {
  constructor(props: IContractFilterProps) {
    super(props);

    this.state = {
    }
  }

  private renderDateField = (filter: Filter<Date|null>) => {
    return <DatePicker_react 
      onChange={(ev) => { 
          filter.OnChange(ev);
      }} 
      selected={filter.currentFilter ? 
        filter.currentFilter : null}
      placeholderText='åååå-mm-dd'
    />
  }

  public render() {
    return (
      <Stack gap={1} aria-expanded={this.props.isOpen} className='contract-filter'>
        <div className='contract-filter-title'>{t("contractListPage.filter.title")}</div>
        <Row>
          <Col xs={12} sm={6} md={6} lg={6}>
            <div className='contract-filter-field-label'>{t("general.supplier")}</div>
            <Typeahead 
              placeholder={t('contractListPage.filter.supplierPlaceholder').toString()}
              id={"supplier"}
              defaultInputValue={this.props.filterOptions.supplier.currentFilter}
              options={this.props.suppliers.map(supplier => `${supplier.supplierName} - ${supplier.supplierOrgNr}` )}
              onChange={(selected) => {
                if(selected.length > 0){
                  const supplierLabel = selected[0];
                  const foundSupplier = this.props.suppliers.find(supp => `${supp.supplierName} - ${supp.supplierOrgNr}` == supplierLabel);
                  if(foundSupplier)
                    this.props.filterOptions.supplier.OnChange(foundSupplier.supplierOrgNr);
                }
              }}
              onInputChange={(text) => {
                this.props.filterOptions.supplier.OnChange("");
              }}
            />
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
          <div className='contract-filter-field-label'>{t("general.category")}</div>
            <Typeahead 
              placeholder={t('contractListPage.filter.categoryPlaceholder').toString()}
              id={"category"}
              defaultInputValue={this.props.filterOptions.category.currentFilter}
              options={this.props.filterOptions.category.options ?? []}
              onChange={(selected) => {
                if(selected.length > 0){
                  const newVal = selected[0];
                  if(newVal)
                    this.props.filterOptions.category.OnChange(newVal.toString());
                }
              }}
              onInputChange={(text) => {
                this.props.filterOptions.category.OnChange("");
              }}
            />
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <div className='contract-filter-field-label'>{t("contractListPage.filter.startDateBegin")}</div>
            {this.renderDateField(this.props.filterOptions.startDateBegin)}
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <div className='contract-filter-field-label'>{t("contractListPage.filter.startDateEnd")}</div>
            {this.renderDateField(this.props.filterOptions.startDateEnd)}
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <div className='contract-filter-field-label'>{t("contractListPage.filter.startReportDateBegin")}</div>
            {this.renderDateField(this.props.filterOptions.startReportDateBegin)}
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <div className='contract-filter-field-label'>{t("contractListPage.filter.startReportDateEnd")}</div>
            {this.renderDateField(this.props.filterOptions.startReportDateEnd)}
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <div className='contract-filter-field-label'>{t("contractListPage.filter.endReportDateBegin")}</div>
            {this.renderDateField(this.props.filterOptions.endReportDateBegin)}
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <div className='contract-filter-field-label'>{t("contractListPage.filter.endReportDateEnd")}</div>
            {this.renderDateField(this.props.filterOptions.endReportDateEnd)}
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <div className='contract-filter-field-label'>{t("contractListPage.filter.terminateDateBegin")}</div>
            {this.renderDateField(this.props.filterOptions.terminateDateBegin)}
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <div className='contract-filter-field-label'>{t("contractListPage.filter.terminateDateEnd")}</div>
            {this.renderDateField(this.props.filterOptions.terminateDateEnd)}
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <div className='contract-filter-field-label'>{t("general.archived")}</div>
            <StyledCheckbox 
              isChecked={this.props.filterOptions.archived.currentFilter} 
              label={''} 
              onChange={(newVal) => this.props.filterOptions.archived.OnChange(newVal)}
            />
          </Col>
        </Row>
      </Stack>
    );
  }
}
