import Urls from "../../lib/Urls";
import { Code, ReturnFetchObj,kcFetch } from "../../lib/utils";
import { t } from 'i18next'

export interface TRole {
  id:string;
  name: string;
}

const Get = async () : Promise<TRole[]> => {
  const response = await kcFetch(Urls.Roles);
  const json : any[] = await response.json();
  return json
    .map(grp => {
      return {
        id: grp.id,
        name: grp.name,
        SupplierID: grp.attributes?.SupplierID[0] ?? undefined
      }
    });
}

// const GetById = async (id:string) : Promise<TRole> => {
//   const response = await kcFetch(Urls.Roles+"/"+id)
//   const grp = await response.json();
//   return {
//     id: grp.id,
//     name: grp.name
//   }
// }

const GetUserRoles = async (id:string) : Promise<TRole[]> => {
  const response = await kcFetch(Urls.UserRoles.replace("{id}", id));
  const json : any[] = await response.json();
  return json.map(role => {
    return {
      id: role.id,
      name: role.name
    }
  });
}

const AddUserRoles = async (userId:string, roles: TRole[]) => {
  if(roles.length == 0){
    return ReturnFetchObj(t('api.rolesMapping.PostSuccess'), Code.OK, {userId, roles});
  }
  try {
    const response = await kcFetch(Urls.UserRoles.replace("{id}", userId), "POST", JSON.stringify(roles), {"Content-Type": "application/json"});
    return ReturnFetchObj(t('api.rolesMapping.PostSuccess'), Code.OK, {userId, roles});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.rolesMapping.PostFail'), Code.ADD_ERR, {userId, roles});
  }
}

const RemoveUserRoles = async (userId:string, roles: TRole[]) => {
  if(roles.length == 0){
    return ReturnFetchObj(t('api.rolesMapping.DeleteSuccess'), Code.OK, {userId, roles});
  }
  try {
    const response = await kcFetch(Urls.UserRoles.replace("{id}", userId), "DELETE", JSON.stringify(roles), {"Content-Type": "application/json"});
    return ReturnFetchObj(t('api.rolesMapping.DeleteSuccess'), Code.OK, {userId, roles});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.rolesMapping.DeleteFail'), Code.ADD_ERR, {userId, roles});
  }
}



export {
  Get,
  // GetById,
  GetUserRoles,
  AddUserRoles,
  RemoveUserRoles
}