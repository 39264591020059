/* eslint-disable array-callback-return */
import * as React from "react";
import { StyledModal } from "./StyledModal";

import { Button, Dropdown,  Spinner, Stack } from "react-bootstrap";
import { IconLookup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import { NullReport } from "../../services/API/NullReports";
import i18n from "../../i18n";
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";
import { API } from "../../services/API";
import { TSupplier } from "../../services/API/Suppliers";
import { Department, DepartmentFunctionArgs } from "../../services/API/Departments";
import { DataService } from "../../services/DataService";
interface INullReportHandleStatusModal{
    onDismiss():void;
    kickbackReportId?:string;
    handleUpdatedStatusSaveSuccess: (dissmiss:boolean,update:NullReport,info:any,status?:number) => void;
    selectedPeriod?:string;
    supplier?:string;
    selectedReport:NullReport;
    supplierName:TSupplier;
}
interface INullReportHandleStatusModalState{
    loading:boolean;
    selectedStatus:Statt
    statt:any;
    status:Status | undefined
    saving:boolean;
    labels:JSX.Element[]
    department?:Department;
}
type Status = {
    key:number;
    value:string;
    element:JSX.Element;
  }
  interface Statt{
    [key:number]:any
  }
class NullReportHandleStatusModal extends React.Component<INullReportHandleStatusModal,INullReportHandleStatusModalState>{
constructor(props:INullReportHandleStatusModal){
    super(props)
    this.state = ({loading:false,selectedStatus:'',statt:{},status:undefined,saving:false,labels:[<></>]})
}
async componentDidMount(): Promise<void> {
  this.setState({loading:true})
  if(typeof this.props.selectedReport.departmentId == 'number'){
      const department:DepartmentFunctionArgs = {}
      department.id = this.props.selectedReport.departmentId
      const fetchedDepartment:Department | undefined = await DataService.Departments.GetDepartment(department)
      this.setState({department:fetchedDepartment})
  }
  
  this.findIcons()
}
private buildLabels = () => {
  const { supplierOrgId, reportedTime, period, reportedBy } = this.props.selectedReport;
  const supplierName = this.props.supplierName
  //const res: JSX.Element[] = [];
  //res.push(
  return   <div> <>
      <div>
      <b  key="1">{i18n.t("kickback.fileModal.supplier")}</b>        
      </div>
      <div>        
      {supplierName?.supplierName} - {supplierOrgId}
      </div>
      
      {typeof this.props.selectedReport !== 'undefined' &&
           this.props.selectedReport.departmentId !== null &&
            <tr>
              
            <b>{i18n.t('departmentList.singular')} <FontAwesomeIcon icon='building'/></b>
            <div >{this.state.department?.department}</div>
          </tr>
           }
      
        <div>
        <b key="2">{i18n.t("kickback.fileModal.reportDate")}</b>
        
        </div>
        <div>
        {reportedTime.toLocaleDateString("sv-SE")}
        </div>
        </>
        <>
        <div>
        <b key="3">{i18n.t("kickback.fileModal.period")}</b>
        </div>
        <div>
        {period}
        </div>
        </>
        <>
        <div>
        <b key="4">{i18n.t("kickback.fileModal.reporter")}</b>
        </div>
        <div>
        {reportedBy.includes('@') ? <a href={`mailto:${reportedBy}`}>{reportedBy}</a> : reportedBy}
        </div>
        
        </>
        </div>
  //);
  ///this.setState({ labels: res });
};
 findIcons = () => {
  
    const statt:Statt = {}
    AppSettings.kickbackReportsStatusIcons.map((x,index) => {
      const name:any = x.icon 
      const lookup:IconLookup = {prefix:'fas', iconName: name }
        statt[`${x.key}`] = {key:x.key,value: i18n.t(`kickback.status.${x.key}`),element:<> <FontAwesomeIcon  title={i18n.t(`kickback.status.${x.key}`)|| ''}style={{color:`${x.color}`}} width={15} icon={findIconDefinition( lookup )}/>{' '} {i18n.t(`kickback.status.${x.key}`)} </>} as Status 
    })
    const inititalStatus = statt[`${this.props.selectedReport.status}`]
    this.setState({statt},() => this.setState({status:inititalStatus,loading:false}))
   
    
  }
   handleReportUpdatedStatusSave = async():Promise<void> =>{
    //stänger modalen props.onDismiss()
    this.setState({saving:true})
    
    let updated:NullReport = JSON.parse(JSON.stringify(this.props.selectedReport))//buildUpdateData()
   if(typeof this.state.status !== 'undefined'){

       updated.status = this.state.status.key
   }
    const res = await API.NullReports.UpdateStatus(updated)
    
    if(res.Code === 0){
     this.setState({saving:false}) 
      this.props.onDismiss()
      this.props.handleUpdatedStatusSaveSuccess(false,updated,res,res.Code)
    }
    else{
      //poppa upp error, fixa en spinner som ersätter save knappen, fixa till översättningar 
      //setShowDataError(true)
      this.props.handleUpdatedStatusSaveSuccess(false,updated,res,res.Code)
      this.setState({saving:false})
    }
    
   } 
render(){
    return(
     <>
     {this.state.loading ? <Spinner/>:<StyledModal
     title={i18n.t('general.nullReportUpdate') as string}
     onDismiss={this.props.onDismiss}
     body={
        <Stack gap={3}>
            
            
            <div style={{position:'relative'}}>
            <div style={{float:'right',top:0,left:0}}>
            
            <Dropdown>
                  <Dropdown.Toggle bsPrefix='newStyleToggle'>
                    {this.state.status?.element}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>                  
                  {Object.entries(this.state.statt).map(([x,value]) => {
                        return <Dropdown.Item 
                        key={x} 
                        id={`${x}`} 
                        onClick={() => this.setState({status:this.state.statt[`${x}`]})}>
                            {this.state.statt[`${x}`].element }
                        </Dropdown.Item>
                      })}
                  </Dropdown.Menu>
                  </Dropdown>
                  </div>
                  <Stack gap={1}>
                  {this.buildLabels()}
                  </Stack>
                  </div>
        </Stack>
     }
     footer={<>
     
     
     <Button 
         disabled={this.props.selectedReport.status === this.state.status?.key}
         onClick={this.handleReportUpdatedStatusSave}
         className='button-primary'>       
          {this.state.saving ? <Spinner/> : i18n.t('general.save')}
        </Button>
        <Button onClick={() => this.props.onDismiss()} className='button-cancel'>
          {
            <>{i18n.t("general.close")}</>
          }
        </Button>
     
     </>}
     />}
     </>
    )

}

}
export default withTranslation()(NullReportHandleStatusModal)