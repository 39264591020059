import * as React from "react";
import { StyledModal } from "./StyledModal";
import { DataService } from "../../services/DataService";
import { Button, Form, Spinner, Stack } from "react-bootstrap";
import { Code, IsUserSinfra } from "../../lib/utils";
import { faInfoCircle, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useCurrentDepartmentHook } from "../SupplierBar/SupplierBar";

export interface NullReportModalProps {
  onDismiss(): void;
  disabled: boolean;
  onSubmitted(msg: string): void;
}

export function NullReportModal(props: NullReportModalProps) {
  const today = new Date();
  const [period, setPeriod] = React.useState(
    new Date(today.getFullYear(), today.getMonth(), 1).toISOString().slice(0, 7)
  );
  const [isSaving, setSaving] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [missingReports, setMissingReports] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [isPeriodLoading, setIsPeriodLoading] = React.useState(true);
  const [isPeriodReported, setIsPeriodReported] = React.useState(false);
  const {department,reportedPeriods} =  useCurrentDepartmentHook();
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (department) {
        await fetchDepartmentMissedPeriods();
      } else {
        await fetchMissingReports();
      }
      setLoading(false);
    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department]);

  React.useEffect(() => {
    checkPeriodReported();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, department, reportedPeriods]);

  const checkPeriodReported = () => {
    setIsPeriodLoading(true)
    if (department) {
      const isReported = !reportedPeriods[period]?.kickbackReport || !reportedPeriods[period]?.statisticReport;
      
      setIsPeriodReported(isReported);
      setIsPeriodLoading(false);
    } else {
      DataService.NullReports.IsPeriodReported(period).then(isReported => {
        setIsPeriodReported(isReported);
        setIsPeriodLoading(false);
      });
    }
  };

  const fetchMissingReports = async () => {
    const missingReports = await DataService.Invoices.GetMissedPeriods();
    
    setMissingReports(missingReports);
    if (missingReports.length > 0) setPeriod(missingReports[0]);
  };

  const fetchDepartmentMissedPeriods = () => {
    const periodKeys = Object.entries(reportedPeriods).filter(([key, value]) => value.statisticReport).map(([key]) => key);
    setMissingReports(periodKeys);
   
    if (periodKeys.length > 0) setPeriod(periodKeys[0]);
  };

  const onDismiss = () => {
    if (isSaving) return;
    props.onDismiss();
  };

  const onClickSubmit = async () => {
    setErrorMessage("");
    setSaving(true);
    const result = await DataService.NullReports.Post({ period: period ,departmentId:department?.id});
    if (result.Code == Code.OK) {
      props.onSubmitted(result.Message);
      return;
    }
    setErrorMessage(result.Message);
    setSaving(false);
  };

  function opt(period : string) {
    const date = new Date(period);
        const text = `${date.toLocaleString("default", { month: "long" })} ${date
          .getFullYear()
          .toString()}`;
        return <option
        key={period}
        style={{ textTransform: "capitalize" }}
        value={period}
        onClick={() => {
          setPeriod(period);
        }}
      >
        {text}
      </option> 
  }

  function generateMonthOptions() {
    if(missingReports.length > 0){
      return missingReports.map(key => {
       return opt(key);
      })
    }
    const key = new Date(today.getFullYear(), today.getMonth(), 1)
        .toISOString()
        .slice(0, 7);
    const key2 = new Date(today.getFullYear(), today.getMonth()+1, 1)
      .toISOString()
      .slice(0, 7);
    return [opt(key), opt(key2)];
  }
 const generateTest = () => {
    return Object.keys(reportedPeriods).map(key => {return opt(key)})
 }
 
  return (
    <StyledModal
      onDismiss={onDismiss}
      title={t("nullReport.nullReport").toString()}
      body={
        <Stack className="submit-modal-body" gap={3}>
          <Form.Group>
            <Form.Label>{t("general.period")}:</Form.Label>
            {loading ? (
              <div>
                <Spinner />
              </div>
            ) : (
              <Form.Select
                style={{ textTransform: "capitalize" }}
                value={period}
                onChange={(ev) => {
                  setPeriod(ev.target.value);
                }}
              >
                {typeof department == 'undefined' ?  generateMonthOptions() : generateTest()}
              </Form.Select>
            )}
          </Form.Group>
          {errorMessage && (
            <div className="error-message">
              <span>{t("general.errorMsg")}:</span>
              {errorMessage}
            </div>
          )}
          {
            isPeriodReported && !IsUserSinfra() &&
            <div className="info-message">
              <FontAwesomeIcon icon={faInfoCircle} />
              {t("nullReport.alreadyReported")}
            </div>
          }
        </Stack>
      }
      footer={
        <Stack direction="horizontal" gap={2}>
          <Button
            disabled={props.disabled || isSaving || IsUserSinfra() || loading || isPeriodLoading || isPeriodReported}
            className="button button-submit"
            onClick={onClickSubmit}
          >
            {isSaving ? (
              <Spinner animation="border" size={"sm"} />
            ) : (
              <>
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  style={{ marginRight: 4 }}
                />
                {t("general.send")}
              </>
            )}
          </Button>
          <Button
            disabled={isSaving}
            className="button button-cancel"
            onClick={onDismiss}
          >
            {t("general.close")}
          </Button>
        </Stack>
      }
    />
  );
}
