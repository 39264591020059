/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import ReactToPrint from "react-to-print";
import PdfFormatter from "./PdfFormatter";
import { Button } from "react-bootstrap";
import {
  withTranslation
} from "react-i18next";
import i18n from "../../i18n";


interface Props {
  data: any;
  options: any;
  format: string;
  formattedDataForbarChart:any[];
  rows:any[];
}

export const Print: React.FunctionComponent<Props> = ({
  data,
  options,
  format,
  formattedDataForbarChart,
  rows
}) => {
  let pdfFormatter: PdfFormatter | null = null;
  
  const reactToPrintContent = () => {
    return pdfFormatter;
  };

  const setComponentRef = (ref: PdfFormatter) => {
    pdfFormatter = ref;
  };
  
  const reactTrigger = () => {
    return (
      <Button className='primary'>{i18n.t("print.exportButton")}</Button>      
    );
  };
  
  return (
    <div>
      <ReactToPrint
        content={reactToPrintContent}
        trigger={reactTrigger}
      />
      <div style={{ display: "none" }}>
        <PdfFormatter ref={setComponentRef} data={data} options={options} formattedDataForbarChart={formattedDataForbarChart} rows={rows} />
      </div>
    </div>
  );
};
export default withTranslation() (Print);
