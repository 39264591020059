import * as React from "react";
import { DataService } from "../../services/DataService";
import { IsUserSinfra } from "../../lib/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useCurrentDepartmentHook } from "../SupplierBar/SupplierBar";

export interface IReportNoticeProps {
  hideKickbackMsg?: boolean;
  hideInvoiceMsg?: boolean;
  withLinks?: boolean;
  reportType?:number;
  periods?:string[] | string;
}

/*export default*/ function ReportNotice(props : IReportNoticeProps) {
  const [kickbackText, setKickbackText] = React.useState("");
  const [invoiceText, setInvoiceText] = React.useState("");
  const { t } = useTranslation(); 
  const {currentDepartment,getMissedPeriodsAsString} = useCurrentDepartmentHook();
  
  React.useEffect(() => {
   async function start(){
     if(currentDepartment()){
      await checkDepartmentPeriods()
     }
     else{
       await checkRegularPeriods();
     }
   } 
   start();
  }, [currentDepartment]);
  const checkDepartmentPeriods = async () => {
  
    if (IsUserSinfra()) return;
   const {kickbackText,statisticText} = await getMissedPeriodsAsString();
    if(kickbackText.length > 0){
      setKickbackText(
        t("reportNotice.kickback.description").replace("{months}",kickbackText))
    }
    if(statisticText.length > 0){
      setInvoiceText(t("reportNotice.invoice.description").replace("{months}",statisticText))
    }
  }

  const checkRegularPeriods = async () => {
    if (IsUserSinfra()) return;
    const [missedKickbackReports, missedInvoiceReport] = await Promise.all([
      DataService.Kickback.GetMissedPeriods(),
      DataService.Invoices.GetMissedPeriods(),
    ]);
        if (missedKickbackReports.length > 0) {
        const months = missedKickbackReports
          .map((p) => new Date(p).toLocaleDateString("sv-se", { month: "long" }))
          .join(",");
        setKickbackText(
          t("reportNotice.kickback.description").replace("{months}", months)
        );
      }
      if (missedInvoiceReport.length > 0) {
        const months = missedInvoiceReport
          .map((p) => new Date(p).toLocaleDateString("sv-se", { month: "long" }))
          .join(",");
        setInvoiceText(
          t("reportNotice.invoice.description").replace("{months}", months)
        );
      }    
  };


  const renderNotice = (text: string, url: string) => {
    return (
      <div className="notice">
        <FontAwesomeIcon icon={faWarning} />
        <div
          className={props.withLinks ? "notice-clickable" : ""}
          onClick={() => {
            if (props.withLinks) window.location.href = url;
          }}
        >
          {text}
        </div>
      </div>
    );
  };

  return (
    <>    
      {(kickbackText.length > 0 && !props.hideKickbackMsg || invoiceText.length > 0 && !props.hideInvoiceMsg) && (
        <div className="report-notice">
          {kickbackText.length > 0 &&
            !props.hideKickbackMsg &&
            renderNotice(kickbackText, "/report")}
          {invoiceText.length > 0 &&
            !props.hideInvoiceMsg &&
            renderNotice(invoiceText, "/report")}
        </div>
      )}
    </>
  );
}
export default React.memo(ReportNotice)
