import * as React from 'react';
import { Form, FormControlProps } from 'react-bootstrap';

interface IDatePickerProps extends FormControlProps {
  inputRef?(ref:any):void;
}

const DatePicker_bst: React.FunctionComponent<IDatePickerProps> = (props) => {
  var formattedValue = React.useMemo(() => {
    const substringEnd = props.type == "month" ? 7 : 10;
    const date = new Date(props.value?.toString() ?? "");
    if(date.toString() == "Invalid Date")
      return undefined;
    const val = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
    .toISOString()
    .substring(0,substringEnd);
    return val;
  }, [props.value]);
 
  return <Form.Control 
    aria-required
    onKeyDown={(ev) => false}
    //@ts-ignore
    ref={(ref) => { 
      if(props.inputRef && ref) 
        props.inputRef(ref); 
    }}
    // onInput={(ev) => false}
    {...props}
    value={formattedValue}
  />
};

export default DatePicker_bst;
