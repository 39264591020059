import * as React from "react";
import { Button, Col, Row, Stack } from "react-bootstrap";
import { WithTranslation, withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fa0 } from "@fortawesome/free-solid-svg-icons";
import { NullReportModal } from "../modal/NullReportModal";
import { StyledModal } from "../modal/StyledModal";
import { Infobar } from "../Infobar/Infobar";
import { DataService } from "../../services/DataService";
import StyledAccordion from "../Accordion/StyledAccordion";

export interface INullReportProps extends WithTranslation {}

export interface INullReportState {
  showNullReportModal: boolean;
  showSuccessModal: boolean;
  successMsg: string;
  canReport: boolean;
  isLoading: boolean;
}

class NullReport extends React.Component<
  INullReportProps,
  INullReportState
> {
  constructor(props: INullReportProps) {
    super(props);

    this.state = {
      showNullReportModal: false,
      showSuccessModal: false,
      successMsg: "",
      canReport: false,
      isLoading: true,
    };
  }

  async componentDidMount(): Promise<void> {
    const canReport =
      await DataService.InvoiceReports.CanActiveSupplierReport();
    this.setState({
      canReport,
      isLoading: false,
    });
  }
  
  private getInstructionTexts = () => {
    let texts = [];
    const key = "nullReport.instructionText";
    let i = 1;
    let potentialText = this.props.t(key+i);
    while(potentialText != key+i){
      texts.push(potentialText);
      i++;
      potentialText = this.props.t(key+i);
    }
    return texts;
  }

  public render() {
    const instructionTexts = this.getInstructionTexts();
    return (
      <Stack gap={1} className="section zero">
        <div>
          <h3>{this.props.t("nullReport.title")}</h3>
        </div>
        {
          instructionTexts.length > 0 &&   
            <ul>
              {
                instructionTexts.map(text => <li>{text}</li>)
              }
            </ul>
        }
        {!this.state.canReport && !this.state.isLoading && (
          <Infobar text={this.props.t("nullReport.inactiveMsg")} />
        )}
        {this.state.canReport && (
          <Row>
            <Col xs={12} sm={5} md={3} style={{ marginLeft: "auto" }}>
              <Button
                className="btn"
                style={{ width: "100%" }}
                onClick={() => {
                  this.setState({ showNullReportModal: true });
                }}
              >
                <>
                  <FontAwesomeIcon icon={fa0} style={{ marginRight: 8 }} />
                  {this.props.t("nullReport.nullReport")}
                </>
              </Button>
            </Col>
          </Row>
        )}

        {this.state.showNullReportModal && (
          <NullReportModal
            onDismiss={() => {
              this.setState({ showNullReportModal: false });
            }}
            disabled={false}
            onSubmitted={(msg: string) => {
              this.setState({
                showNullReportModal: false,
                showSuccessModal: true,
                successMsg: msg,
              });
              window.location.reload();
            }}
          />
        )}

        {this.state.showSuccessModal && (
          <StyledModal
            onDismiss={() => {
              this.setState({ showSuccessModal: false });
            }}
            title={this.props.t("invoiceReport.successModalTitle") as string}
            body={
              <Stack gap={3} style={{ alignItems: "center" }}>
                <div style={{ fontSize: 18, fontWeight: 500 }}>
                  {this.state.successMsg}
                </div>
                <Button
                  style={{ width: 100 }}
                  className="button button-cancel"
                  onClick={() => {
                    this.setState({ showSuccessModal: false });
                  }}
                >
                  {this.props.t("general.close")}
                </Button>
              </Stack>
            }
          />
        )}
      </Stack>
    );
  }
}

export default withTranslation()(NullReport);
