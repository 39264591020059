import { faExclamation, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Spinner } from 'react-bootstrap';
import { ReadFriendlyNumber } from '../../lib/utils';
import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual';


export interface ListColumn<TItem> {
  key: string;
  fieldName: string;
  name: string;
  minWidth: number | string;
  maxWidth?: number | string;
  onRender?(item: TItem, col: ListColumn<TItem>, index:number):JSX.Element|string;
  isSortedDescending?:boolean;
  isSorted?:boolean;
  isActive: boolean;
  footer?: ((items: TItem[]) => string|JSX.Element) | FooterOptions;
  sorting?: boolean;
  pinned?:boolean;
  export?(item: TItem):string|number;
  customCellPadding?:string;
  disabled?:boolean;
  sort?(items: TItem[], isSortedDescending?: boolean):TItem[];
 
}

interface Props<TItem> {
  items: TItem[];
  columns: ListColumn<TItem>[];
  onClick?(item:TItem, index:number):void;
  setColumns(cols:ListColumn<TItem>[]):void;
  initialSortKey?:string;
  descendingSort?:boolean;
  isLoading?:boolean;
  noItemsText:string;
  uniqueKey:string;
  disableHover?:boolean;
}

interface State {
  activeSort: {
    fieldName?:string;
    descending: boolean
  }
}

export enum FooterOptions {
	NONE,
	SUM,
	COUNT
}

export default class Datalist<TItem> extends React.Component<Props<TItem>, State> {
  constructor(props: Props<TItem>) {
    super(props);
    this.state = {
      activeSort: {
        descending: this.props.descendingSort ?? true,
        fieldName: ""
      }
    };
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement> | null, column: ListColumn<TItem>) : void => {
    const newColumns: ListColumn<TItem>[] = this.props.columns.slice();
    const currColumn: ListColumn<TItem> = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: ListColumn<TItem>) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const {activeSort} = this.state
    this.setState({activeSort:{ descending: !activeSort.descending, fieldName: column.fieldName}});
    this.props.setColumns(newColumns);
  };

  private mapToSortableValue = (val:any) => {
    if(val instanceof Date){
      return (val as unknown as Date).toISOString() ?? ""
    }
    else if(typeof val == "number"){
      return val;
    }
    else if(typeof val == 'boolean'){
      return Number(val);
    }
    return val?.toString().toLowerCase() ?? ""
  }

  private _copyAndSort = (items: TItem[], columnKey?: string, isSortedDescending?: boolean): TItem[]  => {
    if(!columnKey)
      return items;
    const key = columnKey as keyof TItem;
    const column = this.props.columns.find(c => c.key == columnKey);
    if(column?.sort)
      return column.sort(items.slice(0), isSortedDescending);
    return items.slice(0).sort((a: TItem, b: TItem) => {
      const valueA = this.mapToSortableValue(a[key]);
      const valueB = this.mapToSortableValue(b[key]);
      return (isSortedDescending ? valueA < valueB : valueA > valueB) ? 1 : -1;
    });
  }

  componentDidMount():void {
    const colToSort = this.props.columns.find(col => col.key === this.props.initialSortKey);
    if(colToSort){
      this._onColumnClick(null, colToSort);
    }
  }

  private RoundTo = (value:number, precision: number) : number => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  private renderSum = (items:TItem[], col:ListColumn<TItem>) => {
    const sum = items.reduce((prev, curr:any) => {
      const value = curr[col.fieldName] ?? 0;
      return prev+parseFloat(value);
    }, 0);
    return "tot. "+ReadFriendlyNumber(this.RoundTo(sum, 2));
  }

  private getTrClasses = () => {
    return this.props.disableHover ? "datalistTableRow" : "datalistTableRow datalistTableRowHover"
  }

  public render(): React.ReactElement<Props<TItem>> {
    return (
      <>
      {
        <DatalistRenderer 
          items={this.props.items} 
          columns={this.props.columns} 
          setColumns={this.props.setColumns} 
          noItemsText={this.props.noItemsText} 
          uniqueKey={this.props.uniqueKey} 
          activeSort={this.state.activeSort} 
          _onColumnClick={this._onColumnClick} 
          descendingSort={this.props.descendingSort}
          disableHover={this.props.disableHover}
          isLoading={this.props.isLoading}
          onClick={this.props.onClick}
          _copyAndSort={this._copyAndSort}
          renderSum={this.renderSum}
          getTrClasses={this.getTrClasses}
        />
      }
      </>
    );
  }
}


interface IDatalistRendererProps<TItem> {
  items: TItem[];
  columns: ListColumn<TItem>[];
  onClick?(item:TItem, index:number):void;
  setColumns(cols:ListColumn<TItem>[]):void;
  initialSortKey?:string;
  descendingSort?:boolean;
  isLoading?:boolean;
  noItemsText:string;
  uniqueKey:string;
  disableHover?:boolean;
  activeSort: {
    fieldName?:string;
    descending: boolean
  }
  _onColumnClick(ev: React.MouseEvent<HTMLElement> | null, column: ListColumn<TItem>) : void;
  _copyAndSort(items: TItem[], columnKey?: string, isSortedDescending?: boolean): TItem[];
  renderSum(items:TItem[], col:ListColumn<TItem>):string;
  getTrClasses():string;
}

function DatalistRenderer<TItem>(props : IDatalistRendererProps<TItem>)  {
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: props.items.length,
    getScrollElement : () => tableContainerRef.current,
    estimateSize: () => 30,
    overscan: 2,
  });
  const virtualRows = rowVirtualizer.getVirtualItems();

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? rowVirtualizer.getTotalSize() - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;


  const columns = props.columns
      .map(col => {
        return {
          ...col,
          onColumnClick: col.sorting === true ? (ev:any, column:ListColumn<TItem>) => { props._onColumnClick(ev, column)} : undefined
        }
      })
      .filter(col => col.isActive === true);

  const items = React.useMemo(() => {
    return props._copyAndSort(props.items, props.activeSort.fieldName, props.activeSort.descending) as any
  }, [props.activeSort, props.items])
  
  return <div className={"datalist"} ref={tableContainerRef}>
  <table className={"datalistTable"}>
    <thead className={"datalistTableHead"}>
      <tr>
        {
          columns.map(col => {
            let classes = "datalistTableHeader";
            if(col.onColumnClick) 
              classes += ` ${"datalistTableHeaderSortable"}`
            return <th key={`th-${col.key}`} style={{minWidth: col.minWidth, maxWidth: col.maxWidth, width: col.maxWidth}} onClick={() => { if(col.onColumnClick) col.onColumnClick(null, col) }} className={classes}>
              {col.name}
              {
                col.onColumnClick && props.activeSort.fieldName != col.fieldName && col.sorting == true &&
                <FontAwesomeIcon icon={faSort} style={{marginLeft : "6px"}}/>
                // <Icon styles={{root:{marginLeft: "6px"}}} iconName="Sort"/>
              }
              {
                props.activeSort.fieldName == col.fieldName && col.sorting == true &&
                <FontAwesomeIcon icon={col.isSortedDescending ? faSortUp : faSortDown} style={{marginLeft : "6px", fontWeight: "bold"}} />
                // <Icon styles={{root:{fontWeight: "bold", marginLeft: "6px"}}} iconName={col.isSortedDescending ? "SortUp" : "SortDown"}/>
              }
              </th>
          })
        }
      </tr>
    </thead>
    <tbody>
    {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
      {
        props.isLoading ?
        <td className={"dataListEmptyTable"} colSpan={columns.length}>
          {/* <LoadingSpinner size={32}/> */}
          <Spinner />
        </td>
        
        :
        <>
        {
          items.length < 1 &&
          <td key={"datalist-empty-cell"} className={"dataListEmptyTable"} colSpan={columns.length}>
            <FontAwesomeIcon style={{marginRight: "4px"}} icon={faExclamation}/> {props.noItemsText}
          </td>
        }
        {
          virtualRows.map((virtualRow:any, i: number) => {
            return <tr key={`tr-${items[virtualRow.index][props.uniqueKey]}-${i}`} style={{cursor: props.onClick ? "pointer" : "unset"}} className={props.getTrClasses()} onClick={(ev) => { if(props.onClick) props.onClick(items[virtualRow.index], i) }}>
              {
                columns.map(col => {
                  return <td key={`td-${items[virtualRow.index].id}-${col.key}`} aria-disabled={col.disabled} style={{maxWidth: col.maxWidth, padding: col.customCellPadding ? col.customCellPadding : undefined}} className={"datalistTableCell"}>
                    
                    {
                    col.onRender ? col.onRender(items[virtualRow.index], col, i) : items[virtualRow.index][col.fieldName]
                    }
                    </td>
                    
                })
              }
            </tr>
          })
        }
        <tr></tr>
        </>
      }
      {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
    </tbody>
    {
      columns.some(c => c.footer != undefined) &&
      <tfoot className={"datalistTableFoot"}>
        <tr>
          {
            columns.map(col => {
              if(typeof col.footer == 'function'){
                return <th key={"datalist-footer-"+col.key}>
                  {col.footer(props.items)}
                </th>
              }
              else if(col.footer == FooterOptions.SUM){
                return <th key={"datalist-footer-"+col.key}>
                  {
                    props.renderSum(items, col)
                  }
                </th>  
              }
              else if(col.footer == FooterOptions.COUNT){
                return <th key={"datalist-footer-"+col.key}>
                  {
                    items.length
                  }
                </th>
              }
              return <th key={"datalist-footer-"+col.key}></th>
              
            })
          }
        </tr>
      </tfoot>
    }
  </table>
</div>;
};

