import * as React from 'react';
import { StyledModal } from './StyledModal';
import { DataService } from '../../services/DataService';
import { Button, Form, Spinner, Stack } from 'react-bootstrap';
import { IsReportingDivided, Code, IsUserSinfra, ReadFriendlyNumber, StringToNumber } from '../../lib/utils';
import { faInfoCircle, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Contract } from '../../services/API/Contracts';
import { useState } from 'react';
import { TKickback } from '../../services/API/Kickback';
import { useCurrentDepartmentHook } from '../SupplierBar/SupplierBar';
import { Department } from '../../services/API/Departments';

export interface SubmitKickbackReportModalProps {
  kickbacks: TKickback[];
  onDismiss():void;
  disabled:boolean;
  onSubmitted():void;
  period:string;
}

export function SubmitKickbackReportModal (props: SubmitKickbackReportModalProps) {
  const [isSaving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  //const [comment, setComment] = useState("");
  const { t, i18n } = useTranslation();
  const {currentDepartment} =  useCurrentDepartmentHook();
 const departMent:Department | undefined = currentDepartment()

  const onDismiss = () => {
    if(isSaving)
      return;
    props.onDismiss();
  }

  const onClickSubmit = async () => {
    setErrorMessage("");
    setSaving(true);
    const result = await DataService.Kickback.SubmitReport(props.period/*, comment,*/, props.kickbacks,departMent?.id);
    if(result.Code == Code.OK){
      props.onSubmitted();
      return;
    }
    setErrorMessage(result.Message);
    setSaving(false);
  }

  const { totalSum, totalKickback } = props.kickbacks.reduce((prev, curr) => {
    const val = StringToNumber(curr.sumExclMomsChangesProps.value);
    const kickbackNumber = curr.kickbackProcent ?? 0;
    const calculatedKickback = val * (kickbackNumber/100);
    return {
      totalKickback: prev.totalKickback + calculatedKickback,
      totalSum: prev.totalSum + val
    };
  }, { totalKickback: 0.0, totalSum: 0.0});
console.log(departMent)
  return (
    <StyledModal 
      onDismiss={onDismiss}
      title={t('kickback.previewModal.title').toString()}
      body={
        <Stack className='submit-modal-body' gap={3}>
          <table>
          <tr>
              <td>{t('kickback.list.period')}:</td>
              <td>{props.period}</td>
            </tr>
            <tr>
              <td>{t('kickback.previewModal.noOfRows')}:</td>
              <td>{props.kickbacks.length}</td>
            </tr>
            <tr>
              <td>{t('kickback.previewModal.totalSum')}:</td>
              <td>{ReadFriendlyNumber(totalSum) + " Kr"}</td>
            </tr>
            <tr>
              <td>{t('kickback.previewModal.totalKickback')}:</td>
              <td>{ReadFriendlyNumber(totalKickback) + " Kr"}</td>
            </tr>
          </table>
          {/* <Form.Group>
            <Form.Label>{t("general.comment")}:</Form.Label>
            <Form.Control 
              as="textarea" 
              value={comment}
              onChange={(ev) => setComment(ev.target.value)}
            />
          </Form.Group> */}
          {
            errorMessage &&
            <div className='error-message'>
              <span>{t('general.errorMsg')}:</span>
              {errorMessage}
            </div>
          }
          {/* {
            totalKickback == 0 &&  totalSum == 0 && !IsUserSinfra() && 
            <div className="info-message">
              <FontAwesomeIcon icon={faInfoCircle} />
              {t("kickback.zeroKickback")}
            </div>
          } */}
        </Stack>
      }
      footer={
      <Stack direction="horizontal" gap={2}>
        <Button disabled={props.disabled || isSaving || props.kickbacks.length == 0 || IsUserSinfra()} className="button button-submit" onClick={onClickSubmit}>
          {
            isSaving ?
            <Spinner animation="border" size={'sm'}/>
            :
            <><FontAwesomeIcon icon={faPaperPlane} style={{marginRight: 4}}/>{t('general.send')}</>
          }
        </Button>
        <Button disabled={isSaving} className="button button-cancel" onClick={onDismiss}>{t('general.close')}</Button>
      </Stack>}
    />
  );
}
