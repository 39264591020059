import * as React from 'react';
import { Spinner, Stack } from 'react-bootstrap';
import ReportNotice from '../components/ReportNotice/ReportNotice';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import InvoiceReport from '../components/ReportPage/InvoiceReport';
import KickbackReport from '../components/ReportPage/KickbackReport';
import { DataService } from '../services/DataService';
import NullReport from '../components/ReportPage/NullReport';
import { CheckReportPermission } from '../lib/utils';

export interface IReportPageProps extends WithTranslation {
}

export interface IReportPageState {
  isLoading: boolean;
  invoiceReportInProgress: boolean;
  hideReportNotice: boolean;
}

class ReportPage extends React.Component<IReportPageProps, IReportPageState> {
  constructor(props: IReportPageProps) {
    super(props);

    this.state = {
      isLoading: true,
      invoiceReportInProgress: true,
      hideReportNotice: false
    }
  }

  async componentDidMount(){
    await CheckReportPermission(window.location.href)
    const invoiceReportInProgress = await DataService.InvoiceReports.IsInvoiceImportInProgress();
    this.setState({
      isLoading: false,
      invoiceReportInProgress: invoiceReportInProgress
    }, () => {
      if(invoiceReportInProgress)
        this.pollInvoiceStatus();
    });
    
  }

  componentWillUnmount(): void {
    if(this.pollIntervalId){
      clearInterval(this.pollIntervalId);
      this.pollIntervalId = undefined;
    }
  }

  private pollIntervalId : any = undefined;
  private pollInvoiceStatus = () => {
    if(this.pollIntervalId == undefined){
      this.pollIntervalId = setInterval(async () => {
        const inprogress = await DataService.InvoiceReports.IsInvoiceImportInProgress();
        if(inprogress != this.state.invoiceReportInProgress){
          this.setState({ invoiceReportInProgress: inprogress, hideReportNotice: false });
          clearInterval(this.pollIntervalId);
          this.pollIntervalId = undefined;
        }
      }, 10000);
    }
  }

  private onSubmittedInvoiceReport = async () => {
    const invoiceReportInProgress = await DataService.InvoiceReports.IsInvoiceImportInProgress();
    this.setState({
      invoiceReportInProgress: invoiceReportInProgress,
      hideReportNotice: true
    }, () => {
      this.pollInvoiceStatus();
    });
  }

  public render() {
    return (<Stack gap={4} className="report-page">
      {
        this.state.hideReportNotice == false &&
        <ReportNotice />
      }
        <div>
          <h2>{this.props.t('reportPage.title')}</h2>
          <Trans i18nKey={'reportPage.description'}/>
        </div>
        {
          this.state.isLoading &&
          <Spinner />
        }
        <NullReport />
        <InvoiceReport 
          onSubmittedReport={this.onSubmittedInvoiceReport}
        />
        <KickbackReport 
          invoiceReportInProgress={this.state.invoiceReportInProgress}
        />
      </Stack>
    );
  }
}

export default withTranslation()(ReportPage);