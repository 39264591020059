// export const longLogoSrc = '/logo-sinfra-2.png';
export const longLogoSrc = "/logo-sinfra-2-dark.png";
export const longLightLogoSrc = '/logo-sinfra-2-light.png';

export const userColorArray = [
    { bg: "#2E0014", txt: "#FFFFFF" },
    { bg: "#A5907E", txt: "#FFFFFF" },
    { bg: "#C5D86D", txt: "#000000" },
    { bg: "#FFC43D", txt: "#000000" },
    { bg: "#FAA916", txt: "#000000" },
    { bg: "#1A8FE3", txt: "#FFFFFF" },
    { bg: "#ff7096", txt: "#FFFFFF" },
    { bg: "#EE6C4D", txt: "#FFFFFF" },
    { bg: "#BA181B", txt: "#FFFFFF" },
    { bg: "#602706", txt: "#FFFFFF" },
    { bg: "#593959", txt: "#FFFFFF" },
    { bg: "#84A65C", txt: "#000000" },
    { bg: "#33658A", txt: "#FFFFFF" },
    { bg: "#ff0a54", txt: "#FFFFFF" },
    { bg: "#ff477e", txt: "#FFFFFF" },
    { bg: "#FB6107", txt: "#FFFFFF" },
    { bg: "#D16666", txt: "#FFFFFF" },
    { bg: "#DAD2D8", txt: "#000000" },
    { bg: "#0AD3FF", txt: "#FFFFFF" },
    { bg: "#F16055", txt: "#FFFFFF" },
    { bg: "#ED2A1D", txt: "#FFFFFF" },
    { bg: "#0088FE", txt: "#FFFFFF" },
    { bg: "#CE6D8B", txt: "#FFFFFF" },
    { bg: "#C2476E", txt: "#FFFFFF" },
    { bg: "#69735f", txt: "#FFFFFF" },
    { bg: "#6610F2", txt: "#FFFFFF" },
    { bg: "#A5907E", txt: "#FFFFFF" },
    { bg: "#F16055", txt: "#FFFFFF" },
    { bg: "#0088FE", txt: "#FFFFFF" },
    { bg: "#7D5BA6", txt: "#FFFFFF" }];

export const dateFormats = [
    "yyyy-MM-dd",
    "dd-MM-yyyy",
    "dd/MM/yyyy",
    "dd.MM.yyyy",
    "yyyy.MM.dd",
    "yyyy MM dd",
    "d/M/yyyy",
    "d.M.yyyy"
];
