import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DataService } from '../services/DataService';
import Datalist, { ListColumn } from '../components/Datalist/Datalist';
import { Stack } from 'react-bootstrap';
import { SearchBar } from '../components/SearchBar/SearchBar';
import { TGroup } from '../services/API/Groups';
import { TSupplier } from '../services/API/Suppliers';
import { IsUserSinfra } from '../lib/utils';
import StyledCheckbox from '../components/StyledCheckbox/StyledCheckbox';

interface Props extends WithTranslation {

}

interface State {
  isLoading: boolean;
  suppliers: TSupplier[];
  activeSuppliers: TSupplier[];
  columns: ListColumn<TSupplier>[];
  searchText:string;
  onlyShowActive:boolean;
}

class SupplierManagementPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    
    this.state = {
      isLoading: true,
      suppliers:[],
      activeSuppliers: [],
      columns: this.columns,
      searchText: "",
      onlyShowActive: true
    }

  }

  async componentDidMount(): Promise<void> {
    
    if(!IsUserSinfra())
      window.location.href = "/"
    const [activeSuppliers, suppliers] = await Promise.all([
      DataService.Suppliers.Get(true),
      DataService.Suppliers.Get()
    ]);
    this.setState({
      isLoading: false,
      suppliers: suppliers,
      activeSuppliers
    });
  }

  private columns : ListColumn<TSupplier>[] = [
    {
      name: this.props.t("general.supplierName"),
      fieldName: "supplierName",
      key: "supplierName",
      minWidth: 100,
      isActive: true,
      sorting: true
    },
    {
      name: this.props.t("general.supplierOrgId"),
      fieldName: "supplierOrgNr",
      key: "supplierOrgNr",
      minWidth: 100,
      isActive: true,
      sorting: true
    }
  ]

  render() {
    const currentList = this.state.onlyShowActive ? this.state.activeSuppliers : this.state.suppliers;
    const suppliers = currentList.filter(supp => {
      const onSearch = this.state.searchText.length > 0 ?
        supp.supplierName.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase()) 
          || supp.supplierOrgNr.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase())
        : true;
      return onSearch;
    })
    return (
      <Stack gap={4}>
        <div>
          <h2>{this.props.t('supplierManagement.title')}</h2>
          {/* <p>{this.props.t('supplierManagement.description')}</p> */}
        </div>
        <div className='list-toolbar'>
          <SearchBar
            onChange={(newVal) => { this.setState({ searchText: newVal }) }}
            value={this.state.searchText}
            placeholder={this.props.t("supplierManagement.searchPlaceholder").toString()}
          />
          <StyledCheckbox 
            isChecked={this.state.onlyShowActive} 
            label={this.props.t('supplierManagement.onlyActive')} 
            onChange={(newVal: boolean) => {
              this.setState({ onlyShowActive: newVal });
            }} 
          />
        </div>
        <div style={{height: "50vh"}}>
          <Datalist 
            uniqueKey='id'
            items={suppliers} 
            columns={this.state.columns} 
            setColumns={(cols) => this.setState({columns: cols})} 
            noItemsText={this.props.t("supplierManagement.noItemsInTable")} 
            initialSortKey='supplierName'
            onClick={(supplier) => { window.location.href = `/editSupplier/${supplier.supplierOrgNr}` }}
            isLoading={this.state.isLoading}
          />
        </div>
      </Stack>
   );
  }
 
};

export default withTranslation()(SupplierManagementPage);