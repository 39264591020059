import * as React from 'react'
//import { InvoiceReport } from '../../services/API/InvoiceReports'
import Accordion from '../Accordion/StyledAccordion';
import i18n from '../../i18n';
import { ReadFriendlyNumber } from '../../lib/utils';
import { HandleActionModal, InternalInvoiceReport } from './editTypes';
import { Dropdown, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataService } from '../../services/DataService';
import { InvoiceReport } from '../../services/API/InvoiceReports';
import { Department } from '../../services/API/Departments';
import { DisplayHistory } from './DisplayHistory';
import { Customer } from '../../services/API/Customer';

interface InfoProps{
    data:InternalInvoiceReport/*InvoiceReport*/;
    handleChange:(e:React.ChangeEvent<HTMLTextAreaElement> | number,key:keyof Pick<InternalInvoiceReport,'departmentId' | 'comment'>,item?:InternalInvoiceReport) => void;
    handleActionModal:HandleActionModal;
    editHistory:any;
    customers:Customer[];
    departments?:Department[];
    department?:Department;
  }
const InfoBar = (props:InfoProps) => {
  
    const renderInfo = () => {
        const cellStyle : React.CSSProperties = {
          paddingLeft:8
        }   
         const statusObj = DataService.InvoiceReports.GetStatus(props.data as InvoiceReport);   
        return (
          <>
           <Stack style={{ justifyContent: "", flexWrap: "wrap" }}>
          {/* <div style={{maxHeight: 500}}className="submitModalInfoCardContainer"> <FontAwesomeIcon className="infoIcon" icon="info-circle" />
          
          <div
            
            className="submitModalInfoCardContentActive"
          >
             <p style={{paddingBottom:'0px',fontSize:'14px',marginLeft:10}} >
              <li className="infoLielement">Sök rätt på rader med hjälp av ett fakturanummer</li>
              </p>
            <p style={{paddingBottom:'0px',fontSize:'14px',marginLeft:10}}>
              <li className="infoLielement">Har du gjort ändringar som du ångar? Avaktivera editeringsläget</li>
              </p>
              <p style={{paddingBottom:'0px',fontSize:'14px',marginLeft:10}}>
              <li className="infoLielement">Har rapporten historiska editeringar på sig? Det går då att återställa rapporten till tidigare versioner</li>
              </p>
              <p style={{paddingBottom:'0px',fontSize:'14px',marginLeft:10}}>
              <li className="infoLielement">Glömt inte att skicka in ändringar som är gjorda</li>
              </p>
          </div>
          </div> */}
         {typeof props.editHistory !== 'undefined' && props.editHistory.length > 0 &&
           <DisplayHistory data={props.editHistory} customers={props.customers}/>
         }     
          <div style={{ fontSize: 18}}>{props.data?.supplierName+`(${props.data?.supplierOrgId})`}{` - `}<FontAwesomeIcon icon={statusObj.icon} color={statusObj.color} style={{marginRight:"5px"}}/>{statusObj.statusText}
          </div>
         {props.data?.reporter.includes('@') ? <a href={`mailto:${props.data?.reporter}`}>{props.data?.reporter}</a>:<div style={{ fontSize: 14, fontStyle: "italic", color: "#878686"}}>{props.data?.reporter}</div>} 
          <div style={{ fontSize: 14, fontStyle: "italic", color: "#878686"}}>{props.data?.reportDate.toLocaleDateString()}</div>
        </Stack>
        
          <Accordion open title={i18n.t("invoiceReportList.invoiceReportModal.info").toString()} titleStyle={{fontSize: "1.1rem", margin: 0}} style={{padding: "0px 10px 0px"}} contentStyle={{paddingLeft: 4}}>
          <table 
          >
            <tbody>
              <tr>
                <td>{i18n.t("invoiceReportList.invoiceReportModal.comment")} </td>
                <td style={cellStyle}><textarea  cols={30} style={{resize:'both'}} value={props.data.comment} onChange={(e) => 
                 props.handleChange(e,'comment',props.data)}/></td>
              </tr>
              <tr>
                <td>{i18n.t("invoiceReportList.invoiceReportModal.noOfInvoices")} </td>
                <td style={cellStyle}>{props.data.noOfInvoices}</td>
              </tr>
              <tr>
                <td>{i18n.t("invoiceReportList.invoiceReportModal.noOfInvoicesImported")} </td>
                <td style={cellStyle}>{props.data.noOfInvoicesImported}</td>
              </tr>
              <tr>
                <td>{i18n.t("invoiceReportList.invoiceReportModal.totalSumExclMoms")} </td>
                <td style={cellStyle}>{ReadFriendlyNumber(props.data.totalSumExclMoms ?? 0)}</td>
              </tr>
              <tr>
                <td>{i18n.t("invoiceReportList.invoiceReportModal.totalSumExclMomsImported")} </td>
                <td style={cellStyle}>{ReadFriendlyNumber(props.data.totalSumExclMomsImported ?? 0 )}</td>
              </tr>
              <tr>
                <td>{i18n.t("invoiceReportList.invoiceReportModal.noOfWarnings")} </td>
                <td style={cellStyle}>{props.data.noOfWarnings}</td>
              </tr>
             {typeof props.data?.departmentId !== 'undefined' &&
             props.data?.departmentId !== null &&
              <tr>
              {typeof props?.departments !== 'undefined' && 
              <>
              <td>
              {i18n.t('departmentList.singular')}
              <FontAwesomeIcon style={{marginLeft:'4px'}} icon='building'/>
            </td>
                  <td style={cellStyle}>
                <Dropdown>
                <Dropdown.Toggle bsPrefix='newStyleToggle'>
                  {props?.department?.department ?? 'Välj ny avdelning för rapporten'}
                </Dropdown.Toggle>
                <Dropdown.Menu>                  
                {props.departments?.map((x:Department) => {
                      return <Dropdown.Item key={x.id} id={`${x}`} onClick={() => {
                        if(x.id !== props.data?.departmentId){
                          props.handleActionModal('CHANGEDEPARTMENT',true,x.id)
                        }
                      }}>{x.department }</Dropdown.Item>
                    })}
                </Dropdown.Menu>
                </Dropdown>
                </td>
                </>
              }
              
            </tr>
             }
              
            </tbody>
          <p></p>
          </table>
          </Accordion>
          </>
        )
      }
      return(
        <>
        {renderInfo()}
        </>
      )  
}
export{
    InfoBar
}