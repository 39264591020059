/* eslint-disable react/jsx-pascal-case */
import * as React from 'react';
import Datalist, { ListColumn } from '../../Datalist/Datalist';
import { useTranslation } from 'react-i18next';
import { Button, Form, OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledModal } from '../../modal/StyledModal';
import { Department, DepartmentDateKeys, DepartmentFunctionArgs } from '../../../services/API/Departments';
import { DataService } from '../../../services/DataService';
import DatePicker_react from '../../DatePicker/DatePicker_react';
import StyledAccordion from '../../Accordion/StyledAccordion';


interface IDepartmentListProps {
  
  departments:Department[];
  onChange(dep:Department):void;
  onDelete(id:number):void;
}

const DepartmentList: React.FunctionComponent<IDepartmentListProps> = (props) => {
  const { t } = useTranslation();
  const [ selectedDepartment, setSelectedDepartment ] = React.useState<Department>(DataService.Departments.GetNewDepartment());
  const [ isModalOpen, setIsModalOpen ] = React.useState(false);
  const modalTitle = React.useMemo(() => {
    return selectedDepartment.id == 0 ? 
      t("departmentList.modal.addTitle")
      :
      t("departmentList.modal.updateTitle")
  }, [selectedDepartment]);
const  showValidationInfo = (props: any, key: keyof DepartmentDateKeys,show:boolean): JSX.Element => {
    const validationText = {
      startDate:'Välj ett startdatum för att aktivera rapportering för denna avdelning',
      endDate:'Detta datum kan lämnas tomt så länge som ett startdatum är satt så kommer rapporteringen att fungera'
    }[key];
    if(show){
      return <Tooltip id="button-tooltip" {...props}>
           <span>{validationText}</span>
         </Tooltip>
    }
    else{
      return <></>
    }
  };
const dateActive = (key: keyof DepartmentDateKeys) => {
  return selectedDepartment[key] == null
}  
const renderDateFields = () => {
 return <table style={{width:'100%',marginTop:'10px'}}>
        <thead>
          <tr >
            <td 
            aria-haspopup={'true'}
            tabIndex={0}
            style={{width:'48%'}}>
              Startdatum 
              {dateActive('startDate') && 
                <OverlayTrigger
                placement="auto"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => showValidationInfo(props, "startDate",dateActive('startDate'))}
              >    
                <FontAwesomeIcon style={{color:'#b30404',cursor:'pointer',marginLeft:4}} icon='circle-exclamation'/>
              </OverlayTrigger>
              }
           </td>
            <td style={{width:'48%'}}>Slutdatum</td>
          </tr>
        </thead>
        <tbody>
          <tr >
            <td  style={{padding:2}}><DatePicker_react value={selectedDepartment.startDate?.toLocaleDateString('sv-SE')?? ''} onChange={(e) =>  setSelectedDepartment({ ...selectedDepartment, startDate: e ,changed:true})}/></td>
            <td  style={{padding:2}}><DatePicker_react value={selectedDepartment.endDate?.toLocaleDateString('sv-SE')?? ''} onChange={(e) => setSelectedDepartment({ ...selectedDepartment, endDate: e ,changed:true})}/></td>
          </tr>
        </tbody>
 </table>
}
  return <div className='department-list'>
    <div className="field-label">
      {t("departmentList.title")}
    </div>
    <Datalist 
      items={props.departments} 
      columns={[
        {
          fieldName:"department", 
          isActive: true,
          key:"department", 
          minWidth: 100,
          name: t("departmentList.table.name")
        },
        {
          fieldName: "remove",
          isActive: true,
          key: "remove",
          minWidth: 30,
          customCellPadding: "0px",
          maxWidth: 30,
          name: "",
          onRender: (item) => 
            item.id < 0 ? <Button 
              className='button-danger tool-button'
              onClick={() => { props.onDelete(item.id) }}
            >
              <FontAwesomeIcon icon={"trash"}/>
            </Button> : <></>
            // <Button 
            //   className='button-danger tool-button'
            //   onClick={() => { props.onDelete(item.id) }}
            // ><FontAwesomeIcon icon={"trash"}/></Button>
        },
        {
          fieldName: "edit",
          isActive: true,
          key: "edit",
          minWidth: 30,
          maxWidth: 30,
          customCellPadding: "0px",
          name: "",
          onRender: (item) => <Button 
            className='tool-button'
            onClick={() => { 
              setSelectedDepartment(item);
              setIsModalOpen(true);
            }}
          >
            <FontAwesomeIcon  icon={"edit"}/>
          </Button>
        }
        
      
      ]}
      setColumns={function (cols: ListColumn<Department>[]): void {
        throw new Error("Function not implemented.");
      } } 
      noItemsText={t("departmentList.noDepartments")} 
      uniqueKey={"id"}
    />
    <Button 
      style={{float:"right"}}
      onClick={() => {
        setSelectedDepartment(DataService.Departments.GetNewDepartment());
        setIsModalOpen(true);
      }}
    >
        <FontAwesomeIcon icon={"add"} style={{marginRight: 4}}/>
        {t("departmentList.add")}
    </Button>
    {
      isModalOpen &&
      <StyledModal 
        title={modalTitle} 
        onDismiss={() => {setIsModalOpen(false);}}
        body={
          <Stack>
            <Form.Group>
              <Form.Label>{t("departmentList.modal.departmentname")}</Form.Label>
              <Form.Control 
                type='text' 
                value={selectedDepartment.department} 
                onChange={(ev) => { setSelectedDepartment({ ...selectedDepartment, department: ev.currentTarget.value ,changed:true}) }}
              />
             {renderDateFields()}
              
            </Form.Group>
          </Stack>
        }
        footer={
          <Stack direction='horizontal' style={{gap:10}}>
            <OverlayTrigger
              placement='left-start'
              delay={{ show: 250, hide: 200 }}
              overlay={(props) => showValidationInfo(props, "startDate",dateActive('startDate'))}
            >
              <div>
              <Button disabled={dateActive('startDate')} onClick={() => { props.onChange(selectedDepartment); setIsModalOpen(false);}}>
            
            Spara
            </Button>
              </div>
              </OverlayTrigger>
           
            <Button 
              className='button-cancel'
              onClick={() => {setIsModalOpen(false);}}
            >{t("general.cancel")}</Button>
          </Stack>
        }
      />
    }
  </div>
  ;
};

export function useDepartments(supp?:string){
  const [isLoading, setIsLoading] = React.useState(true);
  const [items, setItems] = React.useState<Department[]>([]);
  const [noOfNewItems, setNoOfNewItems] = React.useState(0);
  const [deletedDepartments,setDeletedDepartments] = React.useState<number[]>([])
  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
   
    const fetchedDepartments = await DataService.Departments.Get({supplierOrgId:supp}as DepartmentFunctionArgs);
    setItems(fetchedDepartments);
    setIsLoading(false);
  };

  const AddOrUpdate = (department: Department) => {
    setItems((_departments) => {
      const index = _departments.findIndex(dep => department.id == dep.id);
      if(index == -1){
        const incremented = noOfNewItems + 1;
        department.id = -incremented;
        department.supplierOrgId = supp
        setNoOfNewItems(incremented);
        return _departments.concat(department);
      }
      const newDepartments = [..._departments];
      newDepartments.splice(index, 1, department);
      return newDepartments;
    });
  }

  const RemoveById = async (id: number) => {
    //const idBuff:DepartmentFunctionArgs ={id}
    setDeletedDepartments([...deletedDepartments,id])
    setItems((_departments) => {
      return _departments.filter(dep => dep.id != id)
    })
  }

  return {
    isLoading,
    items,
    AddOrUpdate,
    RemoveById,
    deletedDepartments
  };
}

export default DepartmentList;
