import { useEffect } from 'react';

import { Stack } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import News from '../components/News/News';

interface Props {

}

function NewsListPage(props: Props) {

  useEffect(() => {
  }, []);


  return (
    <Stack gap={4} >
      {
        <News listMode={true}/>
      }
    </Stack>
  );
 
};

export default withTranslation()(NewsListPage);