import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export interface ISearchBarProps {
  placeholder?:string;
  value:string;
  onChange(newValue: string):void;
}

export function SearchBar (props: ISearchBarProps) {
  return (
    <div className='search-bar'>
      <div className='search-bar-icon'><FontAwesomeIcon icon={faSearch} /></div>
      <input 
        className='search-bar-input' 
        value={props.value} 
        onChange={(ev) => { props.onChange(ev.target.value) }}
        placeholder={props.placeholder}
      />
      {
        <div 
          onClick={() => { props.onChange(""); }}
          aria-expanded={props.value ? "true" : "false"} 
          className='search-bar-icon search-bar-icon-button'
        >
          <FontAwesomeIcon icon={faClose} /></div>
      }
      
    </div>
  );
}
