/* eslint-disable react/jsx-pascal-case */
import * as React from 'react'
import { ArticleKeys, AssortedFields, ProjectInvoice/*, TypeAheadKeys, TypeAheadStateKeys */} from '../../pages/KickbackProjectReportPage';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger } from 'react-bootstrap';
//import { Typeahead } from 'react-bootstrap-typeahead';
//import i18n from '../../i18n';
//import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import DatePicker_react from '../DatePicker/DatePicker_react';
import { _helpers } from '../../lib/ProjectReportValidation';
import { Placement } from 'react-bootstrap/esm/types';
//import { delayedAction } from '../../lib/utils';

// interface Props{
//   onChange:(uniqueRowId:number,e:any,key:keyof TypeAheadKeys) => void;
//   onBlur:(e:any,uniqueRowId:number,key:keyof TypeAheadKeys) => void;
//   handleRefOrCommentUpdate:(uniqueRowId:number,key:keyof TypeAheadKeys) => void;
//   blur:Readonly<boolean>;
//   item:Readonly<ProjectInvoice>;
//   keyLa:Readonly<keyof TypeAheadKeys>;
//   //stateKey:Readonly<keyof TypeAheadStateKeys>;
//   allowNew:Readonly<boolean>;
//   overlay:Readonly<boolean>;
//   title:Readonly<string>;
//   warningSeverity:Readonly<string>;
//   placeHolder:Readonly<string>;
//   options:string[];
//   className?:Readonly<string>;
//   span?:boolean;
//   showValidationInfo?:(props:any,key:keyof TypeAheadKeys) => JSX.Element;
// }

// export const ProjectTypeahead = ({onChange,onBlur,handleRefOrCommentUpdate,showValidationInfo,blur,item,keyLa,allowNew,overlay,title,warningSeverity,placeHolder,className,options,span}:Props) => {
//     console.log(item[keyLa])
//     return<>
//     {typeof item[keyLa] == 'undefined' ? <></>:  
    
//     <div
//     className={`fieldWrapper ${item[keyLa].length == 0 ? `${warningSeverity}`: ''}`}
//     style={item.isDuplicate.value == true ? { backgroundColor: `${item.isDuplicate.backgorundColor}`} : { backgroundColor: "" }}
//     >
//         {item[keyLa].length == 0 ? (
//             <>
//             <Typeahead
//               allowNew={allowNew}
//               positionFixed
//               placeholder={placeHolder}
//               newSelectionPrefix={i18n.t("kickbackProject.add")+' '}
//               emptyLabel={`${i18n.t("kickbackProject.emptyLabel")}`}
//               id={`${item.uniqueRowId}`}
//               options={options}
//               onBlur={blur ? (e) => onBlur(e.target.value,item.uniqueRowId,keyLa)/*this.handleTypeaheadBlur(e.target.value,item.uniqueRowId,key)*/: undefined }
//               onChange={(e) =>
//                 onChange(item.uniqueRowId, e, keyLa) //this.onChangeContractUsers(item.uniqueRowId, e, key)
//             }
//             >
//               {item[keyLa]}
//             </Typeahead>
//                   {item[keyLa].length == 0 && (
//                       <FontAwesomeIcon
//                       className="exclamationIconStyle"
//                       icon={faExclamation}
//                       />
//                       )}
//           </>
//         ) : (
            
//             <div
//             title={title}
//             className={className ?? "projectNameDiv"}
//             onClick={() =>
//                 handleRefOrCommentUpdate(item.uniqueRowId, keyLa)
                
//             }
//             >
//           {typeof span !== 'undefined' ?   
//            <span
//            className="contractSpan"
//            >
//                     {" "}
//                     {item.contractId}-
//                     {/* {stateKey == 'contractDict' && stateKey[item.contractId].title}-
//                     {this.state.contractDict[item.contractId].name} */}
//                   </span>
//           :  item[keyLa]
//           }  
//           </div>
//         )}
//         {overlay && typeof showValidationInfo !== 'undefined' && (
//             <OverlayTrigger
//             placement="right"
//             delay={{ show: 250, hide: 400 }}
//             overlay={(props) => showValidationInfo(props, keyLa)}
//             >
//            {item[keyLa].length == 0 ? (
//                <FontAwesomeIcon
//                className="exclamationIconStyle"
//                icon={faExclamation}
//                />
//                ):<></>}
//           </OverlayTrigger>
//         )}
//       </div>
    
// }</>
// }
type InputFieldKeys = Pick<ProjectInvoice, "totalExclMoms" | "invoiceNumber" | "comment" | 'articleNumber' | 'articlePricePerUnit' | 'articleQuantity' | 'articleQuantityType' | 'articleText' | 'rowSumExclMoms'>
type DateFields = Pick<ProjectInvoice,"billingDate" | "projectEndDate">
type OnChangeToRun = Pick<InputFieldOnChange,"onChangeForArticle" | "onChangeForOriginal" | "onChangeForInvTagComRef">
type InputFieldOnChange = {
  onChangeForOriginal?:(uniqueRowId:Readonly<number>,e:Readonly<any>,key:Readonly<keyof InputFieldKeys> ) => Promise<void> ;
  onChangeForArticle?:(item:Readonly<ProjectInvoice>,e:Readonly<any>,key:Readonly<keyof ArticleKeys>) => Promise<void>;
  onChangeForInvTagComRef?:(val:any,row:Readonly<ProjectInvoice>,key:keyof AssortedFields) => void;
}

interface ProjectFieldProps{
    divStyle:Pick<React.HTMLAttributes<HTMLElement>,"className" | "style">
    item:Readonly<ProjectInvoice>;
    inputField:Readonly<keyof InputFieldKeys> | Readonly<keyof ArticleKeys> | Readonly<keyof AssortedFields>;
    overlay:Readonly<boolean>;
    value:Readonly<string|number| readonly string[] | undefined>;
    placeHolder:Readonly<string>;
    placement:Readonly<Placement | undefined>;
    onChange?:{key:Readonly<keyof OnChangeToRun>,change:InputFieldOnChange};
    disabled?:boolean;
    showValidationInfo?:(props:any,key:Readonly<keyof InputFieldKeys>) => JSX.Element;
    
}
export const ProjectInputField = ({divStyle,item,inputField,overlay,value,placeHolder,placement,onChange,disabled,showValidationInfo}:ProjectFieldProps) =>{  
 const [cache,setCache] = React.useState<any>(value)
 const timerHandler = React.useRef<NodeJS.Timeout>()
 React.useEffect(() => {
  setCache(value)
},[item.id])
const internalOnChange = (e:React.ChangeEvent<HTMLInputElement>) => {
  switch(onChange?.key){
    case 'onChangeForArticle' : onChange?.change['onChangeForArticle']?.(item,e.target.value as any,inputField as keyof ArticleKeys) 
    break
    case 'onChangeForInvTagComRef' : onChange?.change['onChangeForInvTagComRef']?.(e.target.value,item,inputField as keyof AssortedFields)
    break
    case 'onChangeForOriginal' : onChange.change['onChangeForOriginal']?.(item.uniqueRowId,e.target.value as any,inputField as keyof InputFieldKeys)
    break
  }
}
  return(
        <>
              
        <div {...divStyle}>
          {inputField !== 'comment' ?
          <>
          <input
          placeholder={placeHolder/*i18n.t('kickbackProject.fieldInvoiceNumberInput') ?? ''*/}
          style={{width:'125px'}}
          type="text"
          autoComplete="off"
          spellCheck="false"
          
          disabled={disabled}
          onChange={(e) =>
            {
              clearTimeout(timerHandler.current)
              const pendingValue = e
              setCache(pendingValue.target.value)
              timerHandler.current = setTimeout(() => {
                internalOnChange(pendingValue)
              },500)
            } 
          }
          value={disabled == true  ? value: cache} 
         
        />
        {overlay &&
           <FontAwesomeIcon
            className="exclamationIconStyle"
              icon={faExclamation}
            /> 
        }
        </>
        :
         (<input 
            className="commentInputField"
            type="text"
            onChange={(e) =>{
              clearTimeout(timerHandler.current)
              const pendingValue = e
              setCache(pendingValue.target.value)
              timerHandler.current = setTimeout(() => {
                internalOnChange(pendingValue)
              },500)
            }
            }
            autoComplete="off"
            spellCheck="false"
            disabled={disabled}
            defaultValue={cache}
            />
          )
          }
          
          { overlay && (
            <>
            <OverlayTrigger
              placement={placement}
              delay={{ show: 250, hide: 400 }}
              overlay={typeof showValidationInfo !== 'undefined' ? (props) => showValidationInfo(props, inputField as keyof InputFieldKeys) : (props) => <></>}
            >
              <FontAwesomeIcon
            className="exclamationIconStyle"
              icon={faExclamation}
            />
            </OverlayTrigger>
          
           </> 
          )}
        </div>
         
       
        
        </>
    )
}
interface ProjectDateFieldProps{
  divStyle:Pick<React.HTMLAttributes<HTMLElement>,"className" | "style">;
  dateField:keyof DateFields;
  item:ProjectInvoice;
  period:string;
  overlay:boolean;
  minMax:boolean;
  handleDatechange:(event:Date | null ,item:ProjectInvoice/*uniqueRowId:number*/) => void;
  showValidationInfo:(props:any,key:keyof DateFields) => JSX.Element;

}
export const ProjectDateField = ({divStyle,dateField,item,period,overlay,minMax,handleDatechange,showValidationInfo}:ProjectDateFieldProps) => {
 
 return <div {...divStyle}>
              <DatePicker_react
            locale={"sv-SE"}
            dateFormat={"yyyy-MM-dd"}
            selected={ item[dateField] ? new Date(item[dateField]) : null}
            minDate={minMax ? new Date(_helpers.getMinOrMaxDateRange("min",period as string)) : null}
            maxDate={minMax ? new Date(_helpers.getMinOrMaxDateRange("max",period as string)) : null}
            onChange={(e) => {handleDatechange(e, item)}}
            placeholderText="YYYY-MM-DD"
          />
          {overlay && (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => showValidationInfo(props, dateField)}
            >
              <FontAwesomeIcon
              className="exclamationIconStyle"
                icon={faExclamation}
              />
            </OverlayTrigger>
          )}
        </div>
}