import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { TypeaheadComponentProps } from 'react-bootstrap-typeahead/types/components/Typeahead';

export interface IChoiceInputProps {
}

export function ChoiceInput (props: TypeaheadComponentProps) {
  return (
    <div>
      <Typeahead
          positionFixed
          {...props}
          // id="basic-typeahead-single"
          // labelKey="name"
          // onChange={setSingleSelections}
          // options={options}
          // placeholder="Choose a state..."
          // selected={singleSelections}
        />
    </div>
  );
}
