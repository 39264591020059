import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";

import { Button, Form, OverlayTrigger, Spinner, Stack, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { DataService } from "../../services/DataService";
import { StyledModal } from "../modal/StyledModal";
import Datalist, { FooterOptions } from "../Datalist/Datalist";
import {
  FetchReturn,
  IsUserSinfra,
  ReadFriendlyNumber,
  StringToNumber
} from "../../lib/utils";
import { SubmitKickbackReportModal } from "../modal/SubmitKickbackReportModal";
import UploadKickbackModal from "../modal/UploadKickbackModal";
import { Infobar } from "../Infobar/Infobar";
import { TKickback } from "../../services/API/Kickback";
import Field from "../fields/Field";
import { Department, DepartmentFunctionArgs } from "../../services/API/Departments";
import ToasterMsg from "../Toaster/ToasterMsg";


interface Props extends WithTranslation {
  invoiceReportInProgress: boolean;
}

interface State {
  error?: Error;
  kickbacks: TKickback[];
  AreInvoicesValid: boolean;
  isLoading: boolean;
  showSubmitModal: boolean;
  showSuccessModal: boolean;
  successMsg: string;
  showKickbackUploadModal: boolean;
  period: string;
  availablePeriods: string[];
  canReport: boolean;
  department:Department | undefined
  invoiceSuccesfullyPulseToggle:boolean;
  focus:boolean;
}

class KickbackReport extends React.Component<Props, State> {
  private dd = React.createRef<HTMLDivElement>()
  constructor(props: Props) {
    super(props);
    
    this.state = {
      kickbacks: [],
      AreInvoicesValid: false,
      isLoading: true,
      showSubmitModal: false,
      showSuccessModal: false,
      successMsg: "",
      showKickbackUploadModal: false,
      availablePeriods: [],
      canReport: false,
      period: "",
      department:undefined,
      invoiceSuccesfullyPulseToggle:false,
      focus:false
    };
  }
  async componentDidMount() {
    if(!IsUserSinfra()){
      this.loadKickbackItems();
      //this.focusDiv()
    }
      
    
     
    else{
      this.setState({
         isLoading: false
      })
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if(prevProps.invoiceReportInProgress && this.props.invoiceReportInProgress == false){
      this.setState({ isLoading: true })
      const year = this.state.period.slice(0, 4);
      const month = this.state.period.slice(5, 7);
      const [kickbacks, availablePeriods] = await Promise.all([
        DataService.Kickback.GetNewKickbackItems(year, month),
        typeof this.state.department == 'undefined' ? DataService.Kickback.GetPossibleKickbackPeriods() : this.getUserDepartmentPeriods(this.state.department.id)
      ])   
      this.setState({ kickbacks, availablePeriods, period: availablePeriods.length > 0 ? availablePeriods[0] : this.state.period, isLoading:false,invoiceSuccesfullyPulseToggle:true });
    }
    if(!prevProps.invoiceReportInProgress && this.props.invoiceReportInProgress){
      this.focusDiv()
    }
  }
  private getUserDepartmentPeriods = async (departmentId:number) => {
    const availablePeriods:string[] = []
    const da:DepartmentFunctionArgs = {reportCheck:{departmentId:departmentId,report:4}} 
    const reporttttt = await DataService.Departments.CheckAlreadyReportedDepartment(da)
  
    
     Object.entries(reporttttt).forEach(([key,value]) => {
      if(value?.kickbackReport && typeof value?.invoicesNotReported !== 'undefined'){
        if(value.invoicesNotReported.length > 0){
          availablePeriods.push(key)
        }
      }
     })
    
     return availablePeriods
  }
  private loadKickbackItems = async () => {
    this.setState({ isLoading: true });
    const [canReport,department] = await Promise.all([
      DataService.Kickback.CanActiveSupplierReport(),
      DataService.Departments.GetUserAssignedDepartment()
    ])
  
    if(typeof department.Content.response !== 'undefined'){
        const availablePeriods2 = await this.getUserDepartmentPeriods(department.Content.response.id)
  
        const initialPeriod = availablePeriods2.length > 0 ? availablePeriods2[0] : this.state.period
        const year = initialPeriod.slice(0, 4);
        const month = initialPeriod.slice(5, 7);
        const kickbacks = await DataService.Kickback.GetNewKickbackItems(year, month)  
  
        this.setState({
        department:department.Content.response,
        kickbacks: kickbacks,
        availablePeriods:availablePeriods2,
        period: initialPeriod,
        canReport,
        isLoading: false
      });
    }   
    else{
      const availablePeriods = await DataService.Kickback.GetPossibleKickbackPeriods()  
     
      const initialPeriod =
        availablePeriods.length > 0 ? availablePeriods[0] : this.state.period;
      const year = initialPeriod.slice(0, 4);
      const month = initialPeriod.slice(5, 7);
     
      const kickbacks = canReport
        ? await DataService.Kickback.GetNewKickbackItems(year, month)
        : [];
     
        this.setState({
        kickbacks: kickbacks,
        availablePeriods: availablePeriods,
        period: initialPeriod,
        canReport,
        isLoading: false
      });
    }
    
   
  };

  

  private onChangePeriod = async (period: string) => {
    this.setState({ isLoading: true });
    const year = period.slice(0, 4);
    const month = period.slice(5, 7);
    const kickbacks = await DataService.Kickback.GetNewKickbackItems(
      year,
      month
    );
    this.setState({
      kickbacks,
      period,
      isLoading: false,
    });
  };

  private onSubmit = async () => {
    this.setState({
      showSubmitModal: false,
      showKickbackUploadModal: false,
      showSuccessModal: true,
      successMsg: this.props.t("kickback.sendSuccessful"),
    });
  };

  private opt = (period: string) => {
    const date = new Date(period);
    const text = `${date.toLocaleString("default", { month: "long" })} ${date
      .getFullYear()
      .toString()}`;
    return (
      <option
        key={period}
        style={{ textTransform: "capitalize" }}
        value={period}
      >
        {text}
      </option>
    );
  };

  private generateMonthOptions = () => {
      return this.state.availablePeriods.map((key) => {
        return this.opt(key);
      });
  };
  private activeDiv = () => {
    this.setState({invoiceSuccesfullyPulseToggle:false})
  }
  private focusDiv = () => {
    if(this.dd.current){

      this.dd.current?.focus();
      this.dd.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    
    
  }
  private getInstructionTexts = () => {
    let texts = [];
    const key = "kickback.instructionText";
    let i = 1;
    let potentialText = this.props.t(key+i);
    while(potentialText != key+i){
      texts.push(potentialText);
      i++;
      potentialText = this.props.t(key+i);
    }
    return texts;
  }
  private checkSumValidation = () => {
    const res = this.state.kickbacks.find((x:TKickback) => {                    
    const parsedChangeValue = +x.sumExclMomsChangesProps.value.replace(',','.').replaceAll(' ','')
    const checkIfSumsDontMatch = x.sumExclMoms.toFixed(2) !== parsedChangeValue.toFixed(2) && x.comment.length == 0 
            if(checkIfSumsDontMatch){
                return x
              }
            }
          )
      return res
  }


  render() {
    const instructionTexts = this.getInstructionTexts();
    const { error, kickbacks } = this.state;
    
    if (error !== undefined) {
      return <div>{error.message}</div>;
    }
    //let currentValues = kickbacks.find((x:TKickback) => x.sumExclMoms !== x.sumExclMomsChangesProps.value && x.comment.length == 0)
    //const toastMsg:FetchReturn<any> = {Content:'',Message:this.props.t('invoiceReport.uploaded'),Code:0}
    return (<Stack  gap={1} className={!IsUserSinfra() &&this.state.invoiceSuccesfullyPulseToggle ? "pulse":"section kickback"} onMouseEnter={this.activeDiv}>
         
         
         {/* <ToasterMsg
                show={this.state.invoiceSuccesfullyPulseToggle}
                handleToastClose={() => {console.log()}}
                message={toastMsg}
                autoClose={true}
                closeDelayMs={2000}
                initial={true}
                //customIcon="hashtag"
              />  */}
         
        <div ref={this.dd }>
          <h3>{this.props.t("kickback.title")}</h3>
        </div>
        {
          instructionTexts.length > 0 &&   
            <ul >
              {
                instructionTexts.map(text => <li><Trans>{text}</Trans></li>)
              }
            </ul>
        }
        {
          this.state.isLoading &&
          <Spinner />
        }
        {!this.state.canReport && !this.state.isLoading && !IsUserSinfra() && (
          <Infobar text={this.props.t("kickback.inactiveMsg")} />
        )}
        {
          this.props.invoiceReportInProgress && !this.state.isLoading && !IsUserSinfra() &&
          <Infobar text={this.props.t("kickback.stillProccessingStatistics")} useSpinner/>
        }
        {
          this.state.availablePeriods.length == 0 && !this.state.isLoading && !IsUserSinfra() &&
          <Infobar text={this.props.t("kickback.noStatistics")}/>
        }
        {
          IsUserSinfra() && <Infobar text={this.props.t("kickback.imitateUser")}/>
        }
        {
          this.state.availablePeriods.length > 0 && !IsUserSinfra() &&
          <Stack gap={3}>
            {this.state.canReport && (
              <Stack
                gap={3}
                direction="horizontal"
                style={{
                  justifyContent: "end",
                  flexWrap: "wrap",
                  alignItems: "flex-end",
                }}
              >
                <Form.Group>
                  {/* <Form.Label>{this.props.t("general.period")}</Form.Label> */}
                  <Form.Select
                    style={{ textTransform: "capitalize" }}
                    value={this.state.period}
                    onChange={(ev) => {
                      this.onChangePeriod(ev.target.value);
                    }}
                  >
                    {this.generateMonthOptions()}
                  </Form.Select>
                </Form.Group>
                <Button
                  disabled={this.props.invoiceReportInProgress 
                    || this.state.kickbacks.some(
                    (c) => c.sumExclMomsChangesProps.validation == "Error"
                  ) || typeof this.checkSumValidation() !== 'undefined'}
                  className="btn button-submit"
                  onClick={() => {
                    this.setState({ showSubmitModal: true });
                  }}
                >
                  <>
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ marginRight: 8 }}
                    />
                    {this.props.t("kickback.markPeriodAsDone")}
                  </>
                </Button>
              </Stack>
            )}

            <div style={{ maxHeight: "60vh" }}>
              <Datalist
                items={kickbacks}
                isLoading={this.state.isLoading}
                uniqueKey="contractId"
                columns={[
                  {
                    fieldName: "contractId",
                    isActive: true,
                    key: "contractId",
                    minWidth: 100,
                    name: this.props.t("general.contract"),
                    onRender(item, col) {
                      return `${item.contractId} - ${item.contractType} - ${item.contractName}`;
                    },
                    footer: FooterOptions.COUNT,
                    disabled: true
                  },
                  {
                    fieldName: "sumExclMoms",
                    isActive: true,
                    key: "sumExclMoms",
                    minWidth: 60,
                    name: this.props.t("kickback.table.header.sumRef"),
                    onRender: (item) => {
                      return ReadFriendlyNumber(item.sumExclMoms);
                    },
                    footer: FooterOptions.SUM,
                    disabled: true
                  },
                  {
                    fieldName: "sum",
                    isActive: true,
                    key: "sum",
                    minWidth: 60,
                    customCellPadding: "1px",
                    name: this.props.t("kickback.table.header.sumExclMoms"),
                    onRender: (item, col) => {
                      return (
                        <Field
                          property={item.sumExclMomsChangesProps}
                          onChange={(val) => {
                            const newValidatedValue =
                              DataService.Kickback.ValidateSum(val);
                            const kickbacks = [...this.state.kickbacks];
                            const index = kickbacks.findIndex(
                              (cont) => cont.contractId == item.contractId
                            );
                            kickbacks.splice(index, 1, {
                              ...kickbacks[index],
                              sumExclMomsChangesProps: newValidatedValue,
                            });
                            this.setState({ kickbacks: kickbacks });
                          }}
                        />
                      );
                    },
                    footer: (items) => {
                      const totalSum = items.reduce((prev, curr) => {
                        const val = StringToNumber(curr.sumExclMomsChangesProps.value);
                        return prev + val;
                      }, 0);
                      return ReadFriendlyNumber(totalSum);
                    },
                  },
                  {
                    fieldName: "comment",
                    isActive: true,
                    key: "comment",
                    minWidth: 60,
                    customCellPadding: "1px",
                    name: this.props.t("general.comment"),
                    onRender: (item, col) => {
                      const parsedChangeValue = +item.sumExclMomsChangesProps.value.replace(',','.').replaceAll(' ','')
                      const checkIfSumsDontMatch = item.sumExclMoms.toFixed(2) !== parsedChangeValue.toFixed(2) && item.comment.length == 0 
                      return (
                        // <div className={boolllllll? 'fieldWrapper  fieldWrapper-error' : ''} >
                        <Field
                          property={{
                            type: "Text",
                            key: "comment",
                            value: item.comment,
                            format: this.props.t("kickback.commentPlaceholder"),
                            message: this.props.t('kickback.commentChanges'),
                            validation:checkIfSumsDontMatch ? "Error": "Good",
                          }}
                          onChange={(val) => {
                            const kickbacks = [...this.state.kickbacks];
                            const index = kickbacks.findIndex(
                              (cont) => cont.contractId == item.contractId
                            );
                            kickbacks.splice(index, 1, {
                              ...kickbacks[index],
                              comment: val,
                            });
                            this.setState({ kickbacks: kickbacks });
                          }}
                        />
                      );
                    },
                  },
                  {
                    fieldName: "kickbackProcent",
                    isActive: true,
                    key: "kickbackProcent",
                    minWidth: 60,
                    name: this.props.t("kickback.table.header.kickbackProcent"),
                    onRender: (item, col) => {
                      return item.kickbackProcent + "%";
                    },
                    disabled: true
                  },
                  {
                    fieldName: "calculated0",
                    isActive: true,
                    key: "calculated0",
                    minWidth: 60,
                    name: this.props.t(
                      "kickback.table.header.calculatedKickback"
                    ),
                    onRender: (item, col) => {
                      if (item.sumExclMomsChangesProps.validation == "Error")
                        return "0,00";
                      const inputValue = StringToNumber(
                        item.sumExclMomsChangesProps.value
                      );
                      const kickbackNumber = item.kickbackProcent ?? 0;
                      const calculatedNumber =
                        inputValue * (kickbackNumber / 100);
                      return ReadFriendlyNumber(calculatedNumber);
                    },
                    footer: (items) => {
                      const kickbackTotal = items.reduce((prev, curr) => {
                        const val = StringToNumber(curr.sumExclMomsChangesProps.value);
                        const kickbackNumber = curr.kickbackProcent ?? 0;
                        const calculatedNumber = val * (kickbackNumber / 100);
                        return prev + calculatedNumber;
                      }, 0);
                      return ReadFriendlyNumber(kickbackTotal);
                    },
                    disabled: true
                  }
                ]}
                noItemsText={this.props.t("kickback.table.empty")}
                initialSortKey="contractId"
                setColumns={(cols) => {}}
              />
            </div>
          </Stack>
        }
        {this.state.showSubmitModal && (
          <SubmitKickbackReportModal
            disabled={false}
            kickbacks={this.state.kickbacks}
            onDismiss={() => {
              this.setState({ showSubmitModal: false });
            }}
            onSubmitted={this.onSubmit}
            period={this.state.period}
          />
        )}
        {this.state.showSuccessModal && (
          <StyledModal
            onDismiss={() => {
              this.setState({ showSuccessModal: false });
            }}
            title={this.props.t("invoiceReport.successModalTitle") as string}
            body={
              <Stack gap={3} style={{ alignItems: "center" }}>
                <div style={{ fontSize: 18, fontWeight: 500 }}>
                  {this.state.successMsg}
                </div>
                <Button
                  style={{ width: 100 }}
                  className="button button-cancel"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  {this.props.t("general.close")}
                </Button>
              </Stack>
            }
          />
        )}
        {this.state.showKickbackUploadModal && (
          <UploadKickbackModal
            onDismiss={() => {
              this.setState({ showKickbackUploadModal: false });
            }}
            onSubmitted={this.onSubmit}
            //period={this.state.period}
            //missingPeriods={this.state.missingPeriods}
          />
        )}
      </Stack>
    );
  }
}

export default withTranslation()(KickbackReport);
