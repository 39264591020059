import { t } from 'i18next';
import * as React from 'react';
import { Spinner, Stack, Button } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { StyledModal } from './StyledModal';
import { TUser } from '../../services/API/Users';
import { Code, IsUserSinfra, setActiveSupplier } from '../../lib/utils';
import { DataService } from '../../services/DataService';
import { TSupplier } from '../../services/API/Suppliers';
import { API } from '../../services/API';

export interface IImpersonationModalProps {
  onDismiss():void;
}

export function ImpersonationModal (props: IImpersonationModalProps) {
  const [loading, setLoading ] = React.useState(false);
  const [ errorMsg, setErrorMsg ] = React.useState("");
  const [suppliers, setSuppliers] = React.useState<TSupplier[]>([]);
  const [supplierLoading, setSupplierLoading] = React.useState(true);
  const [selectedSupplier, setSelectedSupplier] = React.useState<TSupplier>();
  const [users, setUsers] = React.useState<TUser[]>([]);
  const [userLoading, setUserLoading] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<TUser>();
  
  React.useEffect(() => {
    
    DataService.Suppliers.Get(true)
      .then(suppliers => {
          setSuppliers(suppliers);
          setSupplierLoading(false);
      });
  }, []);

  const initUsers = async (orgNr : string) => {
    setUserLoading(true);
    const users = await DataService.Suppliers.GetSupplierUsers(orgNr);
    setUsers(users);
    setUserLoading(false);
  }

  const onimpersonate = async (user : TUser, orgNr: string) => {
    setLoading(true);
    setErrorMsg("");
    const res = await DataService.Users.Impersonate(user.id);
    if(res.Code != Code.OK){
      setErrorMsg(res.Message);
      setLoading(false);
    }
    else{
      setActiveSupplier(orgNr);
      window.location.reload();
    }
  }

  return (
    <StyledModal 
      title={t('impersonationModal.title').toString()} 
      onDismiss={props.onDismiss}   
      body={
          <>
          <p>
            {t('impersonationModal.description')}
          </p>
          {
            supplierLoading &&
            <Spinner />
          }
          {
            !supplierLoading &&
            <Stack gap={3}>
              <div>
                <div className='user-page-field-label'>{t("general.supplier")}</div>
                <Typeahead 
                  options={suppliers.map(supplier => `${supplier.supplierName} - ${supplier.supplierOrgNr}`)}
                  placeholder={t('impersonationModal.searchPlaceholder').toString()}
                  disabled={loading}
                  id={"navSuppliers"}
                  onChange={(selected) => {
                  if(selected.length > 0){
                      const orgNr = selected[0].split(" - ")[1];
                      const foundSupp = suppliers.find(supp => supp.supplierOrgNr == orgNr);
                      if(foundSupp){
                        setSelectedSupplier(foundSupp);
                        initUsers(foundSupp.supplierOrgNr);
                      }
                  }
                  }}
                  onInputChange={(text) => {
                    setSelectedSupplier(undefined);
                    setSelectedUser(undefined);
                  }}
                />
              </div>
              <div>
                <div className='user-page-field-label'>
                  {t("general.user")} 
                  {
                    userLoading &&
                    <Spinner style={{ width: "20px", height: "20px", marginLeft: 8 }}/>
                  }
                </div>
                <Typeahead 
                  options={users.map(user => user.email)}
                  disabled={userLoading || selectedSupplier == undefined || loading}
                  placeholder={t('impersonationModal.searchPlaceholder').toString()}
                  id={"navUsers"}
                  onChange={(selected) => {
                  if(selected.length > 0){
                      const email = selected[0];
                      const foundUser = users.find(user => user.email == email);
                      if(foundUser)
                        setSelectedUser(foundUser);
                  }
                  }}
                  onInputChange={(text) => {
                    setSelectedUser(undefined);
                  }}
                />
              </div>
              {
                errorMsg &&
                <div className='error-message'>
                  <span>{t('general.errorMsg')}:</span>
                  {errorMsg}
                </div>
              }
            </Stack>
            
          }
      
        </>
      }
      footer={
          <Stack gap={3} direction='horizontal'>
            {
              loading &&
              <Spinner />
            }
            {
              !loading &&
              <>
                <Button disabled={selectedUser == undefined} onClick={() => { 
                  if(selectedUser && selectedSupplier)
                    onimpersonate(selectedUser, selectedSupplier.supplierOrgNr); 
                }}>
                    {t('impersonationModal.impersonate')}
                </Button>
                <Button className="button-cancel" onClick={() => { props.onDismiss() }}>
                    {t('general.cancel')}
                </Button>
              </>
            }
          </Stack>
      }            
  />
  );
}
