import * as React from 'react'
import { Spinner } from "react-bootstrap";
import {
  Contract,
  ContractDict,
  CustomerDict,
  Expression,
  Filter,
  OptionsAvailable,
  Period,
  ProjectInvoice,
} from "../pages/KickbackProjectReportPage";
import { GroupedProjectNameAndEndDate } from "../services/API/Kickback";
import i18n from '../i18n';
import { ReadFriendlyNumber } from './utils';
interface ProjectReportValidationParams {
  validContracts: ContractDict;
  validCustomers: CustomerDict;
  existingProjects: GroupedProjectNameAndEndDate;
  availableOptions: OptionsAvailable;

}
enum KeysToSearch {
  projectName = "projectName",
  customerName = "customerName",
  customerOrgId = "customerOrgId",
  contractId = "contractId",
  billingDate = "billingDate",
  totalExclMoms = "totalExclMoms",
  tag = "tag",
  projectEndDate = "projectEndDate",
  reference = "reference",
  rowSumExclMoms = "rowSumExclMoms",
  articleNumber = "articleNumber",
  articlePricePerUnit = "articlePricePerUnit",
  articleQuantity = "articleQuantity",
  articleQuantityType = "articleQuantityType",
  articleText = "articleText"
}
type PropsKeys = Pick<ProjectReportValidationParams, "existingProjects">;
type ProjectOptions = Pick<ProjectInvoice, "reference" | "tag" | "projectName">;
type SumValidation = Pick<ProjectInvoice, "totalExclMoms">;
type ArticleValidation = Pick<ProjectInvoice,'rowSumExclMoms'>
const _helpers = {
  checkRowSumExcl:(item:ProjectInvoice,key:keyof ArticleValidation) =>{
    item[key] = item[key].replace(/['a-öA-Ö'_!"`'#%&:; <>=@{}~$()*+/\\?[\]^|(\r\n|\r|\n)]+/g,'').replace(',','.')
  },
  checkKey: (val: string): val is KeysToSearch => {
    return val in KeysToSearch;
  },
  deepClone: (input: any): any => {
    const res = JSON.parse(JSON.stringify(input));
    return res;
  },
  convertToPercentage: (percentage: string): number => {
    let parsedPerc = +percentage.replace(",", ".");
    return parsedPerc / 100;
  },
  updateSum:(itemToChange:Readonly<ProjectInvoice>,validation:[boolean,string],val:string):ProjectInvoice =>{
   const buff = {...itemToChange}
   buff.totalExclMoms.display = val.replace(/['a-öA-Ö'_!"`'#%&:; <>=@{}~$()*+/\\?[\]^|(\r\n|\r|\n)]+/g,'')
    if (validation[1] == "neg") {
      //buff.totalExclMoms.display = val;
      //let value: string = val;
      let handeldComma = buff.totalExclMoms.display.replace(",", ".");
      let split = +handeldComma.replace("-", "");
      buff.totalExclMoms.value = -Math.abs(split);
      buff.totalExclMoms.valid = true;
      buff.totalExclMoms.error = false;
    } else if (validation[1] == "start") {
     // buff.totalExclMoms.display = val;
      buff.totalExclMoms.value = 0;
      buff.totalExclMoms.valid = false;
    } else if (validation[1] == "pos") {
      // buff.totalExclMoms.display = val;
      // let value = val;
      let handeldComma = buff.totalExclMoms.display.replace(",", ".");
      buff.totalExclMoms.value = +handeldComma;
      buff.totalExclMoms.valid = true;
      buff.totalExclMoms.error = false;
    } else if (validation[0] == false) {
      //buff.totalExclMoms.display = val;
      buff.totalExclMoms.value = 0;
      buff.totalExclMoms.valid = false;
      buff.totalExclMoms.error = true;
    }
    return buff
  },
  isValidRow:(row:ProjectInvoice):boolean => {
    if(row.customerName.length > 0 &&row.contractId.length > 0 &&row.invoiceNumber.length > 0 && row.billingDate.length > 0 ){
      return true
    }
    return false
  },
  articleFileUploadedNowGroupAndAddIds:(articlesToGroup:Readonly<ProjectInvoice[]>) => {
    //här vill jag identifiera de rader som tillhör samma grupp(projektnamn,kund,kontrakt,fakturadatum,fakturanummer)//-${articlesToGroup[index].billingDate}
    const buff:{[groupId:string]:ProjectInvoice} = {}
    let groupedUploadedReport:ProjectInvoice[] = [...articlesToGroup]
    for (let index = 0; index < articlesToGroup.length; index++) {
      if(!buff[`${articlesToGroup[index].projectName}-${articlesToGroup[index].customerOrgId}-${articlesToGroup[index].invoiceNumber}-${articlesToGroup[index].contractId}-${articlesToGroup[index].billingDate}`]){
        groupedUploadedReport[index].id = index
        groupedUploadedReport[index].groupId = Math.random()
        //groupedUploadedReport[index].articleQuantityType = ''
        buff[`${articlesToGroup[index].projectName}-${articlesToGroup[index].customerOrgId}-${articlesToGroup[index].invoiceNumber}-${articlesToGroup[index].contractId}-${articlesToGroup[index].billingDate}`] = articlesToGroup[index]
      }
      else{
        groupedUploadedReport[index].id = index
        groupedUploadedReport[index].groupId = buff[`${articlesToGroup[index].projectName}-${articlesToGroup[index].customerOrgId}-${articlesToGroup[index].invoiceNumber}-${articlesToGroup[index].contractId}-${articlesToGroup[index].billingDate}`].groupId
        //groupedUploadedReport[index].articleQuantityType = ''
      }
      
    }
    return groupedUploadedReport.sort((a,b) =>{ 
      if(a.groupId < b.groupId){
        return -1
      }
      if(a.groupId > b.groupId){
        return 1
      }
      return 0
     })
  },
  isValidArticleGroup:(group:ProjectInvoice[]):{warnings:number,errors:number} => {
    let warnings = 0
    let errors=0
    for (let index = 0; index < group.length; index++) {
      if(group[index].articleQuantityType.length == 0 || group[index].articleText.length == 0 || group[index].rowSumExclMoms.length == 0 || group[index].projectName.length == 0 || group[index].customerOrgId.length == 0 || group[index].invoiceNumber.length == 0){
        errors++
      }
      if(group[index].articlePricePerUnit.length == 0){
        errors++
      }
      if ( group[index].articleQuantity.length == 0){
        errors++
      }
      
    }
   warnings =  group[0].isDuplicate.value ? 1 : 0
    //console.log(warnings,errors)
    return {warnings,errors}
  },
  updateArticle:async(item:ProjectInvoice,e:any,key:Readonly<keyof ProjectInvoice>):Promise<void> =>{
      console.log(item,e,key)
  },
  checkTemporaryDuplicates:(x: ProjectInvoice[],articleReporter:boolean): ProjectInvoice[] => {
    const temp = [...x]
    const grouped:{[groupId:string]:ProjectInvoice[]} = temp.reduce((grouped:{[groupId:string]:ProjectInvoice[]},item:ProjectInvoice) => {
      if(!grouped[`${item.groupId}`]){
        grouped[`${item.groupId}`] = []
        grouped[`${item.groupId}`].push(item)
        return grouped
      }
      else{
        grouped[`${item.groupId}`].push(item)
        return grouped
      }
      
    },{})
   const depPA = Object.values(grouped).reduce((accum:{[key:string]:{ rows: number[]; rowColor: string }},item:ProjectInvoice[]) => {
      const totSum = item.reduce((acc:number,item:ProjectInvoice) => {
        return acc + +item.rowSumExclMoms
      },0)
      
    if(!accum[`${item[0].customerName}-${item[0].contractId}-${item[0].invoiceNumber}-${item[0].billingDate}-${totSum}-`]){
      accum[`${item[0].customerName}-${item[0].contractId}-${item[0].invoiceNumber}-${item[0].billingDate}-${totSum}-`] = {rows: [], rowColor: ''}
      accum[`${item[0].customerName}-${item[0].contractId}-${item[0].invoiceNumber}-${item[0].billingDate}-${totSum}-`].rows.push(item[0].uniqueRowId)
    return accum
    } 
    else{
      accum[`${item[0].customerName}-${item[0].contractId}-${item[0].invoiceNumber}-${item[0].billingDate}-${totSum}-`].rows.push(item[0].uniqueRowId)
      return accum
    } 
    },{})
    const result: ProjectInvoice[] =  _helpers.handleInvalidRowUpdate(
      depPA,
      x
    );
    return result
  },
  checkDuplicates:  (x: Readonly<ProjectInvoice[]>,articleReporter:boolean): ProjectInvoice[] => {
  
    const testDuplicatesDuplicates: {
      [key: string]: { rows: number[]; rowColor: string };
    } = x.reduce(
      (
        testDuplicatesDuplicates: {
          [key: string]: { rows: number[]; rowColor: string };
        },
        item
      ) => {
        //console.log(item)
       if(_helpers.isValidRow(item)){
        if (
          !testDuplicatesDuplicates[
            `${item.customerName}-${item.contractId}-${item.invoiceNumber}-${item.billingDate}-${articleReporter ? item.rowSumExclMoms :item.totalExclMoms.value}-`
          ]
        ) {
          
          testDuplicatesDuplicates[
            `${item.customerName}-${item.contractId}-${item.invoiceNumber}-${item.billingDate}-${articleReporter ? item.rowSumExclMoms :item.totalExclMoms.value}-`
          ] = { rows: [], rowColor: ''/*invalidColors[colorIndex]*/ };
          testDuplicatesDuplicates[
            `${item.customerName}-${item.contractId}-${item.invoiceNumber}-${item.billingDate}-${articleReporter ? item.rowSumExclMoms :item.totalExclMoms.value}-`
          ].rows.push(item.uniqueRowId);
         // colorIndex++;
        } else {
          testDuplicatesDuplicates[
            `${item.customerName}-${item.contractId}-${item.invoiceNumber}-${item.billingDate}-${articleReporter ? item.rowSumExclMoms :item.totalExclMoms.value}-`
          ].rows.push(item.uniqueRowId);
          if (
            testDuplicatesDuplicates[
              `${item.customerName}-${item.contractId}-${item.invoiceNumber}-${item.billingDate}-${articleReporter ? item.rowSumExclMoms :item.totalExclMoms.value}-`
            ].rows.length > 1
          ) {
            testDuplicatesDuplicates[
              `${item.customerName}-${item.contractId}-${item.invoiceNumber}-${item.billingDate}-${articleReporter ? item.rowSumExclMoms :item.totalExclMoms.value}-`
            ].rowColor = ''/*invalidColors[colorIndex]*/;
          }
        }
        return testDuplicatesDuplicates;
       }
       return testDuplicatesDuplicates;
       }       
      ,
      {}
    );
    let result: ProjectInvoice[] =  _helpers.handleInvalidRowUpdate(testDuplicatesDuplicates,x);
    // console.log(result)
    // if(articleReporter){
    //   result =  _helpers.articleFileUploadedNowGroupAndAddIds(result)
    // }
    return result;
  },
  handleInvalidRowUpdate:  (
    duplicates: {[key:string]:{rows:number[],rowColor:string}},
    stateAsOffNow: Readonly<ProjectInvoice[]>
  ): ProjectInvoice[] => {

    let dup: ProjectInvoice[] = [...stateAsOffNow]//_helpers.deepClone(stateAsOffNow);
    if (typeof duplicates !== "undefined") {
      Object.entries(duplicates).forEach(([key, value]) => {
        if (duplicates[key].rows.length > 1) {
          const back = generateRgb()
          duplicates[key].rows.forEach((x: any) => {
            for (let index = 0; index < dup.length; index++) {
              if (dup[index].uniqueRowId == x) {
                dup[index].isDuplicate = {
                  value: true,
                  backgorundColor: back,
                };
              }
            }
          });
         
        } else {
          duplicates[key].rows.forEach((x: any) => {
            for (let index = 0; index < dup.length; index++) {
              if (dup[index].uniqueRowId == x) {
                dup[index].isDuplicate = { value: false, backgorundColor: "" };
              }
            }
           
          });
        }
      });
    }
    return dup;
  },
  checkValidNumber:(x: ProjectInvoice, key: keyof SumValidation) =>{
    
    x[key].display = x[key].display.replace(/['a-öA-Ö'_!"`'#%&:; <>=@{}~$()*+/\\?[\]^|(\r\n|\r|\n)]+/g,'')
   
    if (
      x[key].display[0] == "-" &&
      x[key].display.match(/[1-9,.,\s]/) !== null &&
      !x[key].display.match(/['a-öA-Ö']/)
    ) {
     
      x[key].valid = true;
      x[key].error = false;
      x[key].value = +x[key].display.replace(',','.')/*x[key].value == null ? 0 : +ReadFriendlyNumber(x[key].value).replace(' ','').replace(',','.');*/
      x[key].display = ReadFriendlyNumber(x[key].value).replace(',','.').replace(' ','');
      //return input
      // return {...x[key],valid:true,error:false};
    } else if (
      x[key].display.match(/^-?[0-9,.,\s]+$/) &&
      x[key].display.length > 0 &&
      !x[key].display.match(/['a-öA-Ö']/)
    ) {
      
      x[key].valid = true;
      x[key].error = false;
      x[key].value =  +x[key].display.replace(',','.').replace(' ','')/*x[key].value == null ? 0 : +ReadFriendlyNumber(x[key].value).replace(' ','').replace(',','.');*/
      x[key].display = ReadFriendlyNumber(x[key].value).replace(',','.').replace(' ','');
      //return input
    } else {
      x[key].valid = false;
      x[key].error = true;
      x[key].value = 0;
      x[key].display = "";
      //return input
    }
  },
  finalizeProjectEndDates :(validatedData:ProjectInvoice[],projectDates:GroupedProjectNameAndEndDate):any =>{
    
   const sortedData = [...validatedData]
   const groupedData =  sortedData.reduce((acc:{[key:string]:ProjectInvoice},item) => {
      if(!acc[`${item.projectName}["@@££$23€£@£$^^]${item.contractId}`]){
        acc[`${item.projectName}["@@££$23€£@£$^^]${item.contractId}`] = item
        return acc
      }
      else{
        if(item.projectEndDate.localeCompare(acc[`${item.projectName}["@@££$23€£@£$^^]${item.contractId}`].projectEndDate,'sv-SE') == 1/*new Intl.Collator('sv-SE').compare(item.projectEndDate,acc[`${item.projectName}["@@££$23€£@£$^^]${item.contractId}`].projectEndDate) == 1*/){
          acc[`${item.projectName}["@@££$23€£@£$^^]${item.contractId}`] = item
        }
        return acc
      }
    },{})
    const groupedKeys = Object.keys(groupedData)
    for (let index = 0; index < groupedKeys.length; index++) {
      const element = groupedKeys[index].split('["@@££$23€£@£$^^]');
      if(element[1].length > 0  ){
        projectDates[element[1]][element[0]] = groupedData[groupedKeys[index]].projectEndDate
      }
      
    }
    validatedData.forEach(x => {
      if(x.contractId.length > 0){
        x.projectEndDate = projectDates[x.contractId][x.projectName] as string
      }
    } )
  },
  getMinOrMaxDateRange:(key: string,period:string | null): string => {
    const locale = i18n.language
    const monthNums: { [key: string]: number } = {
    };
    let monthValues = 1
    for (let index = 0; index < 12; index++) {
      
      if(!monthNums[`${new Date(2020,index).toLocaleDateString("sv-SE",{month:'long'}).toLowerCase()}`]){
        monthNums[`${new Date(2020,index).toLocaleDateString("sv-SE",{month:'long'}).toLowerCase()}`] = monthValues
      }
      monthValues++
    }
    let year: string | undefined = undefined
    if (typeof period == 'string'){
      year = period.split("-")[1];
    }
    if (typeof year !== "undefined") {
      const period2 =period?.split("-")[0] as string 
      const dateMax = new Date(
        +year,
        monthNums[period2.toLowerCase()],
        0
      ).getDate();
      
      const month = monthNums[period2.toLowerCase()];
      return key == "min"
        ? `${year}-${month < 10 ? "0" + month : month}-01`
        : `${year}-${month < 10 ? "0" + month : month}-${dateMax}`;
    } else {
      return "";
    }
  },
  handleHistoricProjectEndDates:(
    values: GroupedProjectNameAndEndDate,
    contracts: any[]
  ): GroupedProjectNameAndEndDate => {
    const result: GroupedProjectNameAndEndDate = {};
    contracts.reduce((result: GroupedProjectNameAndEndDate, item: any) => {
      if (!result[item.contractId]) {
        result[item.contractId] = {};
      }
      return result;
    }, result);
    if (Object.keys(values).length > 0) {
      Object.entries(values).forEach(([key, value]) => {
        if (!result[key]) {
          result[key] = {};
        } else {
          result[key] = value;
        }
      });
    }
    return result;
  },
  createContractDict:(values: any[]): ContractDict => {
    const result: ContractDict = {};
    values.reduce((result: ContractDict, item: any) => {
      if (!result[item.contractId]) {
        const contract: Contract = {
          kickbackPercent: item.kickbackPercent,
          name: item.name,
          title: item.title,
        };
        result[item.contractId] = contract;
      }
      return result;
    }, result);
    return result;
  },
  createCustomerDict:(values: any[]): CustomerDict => {
    const result: CustomerDict = {};
  
    values.reduce((result: CustomerDict, item: any) => {
      if (!result[item.customerOrgId]) {
        result[item.customerOrgId] = {customerOrgId:item.customerOrgId,customerName:item.customername,customerId:item.customerId};
      }
      return result;
    }, result);
    return result;
  },
  buildReportPeriods:(): string[] => {   
    const locale = i18n.language
    const dateToday = new Date()
    const periods:string[] = []    
    for (let index = 5; index > 0; index--) {
      const pastDateThatHauntsMySpirit = new Date(dateToday.getFullYear(),dateToday.getMonth() - index)      
      periods.push(`${pastDateThatHauntsMySpirit.toLocaleDateString(locale,{month:'long'}).toLowerCase()}-${pastDateThatHauntsMySpirit.getFullYear()}`)     
    }
    periods.push(`${dateToday.toLocaleDateString(locale,{month:'long'}).toLowerCase()}-${dateToday.getFullYear()}`)
    return periods
    
    

  },
  activeFilterRow:(activeFilter:Readonly<Filter>,period:Readonly<string>,data:Period): ProjectInvoice[] => {

    const expressions:Expression[] = []
    Object.entries(activeFilter).forEach(([key,value]) => {
        if(value){
          switch(key){
            case 'duplicates': expressions.push({
             key:key,
             propertys:['isDuplicate'],
             //opertaion:'equal',
             value:true
           })
           break;
           case 'errors': expressions.push({
             key:key,
             propertys:['invoiceNumber','customerName' ,'customerOrgId' , 'contractId','totalExclMoms','billingDate','projectName'],
             //opertaion:'lenght == 0',
             value:true
           })
           break;
           case 'warnings':expressions.push({
             key:key,
             propertys:[/*'projectName',*/'totalExclMoms','tag','invoiceReference'],
             //opertaion:'valid equal false',
             value:false
           }) 
           break;
         }
        }
        
    })
    if(expressions.length > 0){
      const result = new Set<ProjectInvoice>()
    expressions.forEach(async (x:Expression) => {
      switch (x.key){
        case 'duplicates': 
        for (let index = 0; index < data[period].length; index++) {
          if(data[period][index].isDuplicate.value == x.value){
            result.add(data[period][index])
          }
        }        
          break;
        case 'errors': data[period].filter((q:any,val) => {
          Object.entries(q).forEach(([key,value]) => {
            let val:any = value
            if(x.propertys.includes(key)){
              if(key !== 'totalExclMoms' && val.length == 0){
                result.add(q)
              }
              else if(key == 'totalExclMoms' && q[key].error == true ){
               result.add(q)
              }
            }
          })
        })
          break;
        case 'warnings': data[period].filter((q:any,val) => {

          Object.entries(q).forEach(([key,value])=>{
            if(x.propertys.includes(key)){
              if(q[key].valid == x.value || q[key].length == 0){
                result.add(q)
              }
            } 
          })
        })
          break;
      }
    })
    if(result.size > 0){
    const buff:ProjectInvoice[] = []    
    result.forEach(x => buff.push(x))
    
      return buff
    }
    else{
      return []
    }
    }
    return []
  },
  validateSum:(sumToValidate: string): [boolean, string] => {
    if (
      sumToValidate[0] == "-" &&
      sumToValidate.match(/[1-9,.,\s]/) !== null &&
      !sumToValidate.match(/['a-öA-Ö']/)
    ) {
      return [true, "neg"];
    } else if (
      sumToValidate.match(/^-?[0-9,.,\s]+$/) &&
      sumToValidate.length > 0 &&
      !sumToValidate.match(/['a-öA-Ö']/)
    ) {
      return [true, "pos"];
    } else {
      return [false, "nan"];
    }
  }
  
};
const ProjectReportValidation =   (props: ProjectReportValidationParams,dataToValidate: Readonly<ProjectInvoice[]>
,reportingArticles:boolean): [ProjectInvoice[], ProjectReportValidationParams] => {
  
  let currentState: ProjectReportValidationParams = _helpers.deepClone(props);
  
  let input: ProjectInvoice[] = [...dataToValidate]//_helpers.deepClone(dataToValidate);
  
  try{
    for (let index = 0; index < input.length; index++) {
      // eslint-disable-next-line no-loop-func
      Object.entries(input[index]).forEach(([key, value]) => {
        if (_helpers.checkKey(key)) {
          validate(input[index], key, currentState);
        }
      });
    }
    for (let index = 0; index < input.length; index++) {
      const contractdId = input[index].contractId;
      const projectname = input[index].projectName;
      if(projectname.length > 0){
        const endDate = currentState['existingProjects'][contractdId]?.[projectname]
        input[index].projectEndDate =  endDate == null ? '': endDate as string
      }
      else{
        input[index].projectEndDate = ''
      }
     
    }

    return [input,currentState]   
  }catch(error){
    console.log(error)
    const errInp:ProjectInvoice[] = []
    return [errInp,currentState]   
  }
 
   
 
};

function validate(
  x: ProjectInvoice,
  key: keyof ProjectInvoice,
  curr: ProjectReportValidationParams
) {

  switch (key) {
    case "totalExclMoms": {
      _helpers.checkValidNumber(x, key);
      break;
    }

    case "customerName":
    case "customerOrgId": {
      checkValidCustomer(x, curr);
      break;
    }

    case "contractId": {
      validateContractId(x, key,curr);
      break;
    }
    case "reference":
    case "tag":
    case "projectName": {
      const res = validateOptions(x, key, curr, "existingProjects");
      curr.availableOptions[key as keyof ProjectOptions] = res[0];
      curr["existingProjects"] = res[1];
      break;
    }
    case "rowSumExclMoms":{
    
      _helpers.checkRowSumExcl(x,key)

    }
  }
}

function validateOptions(
  x: ProjectInvoice,
  key: keyof ProjectOptions,
  curr: ProjectReportValidationParams,
  keyForProj: keyof PropsKeys
): [string[], GroupedProjectNameAndEndDate] {
  x.projectName =  x.projectName.replace(/[(\r\n|\r|\n)]+/g,'')
  if (key == "projectName") {
    const res = _helpers.deepClone(curr.availableOptions[key]) as (typeof curr.availableOptions)[typeof key];
    const projectDates: GroupedProjectNameAndEndDate = _helpers.deepClone(curr[keyForProj]) as (typeof curr)[typeof keyForProj];
    if (typeof res.find((q) => q == x.projectName)?.valueOf() == "undefined") {
      res.push(x.projectName);
    }
    const reg = new RegExp(/\d{4}-\d{2}-\d{2}/);

    const correctFormat = x.projectEndDate.match(reg);
    const billingDateFormat = x.billingDate.match(reg);
    if(Object.keys(projectDates).length > 0 && typeof projectDates[x.contractId] !== 'undefined'){
      if (typeof projectDates[x.contractId][x.projectName] !== 'undefined') {
        projectDates[x.contractId][x.projectName] = correctFormat == null? typeof projectDates[x.contractId][x.projectName] !== "undefined"
              ? projectDates[x.contractId][x.projectName]
              : null
            : x.projectEndDate;
      } else if (
        !projectDates[x.contractId][x.projectName] &&
        correctFormat !== null
      ) { 
        projectDates[x.contractId][x.projectName] = x.projectEndDate;
      }
  
      x.projectEndDate =correctFormat !== null? x.projectEndDate: projectDates[x.contractId][x.projectName]?.toString() ?? "";
      x.billingDate = billingDateFormat !== null ? x.billingDate : ''
      return [res, projectDates];
    }
    x.projectEndDate = ""
    x.billingDate = billingDateFormat !== null ? x.billingDate : ''
    return [res,projectDates]
   
  } else {
    const res = _helpers.deepClone(
      curr.availableOptions[key]
    ) as (typeof curr.availableOptions)[typeof key];
    
    if (typeof res.find((q) => q == x[key])?.valueOf() == "undefined") {
      res.push(x[key]);
    }
    return [res, curr[keyForProj]];
  }
}

function validateContractId(
  x: ProjectInvoice,
  key:string,
  curr: Readonly<ProjectReportValidationParams>
) {
  
  if (typeof curr.validContracts[x["contractId"]] == "undefined") {
    x["contractId"] = "";
  } else {
    x["kick"] = _helpers.convertToPercentage(
      curr.validContracts[x["contractId"]].kickbackPercent
    );
  }
}
function checkValidCustomer(
  x: ProjectInvoice,
  curr: Readonly<ProjectReportValidationParams>
) {
    
    x.customerOrgId = x.customerOrgId.replace(/[-._!"`'#%&,:;<>=@{}~$()*+/\\?[\]^|(\r\n|\r|\n)]+/g,'')
    if (typeof curr.validCustomers[x["customerOrgId"]] == "undefined") {
    x["customerName"] = "";
    x["customerOrgId"] = "";
  }
}
function generateRgb():string{
  const minCeiled = Math.ceil(1);
  const maxFloored = Math.floor(255);

  Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled)
  return `rgb(${getStringNum()},${getStringNum()},${getStringNum()},0.2)`
}
function getStringNum():number{
  const minCeiled = Math.ceil(1);
  const maxFloored = Math.floor(255);

 return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled)
}
interface OverlayProps {
  val:boolean;
}

const OverLay = ({val}:OverlayProps): JSX.Element => {

    const [internalState,setState] = React.useState(false)
    React.useEffect(() => {setState(val)},[val])
      return (
    <>
   
      {internalState ? (
        <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          color: "white",
          backgroundColor: "rgba(0, 0, 0, 0.685)",
          width: "100%",
          height: "100vh",
          top: 0,
          left: 0,
          zIndex: 9998,
          overflowX: "hidden",
          overflowY: "hidden",
        }}
        >
          <Spinner />
        </div>
      ) : (
        <></>
        )}
        
    </>
  );
};

export { ProjectReportValidation, _helpers, OverLay };
