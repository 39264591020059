/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
import * as React from "react";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
  Cell,
} from "recharts";
import { IDashboardExpectedReports } from "../../services/API/DashBoard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner, Stack } from "react-bootstrap";

interface IExpectedReportsBarChartProps {
  data: any;
  onClick: (data: any) => void;
  onClickForward: () => void;
  onClickBackward: () => void;
  yearFilter: string[];
  periodIndex: number;
  loading:boolean;
}
interface IExpectedReportsBarChartState {
  data: IDashboardExpectedReports[];
  loading: boolean;
  width: number;
  height: number;
}
class ExpectedReportsBarChart extends React.Component<
  IExpectedReportsBarChartProps,
  IExpectedReportsBarChartState
> {
  constructor(props: IExpectedReportsBarChartProps) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  async componentDidMount(): Promise<void> {
    window.addEventListener("resize", this.HandleRezise);
    this.setState({ /*data: res,*/ loading: false });
  }
  componentWillUnmount(): void {
    window.removeEventListener("resize", this.HandleRezise);
  }
  HandleRezise = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  YAxisTickLabel: any = (value: any, index: any): any => {
    const date = new Date(value);
    const he = date.toLocaleDateString(i18n.language, {
      year: "2-digit",
      month: "short",
    });
    return he as string;
  };
  CustomLegend: any = (payload: any): any => {
    if (payload && typeof payload.payload !== "undefined") {
      const dataSetLeft = payload.payload.slice(0,3)
      const dataSetRight = payload.payload.slice(3,2 + payload.payload.length)
      return (
        <div
          className="dashboardChartsscontainerTable"
          style={{ display: "flex", flex: 1,flexWrap:'wrap',flexDirection:'row',margin:'0px 11px 0px 60px'}}
        >
          <div style={{ flex: '1 1 33%', minWidth: '250px' }}>
          <span style={{textAlign:'left'}}>
          {dataSetLeft.map((entry: any, index: any) => (
               
                
                 <p style={{margin:0,fontSize:'small'}}> <FontAwesomeIcon
                    style={{ color: entry.color, paddingRight: 5 }}
                    icon={"square"}
                    />
                   {i18n.t(`dashboard.${entry.dataKey}`)}
                   </p>
                  
               
              ))}
            
          </span>
          </div>
          <div style={{ flex: '1 1 33%', minWidth: '250px' }}>
          <span style={{textAlign:'left'}}>
              {dataSetRight.map((entry:any,index:any) => (
                
                
                <p style={{margin:0,fontSize:'small'}}> <FontAwesomeIcon
                   style={{ color: entry.color, paddingRight: 5 }}
                   icon={"square"}
                   />
                  {i18n.t(`dashboard.${entry.dataKey}`)}
                  </p>
                 
                 
              ))

              }
              </span>
              </div>
        </div>
      );
    }
  };
  CustomToolTip: any = (active: any, payload: any, label: any): any => {
    if (typeof active.payload[0] !== "undefined" && active.payload !== null) {
      return (
        <div
          style={{
            borderRadius: "5px",
            backgroundColor: "#fcfbfa",
            textAlign: "center",
            padding: "5px"
          }}
        >
          <b>{i18n.t("dashboard.summary")}</b>
          <table style={{
            textAlign: 'start',
            borderSpacing: '4px',
            borderCollapse: 'separate'
            }}>
            <thead>
              <th></th>
              <th></th>
            </thead>
            <tbody>
              <tr>
                <td>{i18n.t("dashboard.period")} :</td>
                <td>{active.payload[0].payload.period}</td>
              </tr>
              <tr>
                <td>{i18n.t("dashboard.invoiceReports")} :</td>
                <td>{active.payload[0].payload.toolTipData.invoiceReports - active.payload[0].payload.toolTipData.csrdReports}</td>
              </tr>
              <tr>
                <td>{i18n.t("dashboard.nullReports")} :</td>
                <td>{active.payload[0].payload.toolTipData.nullReports}</td>
              </tr>
              <tr>
                <td>{i18n.t('dashboard.csrdReports')}</td>
                <td>{active.payload[0].payload.toolTipData.csrdReports}</td>
              </tr>
              <tr>
                <td>{i18n.t("dashboard.totalReported")} :</td>
                <td>{active.payload[0].payload.toolTipData.nullReports + active.payload[0].payload.toolTipData.invoiceReports}</td>
              </tr>
              <tr>
                <td>{i18n.t("dashboard.reportsExpected")} :</td>
                <td>{active.payload[0].payload.toolTipData.reportsExpected}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  };
  CustomXaxisLabels: any = (props: any): any => {
    const { x, y, stroke, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          style={{ fontSize: "small" }}
          textAnchor="end"
          fill="#666"
        >
          {payload.value}
          {"%"}
        </text>
      </g>
    );
  };
  CustomBarLabels: any = (props: any): JSX.Element => {
    let { x, y, width, height,style, value } = props;
    const fireOffset = value.toString().length < 5;
    const offset = fireOffset ? -40 : 5;
    style['paddingRight'] = '5px'
    style['fontSize'] = "14px"
    let counter = 10
    if (value <= 10 ) {
      
      return (
      <p></p>
      );
    } 
     else {
      return (
        <text
          x={x - offset}
          y={y + height - 5}
          style={{ fontSize: "14px" }}
          fill={"#fff"}
          textAnchor="end"
        >
          {value}%
        </text>
      );
    }
  };
  render() {
    return (
      <div >
        {this.props.loading && <Spinner/>}
        <div>
        {!this.props.loading && (
          <div
            style={{
              // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              // display: "flex",
              // flex: 1,
            }}
          >
            <Stack>
            {this.props.periodIndex !== -1 && (
                <Stack
                  style={{
                    marginTop:10,
                    justifyContent: "center",
                  }}
                  direction="horizontal"
                >
                  <FontAwesomeIcon
                    onClick={() => this.props.onClickBackward()}
                    style={
                      this.props.periodIndex === 1
                        ? { color: "#dddd" }
                        : { color: "rgb(58, 165, 50,0.8)", cursor: "pointer" }
                    }
                    size="xl"
                    icon={"arrow-alt-circle-left"}
                  />
                  <div
                    style={{
                      borderRadius: "5px",
                      border: "2px solid rgb(225, 225, 225)",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      marginRight: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    {" "}
                    {this.props.yearFilter[this.props.periodIndex - 1]}
                  </div>
                  <FontAwesomeIcon
                    onClick={() => this.props.onClickForward()}
                    style={
                      this.props.periodIndex <= this.props.yearFilter.length - 1
                        ? { color: "rgb(58, 165, 50,0.8)", cursor: "pointer" }
                        : { color: "#dddd" }
                    }
                    size="xl"
                    icon={"arrow-alt-circle-right"}
                  />
                </Stack>
              )}
              <BarChart
                width={
                  this.state.width <= 600
                    ? this.state.width / 1.2
                    : this.state.width / 1.8
                }
                height={this.state.height <= 800 ? this.state.height - 50 :this.state.height / 1.8}
                layout="vertical"
                data={this.props.data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 20,
                }}
                barGap={8}
              >
                <XAxis
                  tick={this.CustomXaxisLabels}
                  dataKey={"reportsExpected"}
                  type="number"
                />
                <YAxis
                  orientation="left"
                  type="category"
                  tickFormatter={this.YAxisTickLabel}
                  dataKey={"period"}
                />

                <Tooltip content={this.CustomToolTip} />
                <Legend
                  content={this.CustomLegend}
                  verticalAlign="bottom"
                  align="center"
                  margin={{ top: 0, left: 100, right: 0, bottom: 0 }}
                />
                <Bar dataKey={"fullyReported"} stackId={"a"} fill="#07a235"/>
                <Bar
                 
                  dataKey="invoiceReports"
                  stackId={"a"}
                  style={{ opacity: "0.8", cursor: "pointer" }}
                  fill="#009ee1"
                  onClick={(e) => this.props.onClick(e.period)}
                >
                  <LabelList
                    dataKey="invoiceReports"
                    content={ this.CustomBarLabels}
                    position="insideTop"
                    style={{ fill: "white" }}
                    
                  />
                </Bar>
                <Bar
                  dataKey="nullReports"
                  //barSize={40}
                  
                  stackId={"a"}
                  style={{ opacity: "0.8", cursor: "pointer" }}
                  fill="#f7af32"
                  onClick={(e) => this.props.onClick(e.period)}
                >
                  <LabelList
                    dataKey="nullReports"
                    content={this.CustomBarLabels}
                    position="insideTop"
                    style={{ fill: "white" }}

                  />
                </Bar>
                <Bar
                  dataKey="reportsExpected"
                  stackId="a"
                  style={{ opacity: "0.8", cursor: "pointer" }}
                  fill="#dddd"
                  onClick={(e) => this.props.onClick(e.period)}
                />
                <Bar 
                dataKey="csrdReports" 
                stackId="a" 
                style={{ opacity: "0.8", cursor: "pointer" }} 
                fill="#eb5120"
                onClick={(e) => this.props.onClick(e.period)}
                >
                   <LabelList
                    dataKey="csrdReports"
                    content={this.CustomBarLabels}
                    position="insideTop"
                    style={{ fill: "white" }}

                  />
                </Bar>
              </BarChart>
            </Stack>
          </div>
        )}
        </div>
      </div>
    );
  }
}
export default withTranslation()(ExpectedReportsBarChart);
