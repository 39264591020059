import Urls from "../../lib/Urls";
import { Code, ReturnFetchObj, apiFetch, convertToQuerystring } from "../../lib/utils";
import { t } from 'i18next'
import { Department } from "./Departments";

export interface NullReport {
  period:string;
  supplierOrgId:string;
  reportedBy:string;
  reportedTime:Date;
  id:number;
  status:number;
  departmentId?:number;
}

export type NewNullReport = Pick<NullReport, "period"|'departmentId'>

const Get = async (opt?: {year?:string, month?:string, supplier?: string}) : Promise<NullReport[]> => {
  const queryString = convertToQuerystring(opt);
  const url = queryString.length > 0 ? `${Urls.NullReport}?${queryString}` : Urls.NullReport;
  const response = await apiFetch(url);
  const nullReports = await response.json();
  return nullReports.map((nReport:any) => {
    const report : NullReport = {
      ...nReport,
      id: parseInt(nReport.id),
      reportedTime: new Date(nReport.reportedTime)
    }
    return report;
  });
}

const Post = async (nullReport: NewNullReport) => {
  try {
    const response = await apiFetch(Urls.NullReport, "POST", JSON.stringify({period: nullReport.period,departmentId:nullReport.departmentId}), {"Content-Type": "application/json"});
    return ReturnFetchObj(t('api.nullreport.postSuccess'), Code.OK, {nullReport});
  } catch (error) {
    console.error(error);
    return ReturnFetchObj(t('api.nullreport.postFail'), Code.ADD_ERR, {nullReport});
  }
}
const UpdateStatus = async(nullReport:NullReport) => {
  try{
    //console.log(nullReport)
   // const queryOb = convertToQuerystring(nullReport)
    const query:string = `${Urls.NullReport}/updateNullreportStatus?` 
    const res = await apiFetch(query,'PUT',JSON.stringify(nullReport),{"Content-Type": "application/json"})
    return ReturnFetchObj(t('api.kickback.updateSuccess'),Code.OK,{res})
  }catch(error){
    return ReturnFetchObj(t('api.nullreport.postFail'), Code.UPDATE_ERR, {nullReport});
  }
}



export {
  Post,
  Get,
  UpdateStatus
}