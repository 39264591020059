import * as React from 'react';
import { StyledModal } from './StyledModal';
import { WithTranslation, withTranslation } from 'react-i18next';
import Datalist, { ListColumn } from '../Datalist/Datalist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { DataService } from '../../services/DataService';
import { Spinner } from 'react-bootstrap';

export interface ITemplateListModalProps extends WithTranslation {
  onDismiss():void;
}

export interface ITemplateListModalState {
  columns: ListColumn<ListFileItem>[];
  items: ListFileItem[];
  loadingIndexes: number[];
}

interface ListFileItem {
  name: string;
}

class TemplateListModal extends React.Component<ITemplateListModalProps, ITemplateListModalState> {
  constructor(props: ITemplateListModalProps) {
    super(props);

    this.state = {
      loadingIndexes: [],
      items: [],
      columns: [
        {
          fieldName: "name",
          isActive: true,
          key: "name",
          minWidth: 100,
          name: this.props.t('templateListModal.fileName')
        },
        {
          fieldName: "download",
          isActive: true,
          key: "download",
          minWidth: 40,
          maxWidth: 40,
          name: "",
          onRender: (item, col, i) => {
            if(this.state.loadingIndexes.includes(i))
              return <Spinner style={{width: 20, height: 20, color: "#00cdff"}}/>
            return  <div style={{textAlign: "center"}}><FontAwesomeIcon icon={faFileDownload}/></div>
          }
        }
      ]
    }
  }

  async componentDidMount() {
    const templates = await DataService.InvoiceReports.GetTemplates();
    const items = templates.map(name => ({name}));
    this.setState({ items });
  }

  public render() {
    return (
      <StyledModal 
        title={this.props.t('templateListModal.title').toString()} 
        onDismiss={this.props.onDismiss}
        className='templateModal'
        body={
        <Datalist 
          items={this.state.items} 
          columns={this.state.columns} 
          setColumns={(cols) => { this.setState({ columns: cols }) }} 
          noItemsText={this.props.t('templateListModal.noItems')} 
          uniqueKey={'name'}
          onClick={async (item, i) => {
            this.setState({ loadingIndexes: this.state.loadingIndexes.concat(i) });
            await DataService.InvoiceReports.DownloadTemplate(item.name);
            this.setState({ loadingIndexes: this.state.loadingIndexes.filter(n => n != i) });
          }}  
        />
      }
        />
    );
  }
}

export default withTranslation()(TemplateListModal)