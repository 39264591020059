import * as React from 'react';
import { Modal } from 'react-bootstrap';

export interface IStyledModalProps {
  title:string | JSX.Element;
  onDismiss():void;
  body?:JSX.Element;
  footer?:JSX.Element;
  className?: string;
  headerStyle?: React.CSSProperties;
}

export function StyledModal (props: IStyledModalProps) {
  
  return (
    <Modal show={true} onHide={props.onDismiss} className='styled-modal' dialogClassName={props.className}>
      <Modal.Header closeButton style={props.headerStyle}>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      {
        props.body &&
        <Modal.Body>
          {
            props.body
          }
        </Modal.Body>
      }
      {
        props.footer &&
        <Modal.Footer>
        {
          props.footer
        }
      </Modal.Footer>
      }
      
    </Modal>
  );
}
