import { faAdd, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button, Stack } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TNews } from '../../services/API/News';
import { DataService } from '../../services/DataService';
import NewsList from './NewsList';
import { IsUserNewsEditor, IsUserSinfra } from '../../lib/utils';
import Datalist, { ListColumn } from '../Datalist/Datalist';
import i18n from '../../i18n';

export interface INewsProps extends WithTranslation {
  numberOfNews?:number;
  sortBy?:"Updated" | "Published";
  showAllNewsButton?:boolean;
  listMode?:boolean;
}

export interface INewsState {
  news: TNews[];
  isLoading: boolean;
  columns: ListColumn<TNews>[];
}

class News extends React.Component<INewsProps, INewsState> {
  constructor(props: INewsProps) {
    super(props);

    this.state = {
      isLoading: true,
      news: [],
      columns: [
        {
          key: "title",
          fieldName: "title",
          name: this.props.t("news.list.title").toString(),
          minWidth: 200,
          maxWidth: 300,
          isActive: true,
          sorting: true
        },
        {
          key: "ingress",
          fieldName: "ingress",
          name: this.props.t("news.list.ingress").toString(),
          minWidth: 100,
          maxWidth: "50vw",
          isActive: true,
          onRender: (item, col) => <div style={{margin: "10px", overflow: "hidden", maxHeight: "50px", display:"flex"}} dangerouslySetInnerHTML={{__html: item.ingress}}></div>
        },
        {
          key: "created",
          fieldName: "created",
          name: this.props.t("news.list.created").toString(),
          minWidth: 160,
          maxWidth: 160,
          isActive: IsUserNewsEditor(),
          sorting: true,
          onRender: (item ,col) => item.created.toLocaleDateString(undefined, { year: "numeric", month: "2-digit", day: "2-digit", hour:"2-digit", minute: "2-digit" })
        },
        {
          key: "updated",
          fieldName: "updated",
          name: this.props.t("news.list.updated").toString(),
          minWidth: 160,
          maxWidth: 160,
          isActive: IsUserNewsEditor(),
          sorting: true,
          onRender: (item ,col) => item.updated.toLocaleDateString(undefined, { year: "numeric", month: "2-digit", day: "2-digit", hour:"2-digit", minute: "2-digit" })
        },
        {
          key: "publishedFrom",
          fieldName: "publishedFrom",
          name: this.props.t("news.list.publishedDate").toString(),
          minWidth: 160,
          maxWidth: 160,
          isActive: true,
          sorting: true,
          onRender: (item ,col) => DataService.News.isPublished(item) == true ? item.publishedFrom.toLocaleDateString(undefined, { year: "numeric", month: "2-digit", day: "2-digit", hour:"2-digit", minute: "2-digit" }) : ""
        }
      ]
    }
  }

  async componentDidMount(): Promise<void> {
    const news = (await DataService.News.Get()).filter(x => x.lang == i18n.language && x.title.length > 0);
    
    if(this.props.sortBy == "Published")
      news.sort((a,b) => a.publishedFrom.getTime() < b.publishedFrom.getTime() ? 1 : -1);
    else if(this.props.sortBy == "Updated")
      news.sort((a,b) => a.updated.getTime() < b.updated.getTime() ? 1 : -1);

    this.setState({
      isLoading: false,
      news: this.props.numberOfNews ? news.slice(0, this.props.numberOfNews) : news
    });
  }

  public render() {
    return (
      <Stack className='news' gap={2}>
        <div className='news-title-wrapper'>
          <div className='news-title'>{this.props.t('general.news')}</div>
          {
            IsUserNewsEditor() &&
            <Button 
              className="btn"
              onClick={() => { window.location.href = "/news/create" }}
              >
              <><FontAwesomeIcon icon={faAdd} style={{marginRight: 8}}/>{this.props.t('news.createNews')}</>
            </Button>
          }
        </div>
        
        {
          this.props.listMode ? 
          <Datalist 
            uniqueKey='id'
            items={this.state.news} 
            columns={this.state.columns} 
            setColumns={(newCols) => this.setState({columns: newCols})} 
            isLoading={this.state.isLoading}
            initialSortKey={'publishedFrom'}
            onClick={(item) => { window.location.href = `/news/view/${item.newsArticleId}` }}
            noItemsText={this.props.t('news.emptyItem')}
          />
          :
          <NewsList news={this.state.news} isLoading={this.state.isLoading}/>
        }
        
        {
          this.props.showAllNewsButton &&
          <Button
            className="btn button-cancel"
            onClick={() => { window.location.href = "/newslist" }}
            >
            <><FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{marginRight: 8}}/>{this.props.t('news.moreNews')}</>
          </Button>
        }
      </Stack>
    );
  }
}


export default withTranslation()(News);