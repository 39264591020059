import React, { useEffect, useState } from 'react';
import { DataService } from '../services/DataService';
import Datalist, { FooterOptions, ListColumn } from '../components/Datalist/Datalist';
import { TUser } from '../services/API/Users';
import { Button, Col, Form, FormLabel, Row, Spinner, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { StyledModal } from '../components/modal/StyledModal';
import { Code, apiFetch } from '../lib/utils';
import { InfoModal } from '../components/modal/InfoModal';


interface Props  {

}


function UserPage (props: Props){
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Required<TUser>|null>(null);
  const [statusConfirmModal, setStatusConfirmModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");
  const [successText, setSuccessText] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const {t} = useTranslation()

  const { id } = useParams();

  useEffect(() => {
    if(typeof id != "string"){
      console.error("id is not valid: "+id);
      window.location.href = "/";
      return 
    }
    DataService.Users.GetById(id)
    .then(user => {
      setUser(user);
      setIsLoading(false);
    })
    .catch(err => {
      console.log(err);
    })
  }, [])

  const renderField = (label:string, value:string|undefined) => {
    return <>
      <div className='user-page-field-label'>{label}</div>
      <div className='user-page-field-value'>{value}</div>
    </>
  }

  const renderButtons = () => {
    return <div className='user-page-tools'>
      <Button onClick={() => setResetPasswordModal(true)}>{t("general.resetPassword")}</Button>
      <Button className={buttonCss} onClick={() => setStatusConfirmModal(true)}>{activateText}</Button>
      <Button className='button-cancel' onClick={() => window.history.go(-1)}>{t("general.back")}</Button>
    </div>
  }

  const submitStatusChange = async () => {
    if(user){
      setIsSaving(true);
      const result = await DataService.Users.ChangeUserStatus(user.id, !user.enabled);
      if(result.Code == Code.OK){
        setUser({...user, enabled: !user.enabled});
        setStatusConfirmModal(false);
        setIsSaving(false);
      }
      else{
        setIsSaving(false);
        setErrorMessage(result.Message)
      }
    }
  }

  const submitResetPassword = async () => {
    if(user){
      setIsSaving(true);
      const result = await DataService.Users.ResetPassword(user.id);
      if(result.Code == Code.OK){
        setResetPasswordModal(false);
        setIsSaving(false);
        setSuccessText(result.Message);
      }
      else{
        setIsSaving(false);
        setErrorMessage(result.Message);
      }
    }
  }

  const dismissResetPasswordModal = () => {
    setResetPasswordModal(false);
    setErrorMessage("");
  }

  const dismissToggleStatusModal = () => {
    setStatusConfirmModal(false);
    setErrorMessage("");
  }

  const activeText = user?.enabled ? t('general.active') : t('general.inactive');
  const activateText = user?.enabled ? t('general.deactivate') : t('general.activate');
  const buttonCss = user?.enabled ? "button-danger" : "button-submit";
  const activeIcon = user?.enabled ? faCircleCheck : faCircleXmark;
  const modalButtonIcon = user?.enabled ? faCircleXmark : faCircleCheck;
  const iconCss = user?.enabled ? "user-page-active" : "user-page-inactive";
  const isSmallScreen = window.matchMedia("(max-width: 700px)").matches;
  return (
    <Stack gap={4} className='user-page'>
      {
        isLoading &&
        <Spinner />
      }
      {
        !isLoading &&
        <div>
          
          <div className='user-page-titlewrapper'>
            <div className='user-page-title'>
              {t("userPage.title")} 
            </div> 
            {
              !isSmallScreen &&
              renderButtons()
            }
          </div>
          {/* <div className='user-page-subtitle'>
            <div><FontAwesomeIcon icon={activeIcon} className={iconCss} />{activeText}</div>
          </div> */}
          <Stack style={{marginTop:8}}>
            <Row >
              <Col>
                {renderField(t("general.email"), user?.email)}
              </Col>
              <Col>
                <div className='user-page-field-label'>{t("general.status")}</div>
                <div className='user-page-field-value'><FontAwesomeIcon icon={activeIcon} className={iconCss} />{activeText}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                {renderField(t("general.firstname"), user?.firstName)}
              </Col>
              <Col>
                {renderField(t("general.lastname"), user?.lastName)}
              </Col>
            </Row>
            <Row>
              <Col>
                {renderField(t("general.supplier"), user?.SupplierGroups.length ? (user?.SupplierGroups[0].supplierName + " - " + user?.SupplierGroups[0].supplierOrgNr) : "")}
              </Col>
              <Col>
                {renderField(t("general.created"), user?.created.toLocaleDateString())}
              </Col>
            </Row>
          
            {/* <div style={{marginBottom:8}}>
              <div className='user-page-field-label' >{t("general.supplier")}</div>
              <Stack gap={3}>
                {
                  user?.SupplierGroups.map(group => {
                    return <div className='user-page-field-value'>
                      {group.name + " - " + group.SupplierID}
                      </div>
                  })
                }
              </Stack>
            
            </div> */}
            {
              isSmallScreen &&
              renderButtons()
            }
          </Stack>
        </div>
      }
      {
        statusConfirmModal &&
        <StyledModal 
          title={t("userPage.toggleStatusModalTitle").toString()} 
          onDismiss={() => dismissToggleStatusModal()} 
          body={
            <div>
              <p>
                {
                  user?.enabled ?
                  t("userPage.toggleStatusModalBodyActive")
                  :
                  t("userPage.toggleStatusModalBodyInactive")
                }
              </p>
            {
              errorMsg &&
              <div className='error-message'>
                <span>{t('general.errorMsg')}:</span>
                {errorMsg}
              </div>
            }
            </div>
          }
          footer={
            <Stack direction="horizontal" gap={2}>
              <Button disabled={isSaving} className={buttonCss} onClick={submitStatusChange}>
                {
                  isSaving ?
                  <Spinner animation="border" size={'sm'}/>
                  :
                  <><FontAwesomeIcon icon={modalButtonIcon} style={{marginRight: 4}}/>{activateText}</>
                }
              </Button>
              <Button disabled={isSaving} className="button button-cancel" onClick={() => dismissToggleStatusModal()}>{t('general.close')}</Button>
            </Stack>
          }
        />
      }
      {
        resetPasswordModal &&
        <StyledModal
          onDismiss={() => dismissResetPasswordModal()}
          title={t("general.resetPassword").toString()}
          body={
            <div>
              <p>
                {
                  t("userPage.resetPasswordModalBody")
                }
              </p>
            {
              errorMsg &&
              <div className='error-message'>
                <span>{t('general.errorMsg')}:</span>
                {errorMsg}
              </div>
            }
            </div>
          }
          footer={
            <Stack direction="horizontal" gap={2}>
              <Button disabled={isSaving}  onClick={submitResetPassword}>
                {
                  isSaving ?
                  <Spinner animation="border" size={'sm'}/>
                  :
                  <>{t("general.send")}</>
                }
              </Button>
              <Button disabled={isSaving} className="button button-cancel" onClick={() => dismissResetPasswordModal()}>{t('general.close')}</Button>
            </Stack>
          }
         />
      }
      {
        successText &&
        <InfoModal 
          bodyText={successText}
          title={t("userPage.infoModalSuccess")}
          onDismiss={() => setSuccessText("")}
        />
      }
    </Stack>
  );
 
};

export default UserPage;