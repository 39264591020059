import Urls from "../../lib/Urls";
import { Code, ReturnFetchObj, apiFetch, convertToQuerystring } from "../../lib/utils";

export interface Logs{
    id:number;
    timeReported:Date;
    logLevel:string;
    logType:string;
    logText:string;
    batchId:string;
}


const GetLogsByBatchId = async (batchId:string): Promise<Logs[]> => {
   
    const url = Urls.Logs + `/batchId?batchId=${batchId}`
    const response = await apiFetch(url);
    const jsonLogs = await response.json();
    const result:Logs[] = jsonLogs.map((x:any) => {
        return x as Logs
    }) 
    return result
}

export{
GetLogsByBatchId
}