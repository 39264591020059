import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { ColumnDef } from "@tanstack/react-table";

import EditableTable, { GroupedCol } from "../EditableTable";
import FileUploadInput from "../FileUploadInput";
import { InvoiceImportData, InvoiceFields, InvoiceKeys, InvoiceGroupHeaders } from "../../services/API/InvoiceReports";
import { Button, Overlay, Spinner, Stack, Tooltip } from "react-bootstrap";
import Field from "../fields/Field";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faCheck, faCheckCircle, faCircleCheck, faClone, faFolder, faPaperPlane, faPlus, faTrash, faWarning } from "@fortawesome/free-solid-svg-icons";
import { DataService } from "../../services/DataService";
import { Customer } from "../../services/API/Customer";
import { StyledModal } from "../modal/StyledModal";
import { SubmitInvoiceReportModal } from "../modal/SubmitInvoiceReportModal";
import StyledCheckbox from "../StyledCheckbox/StyledCheckbox";
import { IsUserSinfra, ReadFriendlyNumber, StringToNumber } from "../../lib/utils";
import TemplateListModal from "../modal/TemplateListModal";
import { Infobar } from "../Infobar/Infobar";
import { Department } from "../../services/API/Departments";



interface Props extends WithTranslation {
  onSubmittedReport():void;
}

interface State {
  error?: Error,
  invoices: InvoiceFields[]
  AreInvoicesValid: boolean;
  customers:Customer[];
  isLoading: boolean;
  showSubmitModal:boolean;
  showSuccessModal:boolean;
  successMsg:string;
  showFileImportModal:boolean;
  filterWarnings: boolean;
  filterErrors: boolean;
  filteredInvoices: InvoiceFields[];
  showNullReportModal: boolean;
  templateListModal:boolean;
  showClearModal: boolean;
  canActiveSupplierReport:boolean;
  elevatedErrorMsg: string;
  department?:Department;
  collapsedGroups:number[];
  reportingArticles:boolean;
}

export interface InvoiceGroup extends InvoiceFields {
  items:InvoiceFields[];
}

class InvoiceReport extends React.Component<Props, State> {
  
  constructor(props: Props) {
        super(props);

        this.state = {
          invoices: [],
          AreInvoicesValid: false,
          customers: [],
          isLoading: true,
          showSubmitModal: false,
          showSuccessModal: false,
          successMsg: "",
          showFileImportModal: false,
          showNullReportModal: false,
          filterErrors: false,
          filterWarnings: false,
          filteredInvoices: [],
          templateListModal: false,
          showClearModal: false,
          canActiveSupplierReport: false,
          elevatedErrorMsg: "",
          collapsedGroups: [],
          reportingArticles: false
        }
    }

    async componentDidMount() {
     
    
      const [,,,canReport,isTester,dep] = await Promise.all([
          DataService.Customers.Init({
            select:["isSupplier", "kndOrgnr", "kndNamn", "kndNamnKey", "kndLopnr"], 
            filter: "isSupplier eq false"
          }),
          DataService.Contracts.Init(true),
          DataService.Kickback.GetReportedPeriods(),
          DataService.InvoiceReports.CanActiveSupplierReport(),
          DataService.InvoiceReports.IsActiveSupplierTestingReport(),
          DataService.Departments.GetUserAssignedDepartment()
        ]);
        console.log("InvoiceReport - didMount")
        const savedInvoices = DataService.InvoiceReports.LoadFromLocalstorage();
        if(typeof dep.Content.response?.department !== 'undefined'){
          savedInvoices.forEach(x => this.separatedDepartmentUpdateRowProp(x))
        }
        this.setInvoices(savedInvoices, false);
        this.setState({ isLoading: false, canActiveSupplierReport: canReport,department:dep.Content.response,reportingArticles: isTester  });
        
    }

    private columns: ColumnDef<InvoiceFields>[] = [
      {
        id: "customer",
        header: () => this.props.t('invoiceReport.table.headers.customer'),
        cell: (props) => this._cell(props.row.original, "customer"),
        minSize: 100,
        footer: (info) => {
          return <>{this.props.t('invoiceReport.table.footers.noOfRows')} {this.state.invoices.length}</>
        },
      },
      {
        id: "customerOrgId",
        header: () => this.props.t('invoiceReport.table.headers.customerOrgId'),
        minSize: 100,
        cell: (props) => this._cell(props.row.original, "customerOrgId")
      },
      {
        id: 'contractId',
        header: () => this.props.t('invoiceReport.table.headers.contractId'),
        minSize: 80,
        maxSize: 80,
        cell: (props) => this._cell(props.row.original, "contractId")
      },
      {
        id: 'invoiceNumber',
        header: () => this.props.t('invoiceReport.table.headers.invoiceNumber'),
        minSize: 100,
        maxSize: 100,
        cell: (props) => this._cell(props.row.original, "invoiceNumber")
      },
      {
        accessorKey: 'billingDate.formattedValue',
        header: () => this.props.t('invoiceReport.table.headers.date'),
        minSize: 80,
        maxSize: 80,
        cell: (props) => this._cell(props.row.original, "billingDate")
      },
      {
        id: "sumExclMoms",
        header: () => this.props.t('invoiceReport.table.headers.sum'),
        minSize: 80,
        cell: (props) => {
          if(!this.state.reportingArticles)
            return this._cell(props.row.original, "rowSumExclMoms")
          return <div className="disabled-cell">
            {
              ReadFriendlyNumber(
                this.state.invoices
                .filter(inv => inv.groupId == props.row.original.groupId)
                .reduce((prev, curr) => {
                  return prev + StringToNumber(curr.rowSumExclMoms.value)
                }, 0)
              )
            }
          </div>
        },
        footer: (info) => {
          const sum = this.state.invoices.reduce((prev, curr, i, self) => {
            const value = curr.rowSumExclMoms.value.replaceAll(" ", "").replaceAll(',',".");
            return prev + parseFloat(value)
          }, 0.0);
          return <>{this.props.t('invoiceReport.table.footers.totalSum')} {ReadFriendlyNumber(sum)}</>
        },
      },
      {
        id:"noOfArticles",
        header: () => this.props.t('invoiceReport.table.headers.noOfArticles'),
        minSize:40,
        maxSize:40,
        cell: (props) => {
          const groupItems = this.state.invoices.filter(inv => inv.groupId == props.row.original.groupId);
          return <div className="disabled-cell">
            {
                groupItems.length
            }
          </div>
        }
      },
      {
        id:"warnings/errors",
        header: () => "",
        minSize:25,
        maxSize:25,
        cell: (props) => {
          const groupItems = this.state.invoices.filter(inv => inv.groupId == props.row.original.groupId);
          const {errors,warnings} = DataService.InvoiceReports.Validations.GetValidationStatus(groupItems, this.state.reportingArticles);
          let icon = errors || warnings ? faWarning : faCircleCheck;
          let color = "#07a235";
          if(errors > 0) color = "#b30404";
          else if(warnings > 0) color = "#f8b133";
          return <>{
            <Stack direction="horizontal" gap={2} style={{height: "100%",justifyContent: "center"}}>
                <FontAwesomeIcon icon={icon} color={color} style={{height: "60%", marginRight: 6, marginLeft: 6}}/>
            </Stack>
          }</>
        }
      },
       {
        accessorKey: "none",
        header: () => this.props.t('invoiceReport.table.headers.tools'),
        maxSize: 50,
        minSize: 50,
        cell: (props) => {
          return <Stack direction="horizontal" style={{height:42, justifyContent:"space-evenly"}} >
          <Button 
              className="outline-button row-btn" 
              onClick={(ev) => {
                const item = props.row.original;
                const ind = this.state.invoices.findIndex(inv => inv.groupId == item.groupId);
                const copyItem : InvoiceImportData = {
                  id: -1,
                  groupId: this.findNewGroupId(this.state.invoices),
                  billingDate: "",
                  contractId: item.contractId.value,
                  customer: item.customer.value,
                  customerOrgId: item.customerOrgId.value,
                  invoiceNumber: "",
                  rowSumExclMoms: "",
                  articleQuantity: "",
                  articlePricePerUnit: "",
                  articleNumber: "",
                  articleText: "",
                  articleQuantityType: ""
                };
                this.addRow({copyItem, index: ind+1});
              }}>
            <FontAwesomeIcon icon={faClone} />
          </Button>
          <Button 
            className="button outline-button-danger row-btn" 
            onClick={(ev) => {              
              const updatedRows = this.state.invoices.filter((item, i) => item.groupId != props.row.original.groupId);
              this.setState({ collapsedGroups: this.state.collapsedGroups.filter(g => g != props.row.original.groupId) });
              this.setInvoices(updatedRows, false);
            }}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Stack>
        },
        footer: () => {
          return <Button tabIndex={-1} className="button add-button" style={{height:"100%",padding:0, borderRadius: 0}} onClick={() => this.addRow()}>
            <FontAwesomeIcon icon={faPlus}/>
            {this.props.t('invoiceReport.table.footers.add')}
          </Button>
        },
        meta: "skipPaddingOnFooter"
      }
    ];
    
    private separatedDepartmentUpdateRowProp = (originalData:Readonly<InvoiceFields>) => {
      //const modified:InvoiceFields = structuredClone(originalData)
      const startDate = typeof this.state.department?.startDate == 'string' ? new Date(this.state.department?.startDate) : new Date() 
      if(originalData['billingDate'].value.localeCompare(startDate?.toLocaleDateString('sv-SE'),'sv-SE') == -1){
        originalData['billingDate'].elevatedError = this.props.t('department.noRights') as string
        originalData['billingDate'].validation = 'Error'
        originalData['billingDate'].message = `${this.props.t('department.activeDate')} ${this.state.department?.startDate}`
        
      }
    }
    private groupedColumns: GroupedCol<InvoiceFields>[] = [
      {
        id: "articleNumber",
        header: this.props.t("invoiceReport.table.headers.articleNr"),
        cell: (props) => this._cell(props, "articleNumber"),
        minSize: "100px"
      },
      {
        id: "articleText",
        header: this.props.t("invoiceReport.table.headers.articleText"),
        cell: (props) => this._cell(props, "articleText"),
        minSize: "100px"
      },
      {
        id: "articleQuantity",
        header: this.props.t("invoiceReport.table.headers.articleQuantity"),
        cell: (props) => this._cell(props, "articleQuantity"),
        minSize: "50px"
      },
      {
        id: "articleQuantityType",
        header: this.props.t("invoiceReport.table.headers.articleQuantityType"),
        cell: (props) => this._cell(props, "articleQuantityType"),
        minSize: "30px"
      },
      {
        id: "articlePricePerUnit",
        header: this.props.t("invoiceReport.table.headers.articlePricePerUnit"),
        cell: (props) => this._cell(props, "articlePricePerUnit"),
        minSize: "100px"
      },
      {
        id: "rowSumExclMoms",
        header: this.props.t("invoiceReport.table.headers.rowSumExclMoms"),
        minSize: "100px",
        cell: (props) => this._cell(props, "rowSumExclMoms"),
      },
      {
        id: "nestedTools",
        minSize:"5%",
       header: "",
       cell: (row, index) => {
         return <Button 
           className="button outline-button-danger row-btn" 
           style={{ width: "100%" }}
           onClick={(ev) => {              
             const updatedRows = this.state.invoices.filter((item, i) => item.id != row.id);
             this.setInvoices(updatedRows, false);
           }}>
           <FontAwesomeIcon icon={faTrash} />
         </Button>
       },
       footer: (col, groupId) => {
        return <Button tabIndex={-1} className="button add-button" style={{height:"100%",padding:0, borderRadius: 0}} 
          onClick={() => {
            const ind = this.state.invoices
              .map(inv => inv.groupId)
              .lastIndexOf(groupId);
            if(ind == -1)
              return;
            const item = this.state.invoices[ind];
            const copyItem : InvoiceImportData = {
              id: -1,
              groupId: groupId,
              billingDate: item.billingDate.value,
              contractId: item.contractId.value,
              customer: item.customer.value,
              customerOrgId: item.customerOrgId.value,
              invoiceNumber: item.invoiceNumber.value,
              rowSumExclMoms: "",
              articleQuantity: "",
              articlePricePerUnit: "",
              articleNumber: "",
              articleText: "",
              articleQuantityType: ""
            };
            this.addRow({copyItem, index: ind+1, groupId});
          }}>
          <FontAwesomeIcon icon={faPlus}/>
          {this.props.t("invoiceReport.newArticleText")}
        </Button>
      },
       meta: "skipPaddingOnFooter"
     }
    ]

    private updateRowProp = (newValue: string, itemId: number, keyProp: InvoiceKeys ) => {
      const invoices = [...this.state.invoices];
      const rowIndex = invoices.findIndex(invoice => invoice.id == itemId);
      const updatedItem = {
        ...invoices[rowIndex]
      };
      const modified =  DataService.InvoiceReports.Validations.ValidateInvoiceProperty(keyProp, newValue, updatedItem, this.state.invoices);
      invoices.splice(rowIndex, 1, modified);
     if(typeof this.state.department !== 'undefined' && modified['billingDate'].value.length == 10){
        this.separatedDepartmentUpdateRowProp(modified)
     }
      
      const filteredInvoices = [...this.state.filteredInvoices];
      if(this.state.filterErrors || this.state.filterWarnings){
        const filteredRowIndex = filteredInvoices.findIndex(invoice => invoice.id == itemId);
        filteredInvoices.splice(filteredRowIndex, 1, modified);
      }
      const elevatedErrorMsg = invoices.find(inv => inv.billingDate.elevatedError != "")?.billingDate.elevatedError ?? "";

      const updatedInvoices = InvoiceGroupHeaders.includes(keyProp) ?
        invoices.map(inv => {
          if(inv.groupId == modified.groupId){
            InvoiceGroupHeaders.forEach(key => {
              inv[key] = modified[key];
            }); 
          }
          return inv;
        }) : invoices;

      DataService.InvoiceReports.SaveToLocalstorage(updatedInvoices);
      this.setState({
        invoices: updatedInvoices,
        AreInvoicesValid: DataService.InvoiceReports.Validations.AreInvoicesValid(updatedInvoices, this.state.reportingArticles),
        filteredInvoices: filteredInvoices,
        elevatedErrorMsg
      });
    }
    
    private _cell = (item: InvoiceFields, key: InvoiceKeys) => {
      
      return (
        <Field
          property={item[key]}
          onChange={(val) => this.updateRowProp(val, item.id, key)}
        />
      )
    }

    private findNewGroupId = (invoices:InvoiceFields[]) => {
      const highestGroupId = invoices
        .map(inv => inv.groupId)
        .filter((grpId, i, self) => self.indexOf(grpId) == i)
        .sort((a,b) => b-a)
        .find(t => t) || -1;
      return highestGroupId > 0 ? highestGroupId+1 : 1;
    }

    private addRow = (opt?: {copyItem?:InvoiceImportData; index?:number, groupId?:number}) => {
      const newRow : InvoiceImportData = {
        id: -1,
        groupId: opt?.groupId && opt?.groupId != -1 ? opt.groupId : this.findNewGroupId(this.state.invoices),
        billingDate: "",
        contractId: "",
        customer: "",
        customerOrgId: "",
        invoiceNumber: "",
        rowSumExclMoms: "",
        articleQuantity: "",
        articlePricePerUnit: "",
        articleNumber: "",
        articleText: "",
        articleQuantityType: "",
        ...opt?.copyItem
      }
      const validatedRow = DataService.InvoiceReports.Validations.ValidateInvoices(newRow, this.state.invoices);
      if(opt?.index){
        let copy = [...this.state.invoices];
        copy.splice(opt.index, 0, validatedRow);
        this.setInvoices(copy, false);
      }
      else{
        this.setInvoices(this.state.invoices.concat(validatedRow), false);
      }
      return validatedRow;
    }

    private setInvoices = (invoices:InvoiceFields[], shouldValidate:boolean) => {
      const invoicesWithID = invoices.map((invoice, i) => {
        return {
          ...invoice,
          id: i
        };
      });
      DataService.InvoiceReports.SaveToLocalstorage(invoices);
      const elevatedErrorMsg = invoices.find(inv => inv.billingDate.elevatedError != "")?.billingDate.elevatedError ?? "";
      this.setState({ 
        invoices: invoicesWithID, 
        filteredInvoices: this.getFilteredInvoices(invoicesWithID, this.state.filterWarnings, this.state.filterErrors),
        AreInvoicesValid: shouldValidate ? DataService.InvoiceReports.Validations.AreInvoicesValid(invoicesWithID, this.state.reportingArticles) : this.state.AreInvoicesValid,
        elevatedErrorMsg
      });
    }

    private SubmitInvoices = async () => {
      this.setState({showSubmitModal: false, showSuccessModal: true, successMsg: this.props.t('invoiceReport.sendSuccessful') });
      this.props.onSubmittedReport();
    }

    private getFilteredInvoices = (invoices: InvoiceFields[], filterWarnings: boolean, filterErrors: boolean) => {
      return invoices.filter(invoice => {
        return (filterWarnings && DataService.InvoiceReports.Validations.DoesInvoiceHaveWarning(invoice, this.state.reportingArticles)) ||
        (filterErrors && DataService.InvoiceReports.Validations.DoesInvoiceHaveError(invoice, this.state.reportingArticles))
      });
    }

    private _tempInvoices : InvoiceFields[] = [];

    private getInstructionTexts = () => {
      let texts = [];
      const key = "invoiceReport.instructionText";
      let i = 1;
      let potentialText = this.props.t(key+i);
      while(potentialText != key+i){
        texts.push(potentialText);
        i++;
        potentialText = this.props.t(key+i);
      }
      return texts;
    }
    render() {
      
        const instructionTexts = this.getInstructionTexts();
        const { error, invoices, filteredInvoices, filterErrors, filterWarnings } = this.state;
      
        if (error !== undefined) {
            return <div>{error.message}</div>;
        }
        const items = filterErrors || filterWarnings ? filteredInvoices : invoices;
        const groupedItems =  items.reduce((prev, curr) => {
          const potentialGroup = prev.find(p => p.groupId == curr.groupId);
          if(potentialGroup){
            potentialGroup.items = potentialGroup.items.concat(curr);
          }
          else {
            prev = prev.concat({ 
              ...curr,
              items: [curr]
             })
          }
          return prev;
        }, [] as InvoiceGroup[]);
        return <Stack gap={1} className="section statistic">
          <div>
            <h3>{this.props.t('invoiceReport.title')}</h3>
          </div>
          {
            instructionTexts.length > 0 &&   
              <ul>
                {
                  instructionTexts.map(text => <li><Trans>{text}</Trans></li>)
                }
              </ul>
          }
          {
            this.state.isLoading &&
            <Spinner />
          }
          {
            !this.state.isLoading &&
            <Stack gap={3}>
              {
                !this.state.canActiveSupplierReport && 
                <Infobar text={this.props.t("invoiceReport.inactiveMsg")}/>
              }
              {
                this.state.elevatedErrorMsg && 
                <Infobar text={this.state.elevatedErrorMsg}/>
              }
              <Stack gap={3} direction="horizontal" style={{justifyContent:"space-between", flexWrap: "wrap"}}>
                <Stack direction="horizontal" gap={3} style={{ flexWrap: "wrap"}}>
                  <StyledCheckbox isChecked={this.state.filterWarnings} onChange={(newVal) => { this.setState({ filterWarnings: newVal, filteredInvoices: this.getFilteredInvoices(invoices, newVal, filterErrors) });}} label={this.props.t('invoiceReport.showWarnings')}/>
                  <StyledCheckbox isChecked={this.state.filterErrors} onChange={(newVal) => { this.setState({ filterErrors: newVal, filteredInvoices: this.getFilteredInvoices(invoices, filterWarnings, newVal) });}} label={this.props.t('invoiceReport.showErrors')}/>
                </Stack>
                
                <Stack direction="horizontal" gap={3} style={{ flexWrap: "wrap"}}>
                  <Button onClick={() => { this.setState({ templateListModal: true }) }}>
                  <FontAwesomeIcon icon={faFolder} style={{marginRight: 8}}/>{this.props.t('general.templates')}
                  </Button>
                  {this.state.showFileImportModal === false &&
                  <FileUploadInput
                    invoiceFormat={this.state.reportingArticles ? "Article" : "Standard"}
                    onChange={(newValues : InvoiceImportData[]) => {
                      const groupIds = this.state.invoices.reduce((prev, inv) => {
                        const key = `${inv.customer.value}_${inv.customerOrgId.value}_${inv.contractId.value}_${inv.invoiceNumber.value}_${inv.billingDate.value}`;
                        if(prev[key] == undefined){
                          prev[key] = inv.groupId;
                        }
                        return prev;
                      }, {} as {[k:string]:number});

                      const invoiceFields = newValues.reduce((prev, curr) => {
                        return [...prev, DataService.InvoiceReports.Validations.ValidateInvoices(curr, prev)]
                      }, [] as InvoiceFields[]);
                      const importedInvoices = this.state.reportingArticles ? 
                        invoiceFields.map((inv, i, self) => {
                          const key = `${inv.customer.value}_${inv.customerOrgId.value}_${inv.contractId.value}_${inv.invoiceNumber.value}_${inv.billingDate.value}`;
                          if(!groupIds[key]){
                            groupIds[key] = this.findNewGroupId([...self, ...this.state.invoices]);
                          }
                          inv.groupId = groupIds[key];
                          return inv;
                        }) : invoiceFields;
                      importedInvoices.sort((a,b) => a.groupId - b.groupId);
                      if(this.state.invoices.length > 0){
                        this._tempInvoices = importedInvoices;
                        this.setState({ showFileImportModal: true});
                      }
                      else{
                        this.setInvoices(importedInvoices, true);
                      }
                    }}
                  />}
                  {
                    this.state.canActiveSupplierReport &&
                    <>
                      <Button className="btn button-submit" 
                        onClick={() => {this.setState({showSubmitModal: true, AreInvoicesValid: DataService.InvoiceReports.Validations.AreInvoicesValid(invoices, this.state.reportingArticles)});}}
                      ><><FontAwesomeIcon icon={faPaperPlane} style={{marginRight: 8}}/>{this.props.t('general.review')} + {this.props.t('general.send')}</>
                      </Button>
                    </>
                  }   
                  <Button className="btn button-danger" 
                    onClick={() => {this.setState({ showClearModal: true })}}
                  >
                    <><FontAwesomeIcon icon={faTrash} style={{marginRight: 8}}/>{this.props.t('general.clear')}</>
                  </Button>
                </Stack>
              </Stack>
                <div>
                  <EditableTable 
                    columns={this.state.reportingArticles ? this.columns :
                    this.columns.filter(col => !["warnings/errors", "noOfArticles"].includes(col.id ?? ""))} 
                    grouped={this.state.reportingArticles ? {
                      groupedColumns: this.groupedColumns,
                      collapsedGroups: this.state.collapsedGroups,
                      onCollapseGroup: (groupId:number) => { 
                        const groupIdExists = this.state.collapsedGroups.includes(groupId);
                        const newList = groupIdExists ? 
                          this.state.collapsedGroups.filter(g => g != groupId)
                          :
                          this.state.collapsedGroups.concat(groupId);
                        this.setState({ collapsedGroups: newList });
                      },
                      onCollapseAll: () => {
                        const groupNumbers = this.state.invoices
                          .map(inv => inv.groupId)
                          .filter((grpId, i, self) => self.indexOf(grpId) == i);
                        if(groupNumbers.every(grpId => this.state.collapsedGroups.includes(grpId))){
                          this.setState({ collapsedGroups: [] });
                        }
                        else 
                          this.setState({ collapsedGroups: groupNumbers });
                      }
                    }: undefined}
                    data={groupedItems} 
                    onChange={(newData) => this.setState({invoices: newData})}
                    />
                </div>
            </Stack>
          }
          {
            this.state.showSubmitModal &&
            <SubmitInvoiceReportModal
              disabled={!this.state.AreInvoicesValid}
              invoices={invoices}
              onDismiss={() => {this.setState({showSubmitModal: false})}}
              onSubmitted={this.SubmitInvoices}
              reportingArticles={this.state.reportingArticles}
            />
          }
          {
            this.state.showSuccessModal &&
            <StyledModal 
              onDismiss={() => { this.setState({ showSuccessModal: false }) }}
              title={this.props.t('invoiceReport.successModalTitle').toString()}
              body={
                <Stack gap={3} style={{alignItems:"center"}}>
                  <div style={{fontSize: 18, fontWeight: 500}}>{this.state.successMsg}</div>
                  <Button style={{width: 100}} className="button button-cancel" onClick={() => { this.setInvoices([], false); this.setState({ showSuccessModal: false}) }}>{this.props.t('general.close')}</Button>
                </Stack>
              }
            />
          }
          {
            this.state.showFileImportModal &&
            <StyledModal 
              onDismiss={() => { this.setState({ showFileImportModal: false }) }}
              title={this.props.t('invoiceReport.importModalTitle').toString()}
              body={
                <div>
                  {this.props.t('invoiceReport.rowsExistPrompt')}
                </div>
              }
              footer={
                <Stack gap={3} direction="horizontal" style={{justifyContent:"center"}}>
                  <Button style={{width: 100}}  onClick={() => { 
                    const newInvoices = this._tempInvoices.concat(this.state.invoices);
                    this.setInvoices(newInvoices, true);
                    this.setState({ showFileImportModal: false });
                    this._tempInvoices = [];
                    }}>
                    {this.props.t('general.keep')}
                  </Button>
                  <Button style={{width: 100}} className="button button-danger" 
                    onClick={() => { 
                      this.setInvoices(this._tempInvoices, true);
                      this.setState({ showFileImportModal: false});
                      this._tempInvoices = [];
                    }}>
                    {this.props.t('general.remove')}
                  </Button>
                  <Button style={{width: 100}} className="button button-cancel" 
                    onClick={() => { 
                      this.setState({ showFileImportModal: false }); 
                      this._tempInvoices = [];
                    }}>
                      {this.props.t('general.cancel')}
                  </Button>
                </Stack>
              }
            />
          }
          {
            this.state.templateListModal &&
            <TemplateListModal 
              onDismiss={() => { this.setState({ templateListModal: false }) }}
            />
          }
          {
            this.state.showClearModal &&
            <StyledModal 
              onDismiss={() => { this.setState({ showClearModal: false }) }}
              title={this.props.t('invoiceReport.clearModalTitle').toString()}
              body={
                <div>
                  {this.props.t('invoiceReport.clearModalContent')}
                </div>
              }
              footer={
                <Stack gap={3} direction="horizontal" style={{justifyContent:"center"}}>
                  <Button style={{width: 100}} className="button button-danger" 
                    onClick={() => { 
                      this.setInvoices([], false);
                      this.setState({ showClearModal: false, collapsedGroups: []});
                      this._tempInvoices = [];
                    }}>
                    {this.props.t('general.confirm')}
                  </Button>
                  <Button style={{width: 100}} className="button button-cancel" 
                    onClick={() => { 
                      this.setState({ showClearModal: false }); 
                    }}>
                      {this.props.t('general.cancel')}
                  </Button>
                </Stack>
              }
            />
          }
        </Stack>;
    }
}

export default withTranslation()(InvoiceReport);