import * as React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IFooterProps {
}

const Footer: React.FunctionComponent<IFooterProps> = (props) => {
  const { t } = useTranslation();

  return <footer className='footer'>
  <Container>
    <div className="footer-items">
    <div className="footer-item">
        <h4>{t('footer.about.title')}</h4>
        <div>
          <p>{t('footer.about.p1')}</p>
          <p>{t('footer.about.p2')}</p>
          <p></p>
          <p>
            <img  width={170} height={55} src="/logo-sinfra-2.png"/>
          </p>
        </div>
    </div>
    <div className="footer-item">
      <h4>{t('footer.contact.title')}</h4>
      <div>
        <p>
          <strong>{t('footer.contact.postadress')}</strong>
          <br/>
          {t('footer.contact.postadressvalue')}
        </p>
        <p>
          <strong>{t('footer.contact.visitadress')}</strong>
          <br/>
          {t('footer.contact.visitadressvalue')}
        </p>
        <p>
          <strong>{t('footer.contact.phone')}</strong>
          <br/>
          {t('footer.contact.phonevalue')}
        </p>
        {/* <p> */}
          <strong>{t('footer.contact.email')}</strong>
          <br/>
          { <a style={{color:'#0d6efd',textDecorationLine:'underline'}} href={`mailto:${t('footer.contact.emailvalue')}`}>{t('footer.contact.emailvalue')}</a>}
        {/* </p> */}
      </div>
    </div>
    <div className="footer-item">
      <h4>{t('footer.openinghour.title')}</h4>
      <div>
        <p>
          {t('footer.openinghour.weekdays')} <span>{t('footer.openinghour.weekdaysvalue')}</span>
          {/* <br/>
          Lunchstängt <span>11.45 - 12.45</span> */}
        </p>
      </div>
    </div>
    <div className="footer-item">
      <h4>{t('footer.links.title')}</h4>
      <div>
        <ul>
          <li><a href="https://sinfra.se/">{t('footer.links.sinfraHomePage')}</a></li>
          <li><a href="https://sinfra.se/upphandlingskalender/">{t('footer.links.procurementCalendar')}</a></li>
          <li><a href="https://sinfra.se/nyheter/">{t('footer.links.sinfraNews')}</a></li>
          <li><a href="https://sinfra.se/medlemmar/">{t('footer.links.sinfraMembers')}</a></li>
          <li><a href="https://sinfra.se/ramavtalsomraden/">{t('footer.links.sinfraFrameworkAgreement')}</a></li>
          <li><a href="https://sinfra.se/ramavtalsleverantorer/">{t('footer.links.sinfraFrameworkAgreementSuppliers')}</a></li>
          <li><a href="https://sinfra.se/vanliga-fragor/">{t('footer.links.faq')}</a></li>
          <li><a href="https://sinfra.se/om-oss/">{t('footer.links.about')}</a></li>
        </ul>
      </div>
    </div>
    </div>
  </Container>
</footer>;
};

export default Footer;
