import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Row,
  Spinner,
  Stack,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useTranslation, withTranslation } from "react-i18next";
import { TNews } from "../services/API/News";
import { redirect, useNavigate, useParams } from "react-router-dom";
import RTE from "../components/RTE/RTE";
import {
  faBan,
  faCheckCircle,
  faCloudArrowUp,
  faEdit,
  faEye,
  faEyeSlash,
  faSave,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataService } from "../services/DataService";
import { Code, isInteger, IsUserNewsEditor } from "../lib/utils";
import AuthService from "../services/AuthService";
import RTEslim from "../components/RTE/RTEslim";
import { StyledModal } from "../components/modal/StyledModal";
import i18next from "i18next";
import FlagGenerator from "../components/navigation/FlagGenerator";



interface Props {}

function yearsInFuture(years: number) {
  let today = new Date();
  today.setFullYear(today.getFullYear() + 100);
  return today;
}
export type NewsArticle = {
  [lang: string]: { article: TNews; active: boolean };
};



export type LangObj = {
  locale: string;
  label: string;
  dataSrc: string;
};

function NewsArticlePage(props: Props) {
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [removeConfirmModal, setRemoveConfirmModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [modalErrorMsg, setModalErrorMsg] = useState<string>("");
  const [mode, setMode] = useState("");
  const [testArticle, setTestArticle] = useState<NewsArticle>({});
  // const [newsArticle, setNewsArticle] = useState<TNews>({
  //   created: new Date(),
  //   id: -1,
  //   publishedFrom: new Date(),
  //   publishedUntil: yearsInFuture(100),
  //   title: "",
  //   updated: new Date(),
  //   body: "",
  //   ingress: "",
  //   lang:"",
  // });
  const [selectedLang, setSelectedLang] = useState(i18next.language);

  const { t, i18n } = useTranslation();

  const view = "view";
  const edit = "edit";
  const create = "create";
  const validModes = [view, edit, create];
  const { paramMode, paramId } = useParams();

  const init = async (id?: string) => {
    if (paramMode !== "create") {
      
      const news = await DataService.News.GetById(id as string);
      news[i18n.language].active = true
      setTestArticle(news)
    }
    else if(paramMode == "create"){
      buildArticle();
    }
    setMode(paramMode as string);
    setLoading(false);
  };

  useEffect(() => {
    
    if (typeof paramMode != "string") {
      window.location.href = "/";
    }
    if (!validModes.includes(paramMode?.toLocaleLowerCase() as string)) {
      window.location.href = "/";
    }
    
    if (paramMode != view && IsUserNewsEditor() == false) {
      window.location.href = "/";
    }
    init(paramId);
    
  }, []);

  const buildArticle = (): void => {
    const result: NewsArticle = AppSettings.languages.reduce(
      (result: NewsArticle, item) => {
        if (!result[item.locale]) {
          result[item.locale] = {
            article: {
              created: new Date(),
              id: -1,
              publishedFrom: new Date(),
              publishedUntil: yearsInFuture(100),
              title: "",
              updated: new Date(),
              body: "",
              ingress: "",
              lang:item.locale
            },
            active: item.locale == i18n.language,
          };
        }
        return result;
      },
      {}
    );
    setTestArticle(result);
  };
  const validate = () => {
    if (testArticle[selectedLang].article.title.trim().length < 1) {
      const msg = t("news.error.noTitle");
      setErrorMsg(msg);
      return false;
    }
    if (testArticle[selectedLang].article.ingress.trim().length < 1) {
      const msg = t("news.error.noIngress");
      setErrorMsg(msg);
      return false;
    }
    return true;
  };

  const onSubmit = async (remove?: boolean) => {
    setErrorMsg("");
    if (validate()) {
      setSaving(true);
      const articles:TNews[] = getNewsArticleArray() 
      const result:any = remove
        ? await DataService.News.Remove(testArticle[selectedLang].article.newsArticleId as string)
        :   await DataService.News.Post(articles) ;
      if (result.Code != Code.OK) {
        setErrorMsg(result.Message);
        setSaving(false);
      } else {
        console.log(result)
        window.location.href = "/news/view/" + result.Content[i18n.language].article.newsArticleId;
      }
    }
  };

  const onRemove = async () => {
    setErrorMsg("");
    setModalErrorMsg("");
    setSaving(true);
    const result = await DataService.News.Remove(testArticle[selectedLang].article.newsArticleId as string);
    if (result.Code != Code.OK) {
      setModalErrorMsg(result.Message);
      setSaving(false);
    } else {
      window.location.href = "/newslist";
    }
  };

  const onClickRemove = () => {
    setRemoveConfirmModal(true);
  };
 const getNewsArticleArray = ():TNews[] => {
  
  return  Object.entries(testArticle).map(([key,value]) => { 
    return value['article']
  })
 } 
 const deepClone = (): NewsArticle =>{
  return JSON.parse(JSON.stringify(testArticle),(key,value) => {
    
    if(key == "publishedFrom"|| key == "publishedUntil" || key == "created" || key == "updated"){
    
      return new Date(value)
    }
    else{
      return value
    }
  })
 } 
  const renderDateField = (
    label: string,
    value: Date | undefined | null,
   
    prop: string
  ) => {
    let articles:NewsArticle = deepClone()
    
    return (
      <div>
        <div className="field-label">{label}</div>
        <Form.Control
          disabled={isSaving}
          type="date"
          className="field-value"
          onChange={(ev) => {
            if (ev.target.value) {
              articles[selectedLang].article[prop == "publishedFrom"   ?"publishedFrom" : "publishedUntil"] = new Date(ev.target.value)
              setTestArticle(articles)
            }
          }}
           value={value?.toLocaleDateString()}
          onKeyDown={(ev) => false}
          onInput={(ev) => false}
        />
      </div>
    );
  };
  const createTab = () => {
    return (
      <>
      {createSaveTab()}
        <div className="newsTabOveride">
          <Tabs
            activeKey={selectedLang}
            onSelect={(key) => handleTabSelection(key as string)}
            // className="mb-3"
            
          >
            {AppSettings.languages.map((x: LangObj) => {
              return createLangTabs(x);
            })}
           
          </Tabs>
          
        </div>
      </>
    );
  };
  const handleTabSelection = (key: string): void => {
    setSelectedLang(key);
    updateArticleObjActive(key);
  };
  const updateArticleObjActive = (key: string): void => {
    let article: NewsArticle = deepClone()
    Object.keys(article).forEach((key1) => {
      key == key1
        ? (article[key1].active = true)
        : (article[key1].active = false);
    });
    setTestArticle(article);
  };
  const createSaveTab = (): JSX.Element => {
    return (
  
      <div className="saveTab" >
        <h1 className="saveTitle">{mode == 'create' ? i18n.t('news.createNews') : i18n.t('news.editNews') }</h1>
      {isSaving && <Spinner />}
      <div style={{padding:5,gap:4,display:"flex"}}>
         <Button
           className="btn"
           onClick={() => {
             onSubmit();
      
           }}
           
           disabled={isSaving}
         >
           <>
             <FontAwesomeIcon icon={faSave} style={{ marginRight: 8 }} />
             {t("general.save")}
           </>
         </Button>
         {mode == "edit" && (
           <Button
             className="btn button-danger"
             style={{color:'white',pointerEvents:'all'}}
             onClick={() => {
               onClickRemove();
             }}
          disabled={isSaving}
           >
             <>
               <FontAwesomeIcon icon={faBan} style={{ marginRight: 8 }} />
               {t("general.remove")}
             </>
           </Button>
         )}
         <Button
           className="btn button-cancel"
           style={{color:'white',pointerEvents:'all'}}
           onClick={() => {
             mode == create
               ? (window.location.href = "/")
               : (window.location.href = `/news/view/${testArticle[i18n.language].article.newsArticleId}`);
           }}
           disabled={isSaving}
         >
           {t("general.cancel")}
         </Button>
         </div>
   </div>
        
    );
  };
  const createLangTabs = (langObj: LangObj): JSX.Element => {
    
    return (
      <Tab
        eventKey={langObj.locale}
        title={tabTitleElement(testArticle[langObj.locale].active, langObj)}
      >
        <div className="mumma">{renderBody(langObj.locale)}</div>
      </Tab>
    );
  };
  const tabTitleElement = (value: boolean, langObj: LangObj): JSX.Element => {
   return( <div className="newsTabTitle">
        {langObj.label}
        <div
          className={
            value
              ? "iconActive"
              : "inactiveIcon"
          }
        >
           <div  style={value ? {opacity:1}:{   opacity: 0.2 }}>{FlagGenerator(langObj.locale)}</div>
        </div>
      </div>
   )
  };
  
  const handleContentCreated = (articles: NewsArticle, newVal: string, key:  string) => {
    articles[selectedLang].article[key == "ingress" ? "ingress" : "body"] = newVal
    setTestArticle(articles)
  }


  const renderBody = (key: string): JSX.Element => {
    let articles:NewsArticle = deepClone()
   
    
    return (
      <div className="news-edit-form" style={{borderBottomLeftRadius:'5px',borderBottomRightRadius:'5px',borderTopLeftRadius:'0px',borderTopRightRadius:'5px',border:'2.5px solid #e3e3e3',padding:'35.3px'}}>
        {errorMsg && (
          <div className="error-message">
            <span>{t("general.errorMsg")}:</span>
            {errorMsg}
          </div>
        )}
        <Stack
          direction="horizontal"
          style={{ marginBottom: 16, flexWrap: "wrap", gap: "16px" }}
        >
          
          <input
            className="titleInput"
            disabled={isSaving}
            placeholder={t("news.placeholder").toString()}
            onChange={(ev) =>
              {articles[key].article.title = ev.currentTarget.value as string
                setTestArticle(articles)
              }
            }
            value={testArticle[key].article.title}
          />
          
        </Stack>
        <Row>
          <Col>
            {renderDateField(
              t("news.publishedFrom"),
              testArticle[key].article.publishedFrom,
              "publishedFrom"
            )}
          </Col>
          <Col>
            {renderDateField(
              t("news.publishedUntil"),
              testArticle[key].article.publishedUntil,
              "publishedUntil"
            )}
          </Col>
        </Row>

        <FormGroup>
          <Form.Label style={{ fontSize: "1.5rem" }}>
            {t("news.ingress")}
          </Form.Label>
          {/* <div>{t("news.ingress")}</div> */}
          <RTEslim
            onChange={(newVal) =>
              {
                handleContentCreated(articles,newVal,"ingress")
              }
            }
            value={articles[key].article.ingress || ""}
            disabled={isSaving}
            height="155px"
          />
        </FormGroup>

        <FormGroup>
          <Form.Label style={{ fontSize: "1.5rem" }}>
            {t("news.body")}
          </Form.Label>
          <RTE
            onChange={(newVal) =>
              {
                handleContentCreated(articles,newVal,"body")
              }
            }
            value={articles[key].article.body || ""}
            disabled={isSaving}
          />
        </FormGroup>
      </div>
    );
  };
  

  return (
    <Stack gap={4}>
     
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          {mode == view && (
            <div className="newsCard">
              <Stack
                direction="horizontal"
                style={{ flexWrap: "wrap", gap: 8 ,margin:12}}
              >
               
               
               
                {IsUserNewsEditor() && (
                  <Button
                    style={{ marginLeft: "auto" }}
                    className="btn"
                    onClick={() => {
                      window.location.href = `/news/edit/${testArticle[i18n.language].article.newsArticleId}`;
                    }}
                    disabled={isSaving}
                  >
                    <>
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{ marginRight: 8 }}
                      />
                      {t("general.edit")}
                    </>
                  </Button>
                )}
              </Stack>
              
              <div className="newsHeaderContainer">
              <h1 className="newsHeader"><b>{testArticle[i18n.language].article.title}</b></h1>
              </div>    

              <div
              className="newsIngress"
                dangerouslySetInnerHTML={{ __html: testArticle[i18n.language].article.ingress || "" }}
              ></div>
              <div className="newsDivider">
              <p className="newsDividerLine" />
              </div>
              <div
              className="newsBody"
                dangerouslySetInnerHTML={{ __html: testArticle[i18n.language].article.body || "" }}
              ></div>
               <div className="newsDivider" >
                <span className="newsDividerLineTop" >
                  {DataService.News.isPublished(testArticle[i18n.language].article) ? (
                    <Stack className="newsCreatorStack" direction="horizontal">
                      <FontAwesomeIcon className="newsicon" icon='newspaper'/>
                      <i >{testArticle[i18n.language].article.publishedFrom.toLocaleDateString()}
                      {" "} {i18n.t('news.PublishedBy')}{testArticle[i18n.language].article.createdBy}
                      </i>
                     
                    </Stack>
                    
                    ) : (
                      <Stack className="newsCreatorStack" direction="horizontal" gap={1}>
                      <FontAwesomeIcon icon={faEyeSlash} />
                      {t("general.unPublished")}
                    </Stack>
                  )}
              </span>
                </div>
            </div>
          )}
          {(mode == edit || mode == create) && (
            <>
              <div
                style={{
                  padding: 40,
                 borderRadius: 4,
                }}
              >
                {createTab()}
              </div>
            </>
          )}
        </>
      )}
      {removeConfirmModal && (
        <StyledModal
          title={t("news.removeModal.title").toString()}
          onDismiss={() => {
            if (!isSaving) setRemoveConfirmModal(false);
          }}
          body={
            <>
              <p>{t("news.removeModal.description")}</p>
              <p>
                {modalErrorMsg && (
                  <div className="error-message">
                    <span>{t("general.errorMsg")}:</span>
                    {modalErrorMsg}
                  </div>
                )}
              </p>
            </>
          }
          footer={
            <>
              {isSaving && <Spinner />}
              <Button
                disabled={isSaving}
                className="btn button-danger"
                onClick={() => onRemove()}
              >
                {t("general.remove")}
              </Button>
              <Button
                disabled={isSaving}
                className="btn button-cancel"
                onClick={() => setRemoveConfirmModal(false)}
              >
                {t("general.cancel")}
              </Button>
            </>
          }
        />
      )}
    </Stack>
  );
}

export default withTranslation()(NewsArticlePage);
