import * as React from 'react'
import { Stack, Button } from 'react-bootstrap'
import { StyledModal } from '../modal/StyledModal'
import { Action, ActionModalState, ActionTexts, HandleActionModal, InternalInvoiceReport } from './editTypes'

function ActionModal (action:ActionModalState,addRow:(groupId?:number)=>void,deleteRow:(id:number)=>void,copyRow:<T extends object>(item:T,index:number) => void,handleDeleteModal:HandleActionModal,invoiceReportOnChange:(e:React.ChangeEvent<HTMLTextAreaElement> | number,key:keyof Pick<InternalInvoiceReport,'departmentId' | 'comment'>,item?:InternalInvoiceReport) =>void,submit:() => void)  {
    const actionText:ActionTexts[Action] = {
        ADDROW:{title:'Lägg till',body:'Är du säker på att du vill lägga till en ny fakturarad?'},
        ADDARTICLE:{title:'Lägg till artikelrad',body:'Är du säker på att du vill lägga till en artikelrad?'},
        DELETEROW:{title:'Ta bort',body:'Är du säker på att du vill ta bort den valda raden?'},
        DELETEARTICLEROW:{title:'Ta bort artikelrad',body:'Är du säker på att du vill ta bort den valda artikelraden?'},
        COPY:{title:'Kopiera',body:'Är du säker på att du vill koperia den här raden/gruppen?'},
        CHANGEDEPARTMENT:{title:'Ändra/Lägg till avdelning',body:'Är du säker på att du vill ändra/lägga till en ny avdelning på rapporten?'},
        SUBMIT:{title:'Skicka in',body:'Är du säker på att du vill skicka in ändringar?'},
        NONE:{title:'',body:''},
        ERROR:{title:'Fel',body:'Något gick fel, försök igen senare.'}
    }[action.action]
    const handleConfirm = () => {
        switch(action.action) {
            case 'ADDROW':
              addRow();
              break;
            case 'ADDARTICLE':
                addRow(action.index)
                break;  
            case 'COPY':
              if (action.item) {
                copyRow(action.item,action.id)
              } else {
                console.error("No item provided for copy.");
              }
              break;
            case 'DELETEROW':
                deleteRow(action.id)
              break;
            case 'DELETEARTICLEROW':
                deleteRow(action.id)
                break;  
            case 'CHANGEDEPARTMENT':
                  invoiceReportOnChange(action.id,'departmentId')
                  break;
            case 'SUBMIT' :
                submit();
            break;          
            case 'NONE':
              break;
          }
      };
    return(
        <StyledModal 
        onDismiss={() => { handleDeleteModal('NONE',false,-1)}}
        title={<label>{actionText.title}</label>}
        body={
            <div style={{alignContent:'center',flexDirection:'column',textAlign:'center'}}>
            {actionText.body}
          <Stack direction='horizontal' gap={3} style={{justifyContent:"center",marginTop:'15px'}}>
            {action.action !== 'ERROR' &&
            <Button style={{width: 'fit-content'}} className="button button-danger" onClick={() => handleConfirm()}>{actionText.title}</Button>
            }
            <Button style={{width: 100}} className="button button-cancel" onClick={() => handleDeleteModal('NONE',false,-1)}>{'Stäng'}</Button>
          </Stack>
          </div>
        }
      />
    )
}
export {ActionModal}