import React from "react";
import { Stack } from "react-bootstrap";
import { WithTranslation, withTranslation } from "react-i18next";

interface State {
  hasError: boolean;
  errorMsg: string;
  stack: any;
}

interface Props extends WithTranslation {
  children: JSX.Element;
}

class ErrorBoundary extends React.Component<Props,State> {
  constructor(props:Props) {
    super(props);
    this.state = { hasError: false, errorMsg: "", stack: undefined };
  }

  static getDerivedStateFromError(error:any) {
    return { hasError: true };
  }

  componentDidCatch(error:any, info:any) {
    this.setState({ errorMsg: error.message, stack: info.componentStack })
  }

  render() {
    if (this.state.hasError) {
      return <Stack gap={3}>
        <h3>{this.props.t("errorPage.title")}</h3>
        <h5>{this.props.t("errorPage.description")}</h5>
        <div>
          <p>{this.state.errorMsg}</p>
          <p>{this.state.stack}</p>
        </div>
      </Stack>
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary)