/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import * as React from 'react'
import { Action, ActionModalState, /*DeleteModalState,*/ EditReportType,/*GroupedDataByKey,/* GroupedDataByKey,*/ IEditReportProps,InternalInvoiceEdit,InternalInvoiceReport,InternalInvoiceWithCsrdAndGroupId,InvoicesWithCsrdAndGroupId,ReportDataMapping,/*,  InvoiceReportTextFileds,*/  /*InternalKeysForEIRT,*/  StandardInvoiceReport } from './editTypes'
import { Invoice } from '../../services/API/Invoices';
import { DataService } from '../../services/DataService';
import { Contract } from '../../services/API/Contracts';
import Spinner from 'react-bootstrap/esm/Spinner';
import { StandardInvoiceReportView } from './StandardInvoiceReportView';
import { Customer } from '../../services/API/Customer';
import { editUtils } from './editUtils';
import { Department, DepartmentFunctionArgs } from '../../services/API/Departments';


interface IEDitreportState{
  data?:/*GroupedDataByKey*/any;
  loading:boolean;
  contract:Contract[];
  actionModal:ActionModalState/*DeleteModalState*/;
  customers:Customer[];
  submitActive:boolean;
  blockSubmitButton:boolean;
  editHistory:any;
  departments?:Department[];
  department?:Department;
}
export class EditReport extends React.Component<IEditReportProps,IEDitreportState>{
  constructor(props:IEditReportProps){
    super(props)
    this.callBack = this.callBack.bind(this)
    this.invoiceReportOnChange = this.invoiceReportOnChange.bind(this)
    this.createRightTypeForReportDataInStateAndClone = this.createRightTypeForReportDataInStateAndClone.bind(this)
    this.copyRow = this.copyRow.bind(this)
    this.state = {
      data:undefined,
      loading:true,
      contract:[],
      actionModal:{action:'NONE',show:false,id:-1},
      customers:[],
      submitActive:false,
      blockSubmitButton:false,
      editHistory:undefined 
    }
  }
 async componentDidMount(): Promise<void> {
  const editHistory = await DataService.EditedReports.GetReportHistory(this.props.data[this.props.reportType].report.batchId)

  const departments = await DataService.Departments.Get({supplierOrgId:this.props.data[this.props.reportType].report.supplierOrgId}as DepartmentFunctionArgs)
   let department:Department | undefined = undefined;
   if(typeof departments !== 'undefined'){
    
     department =  departments.find(x => x.id == this.props.data[this.props.reportType].report.departmentId)
   } 
   const customers = await DataService.Customers.Get({
      select:["isSupplier", "kndOrgnr", "kndNamn", "kndNamnKey", "kndLopnr"], 
      filter: "isSupplier eq false"
    })
    const contracts = await DataService.Contracts.GetBySupplierOrgNr(this.props.data[this.props.reportType].report.supplierOrgId)
    const res = structuredClone(this.props.data[this.props.reportType]);
    if(res?.report?.csrd){
     const addedMarkerOnInvoicesArray:InternalInvoiceEdit[] = res?.report?.invoices?.map((x:Invoice) =>{
        return {...x,csrdConnectionId: Math.random()} as InternalInvoiceEdit
      } )
     const connectedMarkerOnCsrdGroupArray:InternalInvoiceWithCsrdAndGroupId[] = res?.report?.csrdGroup?.map((x:InvoicesWithCsrdAndGroupId) => {
         const invoiceMarker:InternalInvoiceEdit | undefined = addedMarkerOnInvoicesArray.find(q => q.invoiceNumber == x.invoiceNumber)
         if(invoiceMarker !== undefined){
            return {...x,csrdConnectionId:invoiceMarker.csrdConnectionId}
         } 
        })
        res.report.invoices = addedMarkerOnInvoicesArray
        res.report.csrdGroup = connectedMarkerOnCsrdGroupArray
    }
    this.setState({data:res,contract:contracts,customers,departments,department,editHistory},() =>this.setState({loading:false}))
  }

  handleSubmit = async() => {
    this.setState({submitActive:true})   
   const response = await DataService.EditedReports.SubmitEditedReport(this.props.reportType,this.state.data);
   if(!response?.ok){
     this.setState({submitActive:false,actionModal:{action:'ERROR',show:true,id:-1}})
    }else{
     this.setState({submitActive:false,actionModal:{action:'NONE',show:false,id:-1}})
    window.location.href = "/invoice-report-list";
   }  
  }
  handleActionModal = (action:Action,show:boolean,id:number,key?:keyof Pick<InvoicesWithCsrdAndGroupId,'groupId'|'id'>,item?:any,index?:number) => {   
    this.setState({actionModal:{action,show,id,key,item,index}})
  }
 handleTypeaheadBlur <T>(value:string,rowId:number,key:keyof T) {
    // let optionsAvailable:OptionsAvailable = _helpers.deepClone(this.state.optionsAvailable)
    //   optionsAvailable[key].find(x => x == value) ?? optionsAvailable[key].push(value)
    // if(value.length > 0){
    //   let stateAsOffNow:Period = _helpers.deepClone(this.state.periodTestData)
    //   let rowToUpdate:ProjectInvoice | undefined =  this.findRow(stateAsOffNow,rowId)
    //   if(rowToUpdate){
    //     switch (key){
    //       case 'projectName':{
    //         rowToUpdate[key] = value
    //         break;
    //       }
    //       case 'reference':{
    //         rowToUpdate[key] = value
    //         break;
    //       }
    //       case 'tag':{
    //         rowToUpdate[key] = value
    //         break;
    //       }
          
    //     }
    //     this.setState({periodTestData:stateAsOffNow,optionsAvailable})
    //   }
      
    // }
  }
  copyRow <T extends object>(rowToCopy:T,index:number)  {
      if(this.props.reportType == 'Invoicereport'){
        const buff:StandardInvoiceReport = this.createRightTypeForReportDataInStateAndClone(this.props.reportType)
        const copiedItem:InternalInvoiceEdit/*Invoice*/ = structuredClone(rowToCopy) as InternalInvoiceEdit/*Invoice*/
        copiedItem.id = copiedItem.id + 1
        buff.report.invoices?.push(copiedItem)
        buff.report.noOfInvoices = buff.report.invoices?.length ?? 0
        buff.report.noOfInvoicesImported = buff.report.invoices?.length ?? 0
        const totSum:number = editUtils.CalculateNewSumTotal(buff,'invoices','priceExcMoms')
        buff.report.totalSumExclMoms = totSum
        buff.report.totalSumExclMomsImported = totSum
        this.setState({data:buff,actionModal:{action:'NONE',show:false,id:-1}})
      }else if(this.props.reportType == 'InvoicereportWithCSRD'){
        
        if('items' in rowToCopy){
          const tempId = Math.random()
          let invoiceSum:number = 0;
          const date = new Date()
          date.setDate(date.getDate() + 30)
          const buff:StandardInvoiceReport = this.createRightTypeForReportDataInStateAndClone('InvoicereportWithCSRD')
          const copyToAdd = structuredClone(rowToCopy.items) as InternalInvoiceWithCsrdAndGroupId[]/*InvoicesWithCsrdAndGroupId[]*/
          const newGroupId = this.findNewGroupId(this.state.data?.report?.csrdGroup,'groupId')
           copyToAdd.forEach((x,index) =>{ 
            invoiceSum +=x.rowSumExclMoms
            buff.report.csrdGroup?.push( {...x,csrdConnectionId:tempId,id:index == 0  ? tempId:Math.random(),groupId:newGroupId,invoiceNumber:'',invoiceId:tempId,billingDate:new Date()})
          })
          const totSumForReport:number = editUtils.CalculateNewSumTotal(buff,'csrdGroup','rowSumExclMoms')
          const newInvoiceToPushToInvoices:InternalInvoiceEdit = {
            supplierOrgId:copyToAdd[0].supplierOrgId,
            contractId:copyToAdd[0].contractId,
            customerOrgId:copyToAdd[0].customerOrgId,
            customerId:copyToAdd[0].customerName,
            priceExcMoms:invoiceSum,
            priceIncMoms:invoiceSum * 0.25 + invoiceSum,
            id:tempId,
            invoiceNumber: '',
            csrdConnectionId:tempId,
            isRemoved: false,
            billingDate: new Date(),
            noOfInvoices:copyToAdd.length,
            dueDate: date,
            sendDate: new Date(),
            customerName:copyToAdd[0].customerName,
            type:copyToAdd[0].type,
            filePath: copyToAdd[0].filePath,
          } /*Invoice*/
          buff.report.invoices?.push(newInvoiceToPushToInvoices)
          buff.report.noOfInvoices = buff.report.invoices?.length ?? 0
          buff.report.noOfInvoicesImported = buff.report.invoices?.length ?? 0
          buff.report.totalSumExclMoms = totSumForReport
          buff.report.totalSumExclMomsImported = totSumForReport
          this.setState({data:buff,actionModal:{action:'NONE',show:false,id:-1}})
        }
 
      }
  }
  private findNewGroupId = (invoices:InvoicesWithCsrdAndGroupId[],path:keyof Pick<InvoicesWithCsrdAndGroupId,'invoiceId'|'groupId'>) => {
    const highestGroupId = invoices
      .map(inv => inv[path])
      .filter((grpId, i, self) => self.indexOf(grpId) == i)
      .sort((a,b) => b-a)
      .find(t => t) || -1;
    return highestGroupId > 0 ? highestGroupId+1 : 1;
  }
  addRow = (groupId?:number) => {
    if(this.props.reportType == 'Invoicereport' || this.props.reportType == 'Projectreport'){
        const buff:StandardInvoiceReport = this.createRightTypeForReportDataInStateAndClone(this.props.reportType)
        const newInvoice:InternalInvoiceEdit/*Invoice*/ = {
              id:buff.report.invoices?.length ?? -1,    
              invoiceNumber: "",
              supplierOrgId: buff?.report?.supplierOrgId,
              customerId: "",
              customerOrgId: "",
              contractId:"",
              priceExcMoms: 0,
              priceIncMoms: 0,
              isRemoved: false,
              billingDate: new Date(),
              noOfInvoices:1,
              dueDate: new Date(),
              sendDate: new Date(),
              customerName:"",
              type: 1,
              filePath: "",
              csrdConnectionId:Math.random(),
          }
          buff.report.invoices?.push(
            newInvoice
        ) 
        buff.report.noOfInvoices = buff.report.invoices?.length ?? 0
        buff.report.noOfInvoicesImported = buff.report.invoices?.length ?? 0
        this.setState({data:buff,actionModal:{action:'NONE',show:false,id:-1}})
    }
    else if(this.props.reportType == 'InvoicereportWithCSRD' || this.props.reportType == 'ProjectreportWithCSRD'){
        const buff:StandardInvoiceReport = this.createRightTypeForReportDataInStateAndClone(this.props.reportType)
        const currentValues = buff.report.csrdGroup?.find((x:InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/) => x.groupId == groupId)
        const tempId = Math.random() 
        const newRow :InternalInvoiceWithCsrdAndGroupId = {
          id: tempId,
          groupId: typeof groupId == 'undefined' ?  this.findNewGroupId(this.state.data?.report.csrdGroup,'groupId'):groupId,
          billingDate: typeof groupId == 'undefined' ?  new Date() : currentValues?.billingDate ?? new Date(),
          contractId: typeof groupId == 'undefined' ?  "" : currentValues?.contractId ?? '',
          customerName: typeof groupId == 'undefined' ?  "" : currentValues?.customerName ?? '',
          customerOrgId: typeof groupId == 'undefined' ?  "" : currentValues?.customerOrgId ?? '',
          invoiceNumber: typeof groupId == 'undefined' ?  "" : currentValues?.invoiceNumber ?? '',
          rowSumExclMoms: 0,
          articleQuantity: 0,
          articlePricePerUnit: 0,
          articleNumber: "",
          articleText: "",
          articleQuantityType: "",
          invoiceId: typeof this.state.actionModal.index == 'undefined' ?  this.findNewGroupId(this.state.data?.report.csrdGroup,'invoiceId'):currentValues?.invoiceId ?? -1,
          filePath:this.state.data?.report.csrdGroup[0].filePath,
          type:1,
          status:this.state.data?.report.csrdGroup[0].status,
          batchId:this.state.data?.report.csrdGroup[0].batchId,
          supplierOrgId:this.state.data?.report.supplierOrgId,
          csrdConnectionId:tempId ,
        }
        buff.report.csrdGroup?.push(newRow)
        const newInvoice:InternalInvoiceEdit/*Invoice*/ = {
          id:tempId,    
          invoiceNumber: "",
          supplierOrgId: this.state.data?.report.supplierOrgId,
          customerId: "",
          customerOrgId: "",
          contractId:"",
          priceExcMoms: 0,
          priceIncMoms: 0,
          isRemoved: false,
          billingDate: new Date(),
          noOfInvoices:1,
          dueDate: new Date(),
          sendDate: new Date(),
          customerName:"",
          type: 1,
          filePath: "",
          csrdConnectionId:tempId,
      }
      if(typeof groupId == 'undefined'){
        buff.report.invoices?.push(
          newInvoice
      ) 
      buff.report.noOfInvoices = buff.report.invoices?.length ?? 0
      buff.report.noOfInvoicesImported = buff.report.invoices?.length ?? 0
      }
     
        this.setState({data:buff,actionModal:{action:'NONE',show:false,id:-1}},()=> console.log(this.state.data))
    }
  }
  deleteRow = (id:number) => {
    
    if(this.props.reportType == 'Invoicereport' || this.props.reportType == 'Projectreport'){
      const buff:StandardInvoiceReport = this.createRightTypeForReportDataInStateAndClone(this.props.reportType)
       buff.report.invoices =  buff.report.invoices?.filter(x => x.id !== this.state.actionModal.id)
      if(buff.report.invoices?.length == 0){
        buff.report.totalSumExclMoms = 0
        buff.report.totalSumExclMomsImported = 0
        
      }
      const totSum:number = editUtils.CalculateNewSumTotal(buff,'invoices','priceExcMoms')
      buff.report.totalSumExclMoms = totSum
      buff.report.totalSumExclMomsImported = totSum
      buff.report.noOfInvoices = buff.report.invoices?.length ?? 0
      buff.report.noOfInvoicesImported = buff.report.invoices?.length ?? 0
        
      this.setState({data:buff,actionModal:{action:'NONE',show:false,id:-1}})
    }else if((this.props.reportType == 'InvoicereportWithCSRD' || this.props.reportType == 'ProjectreportWithCSRD') && typeof this.state.actionModal?.key !== 'undefined'){
      const key = this.state.actionModal.key
      const buff:StandardInvoiceReport = this.createRightTypeForReportDataInStateAndClone(this.props.reportType)
      const itemToLookUp:InternalInvoiceWithCsrdAndGroupId | undefined = buff.report.csrdGroup?.filter(x => x[key] == this.state.actionModal.id)[0]

      buff.report.csrdGroup = buff.report.csrdGroup?.filter(x => x[key] !==this.state.actionModal.id)
      
      if(itemToLookUp !== undefined){
       const remainingItems =  buff.report.csrdGroup?.filter(x => x.invoiceNumber == itemToLookUp.invoiceNumber)
   
       if(remainingItems !== undefined && remainingItems?.length > 0){
         const newInvoiceSum =  remainingItems.reduce((acc,curr)=> {
            return acc +curr.rowSumExclMoms
          },0)
         const invoiceIndex = buff.report.invoices?.findIndex(x => x.invoiceNumber == itemToLookUp.invoiceNumber)
       
         if(invoiceIndex !== undefined && buff.report.invoices !== undefined){

           buff.report.invoices[invoiceIndex].priceExcMoms = newInvoiceSum
           buff.report.invoices[invoiceIndex].priceIncMoms = newInvoiceSum * 0.25 + newInvoiceSum
         }
        }
        else{
          buff.report.invoices =  buff.report.invoices?.filter(x => x.invoiceNumber !== itemToLookUp.invoiceNumber)
        }
      }
      const totSum:number = editUtils.CalculateNewSumTotal(buff,'csrdGroup','rowSumExclMoms')
      buff.report.totalSumExclMoms = totSum
      buff.report.totalSumExclMomsImported = totSum
      buff.report.noOfInvoices = buff.report.invoices?.length ?? 0
      buff.report.noOfInvoicesImported = buff.report.invoices?.length ?? 0
      this.setState({data:buff,actionModal:{action:'NONE',show:false,id:-1}})
    }
  }
  callBack <T extends object> (item:T,index?:number,key?:keyof T){
    const buff = this.createRightTypeForReportDataInStateAndClone(this.props.reportType) 
    if(this.props.reportType == 'Invoicereport' ){
      
      
    }
    if(key  == 'rowSumExclMoms'){
      const rowSum =  {...item} as InternalInvoiceWithCsrdAndGroupId
      buff.report.priceExcMoms = buff.report.priceExcMoms + rowSum.rowSumExclMoms
    }
    if((this.props.reportType == 'Invoicereport' || this.props.reportType == 'Projectreport' )&& /*buff !== undefined && buff['report'] !== undefined &&*/ buff['report']?.invoices !== undefined && index !== undefined /*&& key == ('customerName'||'customerOrgId'||'contractId')*/){
      const invoice = {...item} as InternalInvoiceEdit/*Invoice*/
      const customerId = this.state.customers.filter(x => x.kndOrgnr == +invoice.customerOrgId)[0]
      if(customerId !== undefined){

        invoice.customerId = customerId.kndLopnr.toString()
      }
      invoice.priceIncMoms = invoice.priceExcMoms * 0.25 + invoice.priceExcMoms
      buff.report.invoices[index] = invoice
      const totSum:number = editUtils.CalculateNewSumTotal(buff,'invoices','priceExcMoms')
      
      buff.report.totalSumExclMoms = totSum
      buff.report.totalSumExclMomsImported = totSum
      this.setState({data:buff})
    }
    else if((this.props.reportType == 'InvoicereportWithCSRD' || this.props.reportType == 'ProjectreportWithCSRD') && /*buff !== undefined && buff['report'] !== undefined && */buff['report']?.csrdGroup !== undefined && index !== undefined ){ 
      const foundIndex = buff.report.csrdGroup.findIndex((x:InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/) => x.id == index)
      if(foundIndex > -1){

        buff.report.csrdGroup[foundIndex]  = item as InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/
      }
    if(key == 'customerName' || key == 'customerOrgId' || key == 'contractId'){
        const group = item as InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/
       let matches =  buff.report.csrdGroup.filter((x:InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/) => x.groupId == group.groupId)
        matches.forEach((x:InternalInvoiceWithCsrdAndGroupId/*InvoicesWithCsrdAndGroupId*/) => {
          x.contractId = group.contractId
          x.customerName = group.customerName
          x.customerOrgId = group.customerOrgId
          
        })
      }
      const totSum:number = editUtils.CalculateNewSumTotal(buff,'csrdGroup','rowSumExclMoms')
      buff.report.totalSumExclMoms = totSum
      buff.report.totalSumExclMomsImported = totSum
      this.setState({data:buff},()=> this.setState({data:editUtils.UpdateMainInvoiceWhenChangeOccursOnCsrdArticleRows(this.state.data,item as InternalInvoiceWithCsrdAndGroupId,this.state.customers)}))
    
    }
  }
 invoiceReportOnChange(e:React.ChangeEvent<HTMLTextAreaElement> | number,key:keyof Pick<InternalInvoiceReport,'departmentId' | 'comment'>,item?:InternalInvoiceReport/*InvoiceReport*/){
    const buff:StandardInvoiceReport = structuredClone(this.state?.data) as StandardInvoiceReport
    if(buff !== undefined && buff['report'] !== undefined ){
      if(key == 'comment' && typeof e !== 'number' ){
        buff.report.comment = e.target.value
        this.setState({data:buff})
      }
      else if(key == 'departmentId' && typeof e == 'number'){
        buff.report.departmentId = this.state.actionModal.id
        const newDepartment = this.state.departments?.find(x => x.id == e)
        this.setState({data:buff,department:newDepartment,actionModal:{action:'NONE',show:false,id:-1}}) 
      }
     }
  }
  createRightTypeForReportDataInStateAndClone <T extends EditReportType> (reportType:EditReportType):ReportDataMapping[T] {
    
    const buff:ReportDataMapping = {
        'Invoicereport':structuredClone(this.state?.data) as StandardInvoiceReport ?? {},
        'InvoicereportWithCSRD':structuredClone(this.state?.data) as StandardInvoiceReport ?? {},
        'Kickbackreport':{},
        'Logs':{},
        'Projectreport':structuredClone(this.state?.data) as StandardInvoiceReport ?? {},
        'ProjectreportWithCSRD':structuredClone(this.state?.data) as StandardInvoiceReport ?? {}
      }
      return buff[reportType]
  }
  returnBasedOnStandardReportContainsLogsOrNot = () => {
    const hasLogs = 'Logs' in this.state.data;
    return(
      <>
        { this.state.data !== undefined && (this.props.reportType == 'Invoicereport' || this.props.reportType == 'Projectreport')  &&
        <StandardInvoiceReportView editHistory={this.state.editHistory} department={this.state.department} departments={this.state.departments} submitActive={this.state.submitActive} submit={this.handleSubmit} customers={this.state.customers} contract={this.state.contract} copyRow={this.copyRow} handleDeleteModal={this.handleActionModal} deleteModal={this.state.actionModal} deleteRow={this.deleteRow} addRow={this.addRow} data={this.state.data.report} reportType={this.props.reportType} logs={hasLogs ? this.props.data?.['Logs'] : undefined} invoiceReportOnChange={this.invoiceReportOnChange} callBack={this.callBack}/>
        }
      </>
    )
  }
  returnBasedOnStandardCsrdReportContainsLogsOrNot = () => {
    const hasLogs = 'Logs' in this.props.data;
    return(
      <>
        { this.state.data !== undefined && (this.props.reportType ==  'InvoicereportWithCSRD'   || this.props.reportType == 'ProjectreportWithCSRD') &&
        <StandardInvoiceReportView editHistory={this.state.editHistory} department={this.state.department} departments={this.state.departments} submitActive={this.state.submitActive} submit={this.handleSubmit} customers={this.state.customers} contract={this.state.contract} copyRow={this.copyRow} handleDeleteModal={this.handleActionModal} deleteModal={this.state.actionModal} deleteRow={this.deleteRow} addRow={this.addRow} data={this.state.data.report} reportType={this.props.reportType} logs={hasLogs ? this.props.data?.['Logs'] : undefined} invoiceReportOnChange={this.invoiceReportOnChange} callBack={this.callBack}/>
        }
      </>
    ) 
  }
  
  render(){
    
    return(<>
      <>
       {this.state.loading && 
       <div style={{display:'flex',flexDirection:'column',flexWrap:'wrap',alignContent:'center'}}>
       <Spinner/>
       </div>
       }
       
       {!this.state.loading && (this.props.reportType == 'Invoicereport' || this.props.reportType == 'Projectreport' )   && typeof this.state.data !== 'undefined' &&          
         this.returnBasedOnStandardReportContainsLogsOrNot()
       }
      {!this.state.loading && (this.props.reportType == 'InvoicereportWithCSRD' || this.props.reportType == 'ProjectreportWithCSRD' )&& typeof this.state.data !== 'undefined' && 
        this.returnBasedOnStandardCsrdReportContainsLogsOrNot()
      }
    
       </>
    </>)
  }
}










































































/*Implementera i mån av tid*/
// const   SearchData = async (e: any,reportType:EditReportType,data:/*GroupedDataByKey*/any) => {  
//     const res: any[] = [];
  
//      setTimeout(async () =>{
//       if (e.target.value === '') {
//         //this.setState({searchedData:[],searched:false})
//         return {searchedData:[],searched:false}
        
//       } else {
       
//         for (let index = 0; index < data.length; index++) {
//           let val: boolean[] = [];
//           // eslint-disable-next-line array-callback-return
//           Object.values(data[index]).filter((value) => {
//             if (typeof value === "string") {
//               if (value.toLowerCase().includes(e.target.value.toLowerCase())) {
//                 val.push(true);
//               }
//             }
//           });
//           if (val.includes(true)) {
//             res.push(data[index]);
//           }
//         }
//       }
  
//       if (res.length > 0) {
//         //this.setState({ searchedData: res });
//         return {searchedData:res,searched:true}
//       }
//       else{ 
//         // this.setState({searchedData:[]})
//         return {searchedData:[],searched:true}
//       }
//      },500)

   
//   };