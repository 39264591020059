import Urls from "../../lib/Urls";
import { apiFetch, convertToQuerystring } from "../../lib/utils";

export interface Customer {
  kndLopnr:number;
  kndNamn: string;
  kndNamnKey: string;
  kndOrgnr: number;
  raderad: boolean;
  isSupplier:boolean;
}

export type CustomerGetOptions = {select?: (keyof Customer)[], filter?: string}
const Get = async (opt?:CustomerGetOptions) : Promise<Customer[]> => { 
  const queryString = convertToQuerystring(opt);
  const url = queryString.length > 0 ? `${Urls.Customer}?${queryString}` : `${Urls.Customer}`;
  const response = await apiFetch(url);
  const customers = await response.json();
  return customers;
}



export {
  Get
}