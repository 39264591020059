import { faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Spinner } from 'react-bootstrap';

export interface IInfobarProps {
  text:string;
  useSpinner?:boolean;
  type?: "error"|"info";
}

export function Infobar (props: IInfobarProps) {
  const type = props.type ?? "info";
  return (
    <div className='infobar-wrapper'>
      <div className='infobar' aria-label={type}>
        {
          props.useSpinner ?
          <Spinner style={{marginRight:8, width:25, height:25}}/>
          :
          <FontAwesomeIcon icon={faInfoCircle}/>
        }
          <div>
            {props.text}
          </div>
      </div>
    </div>
  );
}
