/* eslint-disable @typescript-eslint/no-useless-constructor */

import * as React from "react";
import { withTranslation } from "react-i18next";
import {
  Line,
  LineChart
} from "recharts";


interface ICardProps {
  //loading data color
  dataPointsTrendline?: any;
  color: string;
}
interface ICardState {
  width: number;
  height: number;
}
class CardChart extends React.Component<ICardProps, ICardState> {
  constructor(props: ICardProps) {
    super(props);

    this.state = { width: window.innerWidth, height: window.innerHeight };
  }
  componentDidMount(): void {
    window.addEventListener("resize", this.HandleRezise);
  }
  componentWillUnmount(): void {
    window.removeEventListener("resize", this.HandleRezise);
  }
  HandleRezise = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  render() {
    return (
      <div  style={{ display: "flex" }}>
        <div  style={{ flex: 1 }}>
          <LineChart
         
            width={this.state.width <= 600 ? this.state.width / 1.5 :this.state.width / 4}
            height={50}
            data={this.props.dataPointsTrendline}
          >
            <Line
              type="monotone"
              dot={false}
              dataKey="totalReported"
              stroke={this.props.color}
              strokeWidth={2}
            />
          </LineChart>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CardChart);
