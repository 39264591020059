import * as React from "react";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { StyledModal } from "../modal/StyledModal";
import { Button, Dropdown, Stack } from "react-bootstrap";
import { DataService } from "../../services/DataService";
import { IconLookup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";
import { TSupplier } from "../../services/API/Suppliers";

type Status = {
  key: number;
  value: string;
  element: JSX.Element;
};
interface Statt {
  [key: number]: any;
}
interface IFileModalProps {
  data: any;
  handleClose: (
    dissmiss: boolean,
    info?: any,
    status?: number,
    data?: any
  ) => void;
  supplier: TSupplier[];
}
interface IFileModalState {
  loading: boolean;
  labels: JSX.Element[];
  showConfirmationMsg: boolean;
  statt: any;
  status: Status;
}

class KickbackReportFileModal extends React.Component<
  IFileModalProps,
  IFileModalState
> {
  constructor(props: IFileModalProps) {
    super(props);
    this.state = {
      loading: true,
      labels: [<></>],
      showConfirmationMsg: false,
      statt: this.findIcons(),
      status: this.initStatus(),
    };
  }
  componentDidMount(): void {
    this.buildLabels();
  }
  private initStatus = () => {
    if (typeof this.props.data !== "undefined") {
      let initialstatus: any;
      const icOOOOn = AppSettings.kickbackReportsStatusIcons.find(
        (x) => x.key === this.props.data.status
      );
      const name: any = icOOOOn?.icon;
      const lookup: IconLookup = { prefix: "fas", iconName: name };
      initialstatus = {
        key: icOOOOn?.key,
        value: icOOOOn?.status,
        element: (
          <>
            {" "}
            <FontAwesomeIcon
              title={i18n.t(`kickback.status.${icOOOOn?.key}`) || ""}
              style={{ color: `${icOOOOn?.color}` }}
              width={15}
              icon={findIconDefinition(lookup)}
            />{" "}
            {i18n.t(`kickback.status.${icOOOOn?.key}`)}{" "}
          </>
        ),
      };
      return initialstatus;
    }
  };
  private findIcons = () => {
    const statt: Statt = {};
    AppSettings.kickbackReportsStatusIcons.map((x, index) => {
      const name: any = x.icon;
      const lookup: IconLookup = { prefix: "fas", iconName: name };
      statt[`${x.key}`] = {
        key: x.key,
        value: i18n.t(`kickback.status.${x.key}`),
        element: (
          <>
            {" "}
            <FontAwesomeIcon
              title={i18n.t(`kickback.status.${x.key}`) || ""}
              style={{ color: `${x.color}` }}
              width={15}
              icon={findIconDefinition(lookup)}
            />{" "}
            {i18n.t(`kickback.status.${x.key}`)}{" "}
          </>
        ),
      } as Status;
    });
    return statt;
  };
  private buildLabels = () => {
    const { supplierOrgId, reportDate, period, reporter } = this.props.data;
    const supplierName = this.props.supplier.find(
      (x) => x.supplierOrgNr === supplierOrgId
    );
    const res: JSX.Element[] = [];
    res.push(
        <>
        <div>
        <b  key="1">{i18n.t("kickback.fileModal.supplier")}</b>        
        </div>
        <div>        
        {supplierName?.supplierName} - {supplierOrgId}
        </div>
        </>,

        <>
          <div>
          <b key="2">{i18n.t("kickback.fileModal.reportDate")}</b>
          
          </div>
          <div>
          {reportDate.toLocaleDateString("sv-SE")}
          </div>
          </>,
          <>
          <div>
          <b key="3">{i18n.t("kickback.fileModal.period")}</b>
          </div>
          <div>
          {period}
          </div>
          </>,
          <>
          <div>
          <b key="4">{i18n.t("kickback.fileModal.reporter")}</b>
          </div>
          <div>
          {reporter.includes('@') ? <a href={`mailto:${reporter}`}>{reporter}</a> : reporter}
          </div>
          </>
     
    );
    this.setState({ labels: res });
  };
  handleFileSave = async () => {
    await DataService.Kickback.DownloadFile(
      this.props.data.fileName as string,
      this.props.data.supplierOrgId
    );
  };
  handleFileDeleteConfirmation = async () => {
    if (this.state.status.key === 9) {
      this.setState({ showConfirmationMsg: true });
    } else if (this.state.status.key !== this.props.data.status) {
      const response = await DataService.Kickback.UpdateFileStatus(
        this.props.data.fileName as string,
        this.props.data.supplierOrgId,
        this.state.status.key
      );
      this.props.handleClose(
        false,
        response,
        this.state.status.key,
        this.props.data
      );
    } else {
      this.props.handleClose(false);
    }
  };
  handleFileDelete = async () => {
    const response = await DataService.Kickback.DeleteFile(
      this.props.data.fileName as string,
      this.props.data.supplierOrgId
    );

    if (response.Code === 0) {
      //success
      this.setState({ showConfirmationMsg: false });
      this.props.handleClose(false, response,this.state.status.key);
    } else {
      //error
      this.setState({ showConfirmationMsg: false });
      this.props.handleClose(false, response);
    }
  };
  render() {
    return (
      <>
        {!this.state.showConfirmationMsg && (
          <StyledModal
            title={`${i18n.t("kickback.fileModal.handleKickbackFile")}`}
            onDismiss={() => this.props.handleClose(false)}
            body={
              <Stack gap={3}>
                <div style={{ position: "relative" }}>
                  <div style={{ float: "right" }}>
                    <Dropdown>
                      <Dropdown.Toggle bsPrefix="newStyleToggle">
                        {this.state.status.element}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {Object.entries(this.state.statt).map(([x, value]) => {
                          return (
                            <Dropdown.Item
                              key={x}
                              id={`${x}`}
                              onClick={() =>
                                this.setState({
                                  status: this.state.statt[`${x}`],
                                })
                              }
                            >
                              {this.state.statt[`${x}`].element}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <Stack gap={1}>
                  
                    {this.state.labels.map((x, index) => {
                      return <div key={index}>{x}</div>;
                    })}
                  
                  </Stack>
                </div>
              </Stack>
            }
            footer={
              <Stack
                className="submit-modal-body"
                style={{ alignSelf: "center" }}
                gap={3}
                direction="horizontal"
              >
                <Button
                  onClick={() => this.handleFileSave()}
                  className="button-primary"
                >
                  {" "}
                  {i18n.t("kickback.fileModal.saveButton")}
                </Button>
                <Button
                  onClick={() => this.handleFileDeleteConfirmation()}
                  className="button-primary"
                >
                  {i18n.t("kickback.fileModal.deleteButton")}
                </Button>
                <Button
                  onClick={() => this.handleFileDeleteConfirmation()}
                  className="button-cancel"
                >
                  {i18n.t("general.cancel")}
                </Button>
              </Stack>
            }
          />
        )}
        {this.state.showConfirmationMsg && this.state.status.key === 9 && (
          <StyledModal
            title={`${i18n.t("kickback.fileModal.confirmation.title")}`}
            onDismiss={() =>
              this.setState((prevState) => ({
                showConfirmationMsg: !prevState.showConfirmationMsg,
              }))
            }
            body={
              <>
                <b style={{ paddingBottom: "15px" }}>
                  {i18n.t("kickback.fileModal.confirmation.confirmationText")}
                </b>
              </>
            }
            footer={
              <Stack
                className="submit-modal-body"
                style={{ alignSelf: "center" }}
                gap={3}
                direction="horizontal"
              >
                <Button
                  onClick={() => this.handleFileDelete()}
                  className="button-danger"
                >
                  {i18n.t("kickback.fileModal.confirmation.yesButton")}
                </Button>

                <Button
                  onClick={() =>
                    this.setState((prevState) => ({
                      showConfirmationMsg: !prevState.showConfirmationMsg,
                    }))
                  }
                >
                  {i18n.t("kickback.fileModal.confirmation.noButton")}
                </Button>
              </Stack>
            }
          />
        )}
      </>
    );
  }
}
export default withTranslation()(KickbackReportFileModal);
