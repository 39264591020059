import { t } from 'i18next';
import * as React from 'react';
import { Stack } from 'react-bootstrap';
import FilterForm from '../FilterForm/FilterForm';
import { TSupplier } from '../../services/API/Suppliers';

export interface IReportsFilterProps {
  title:string;
  reportDateBegin: Date|null;
  setReportDateBegin(date:Date|null):void;
  reportDateEnd: Date|null;
  setReportDateEnd(date:Date|null):void;
  periodBegin: Date|null;
  setPeriodBegin(date:Date|null):void;
  periodEnd: Date|null;
  setPeriodEnd(date:Date|null):void;
  allSuppliers:TSupplier[];
  selectedSuppliers:string[];
  setSelectedSupplier(suppliers:string[]):void;
  allStatuses:{key:string; text:string}[];
  selectedStatuses:string[];
  setSelectedStatuses(selected:string[]):void;
  isOpen:boolean;
}

export interface IReportsFilterState {
}

export default class ReportsFilter extends React.Component<IReportsFilterProps, IReportsFilterState> {
  constructor(props: IReportsFilterProps) {
    super(props);
  }

  public render() {
    return (
      <Stack gap={1} aria-expanded={this.props.isOpen} className='report-filter'>
        <div className='report-filter-title'>{this.props.title}</div>
        <FilterForm 
          filters={[
            {
              type: "Choice",
              key: "supplier",
              label: t("general.supplier"),
              options: this.props.allSuppliers
                .map(supp => ({key: supp.supplierOrgNr, text: `${supp.supplierName} - ${supp.supplierOrgNr}`})),
              placeholder: t("reportsFilter.supplierPlaceholder").toString(),
              setValue: (e) => { 
                this.props.setSelectedSupplier(e);
               },
              value: this.props.selectedSuppliers
            },
            {
              type: "Choice",
              key: "status",
              label: t("general.status"),
              options: this.props.allStatuses,
              setValue: (e) => { this.props.setSelectedStatuses(e); },
              value: this.props.selectedStatuses,
              placeholder: t("reportsFilter.statusPlaceholder").toString()
            },
            {
              type: "Date",
              key: "reportedFrom",
              label: t("reportsFilter.reportedFrom"),
              setValue: (e) => { this.props.setReportDateBegin(e) },
              value: this.props.reportDateBegin,
              variant: "date",
              placeholder: 'åååå-mm-dd'
            },
            {
              type: "Date",
              key: "reportedTo",
              label: t("reportsFilter.reportedTo"),
              setValue: (e) => { this.props.setReportDateEnd(e) },
              value: this.props.reportDateEnd,
              variant: "date",
              placeholder: 'åååå-mm-dd'
            },
            {
              type: "Date",
              key: "periodFrom",
              label: t("reportsFilter.periodFrom"),
              setValue: (e) => { this.props.setPeriodBegin(e) },
              value: this.props.periodBegin,
              variant: "month",
              placeholder: 'åååå-mm-dd'
            },
            {
              type: "Date",
              key: "periodTo",
              label: t("reportsFilter.periodTo"),
              setValue: (e) => { this.props.setPeriodEnd(e) },
              value: this.props.periodEnd,
              variant: "month",
              placeholder: 'åååå-mm-dd'
            }
          ]}
        />
      </Stack>
    );
  }
}
