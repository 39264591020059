import Keycloak from "keycloak-js";
import i18n, { langStorageKey } from "../i18n";
import { IsImpersonating, getActiveSupplier, getJwtToken, parseJwt } from "../lib/utils";
import { RefreshImpersonation } from "./API/Users";
import { API } from "./API";

const _kc = new Keycloak('/keycloak.json');

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: () => void) => {
  _kc.init({
    onLoad: 'check-sso', //'login-required'
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
  })
    .then((authenticated) => {
      if (!authenticated) {
        console.log("user is not authenticated..!");
        doLogin();
      }
      const storedLocale = window.sessionStorage.getItem(langStorageKey);
      if(storedLocale){
        i18n.changeLanguage(storedLocale);
      }
      else{
        //@ts-ignore
        const userLocale : string = getUser().locale;
        i18n.changeLanguage(userLocale);
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

_kc.onTokenExpired = () => {
  ensureToken()
  .then(() => {
  })
  .catch(() => {
    console.log("Failed to refresh the token, or the session has expired");
  });
};

const ensureToken = async () => {
  let jwt = getJwtToken();
  const parsedJwt = parseJwt(jwt);
  const expiration = parsedJwt.exp*1000;
  if(new Date().getTime() >= expiration){
    await Promise.all([updateToken(1),API.Users.RefreshImpersonation()]);
  }
}

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => { return _kc.token; };

const getRefeshToken = () => _kc.refreshToken;

const isLoggedIn = () => !!_kc.token;

const updateToken = (validity:number) =>
  _kc.updateToken(validity)
    .then(refreshed => refreshed)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;
const getUser = () => _kc.tokenParsed;

const hasRole = (roles: any) => roles.some((role: any) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUser,
  getUsername,
  hasRole,
  getRefeshToken,
  ensureToken
};

export default UserService;