import * as React from "react";
import { StyledModal } from "./StyledModal";
import { WithTranslation, withTranslation } from "react-i18next";
import { Button, Form, Spinner, Stack } from "react-bootstrap";
import { faPaperPlane, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { Code, IsUserSinfra } from "../../lib/utils";
import { DataService } from "../../services/DataService";

export interface IUploadKickbackModalProps extends WithTranslation {
  onDismiss(): void;
  onSubmitted(): void;
  //period: string;
 // missingPeriods:any;
  // disabled: boolean;
}

export interface IUploadKickbackModalState {
  isSaving: boolean;
  errorMsg: string;
  selectedFile: File | undefined;
  comment:string;
  period:any;
}

class UploadKickbackModal extends React.Component<
  IUploadKickbackModalProps,
  IUploadKickbackModalState
> {
  constructor(props: IUploadKickbackModalProps) {
    super(props);

    this.state = {
      isSaving: false,
      errorMsg: "",
      selectedFile: undefined,
      comment:"",
      period:""
    };

  }
  private onChangePeriod = async (period: string) => {
    
    //const year = period.slice(0, 4);
  //  const month = period.slice(5, 7);
    //const kickbacks = await DataService.Kickback.GetNewKickbackItems(year, month);
    // this.setState({ 
    //   kickbacks,
    //   period,
    //   isLoading: false
    //  })

    this.setState({period:period})
  }
  private onClickSubmit = async () => {
    if (this.state.selectedFile) {
    
      this.setState({ isSaving: true, errorMsg: "" });
      const result = await DataService.Kickback.SubmitAsFile(
        this.state.selectedFile,
        
        this.state.period,
        this.state.comment
      );
      if (result.Code == Code.OK) {
        this.props.onSubmitted();
        return;
      }
      this.setState({ errorMsg: result.Message, isSaving: false });
    }
  };
  private generateMonthOptions = () => {
    const currentDate = new Date();
    
      const dates:any =[]
      for (let index = 0; index < 5; index++) {
      const key2 = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1 - index, 1)
      .toISOString()
      .slice(0, 7);
      dates.push(this.opt(key2))  
      }
    return dates
  };
  private opt = (period: string) => {
    const date = new Date(period);
    const text = `${date.toLocaleString("default", { month: "long" })} ${date
      .getFullYear()
      .toString()}`;
    return (
      <option
        key={period}
        style={{ textTransform: "capitalize" }}
        value={period}
      >
        {text}
      </option>
    );
  };

  public render() {
    
    return (
      <StyledModal
        onDismiss={this.props.onDismiss}
        title={this.props.t("uploadKickbackModal.title").toString()}
        body={
          <Stack className="submit-modal-body" gap={3}>
             <div className="report-notice">
           <span> <FontAwesomeIcon style={{marginRight:'5px'}} color="red" icon={faWarning}/>
             {/* OBS! Skicka som fil ska bara användas ifall ni har en specialanpassad kickbackrapport som avser rapportering per projekt  */}
             {this.props.t('uploadKickbackModal.warning')}
             </span>
            </div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                {this.props.t("uploadKickbackModal.fileLabel")}:
              </Form.Label>
              <Form.Control
                type="file"
                accept=".xlsx,.csv"
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                  const files: FileList = ev.target.files as FileList;
                 
                
                    if (files.length > 0)
                    this.setState({ selectedFile: files[0] });
                  else
                  this.setState({selectedFile:files[-1]})
                  
                
                }}
              />
              
            </Form.Group>
            <Form.Group>
            <Form.Label>
               Kommentar:
              </Form.Label>
              <Form.Control
               as="textarea"
              onChange={(e) => {
                this.setState({comment:e.target.value})
              }}
              />
              </Form.Group>
              <Form.Group>
                <Form.Label>{this.props.t("general.period")}</Form.Label>
                <Form.Select
                  style={{ textTransform: "capitalize" }}
                  value={this.state.period}
                  onChange={(ev) => {
                    this.onChangePeriod(ev.target.value);
                  }}
                >
                  <option key={""} value={""}>---- ----</option>
                  {this.generateMonthOptions()}
                </Form.Select>
              </Form.Group>
            {this.state.errorMsg && (
              <div className="error-message">
                <span>{t("general.errorMsg")}:</span>
                {this.state.errorMsg}
              </div>
            )}
          </Stack>
        }
        footer={
          <Stack direction="horizontal" gap={2}>
            <Button
              disabled={
                this.state.isSaving ||
                IsUserSinfra() ||
                (this.state.selectedFile == undefined
               || this.state.comment.length == 0 || this.state.period.length == 0 )
              }
              className="button button-submit"
              onClick={this.onClickSubmit}
            >
              {this.state.isSaving ? (
                <Spinner animation="border" size={"sm"} />
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={faPaperPlane}
                    style={{ marginRight: 4 }}
                  />
                  {t("general.send")}
                </>
              )}
            </Button>
            <Button
              disabled={this.state.isSaving}
              className="button button-cancel"
              onClick={this.props.onDismiss}
            >
              {t("general.close")}
            </Button>
          </Stack>
        }
      />
    );
  }
}

export default withTranslation()(UploadKickbackModal);
