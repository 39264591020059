/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { StyledModal } from "../modal/StyledModal";
import Datalist, { ListColumn } from "../Datalist/Datalist";
import { Button, Spinner, Stack } from "react-bootstrap";
import CsvDownloader from "react-csv-downloader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReadFriendlyNumber } from "../../lib/utils";

interface IViewModalProps {
  onDissmissHandler: () => void;
  data: any;
  viewName: string;
  loading: boolean;
  typing:any;
}
interface IViewModalState {
  columns: any;
  searched:boolean;
  searchedData: any[];

  
}
class ViewModal extends React.Component<IViewModalProps, IViewModalState> {
  constructor(props: IViewModalProps) {
    super(props);

    this.state = { columns: [], searched: false, searchedData: [] };
  }
  async componentDidMount(): Promise<void> {
    const cols = await this.BuildTableHeaders(this.props.data);
    this.setState({columns:cols})
  }
  
  SearchData = async (e: any) => {  
    const res: any[] = [];
    
  
     setTimeout(async () =>{
      if (e.target.value === '') {
        // this.setState({ searchedData: [],searched:false ,vH:'48vh'},async ()=> await this.BuildTableHeaders(this.props.data));
        //await this.BuildTableHeaders(this.props.data)
        this.setState({searchedData:[],searched:false})
        
      } else {
        this.setState({searched:true})
        for (let index = 0; index < this.props.data.length; index++) {
          let val: boolean[] = [];
          Object.values(this.props.data[index]).filter((value) => {
            if (typeof value === "string") {
              if (value.toLowerCase().includes(e.target.value.toLowerCase())) {
                val.push(true);
              }
            }
          });
          if (val.includes(true)) {
            res.push(this.props.data[index]);
          }
        }
      }
  
      if (res.length > 0) {
       // await  ;
      // await this.BuildTableHeaders(res)
        this.setState({ searchedData: res });
      }
      else{ 
       // await this.BuildTableHeaders(this.props.data) 
        this.setState({searchedData:[]})
      }
     },500)
   
  };
  SwitchForHeaderTypes = async (key:any,value:any): Promise<ListColumn<any>> => {    
    switch(value){
      case "date": return { 
        fieldName: key,
        isActive: true,
        key: key,
        minWidth: '',
          maxWidth: 130,
        sorting: true,
        name: key,
        onRender:(item,col) => {
          return item[key] == null ? null :new Date( item[key]).toLocaleDateString('sv-SE')
        }
        } as ListColumn<any>
        case "datetime": return { 
          fieldName: key,
          isActive: true,
          key: key,
          minWidth: '',
          maxWidth: 130,
          sorting: true,
          name: key,
          onRender:(item,col) => {
            return item[key] == null ? null :new Date( item[key]).toLocaleDateString()
          }
          } as ListColumn<any>
      case "bit": return { fieldName: key,
        isActive: true,
        key: key,
        minWidth: '',
        maxWidth: 80,
        sorting: true,
        name: key,
        onRender:(item,col) => {
          //console.log(item[key])
          return item[key]  ? 'true' :'false'
        }
      } as ListColumn<any>
      case "decimal":return { fieldName: key,
        isActive: true,
        key: key,
        minWidth: 100,
        maxWidth: 140,
        sorting: true,
        name: key,
        onRender:(item,col) => {return ReadFriendlyNumber(item[key])}
      } as ListColumn<any>
      case "boolean":return { fieldName: key,
        isActive: true,
        key: key,
        minWidth: '',
        maxWidth: 80,
        sorting: true,
        name: key,
        onRender:(item,col) => {return item[key] ? 'true' :'false'}
      } as ListColumn<any>
      default: return {
        fieldName: key,
        isActive: true,
        key: key,
        minWidth: 100,
        maxWidth: 140,
        sorting: true,
        name: key,
        
      } as ListColumn<any>
    }
  } 
  BuildTableHeaders = async (input: any): Promise<any> => {
    const columns: any[] = [];
    Object.entries(this.props.typing).forEach(async ([key,value]) => {
      const header = await this.SwitchForHeaderTypes(key,value)
      columns.push(header)
    })
    return columns
    //this.setState({ columns: columns });
  };
  render() {
    return (
      <div>
        {this.props.loading ? (
          <Spinner />
        ) : (
          <StyledModal
            title={this.props.viewName}
            className="full-width"
            onDismiss={() => this.props.onDissmissHandler()}
            body={
              <div >
                <div
                  style={{ padding: "5px 5px 5px 5px", position: "relative" }}
                >
                  <span>
                    {" "}
                    <input
                      placeholder={`${i18n.t("dashboard.search")}`}
                      type="text"
                      onChange={(e) => this.SearchData(e)}
                    />
                    <FontAwesomeIcon
                      style={{ paddingLeft: "5px" }}
                      icon="magnifying-glass"
                    />
                    <i style={{ paddingLeft: "5px" }}>
                      {" "}
                      {this.state.searchedData.length > 0
                        ? `${this.state.searchedData.length} ${i18n.t(
                            "dashboard.hits"
                          )}`
                        : (this.state.searched ? i18n.t('dashboard.noHits'):'')}
                   
                    </i>
                  </span>
                </div>
               <div className="testForViewTable">
                  {/* <div className="datalistTable" style={{height:''}}> */}
                  <Datalist
                    items={
                    
                      this.state.searchedData.length > 0
                        ? this.state.searchedData
                        : this.props.data
                    }
                    uniqueKey="invoiceNumber"
                    columns={this.state.columns}
                    noItemsText={i18n.t('dashboard.noItems')}
                    initialSortKey=""
                    descendingSort={false}
                    setColumns={(cols) => this.setState({ columns: cols })}
                  />
                  {/* </div> */}
                  </div>
              </div>
            }
            footer={
              <Stack direction="horizontal" gap={2}>
                <CsvDownloader
                  datas={this.props.data as any}
                  extension=".csv"
                  filename={`${
                    this.props.viewName
                  }-${new Date().toLocaleDateString("sv-SE")}-${i18n.t(
                    "print.csvFileName"
                  )}`}
                  separator=";"
                  wrapColumnChar='"'
                >
                  <Button className="primary">
                    {i18n.t("print.exportButton")}
                  </Button>
                </CsvDownloader>

                <Button
                  className="button button-cancel"
                  onClick={this.props.onDissmissHandler}
                >
                  {i18n.t("dashboard.closeButton")}
                </Button>
              </Stack>
            }
          />
        )}
      </div>
    );
  }
}
export default withTranslation()(ViewModal);
