/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import * as React from 'react';
import { Button, ButtonGroup, Spinner, Stack, ToggleButton } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DataService } from '../../services/DataService';
import Datalist, { FooterOptions, ListColumn } from '../Datalist/Datalist';
import { InvoiceReport } from '../../services/API/InvoiceReports';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconLookup, faFile, faGears } from '@fortawesome/free-solid-svg-icons';
import { TKickbackReport } from '../../services/API/Kickback';
import { ReadFriendlyNumber, RoundTo } from '../../lib/utils';
import InvoiceReportModal from '../modal/InvoiceReportModal';
import { NullReport } from '../../services/API/NullReports';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Department } from '../../services/API/Departments';

export interface IReportsProps extends WithTranslation {
}
//FIXA ÖVERSÄTTNINGAR
enum ReportType {
 statistikrapport = "Statistikrapport",
 nollRapport = "Nollrapport"
}
type Report = {
  id:number;
  reportDate:Date;
  type:ReportType;
  period:string;
  noOfInvoicesImported:number | null;
  totalSumExclMomsImported:number | null;
  status:number | JSX.Element;
  
  
} 
export interface IReportsState {
  kickbackReports: TKickbackReport[];
  isLoading: boolean;
  kickbackColumns: ListColumn<TKickbackReport>[];
  rapportColumns:ListColumn<Report>[];
  showModal: boolean;
  checkedButton: number;
  selectedInvoiceId: number;
  reportData:Report[];
  department?:Department
}

class Reports extends React.Component<IReportsProps, IReportsState> {
  constructor(props: IReportsProps) {
    super(props);

    this.state = {
      isLoading: true,
      kickbackReports: [],
      checkedButton: 1,
      showModal: false,
      
      selectedInvoiceId: -1,
      kickbackColumns: this.kickbackReportColumns,
      rapportColumns:this.reportColumns,
      reportData:[]
    }
  }

  async componentDidMount(): Promise<void> {
    const [invoiceReports, kickbackReports,nullReport,department] = await Promise.all([
      DataService.InvoiceReports.Get(),
      DataService.Kickback.GetReports(true),
      DataService.NullReports.Get(),
      DataService.Departments.GetUserAssignedDepartment()

    ]);
    
    const filterdKickBackReports = kickbackReports.filter(x => x.status !== 9)
   const test =  this.buildData(invoiceReports,nullReport);
    this.setState({
      isLoading: false,
      kickbackReports: filterdKickBackReports,
     reportData:test,
     department:department.Content.response
    });
  }
  private buildData(invoiceReports: InvoiceReport[], nullReport: NullReport[]):Report[] {
    const result:Report[] = []
    for (let index = 0; index < invoiceReports.length; index++) {
      const element = invoiceReports[index];
      const holder:Report = {id:element.id,reportDate:element.reportDate,type : ReportType.statistikrapport,period:this.checkPeriod(element),noOfInvoicesImported:element.noOfInvoicesImported,totalSumExclMomsImported:element.totalSumExclMomsImported,status:<FontAwesomeIcon {...DataService.InvoiceReports.GetStatus(element)}/>} 
      result.push(holder)
    }
    for (let index = 0; index < nullReport.length; index++) {
      const element = nullReport[index];
      const holder:Report = {id:element.id,reportDate:element.reportedTime,type : ReportType.nollRapport,period:element.period,noOfInvoicesImported:0,totalSumExclMomsImported:0,status:<></>} 
      result.push(holder)
    }
    return result;
  }
  private checkPeriod(data:InvoiceReport):string{ 
    return (data.invoicesFrom.getFullYear() === data.invoicesTo.getFullYear() && data.invoicesFrom.getMonth() === data.invoicesTo.getMonth()) ?  data.invoicesFrom.getFullYear() + '-' + (data.invoicesFrom.getMonth() + 1).toString().padStart(2,"0") : `${data.invoicesFrom.toLocaleDateString('sv-SE')} - ${data.invoicesTo.toLocaleDateString('sv-SE')}`
  }
  private reportColumns : ListColumn<Report>[] = [
    {
      key: "reportDate",
      fieldName: "reportDate",
      name: this.props.t("reports.table.date"),
      minWidth: 50,
      maxWidth: 80,
      isActive: true,
      sorting: true,
      footer: FooterOptions.COUNT,
      onRender: (item ,col) => <div>{item.reportDate.toLocaleDateString(undefined, { year: "numeric", month: "2-digit", day: "2-digit" })}</div>
    },
    {
      key: "type",
      fieldName: "type",
      name: this.props.t("reports.table.type"),
      minWidth: 50,
      maxWidth: 125,
      isActive: true,  
      onRender: (item,col) => <div>{item.type === ReportType.statistikrapport ? this.props.t("reports.table.statisticReport") : this.props.t("reports.table.nullReport") }</div>
    },
    {
      key:'period',
      fieldName:'period',
      name: this.props.t("reports.table.period"),
      minWidth:50,
      maxWidth:160,
      sorting: true,
      isActive:true,
      onRender:(item,col) => <div>{item.period}</div>//this.checkPeriod(item)
    },
    {
      key: "noOfInvoicesImported",
      fieldName: "noOfInvoicesImported",
      name: this.props.t("reports.table.numberOfInvoices"),
      minWidth: 50,
      maxWidth:75,
      isActive: true,
      sorting: true,
      onRender:(item,col) => <div >{item.noOfInvoicesImported}</div>
    },
    {
      key: "totalSumExclMomsImported",
      fieldName: "totalSumExclMomsImported",
      name: this.props.t("reports.table.sum"),
      minWidth: 50,
      maxWidth:100,
      isActive: true,
      sorting: true,
      footer: FooterOptions.SUM,
      onRender: (item, col) =>< >{ ReadFriendlyNumber(item?.totalSumExclMomsImported ?? 0)}</>
    },
    {
      key:"status",
      fieldName:"status",
      name: this.props.t("reports.table.status"),
      minWidth:50,
      maxWidth:50,
      isActive:true,
      onRender: (item,col) =>  <>{item.status}</>
    }
  ]

  private kickbackReportColumns : ListColumn<TKickbackReport>[] = [
    {
      fieldName: "reportDate",
      isActive: true,
      key: "reportDate",
      minWidth: 40,
      name: this.props.t("kickback.list.reportDate").toString(),
      onRender(item, col) {
        return item.reportDate.toLocaleDateString()
      },
      footer: FooterOptions.COUNT,
      sorting: true,
      isSortedDescending: true
    },
    {
      fieldName: "period",
      isActive: true,
      key: "period",
      minWidth: 40,
      maxWidth: 80,
      sorting: true,
      name: this.props.t("kickback.list.period")
    },
    {
      fieldName: "isLink",
      isActive: true,
      key: "isLink",
      minWidth: 40,
      maxWidth: 40,
      name: "",
      onRender: (item, col, i) => {
        return  DataService.Kickback.IsFileReport(item) ? <div style={{textAlign: "center"}}><FontAwesomeIcon icon={faFile}/></div> : ""
      },
    },
    {
      fieldName: "noOfContracts",
      isActive: true,
      key: "noOfContracts",
      minWidth: 10,
      sorting: true,
      name: this.props.t("kickback.list.noOfContracts"),
      onRender: (item, col) => {
        return DataService.Kickback.IsFileReport(item) ? "" : item.noOfContracts.toString();
      }
    },
    {
      fieldName: "totalSum",
      isActive: true,
      key: "totalSum",
      minWidth: 50,
      sorting: true,
      footer: FooterOptions.SUM,
      name: this.props.t('kickback.list.totalSum'),
      onRender(item, col) {
        return DataService.Kickback.IsFileReport(item) ? "" : ReadFriendlyNumber(item.totalSum)
      },
    },
    {
      fieldName: "totalKickback",
      isActive: true,
      key: "totalKickback",
      minWidth: 50,
      sorting: true,
      name: this.props.t('kickback.list.totalKickback'),
      footer: FooterOptions.SUM,
      onRender(item, col) {
        return DataService.Kickback.IsFileReport(item) ? "" : ReadFriendlyNumber(item.totalKickback)
      },
    }
  ]

  private renderToggle = (label: string, val: number) => {
    return <div 
      className='reports-toggle'
      aria-selected={this.state.checkedButton == val}
      >
      {label}
      {
        this.state.checkedButton == val &&
        <div></div>
      }
    </div>
  }

  private toggleList = () => {
    const { checkedButton } = this.state;
    this.setState({
      checkedButton: checkedButton == 0 ? 1 : 0
    })
  }

  public render() {
    return (
      <Stack className='reports' gap={2}>
        {/* {
          this.state.isLoading &&
          <Spinner />
        } */}
        <div className='reports-title-wrapper'>
          <div className='reports-title'>{this.props.t('general.reports')}</div>
          <div className='reports-toggle-wrapper' onClick={() => { this.toggleList() }}>
            {this.renderToggle(this.props.t("reports.kickback"), 0)}
            {this.renderToggle(this.props.t("reports.invoice"), 1)}
          </div>
        </div>
          <div><i style={{color:'rgb(135, 134, 149)'}}>{this.state.department?.department}</i></div>
        <div style={{height: "calc(100% - 48px)", overflow: "hidden", borderRadius: "5px", boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}}>
        {
          this.state.checkedButton == 1 ?
          <Datalist 
            uniqueKey='id'
            items={this.state.reportData} 
            columns={this.reportColumns} 
            setColumns={(newCols) => this.setState({rapportColumns:newCols})/*this.setState({invoiceColumns: newCols})*/} 
            isLoading={this.state.isLoading}
            initialSortKey={'reportDate'}
            descendingSort={false}
            noItemsText={this.props.t('reports.empty')}
            onClick={(item)=> { item.type !== ReportType.nollRapport && this.setState({ selectedInvoiceId: item.id }) }}
          />
          :
          <Datalist 
            uniqueKey='reportId'
            items={this.state.kickbackReports} 
            columns={this.kickbackReportColumns} 
            setColumns={(newCols) => this.setState({kickbackColumns: newCols})} 
            isLoading={this.state.isLoading}
            initialSortKey={'reportDate'}
            descendingSort={false}
            noItemsText={this.props.t('reports.empty')}
          />
        }
        </div>
        {
          this.state.selectedInvoiceId > -1 &&
          <InvoiceReportModal 
            onDismiss={() => { this.setState({ selectedInvoiceId: -1 }) }} 
            invoiceReportId={this.state.selectedInvoiceId} 
          />
        }
      </Stack>
    );
  }
}


export default withTranslation()(Reports);


