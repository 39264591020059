import { EditHistory } from "../../components/EditReports/editTypes";
import Urls from "../../lib/Urls";
 import {  apiFetch } from "../../lib/utils";


const GetEditHistory = async(batchId:string) => {
        try {
            const res =  await apiFetch(Urls.EditReport + `/BatchId?batchId=${batchId}`,'GET',{"Content-Type": "application/json"})
            const history = await res.json()
            const result:EditHistory[] = history.map((x:any) => {
                return {...x,changeDate:new Date(x.changeDate)}
            })
            return result
        } catch (error) {
            console.log(error)
        }
}
const SubmitEditedKickback = async(kickbackReport:any,reportType:string) => {
    try{
       
        const parsedReport = {
            reportId:kickbackReport?.reportId,
            reporter:kickbackReport?.reporter,
            status:kickbackReport?.status,
            department:kickbackReport?.department,
            contracts:kickbackReport?.contracts,
            kickbackChanges:kickbackReport?.kickbackChanges
        }
        const send = {report:parsedReport,reportType}
        const responese = await apiFetch(Urls.EditReport+'/editedKickback','POST',JSON.stringify(send),{"Content-Type": "application/json"});
        return responese
    }catch(error){
        console.log(error)
    }
}
const SubmitEditedReport = async(reportType:string,report?:any) => {
    try {
        
     const stringifiedReport = JSON.parse( JSON.stringify(report,(key:any,value:any) => {
            //console.log(key,value)
            if(key == 'reportDate'){
     
                return  new Date(value as string).toLocaleDateString('sv-SE',{year:"numeric",month:'2-digit',day:'2-digit',hour:'2-digit',minute:'2-digit',second:'numeric'})
              }
              if(key == 'invoicesFrom' && (value !== null || undefined)){
                return  new Date(value as string).toLocaleDateString('sv-SE',{year:"numeric",month:'2-digit',day:'2-digit',hour:'2-digit',minute:'2-digit',second:'numeric'})
              }
              if(key == 'invoicesTo'){
                return  new Date(value as string).toLocaleDateString('sv-SE',{year:"numeric",month:'2-digit',day:'2-digit',hour:'2-digit',minute:'2-digit',second:'numeric'})
              }
              if(key == 'invoices'){
              value =    value?.map((x:any) => {
                    return {...x,
                        billingDate:new Date(x['billingDate'] as string).toLocaleDateString('sv-SE',{year:"numeric",month:'2-digit',day:'2-digit',hour:'2-digit',minute:'2-digit',second:'numeric'}),
                        dueDate:new Date(x['dueDate'] as string).toLocaleDateString('sv-SE',{year:"numeric",month:'2-digit',day:'2-digit',hour:'2-digit',minute:'2-digit',second:'numeric'}),
                        sendDate:new Date(x['sendDate'] as string).toLocaleDateString('sv-SE',{year:"numeric",month:'2-digit',day:'2-digit',hour:'2-digit',minute:'2-digit',second:'numeric'})
                    }
                 })   
                 return value
              }
              if(key == 'csrdGroup'){
                value =    value?.map((x:any) => {
                    return {...x,
                        billingDate:new Date(x['billingDate'] as string).toLocaleDateString('sv-SE',{year:"numeric",month:'2-digit',day:'2-digit',hour:'2-digit',minute:'2-digit',second:'numeric'})
                    }
                 })   
                 return value
              }
              return value
        }))
        const send = {report:stringifiedReport.report,reportType:reportType}
        const responese = await apiFetch(Urls.EditReport,'POST',JSON.stringify(send),{"Content-Type": "application/json"});
        return responese
    } catch (error) {
        console.log(error)
    }

}
export {
    SubmitEditedReport,
    GetEditHistory,
    SubmitEditedKickback
}