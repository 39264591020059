import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Popover, Stack } from 'react-bootstrap';

import { userNameObject, userProfileObject } from "../../lib/types";
import { ImpersonationKEY, IsImpersonating, IsUserSinfra, getActiveSupplier, getProfileObject, parseJwt, setActiveSupplier } from "../../lib/utils";
//import { longLogoSrc } from "../../constants/constants";

import AuthService from '../../services/AuthService';

import './usernavprofile.scss';
import { ImpersonationModal } from "../modal/ImpersonationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { TSupplier } from "../../services/API/Suppliers";

const UserNavProfile = (props : {user:any, userSuppliers: TSupplier[]}) => {
    const [profileObject, setProfileObject] = useState<userProfileObject>();
    const [overlayShow, setOverlayShow] = useState(false);
    const [ showImpersonationModal, setShowImpersonationModal ] = useState(false);

    useEffect(() => {
        const cObj = getProfileObject(AuthService.getUser() as userNameObject);
        setProfileObject(cObj);
    }, []);

    const { t, i18n } = useTranslation();

    const logout = () => {
        window.sessionStorage.removeItem(ImpersonationKEY);
        AuthService.doLogout({redirectUri: window.location.origin});
    }

    const getPopover = () => {
        return <Popover id="user-nav-profile-popover" className="user-nav-profile-popover">
            <Popover.Header className="user-nav-profile-popover-header">
            <Stack gap={1} style={{alignItems: "flex-end"}}>
                {
                    IsImpersonating() &&
                    <div>
                        ({AuthService.getUser()?.name})
                    </div>
                }
                {props.user?.name}
            </Stack>
            </Popover.Header>
            {
                IsUserSinfra() == false &&
                <div className="user-nav-supplier-list">
                {
                    props.userSuppliers.map((supplier:TSupplier) => {
                        return <div className="user-nav-profile-popover-logout user-nav-supplier" key={supplier.supplierOrgNr} onClick={() => {
                            setOverlayShow(false);
                            setActiveSupplier(supplier.supplierOrgNr);
                            window.location.reload();
                        }}>
                            {
                                getActiveSupplier() == supplier.supplierOrgNr ?
                                <FontAwesomeIcon icon={faCheck}/>
                                :
                                <div></div>
                            }
                            {`${supplier.supplierName} (${supplier.supplierOrgNr})`}
                        </div>
                    })
                }
                </div>
            }
            {
                IsUserSinfra() &&
                <div className="user-nav-profile-popover-logout" onClick={() => {
                    setOverlayShow(false);
                    setShowImpersonationModal(true);
                }}>
                    {t('nav.impersonate')}
                </div>
            }{
                IsImpersonating() &&
                <div className="user-nav-profile-popover-logout" onClick={() => {
                    window.sessionStorage.removeItem(ImpersonationKEY);
                    window.location.reload();
                }}>
                    {t('nav.cancelImpersonation')}
                </div>
            }
            <div className="user-nav-profile-popover-logout" onClick={logout}>
                {t('auth.logout')}
            </div>
        </Popover>;
    }

    const renderImpersonationProfile = () => {
        const tokenInfoString = window.sessionStorage.getItem(ImpersonationKEY)
        if(!tokenInfoString)
        return <></>
        const tokenInfo = JSON.parse(tokenInfoString);
        const accessTokenInfo = parseJwt(tokenInfo.access_token);
        const profileInfoObj = getProfileObject(accessTokenInfo);
        return <div className="user-nav-profile-root" 
            style={{ 
                position: 'absolute',
                top: '6px',
                right: '8px',
                backgroundColor: profileInfoObj?.bgColor, 
                color: profileInfoObj?.txtColor 
            }}
            >
            <div className="user-nav-profile-initials">
                {profileInfoObj?.initials}
            </div>
        </div>
    }

    const currentSupplier = props.userSuppliers.find(supp => supp.supplierOrgNr == getActiveSupplier())
    return (
        <>{
            props.user &&
            <div>
            <OverlayTrigger show={overlayShow} onToggle={(newVal) => setOverlayShow(newVal)} trigger={"click"} placement={"bottom"} overlay={getPopover()} rootClose={true}>
                    <div style={{position:"relative"}}>
                <div className="user-nav-profile-root" style={{ backgroundColor: profileObject?.bgColor, color: profileObject?.txtColor }}>
                    <div className="user-nav-profile-initials">
                        {profileObject?.initials}
                    </div>
                </div>
                {
                    IsImpersonating() &&
                    renderImpersonationProfile()
                }
                </div>
            </OverlayTrigger>
                
            {
                showImpersonationModal &&
                <ImpersonationModal 
                    onDismiss={() => setShowImpersonationModal(false)}
                />
            }
            </div>
        }
            
        </>
    );
};

export default UserNavProfile;


