import * as React from 'react';

interface IStyledCheckboxProps {
  isChecked:boolean;
  label:string;
  onChange(newVal:boolean):void;
  disabled?:boolean;
}

const StyledCheckbox: React.FunctionComponent<IStyledCheckboxProps> = (props) => {



  return <label className="styled-checkbox">
    <input disabled={props.disabled} onChange={(ev) => props.onChange(ev.target.checked)} type="checkbox" name="checkbox" checked={props.isChecked}/>
    {props.label}
  </label>
};

export default StyledCheckbox;
