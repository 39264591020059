/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { DataService } from "../../services/DataService";
import {
  Code,
  FetchReturn,
  NumberFormatter,
  ReadFriendlyNumber,
  RoundTo,
  StringToNumber,
} from "../../lib/utils";
import { Button, Spinner, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import i18n from "../../i18n";
import { FaFileExport } from "react-icons/fa";
import PrintModal from "../ExportAndPrintPDFcsv/PrintModal";
import { InvoiceMonthly } from "../../services/API/Invoices";
import InvoiceBarChartFilter from "./InvoiceBarChartFilter";

interface Props {}
type Option = {
  label: string;
  value: string;
};

type ValueProp = "numOfInvoices" | "sumExclMoms";

export function InvoiceBarChart(props: Props) {
  const today = new Date();
  const initialToDate = new Date(today.getFullYear(), today.getMonth());
  const initialFromDate = new Date(today.getFullYear(), today.getMonth()-12);
  const [interval, setInterval] = React.useState<{ start: Date; end: Date }>({
    end: initialToDate,
    start: initialFromDate,
  });

  const [valueKey, setValueKey] = React.useState<ValueProp>("sumExclMoms");
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [isSmallLoading, setSmallLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<InvoiceMonthly[]>([]);
  const [contractIdFilter, setContractIdFilter] = React.useState<any[]>([]);
  const [showPrintModal, setShowPrintModal] = React.useState(false);
  const [showDataError, setShowDataError] = React.useState(false);
  const { t } = useTranslation();
  const localStorageKey = "cachedInvoiceBarData";

  function handleClose() {
    setShowPrintModal(false);
  }
  function handleDataError() {
    setShowPrintModal(false);
    setShowDataError(true);
  }
  function printModalLoading(value: boolean) {
    setSmallLoading(value);
  }

  const testids = React.useMemo(() => {
    if (isSmallLoading == false) {
      const test = data
        .map((inv) => inv.contractId)
        .filter((id, ind, self) => {
          if (self.indexOf(id) == ind) {
            return id;
          }
        });
        const sortedIds:string[] = test.sort((x,y) => {if (x > y) {
          return 1;
      }
      if (y > x) {
          return -1;
      }
      return 0;})
      return sortedIds.map((x) => {
        return { label: x, value: x } as Option;
      });
    }

    return [];
  }, [data]);
  

  React.useEffect(() => {
    //CHECK FOR CACHED DATA
    const potentialData = window.localStorage.getItem(localStorageKey);
    if (potentialData) {
      const cachedData = potentialData.split("#").map((cachedPost) => {
        const cachedProps = cachedPost.split(";");
        return {
          priceExcMoms: StringToNumber(cachedProps[0]),
          billingDate: new Date(cachedProps[1]),
          noOfInvoices: Number(cachedProps[2]),
        };
      });
      setData(cachedData as InvoiceMonthly[]);
      setLoading(false);
      setSmallLoading(true);
    }

    DataService.Invoices.GetMonthly()
      .then((data) => {
        setData(data);
        setLoading(false);
        setSmallLoading(false);

        //CACHE DATA
        const dataToCache = data
          .map(
            (inv) =>
              `${inv.priceExcMoms};${inv.billingDate.toLocaleDateString(
                "sv-se"
              )};${inv.noOfInvoices}`
          )
          .join("#");
        window.localStorage.setItem(localStorageKey, dataToCache);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleContractIdSelection = (selected: any) => {
    setContractIdFilter(selected);
  };
  const handleValueKeyChange = (ev: string) => {
    setValueKey(ev as ValueProp);
  };
  const getValue = (invoices: InvoiceMonthly[]) => {
    if (valueKey == "sumExclMoms") {
      return invoices.reduce(
        (prev, curr, i, self) => prev + curr.priceExcMoms,
        0
      );
    } else {
      return invoices.reduce(
        (prev, curr, i, self) => prev + curr.noOfInvoices,
        0
      );
    }
  };

  const getDataPerMonth = () => {
    let startDate = new Date(interval.start.toISOString());
    let months: Date[] = [];
    while(startDate.getTime() <= interval.end.getTime()){
      months = [...months, startDate];
      startDate = new Date(startDate.getFullYear(), startDate.getMonth()+1)
    }
    return months.map((m) => {
      const activitiesThisMonth = data.filter(
        (invoice) =>
          invoice.billingDate.getMonth() == m.getMonth() &&
          invoice.billingDate.getFullYear() == m.getFullYear() &&
          (contractIdFilter.length > 0
            ? contractIdFilter.find((x: Option) =>
                x.value.includes(invoice.contractId)
              )
            : true)
      );

      let dataObj = {
        name: m.toLocaleDateString(i18n.language, { month: "short" }),
        value: getValue(activitiesThisMonth),
        tooltipTitle: m.toLocaleDateString(i18n.language, {
          year: "numeric",
          month: "long",
        }),
        [t("general.sumExclMoms")]: ReadFriendlyNumber(
          RoundTo(
            activitiesThisMonth.reduce(
              (prev, curr, i, self) => prev + Number(curr.priceExcMoms),
              0
            ),
            2
          )
        ),
        [t("general.numOfInvoices")]: activitiesThisMonth.reduce(
          (prev, curr, i, self) => prev + curr.noOfInvoices,
          0
        ),
      };
      return dataObj;
    });
  };

  const CustomTooltip: any = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const categories = Object.keys(payload[0].payload).filter(
        (key) => key != "name" && key != "value" && key != "tooltipTitle"
      );
      return (
        <div className={"invoice-barchart-tooltip"}>
          <div className={"invoice-barchart-tooltip-header"}>
            {payload[0].payload.tooltipTitle}
          </div>
          <table>
            <tbody>
              {categories.map((cat, i) => (
                <tr
                  key={payload[0].payload.tooltipTitle + i}
                  className={"invoice-barchart-tooltip-text"}
                >
                  <td>{cat}:</td>
                  <td>{payload[0].payload[cat]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }

    return null;
  };

  const renderLegend = (props: any) => {
    const renderText =
      valueKey == "numOfInvoices"
        ? t("general.numOfInvoices")
        : t("general.sumExclMoms");
    return (
      <div className="invoice-barchart-legend">
        <div className="invoice-barchart-legend-icon"></div>
        {renderText}
      </div>
    );
  };

  return (
    <>
      <Stack className="invoice-barchart" gap={2}>
        <div className="invoice-barchart-title-wrapper">
          <div className="invoice-barchart-title">
            {t("general.invoiceData")}
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            {isSmallLoading && <Spinner />}

            <div
              style={
                showDataError
                  ? { margin: "0px", padding: "0px" }
                  : { margin: "0px", padding: "0px", display: "none" }
              }
            >
              <span
                className="errorMessagePrint"
                onClick={() => setShowDataError(false)}
              >
                {" "}
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  color="red"
                />{" "}
                {t("print.error")} <FontAwesomeIcon icon={faClose} />
              </span>
            </div>

            <Button
              className="btn button-cancel"
              style={{ cursor: "pointer", float: "right" }}
              onClick={() => setShowPrintModal(true)}
            >
              <FaFileExport />
            </Button>
          </div>
        </div>

        {isLoading && (
          <div className="recharts-loading-container">
            <Spinner />
          </div>
        )}
        {!isLoading && (
          <div className="outerbuddy">
            <div className="invoice-barchart-graph-wrapper">
              <div className="filterWrapper">
                <InvoiceBarChartFilter
                  selected={contractIdFilter}
                  contractIds={testids}
                  handleContractIdFilter={handleContractIdSelection}
                  contractIdFilter={contractIdFilter}
                  valueKey={valueKey}
                  handleValueKeyChange={handleValueKeyChange}
                  fromDate={interval.start}
                  onChangeFromDate={(val) => {
                    const newDate = val ? val : initialFromDate;
                    const newInterval = newDate.getTime() > interval.end.getTime() ?
                      {end : newDate, start:newDate} : { end: interval.end, start: newDate };
                    setInterval(newInterval);
                  }}
                  toDate={interval.end}
                  onChangeToDate={(val) => {
                    const newDate = val ? new Date(val.getFullYear(), val.getMonth()) : initialToDate;
                    const newInterval = newDate.getTime() < interval.start.getTime() ?
                      { end : newDate, start: newDate } : { end: newDate, start: interval.start };
                    setInterval(newInterval);
                  }}
                />
              </div>

              <ResponsiveContainer>
                <BarChart data={getDataPerMonth()}>
                  <Tooltip content={<CustomTooltip />} cursor={false} />
                  <XAxis dataKey="name" />
                  <YAxis
                    width={50}
                    tickFormatter={(val: any, index: number) => {
                      return NumberFormatter(val, 2);
                    }}
                  />
                  <Legend content={renderLegend} />
                  <Bar dataKey={"value"} isAnimationActive={false}>
                    {getDataPerMonth().map((entry, index) => (
                      <Cell
                        key={index.toString()}
                        className="invoice-barchart-cell"
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
        {showPrintModal && showDataError === false && (
          <PrintModal
            show={showPrintModal}
            date={interval}
            contractIds={contractIdFilter}
            handleClose={handleClose}
            handleShow={handleDataError}
            printModalLoading={printModalLoading}
          />
        )}
      </Stack>
    </>
  );
}
