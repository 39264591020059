import React from "react";
import i18n from "i18next";
import { NavDropdown } from "react-bootstrap";
import { changedLangStorageKey, langStorageKey } from "../../i18n";
import AuthService from "../../services/AuthService";
import FlagGenerator from "./FlagGenerator";
// const svTest = require('../../../public/sweden.svg').default
// const enTest = require('../../../public/united.svg').default

export default function LangSelect() {
  const languages : {[key:string]:string} = {
    "en" : "English",
    "sv" : "Svenska"
  }

  const handleMenuItemSelect = (language: string) => {
    i18n.changeLanguage(language);
    sessionStorage.setItem(langStorageKey, language);
    //@ts-ignore
    const userLocale: string = AuthService.getUser().locale;
    if (language != userLocale)
      sessionStorage.setItem(changedLangStorageKey, "true");
    else{
      sessionStorage.removeItem(changedLangStorageKey);
    }
    window.location.reload();
  };
 
  // const getImageNode = (languageCode: string) => {
    
  //   return (
  //     <>
      
  //       <img
  //         src={
  //           AppSettings.languages.find((item) => item.locale == languageCode)
  //             ?.dataSrc
       
  //           }
  //         alt="h"
  //         width={24}
  //         height={24}
  //       />
        
  //     </>
  //   );
  // };

  const titleNode = (
    <React.Fragment>
      {/* {t('general.lang')}
        &nbsp; */}
      {/* {getImageNode(i18n.language)} */}
      {FlagGenerator(i18n.language)}
        <span className="langSelect-label show-on-mobile">{languages[i18n.language]}</span>
    </React.Fragment>
  );

  return (
    <NavDropdown
      id="LanguagePicker"
      title={titleNode}
      className="nav-item langSelect"
      style={{ minWidth: "unset" }}
    >
      {AppSettings.languages
        .filter((ln) => ln.locale != i18n.language)
        .map((item) => (
          <NavDropdown.Item
            key={item.locale}
            onClick={() => handleMenuItemSelect(item.locale)}
          >
            <div style={{ display: "table", width: "100%" }}>
              {/* {getImageNode(item.locale)} */}
              {FlagGenerator(item.locale)} 
              <span className="langSelect-label">{languages[item.locale]}</span>
            </div>
          </NavDropdown.Item>
        ))}
    </NavDropdown>
  );
}
