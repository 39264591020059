import * as React from "react";
import Excel from "exceljs";
import {
  Alert,
  Button,
  Form,
  ModalBody,
  Spinner,
  Stack,
} from "react-bootstrap";
import {
  InvoiceImportData,
  InvoiceFields,
} from "../services/API/InvoiceReports";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import {
  WithTranslation,
  useTranslation,
  withTranslation,
} from "react-i18next";
import {
  parseDate_ddMMyyyy,
  parseDate_yyyyMMdd,
  parseDate_dMyyyy,
} from "../lib/utils";
import { dateFormats } from "../constants/constants";
import { StyledModal } from "./modal/StyledModal";
import { ProjectInvoice } from "../pages/KickbackProjectReportPage";


export interface IFileUploadInputProps extends WithTranslation {
  onChange(valuesFromFile: InvoiceImportData[] | ProjectInvoice[]): void 
  invoiceFormat:InvoiceFormat;
  
}
export type InvoiceFormat = "Standard" | "Project" | "Article" | "ProjectArticle"
export interface IFileUploadInputState {
  loading: boolean;
  errorMsg: string;
  open: boolean;
  selectedDateFormat: string;
  selectedFile: FileList | undefined;
}

class FileUploadInput extends React.Component<
  IFileUploadInputProps,
  IFileUploadInputState
> {
  constructor(props: IFileUploadInputProps) {
    super(props);

    this.state = {
      loading: false,
      errorMsg: "",
      open: false,
      selectedDateFormat: "",
      selectedFile: undefined,
    };
  }

  private headersWithArticles = [
    "supplier",
    "supplierOrgId",
    "customer",
    "customerOrgId",
    "contractId",
    "invoiceNumber",
    "billingDate",
    "articleNumber",
    "articleText",
    "articleQuantity",
    "articleQuantityType",
    "articlePricePerUnit",
    "rowSumExclMoms"
  ];
  private headersWithoutArticles = [
    "supplier",
    "supplierOrgId",
    "customer",
    "customerOrgId",
    "contractId",
    "invoiceNumber",
    "billingDate",
    "sumExclMoms",
  ]
  private projectInvoiceHeaders = [
    "projectName",
    "customerName",
    "customerOrgId",
    "contractId",
    "invoiceNumber",
    "totalExclMoms",
    "billingDate",
    "reference",
    "tag",
    "projectEndDate",
    "comment"
  ]
  private projectInvoiceHeadersWithArticles = [
    "projectName",
    "customerName",
    "customerOrgId",
    "contractId",
    "invoiceNumber",
    //"totalExclMoms",
    "billingDate",
    "reference",
    "tag",
    "projectEndDate",
    "comment",
    "rowSumExclMoms",
    "articleQuantity",
    "articlePricePerUnit",
    "articleNumber",
    "articleText",
    "articleQuantityType"
  ]

  private getHeaders = () => {
    return {
      "Standard": this.headersWithoutArticles,
      "Project": this.projectInvoiceHeaders,
      "Article": this.headersWithArticles,
      "ProjectArticle": this.projectInvoiceHeadersWithArticles
    }[this.props.invoiceFormat]
  }

  private applyRegexOnCustomerOrgId = (orgId: string)  => {
    if(!orgId)
      return "";
    const match = orgId.match(/^SE(.*)01$/);
    if(match){
      return match[1]
    }
    return orgId;
  }

  private mapRow = (rowData: any, index: number) => {
    return {
      "Standard": () : InvoiceImportData => (
        {
          id: -1,
          groupId:index,
          billingDate: this.parseSelectedDateFormatting(rowData.billingDate),
          contractId: rowData.contractId,
          customer: rowData.customer,
          customerOrgId: this.applyRegexOnCustomerOrgId(rowData.customerOrgId),
          invoiceNumber: rowData.invoiceNumber,
          rowSumExclMoms: rowData.sumExclMoms,
          articleQuantity: "",
          articlePricePerUnit: "",
          articleNumber: "",
          articleText: "",
          articleQuantityType: ""
        }
      ),
      "Project": (): ProjectInvoice => (
        {
          uniqueRowId:Math.random(),
          projectName : rowData.projectName ?? '',
          customerName: rowData.customerName ?? '',
          customerOrgId: this.applyRegexOnCustomerOrgId(rowData.customerOrgId),
          contractId: rowData.contractId ?? '',
          invoiceNumber: rowData.invoiceNumber,
          totalExclMoms: { value: +rowData.totalExclMoms ?? '', display: rowData.totalExclMoms, valid: false ,error:false},
          billingDate: this.parseSelectedDateFormatting(rowData.billingDate ?? ''),
          reference: rowData.reference ?? '',
          tag: rowData.tag ?? '',
          projectEndDate: this.parseSelectedDateFormatting(rowData.projectEndDate ?? ''),
          comment: rowData.comment ?? '',
          isDuplicate:{value:false,backgorundColor:''},
          supplierOrgId:''
        } as ProjectInvoice
      ),
      "ProjectArticle": (): ProjectInvoice => (
        {
          uniqueRowId:Math.random(),
          projectName : rowData.projectName ?? '',
          customerName: rowData.customerName ?? '',
          customerOrgId: this.applyRegexOnCustomerOrgId(rowData.customerOrgId),
          contractId: rowData.contractId ?? '',
          invoiceNumber: rowData.invoiceNumber,
          totalExclMoms: { value: +rowData.totalExclMoms ?? '', display: rowData.totalExclMoms ?? '', valid: false ,error:false},
          billingDate: this.parseSelectedDateFormatting(rowData.billingDate ?? ''),
          reference: rowData.reference ?? '',
          tag: rowData.tag ?? '',
          projectEndDate: this.parseSelectedDateFormatting(rowData.projectEndDate ?? ''),
          comment: rowData.comment ?? '',
          isDuplicate:{value:false,backgorundColor:''},
          supplierOrgId:'',
          rowSumExclMoms: rowData.rowSumExclMoms ?? '',
          articleQuantity: rowData.articleQuantity ?? '',
          articlePricePerUnit: rowData.articlePricePerUnit ?? '',
          articleNumber: rowData.articleNumber ?? '',
          articleText: rowData.articleText ?? '',
          articleQuantityType: rowData.articleQuantityType ?? '',
         
        } as ProjectInvoice
      ),
      "Article": () : InvoiceImportData => (
        {
          id: -1,
          groupId:-1,
          billingDate: this.parseSelectedDateFormatting(rowData.billingDate),
          contractId: rowData.contractId,
          customer: rowData.customer,
          customerOrgId: this.applyRegexOnCustomerOrgId(rowData.customerOrgId),
          invoiceNumber: rowData.invoiceNumber,
          rowSumExclMoms: rowData.rowSumExclMoms,
          articleQuantity: rowData.articleQuantity,
          articlePricePerUnit: rowData.articlePricePerUnit,
          articleNumber: rowData.articleNumber,
          articleText: rowData.articleText,
          articleQuantityType: rowData.articleQuantityType
        }
      )
    }[this.props.invoiceFormat]();
  }

  private getValues = (
    headers: string[],
    data: string[],
    delimiter: string,
    csvHeaders?: string[]
  ):/* InvoiceImportData[] | ProjectInvoice[]*/any[] => {
    //Mathias dark magic
    const regexStr = `${delimiter}(?!(?=[^"]*"[^"]*(?:"[^"]*"[^"]*)*\$))`;
    const regex = new RegExp(regexStr, "gm");
    const splitData = data
      .map((row) => row.split(regex))
      .filter((row) => !row.every((str) => str == ""));
    return splitData.map((row,i) => {
      const rowData: any = row.reduce((prev, curr, index) => {
        const key = csvHeaders ? csvHeaders[index] : headers[index];
        
        const value = curr?.trim()?.replaceAll('"', "") ?? "";
       
        if ( headers.includes(key)) {
          return {
            ...prev,
            [key]: value,
          };
        }
        return prev;
      }, {});


      return this.mapRow(rowData,i);
    });
  };

 private  roundToPrecision = (value:number, precision:number) => {
    const factor = Math.pow(10, precision);
    return Math.round(value * factor) / factor;
}
  private readCsv = (file: File, headers: string[]) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event) => {
      const csvData = event?.target?.result as String;
      const csvRows = csvData
        .replace(/\r\n/g, "\n")
        .split("\n")
        .filter((row) => row.length);
      if (csvRows[0] == undefined) {
        return;
      }
      const delimiter = csvRows[0].split(";").length > headers.length-1 ? ";" : ",";
      const arrayData = csvRows.map((dataString) =>
        
        dataString.endsWith(delimiter) ? dataString.slice(0, -1) : dataString
        
      );
      const csvHeaders = arrayData[0].split(delimiter).map(header => header.replaceAll("\"", ""));
      
      const values =  headers.every((key) => csvHeaders.includes(key))
        ? this.getValues(headers, arrayData.slice(1), delimiter, csvHeaders)
        : this.getValues(
            headers,
            headers.some((key) => csvHeaders.includes(key))
              ? arrayData.slice(1)
              : arrayData,
            delimiter
          ); 
        
      this.props.onChange(values);
    };
  };

  private parseSelectedDateFormatting(date:string): string {
    switch (this.state.selectedDateFormat) {
      case "yyyy-MM-dd":
      const currDate = new Date(date).toLocaleDateString("sv-SE");
      return currDate
      case "dd-MM-yyyy":
        return parseDate_ddMMyyyy(date, "-");
      case "dd/MM/yyyy":
        return parseDate_ddMMyyyy(date, "/");
      case "dd.MM.yyyy":
        return parseDate_ddMMyyyy(date, ".");
      case "yyyy.MM.dd":
        return parseDate_yyyyMMdd(date, ".");
      case "yyyy MM dd":
        return parseDate_yyyyMMdd(date, " ");
      case "d/M/yyyy":
        return parseDate_dMyyyy(date, "/");
      case "d.M.yyyy":
        return parseDate_dMyyyy(date, ".");

      default:
        break;
    }
    return date;
  }

  private readExcel = async (files: FileList, workbook: Excel.Workbook, headers: string[]) => {
    const y = await files[0].arrayBuffer();
    await workbook.xlsx.load(y);
   
    const ws = workbook.worksheets[0];
    let excelHeaders: string[] = [];
    ws.getRow(1).eachCell((cell) => excelHeaders.push(cell.text));
   
    const delimiter = ";";
    const rows = ws.getRows(1, ws.rowCount);
    if (rows !== undefined) {
      const rowsData = rows.map((row) =>
        (row.values as []).filter((e) => e !== undefined).join(delimiter)
    );
      const values = headers.every((key) => excelHeaders.includes(key))
        ? this.getValues(
            headers,
            rowsData.slice(1),
            delimiter,
            excelHeaders
          )
        : this.getValues(
            headers,
            headers.some((key) => excelHeaders.includes(key))
              ? rowsData.slice(1)
              : rowsData,
            delimiter
          );
      this.props.onChange(values);
    }
  };
  private handleModalCLose() {
    this.setState({
      open: false,
      selectedFile: undefined,
      selectedDateFormat: "",
    });
  }
  private generateDateFormats = () => {
    const res: any[] = [];
    dateFormats.map((x, index) => {
      res.push(
        <option key={index} value={x}>
          {x}
        </option>
      );
    });
    return res;
  };
  private handleDateSelection(index: any) {
    this.setState({ selectedDateFormat: index });
  }
  private fileInput: HTMLInputElement = document.createElement("input");

  public render() {
    return (
      
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Button
          className="button"
          disabled={this.state.loading}
          onClick={() => this.setState({ open: true })}
        >
          {this.state.loading ? (
            <Spinner />
          ) : (
            <>
              <FontAwesomeIcon icon={faFileImport} style={{ marginRight: 8 }} />
              {this.props.t("invoiceReport.importFile")}
            </>
          )}
        </Button>
        {this.state.open && (
          <StyledModal
            onDismiss={() => this.handleModalCLose()}
            title={this.props.t(
              "invoiceReport.importFile"
            ).toString()} /*closeOnClickOutside={true} onClose={()=> this.handleModalCLose()}*/
            body={
              <div>
                <Stack className="submit-modal-body" gap={3}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      {this.props.t("invoiceReport.chooseFileToUpload")}:
                    </Form.Label>
                    <Form.Control
                      type="file"
                      accept=".xlsx,.csv"
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        const files: FileList = ev.target.files as FileList;
                        if (files.length > 0)
                          this.setState({ selectedFile: files });
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{this.props.t("invoiceReport.chooseDateFormat")}</Form.Label>
                    <Form.Select
                      // style={{ textTransform: "capitalize" }}
                     defaultValue={' '}
                      value={this.state.selectedDateFormat}
                      onChange={(ev) => {
                        this.handleDateSelection(ev.target.value);
                      }}
                    >
                      <option>{this.props.t("invoiceReport.noDateFormat")}</option>
                      {this.generateDateFormats()}
                    </Form.Select>
                  </Form.Group>
                  {this.state.errorMsg && typeof this.state.selectedFile === 'undefined' &&(
                    <div className="error-message">
                      <span>{this.props.t("general.errorMsg")}:</span>
                      {this.state.errorMsg}
                    </div>
                  )}
                </Stack>
              </div>
            }
            footer={
              <div >
                <Stack direction="horizontal" gap={3}>
                <Button
                  className="button"
                 
                  disabled={this.state.loading}
                  onClick={async (ev) => {
                    this.setState({
                      loading: true,
                      errorMsg: "",
                    });
                    if(typeof this.state.selectedFile === 'undefined'){
                      this.setState({
                        loading:false,
                        errorMsg:this.props.t('invoiceReport.importErrorMsg')
                      })
                    }
                      else{
                        
                    const test: unknown = this.state.selectedFile;
                    const files: FileList = test as FileList;
                    const workbook = new Excel.Workbook();
                    
                    const file = files[0];
                    try {
                      //(file.type == "application/vnd.ms-excel" && file.name.endsWith(".csv")) => "text/csv" in disguise
                     
                      if (
                        file.type == "text/csv" ||
                        (file.type == "application/vnd.ms-excel" &&
                          file.name.endsWith(".csv"))
                      ) {
                        this.readCsv(file, this.getHeaders());
                      } else {
                        await this.readExcel(files, workbook, this.getHeaders());
                      }
                      this.setState({
                        loading: false,
                        open:false
                      });
                     
                    
                    } catch (error) {
                      console.error(error);
                      this.setState({
                        loading: false,
                        errorMsg: this.props.t("invoiceReport.importErrorMsg"),

                      });
                    }
                      }
                    
                  }}
                >
                  {this.state.loading ? (
                    <Spinner />
                  ) : (
                    <> <FontAwesomeIcon
                    icon={faFileImport}
                    style={{ marginRight: 8 }}
                  />
                  {this.props.t("invoiceReport.uploadFile")}
                
                </>
                   
                  )}
                </Button>
                <Button className="button-cancel" onClick={()=> this.handleModalCLose()}>{this.props.t('invoiceReport.close')}</Button>   
                </Stack>                
              </div>
            }
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(FileUploadInput);
