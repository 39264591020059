/* eslint-disable no-loop-func */
import { t } from "i18next";
import * as React from "react";

import { withTranslation } from "react-i18next";
import {  Dropdown,  Stack } from "react-bootstrap";

import BarChartDatePicker from "./BarChartDatePicker";

import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ToasterMsg from "../Toaster/ToasterMsg";
import { FetchReturn } from "../../lib/utils";

interface IChartFilterProps {
  contractIds: Option[];
  selected: any;
  handleContractIdFilter: (selected: any) => void;
  contractIdFilter: string[];
  valueKey: ValueProp;
  handleValueKeyChange: (ev: any) => void;
  fromDate:Date;
  onChangeFromDate(val: Date|null):void;
  toDate:Date;
  onChangeToDate(val: Date|null):void;
}
type Option = {
  label: string;
  value: string;
  
};
interface IChartFilterState {
  showFrom: boolean;
  showTo: boolean;
}
type ValueProp = "numOfInvoices" | "sumExclMoms";
class InvoiceBarChartFilter extends React.Component<
  IChartFilterProps,
  IChartFilterState
> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: IChartFilterProps) {
    super(props);
    this.state = {
      showFrom: false,
      showTo: false,
    };
  }
  componentDidMount(): void {
    
  }
 
  render() {
    return (
      <>
        <div className="filterContent">
          <Stack gap={3} direction="horizontal">
            <div
              className="multiSelect"
              style={{ width: "115px", minWidth: "50px" }}
            >
              <MultiSelect
                overrideStrings={{
                  selectSomeItems: t("general.contract"),
                  search: t('general.search'),//"Sök",
                  noOptions: t('general.nohits'),//"Inga träffar",
                  allItemsAreSelected: t('general.all')
                  
                }}
                hasSelectAll={false}
                //ItemRenderer={this.filter}
               // filterOptions={(opt,filter) => this.filter(opt,filter) }
                options={this.props.contractIds}
                value={this.props.selected}
                onChange={(selected: any) => {
                  this.props.handleContractIdFilter(selected);
                }}

                labelledBy={t("general.contract")}
              />
            </div>
            <BarChartDatePicker
              label={t('general.from')}
              onChange={(val) => {
                this.props.onChangeFromDate(val ? new Date(val) : null);
              }}
              value={this.props.fromDate.toISOString()}
            />

            <BarChartDatePicker
              label={t('general.to')}
              onChange={(val) => {
                this.props.onChangeToDate(val ? new Date(val) : null);
              }}
              value={this.props.toDate.toISOString()}
            />
            <div>

              <Dropdown>
                <Dropdown.Toggle bsPrefix="invoiceFormat">
                  <div>
                    <div className="textBlock">
                      <div className="textBlockTitle">
                        <p>{t(`general.${this.props.valueKey}`)}</p>
                      </div>
                      <div className="textBlockIcon">
                        <FontAwesomeIcon
                          style={{ color: "rgb(138,138,138,0.67)" }}
                          size={"sm"}
                          icon="chevron-down"
                        />
                      </div>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={(ev) => {
                      this.props.handleValueKeyChange("numOfInvoices");
                    }}
                  >
                    {t("general.numOfInvoices")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(ev) => {
                      this.props.handleValueKeyChange("sumExclMoms");
                    }}
                  >
                    {" "}
                    {t("general.sumExclMoms")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Stack>
        </div>
      </>
    );
  }
}
export default withTranslation()(InvoiceBarChartFilter);
