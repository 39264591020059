/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { DataService } from '../services/DataService';
import Datalist, { FooterOptions, ListColumn } from '../components/Datalist/Datalist';
import { TUser } from '../services/API/Users';
import { Button, Col, Form, FormLabel, Row, Spinner, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { StyledModal } from '../components/modal/StyledModal';
import { Code, IsUserSinfra, apiFetch } from '../lib/utils';
import { InfoModal } from '../components/modal/InfoModal';
import { TSupplier } from '../services/API/Suppliers';
import { SupplierUserModal } from '../components/modal/SupplierUserModal';
import { Contract, ContractValidation } from '../services/API/Contracts';
import { Typeahead } from 'react-bootstrap-typeahead';
import { TContractCategory } from '../services/API/ContractCategories';
import StyledCheckbox from '../components/StyledCheckbox/StyledCheckbox';
import DatePicker_bst from '../components/DatePicker/DatePicker_bst';

interface Props  {

}


function EditContractPage (props: Props){
  const [isLoading, setIsLoading] = useState(true);
  const [contract, setContract] = useState<Contract>({
    id: -1,
    archived: false,
    contractId: "",
    contractName: "",
    contractTypeId: -1,
    contractType: "",
    kickbackProcent: "0.0",
    supplierName: "",
    supplierOrgNr: "",
    detached: false
  });
  const [suppliers, setSuppliers] = useState<TSupplier[]>([]);
  const [categories, setCategories] = useState<TContractCategory[]>([]);
  const [errorMsg, setErrorMessage] = useState("");
  const [successText, setSuccessText] = useState("");
  const [ isEdit, setIsEdit ] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [ validations, setValidations ] = useState<ContractValidation>({
    contractName: "",
    supplierName: "",
    contractTypeId: "",
    contractId: "",
    kickbackProcent: "",
    procurementValue: ""
  })
  const {t} = useTranslation()

  const { contractId } = useParams();

  useEffect(() => {
    if(!IsUserSinfra())
      window.location.href = "/"
    
    init(parseInt(contractId ?? "") || -1);
    
  }, [])

  const init = async (id:number) => {
    
    const promises : Promise<TSupplier[] | TContractCategory[] | Contract | number>[] = [
      DataService.Suppliers.Get().then(supp => {setSuppliers(supp); return supp;}),
      DataService.Contracts.GetContractCategories().then(cats => {setCategories(cats); return cats;})
    ];
   
    let request = id > -1 ?
      DataService.Contracts.GetById(id).then(c => { setContract(c); return c; }) 
      : DataService.Contracts.GetNextContractId().then(num => {setContract({ ...contract, contractId: num.toString() }); return num;});
    setIsEdit(Boolean(contractId));
    promises.push(request);
    const [fetchedSuppliers,, fetchedContract] = await Promise.all(promises);
    const fc = fetchedContract as Contract;
    const contractSupplier = (fetchedSuppliers as TSupplier[]).find(supp => supp.supplierOrgNr == fc.supplierOrgNr);
    if(Boolean(contractId) && !contractSupplier && fc.detached == false)
      setValidations({...validations, supplierName: t("contractEditPage.supplierOrgNr.invalid")})
      
    setIsLoading(false);
  }

  const renderField = (label:string, value:string, prop: string, validationKey: keyof ContractValidation, type: "text" |"number") => {
    return <>
      <div className='field-label'>{label}</div>
      <Form.Control
        disabled={isSaving}
        value={type == "number" ? value.replace(",", ".").replace("e", "") : value}
        className='field-value'
        type={type}
        onChange={(ev) => setContract({ ...contract, [prop]: ev.target.value })}
        isInvalid={Boolean(validations[validationKey])}
      />
      <Form.Control.Feedback type="invalid">
        {validations[validationKey]}
      </Form.Control.Feedback>
    </>
  }

  const renderDateField = (label: string, value: Date|undefined|null, prop: string) => {
    return <>
      <div className='field-label'>{label}</div>
      <DatePicker_bst
        disabled={isSaving}
        type='date'
        className='field-value'
        onChange={(ev) => {
          setContract({ ...contract, [prop]: ev.target.value ? new Date(ev.target.value) : null })
        }} 
        value={value?.toISOString()}
        onKeyDown={(ev) => false}
        onInput={(ev) => false}
      />
    </>
  }

  const onClickSubmit = async () => {
    setErrorMessage("");
    setIsSaving(true);
    const validation = await DataService.Contracts.ValidateContract(contract);    
    setValidations(validation);
   //@ts-ignore
    const isValid = Object.keys(validation).every((key) => validation[key].length == 0);
     if(isValid){
      const res = await DataService.Contracts.Post(contract);
      if(res.Code == Code.OK){
        setSuccessText(res.Message);
      }
      else{
        setErrorMessage(res.Message);
      }    
  }
    setIsSaving(false);
  }
  
  return (
    <Stack gap={4} className='edit-contract-page'>
      {
        isLoading &&
        <Spinner />
      }
      {
        !isLoading &&
        <div>
          <div className='page-title'>
            {
              isEdit ? t("contractEditPage.editTitle") : t("contractEditPage.title")
            }
            </div> 
          <Stack style={{marginTop:8}} gap={2}>
            <Row>
              <Col xs={12} sm={6} md={6} lg={6}>
                {renderField(t("contractEditPage.form.contractId"), contract.contractId, "contractId", "contractId", "text")}
              </Col>
              {
                contract.detached ?
                <Col xs={12} sm={6} md={6} lg={6}>
                  {renderField(t("general.supplier"), contract.supplierName, "supplierName", "supplierName", "text")}
                </Col>
                :
                <Col>
                <div className='field-label'>{t("general.supplier")}</div>
                <Typeahead 
                  disabled={isSaving}
                  isInvalid={Boolean(validations.supplierName)}
                  className='field-value' 
                  placeholder={t('contractEditPage.supplierPlaceholder').toString()}
                  defaultInputValue={contract.supplierOrgNr ? 
                    contract.supplierName + " - " + contract.supplierOrgNr
                    : contract.supplierName
                  }
                  id={"supplier"}
                  options={suppliers.map(supplier => `${supplier.supplierName} - ${supplier.supplierOrgNr}` )}
                  onChange={(selected) => {
                    if(selected.length > 0){
                      const supplierLabel = selected[0];
                      const newSupplier = suppliers.find(supp => `${supp.supplierName} - ${supp.supplierOrgNr}` == supplierLabel);
                      if(newSupplier)
                        setContract({...contract, supplierName: newSupplier.supplierName, supplierOrgNr: newSupplier.supplierOrgNr})
                    }
                  }}
                  onInputChange={(text) => {
                    setContract({...contract, supplierName: text, supplierOrgNr: ""});
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {validations.supplierName}
                </Form.Control.Feedback>
              </Col>
              }
              
              <Col xs={12} sm={6} md={6} lg={6}>
                {renderField(t("contractEditPage.form.name"), contract.contractName, "contractName", "contractName", "text")}
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <div className='field-label'>{t("contractEditPage.form.category")}</div>
                <Typeahead 
                  disabled={isSaving}
                  isInvalid={Boolean(validations.contractTypeId)}
                  className='field-value' 
                  placeholder={t('contractEditPage.categoryPlaceholder').toString()}
                  defaultInputValue={contract.contractType ?? ""}
                  id={"category"}
                  labelKey={'title'}
                  options={categories.map(c => ({ key: c.id, title: c.title }))}
                  onChange={(selected) => {
                    if(selected.length > 0){
                      const category : any = selected[0];
                      setContract({...contract, contractType: category.title, contractTypeId: category.key});
                    }
                  }}
                  onInputChange={(text) => {
                    setContract({...contract, contractType: "", contractTypeId: -1});
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {validations.contractTypeId}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                {renderField(t("contractEditPage.form.kickback"), contract.kickbackProcent, "kickbackProcent", "kickbackProcent", "number")}
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                {renderField(t("contractEditPage.form.procurementValue"), contract.procurementValue?.toString() ?? "", "procurementValue", "procurementValue", "number")}
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                {renderDateField(t("contractEditPage.form.startDate"), contract.startDate, "startDate")}
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                {renderDateField(t("contractEditPage.form.terminationDate"), contract.terminationDate, "terminationDate")}
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                {renderDateField(t("contractEditPage.form.reportingFromDate"), contract.reportingFromDate, "reportingFromDate")}
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                {renderDateField(t("contractEditPage.form.reportingToDate"), contract.reportingToDate, "reportingToDate")}
              </Col>
              <Col xs={6} sm={6} md={6} lg={6}>
                <div className='field-label'>{t("general.archived")}</div>
                <div className='field-value'>
                  <StyledCheckbox 
                    disabled={isSaving}
                    isChecked={contract.archived} 
                    label={contract.archived ? t('general.yes') : t('general.no')} 
                    onChange={(newVal) => setContract({ ...contract, archived: newVal })}  
                  />
                </div>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6}>
                <div className='field-label'>{t("general.detached")}</div>
                <div className='field-value'>
                  <StyledCheckbox 
                    disabled={isSaving}
                    isChecked={contract.detached} 
                    label={contract.detached ? t('general.yes') : t('general.no')} 
                    onChange={async (newVal) => {
                      const updatedContract = {
                        ...contract,
                        detached: newVal,
                        supplierOrgNr: "",
                        supplierName:contract.supplierName
                      };
                      setContract(updatedContract);
                  }}
                  />
                </div>
              </Col>
            </Row>
            {
                errorMsg &&
                <div className='error-message'>
                  <span>{t('general.errorMsg')}:</span>
                  {errorMsg}
                </div>
              }
           <Stack direction='horizontal'  gap={2} style={{justifyContent: "end"}}>
            <Button disabled={isSaving} className='button' onClick={() => onClickSubmit()}>
              {
                isSaving ?
                <Spinner animation="border" size={'sm'}/>
                :
                <>{isEdit ? t("general.update") : t("general.create")}</>
              }
              </Button>
              <Button disabled={isSaving} className='button-cancel' onClick={() => window.history.go(-1)}>{t("general.cancel")}</Button>
           </Stack>
          </Stack>
        </div>
      }
      {
        successText &&
        <InfoModal 
          bodyText={successText}
          title={t("contractEditPage.infoModalSuccess")}
          onDismiss={() => window.location.href = "/contractManagement"}
        />
      }
    </Stack>
  );
 
};

export default EditContractPage;