/* eslint-disable @typescript-eslint/no-useless-constructor */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Stack } from "react-bootstrap";

import { withTranslation } from "react-i18next";
import DatePicker_react from "../DatePicker/DatePicker_react";

interface IDatePicker {
  label: string;
  value:string;
  onChange(val:string):void;
}
interface IDatePickerState {
  show: boolean;
}
class BarChartDatePicker extends React.Component<IDatePicker, IDatePickerState> {
  constructor(props: IDatePicker) {
    super(props);
    this.state = { show: false };
  }
  componentDidMount(): void {
    document.addEventListener("mousedown", this.HandleClickOutside);
  }
  componentWillUnmount(): void {
    document.removeEventListener("mousedown", this.HandleClickOutside);
  }
  
  private myRef: any = React.createRef();
  HandleClickOutside = (e: any) => {
    if (!this.myRef.current.contains(e.target)) {
      this.setState({ show: false });
    }
  };
  HandleClickInside = (e:any) => {
    
    this.setState((prevState) => ({ show: !prevState.show }));
  };
  onSelectDate = (value:string) => {
    this.props.onChange(value);
    this.setState({
      show: false
    });
  }

  render() {
    return (
      <div className="datePicker">
        <div
          className="datePickerZindex"
          ref={this.myRef}
        >
          <div
            onClick={this.HandleClickInside}
            style={
              this.state.show
                ? {
                    cursor: "pointer",

                    border: "2px solid rgb(0, 205, 255)",
                    borderRadius: "4px",
                    padding: "7px 10px ",
                    alignSelf: "center",
                  }
                : {
                    cursor: "pointer",

                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "7px 10px ",
                    alignSelf: "center",
                  }
            }
          >
            <div
              id="innerMenu"
            >
              <Stack className="datePickerText" gap={2} direction="horizontal">
                <FontAwesomeIcon
                  style={{ color: "#aaaaaa", cursor: "pointer" }}
                  icon="clock"
                />
                <p style={{ color: "#aaaaaa", margin: 0 }}>
                  {this.props.label}
                </p>
                {this.state.show ? (
                  <FontAwesomeIcon
                    size="sm"
                    style={{ color: "#aaaaaa", float: "right" }}
                    icon="chevron-up"
                  />
                ) : (
                  <FontAwesomeIcon
                    size="sm"
                    style={{ color: "#aaaaaa", float: "right" }}
                    icon="chevron-down"
                  />
                )}
              </Stack>
            </div>
          </div>
          {
            this.state.show &&
            <DatePicker_react
              selected={this.props.value ? new Date(this.props.value) : null}
              onChange={(e) => {this.onSelectDate(e?.toDateString() ?? "")}}
              showMonthYearPicker
              inline
            />
          }

        </div>
      </div>
    );
  }
}
export default withTranslation()(BarChartDatePicker);
