/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
} from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReadFriendlyNumber } from "../../lib/utils";
interface IPieChartCategorysProps {
  category: any;
  period?: string;
  data?: any;
  onSelectedSlice: (category: any) => void;
}
interface IPieChartCategorysState {
  data: any;
  activeIndex: number;
  width: number;
  height: number;
}
interface CategoryColors {
  [key: string]: any;
}

class PieChartCategorys extends React.Component<
  IPieChartCategorysProps,
  IPieChartCategorysState
> {
  colors: string[] = [
    "#92c01e",
    "#eb5621",
    "#0597e2",
    "#2c2e88",
    "#229e37",
    "#f7b032",
    "#0e79c4",
  ];
  indexedColor: CategoryColors = {
    "El och Fiber": "#92c01e",
    Sinfra: "#eb5621",
    "Tjänster och Konsulter": "#0597e2",
    "Vatten och Avlopp": "#2c2e88",
    "Värme och kyla": "#229e37",
    Övrigt: "#f7b032",
    Ekonomi: "#0e79c4",
  };
  placeHolder: any[] = [{ name: "", value: 100 }];

  constructor(props: IPieChartCategorysProps) {
    super(props);
    this.state = {
      data: [],
      activeIndex: -1,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  componentDidMount(): void {
    window.addEventListener("resize", this.HandleRezise);

    this.setState({ data: [] });
  }
  componentWillUnmount(): void {
    window.removeEventListener("resize", this.HandleRezise);
  }
  HandleRezise = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  CustomActiveShape: any = (shape: any): any => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = shape;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius + 5}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          style={{ opacity: "0.8", cursor: "pointer" }}
          onMouseLeave={() => this.setState({ activeIndex: -1 })}
          onClick={() => this.props.onSelectedSlice(payload.payload)}
        />
      </g>
    );
  };
  CustomToolTip: any = (active: any, payload: any, label: any): any => {
    if (active && typeof active.payload[0] !== "undefined") {
      const path: string = `dashboard.category.${active.payload[0].payload.name}`;
      return (
        <div
          style={{
            borderRadius: "5px",
            backgroundColor: "#fcfbfa",

            textAlign: "center",
          }}
        >
          <b>{i18n.t("dashboard.sum")}</b>
          <p style={{ margin: "0", padding: 0 }}>
            {ReadFriendlyNumber(active.payload[0].payload.value)}
          </p>
          <p style={{ margin: "0", paddingLeft: "5px", paddingRight: "5px" }}>
            {i18n.t("dashboard.cat")} : {i18n.t(path)}
          </p>
        </div>
      );
    }
  };
  CustomLegend: any = (payload: any): any => {
    if (payload && typeof payload !== "undefined") {
      return (
        <div className="pieLegend">
          <table>
            <thead />
            <tbody >
            {Object.entries(this.indexedColor).map(([q, val]) => {
              if (!payload.payload.find((x: any) => x.value === q)) {
                const path: string = `dashboard.category.${q}`;
                return (
                  // <tr
                  //   key={Math.random().toString()}
                  //   style={{  }}
                  // >
                  //   <td>
                  //     <FontAwesomeIcon
                  //       style={{
                  //         color: "#dddd",
                  //         paddingRight: 5,
                  //         marginLeft:15,
                  //         paddingBottom:2
                  //       }}
                  //       icon={"square"}
                  //     />
                  //     {i18n.t(path)}
                  //   </td>
                  // </tr>
                  <></>
                );
              } else {
                const path: string = `dashboard.category.${q}`;
                return (
                  <tr
                    key={Math.random().toString()}
                    style={{  }}
                  >
                    <td>
                      <FontAwesomeIcon
                        style={{
                          color: this.indexedColor[`${q}`],
                          paddingRight: 5,
                          marginLeft:15,
                          paddingBottom:2
                        }}
                        icon={"square"}
                      />

                      {i18n.t(path)}
                    </td>
                  </tr>
                );
              }
            })}
            </tbody>
          </table>
        </div>
      );
    }
  };
  render() {
    return (
      <div
        // className="dashboardPieContainer"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          display: "flex",
          flex: 1,
          borderRadius:'5px',
          
        }}
      >
        {Object.keys(this.props.data).length > 0 ? (
          <ResponsiveContainer
            width={
              this.state.width <= 600
                ? `${this.state.width / 3.8}%`
                : `${this.state.width / 2.5}%`
            }
            height={this.state.height <= 800 ? this.state.height + 30 :this.state.height / 1.8}
            //debounce={30}
          >
            <PieChart margin={{}}>
              <Pie
                data={Object.entries(this.props.data).map(([key, value]) => {
                  return { name: key, value };
                })}
                activeIndex={this.state.activeIndex}
                activeShape={this.CustomActiveShape}
                labelLine={false}
                innerRadius={0}
                outerRadius={
                  this.state.width >= 200 && this.state.width <= 300 ? 60 :  
                // this.state.width <= 800  ? 150 : this.state.width >=800 && this.state.width <= 1200 ? this.state.width / 10 
                // : this.state.width / 12
                this.state.width <= 600 ? 125 : this.state.width <= 1400 ? this.state.width / 10: this.state.width / 11
                }
                fill="#8884d8"
                dataKey="value"
                onMouseEnter={(e, index) =>
                  this.setState({ activeIndex: index })
                }
                style={{outline: 'none'}}
              >
                {Object.entries(this.props.data).map(([key, value]) => {
                  return (
                    <Cell
                      style={{ opacity: "0.7" ,outline:'none !important'}}
                      key={`cell-${key}`}
                      fill={this.indexedColor[`${key}`]}
                      
                    />
                  );
                })}
              </Pie>
              <Tooltip content={this.CustomToolTip} />
              <Legend
                verticalAlign="bottom"
                wrapperStyle={{ paddingLeft: "5px", display: "block" }}
                content={this.CustomLegend}
              />
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer
            width={`${this.state.width / 2.5}%`}
            height={this.state.height / 2}
          >
            <PieChart>
              <Pie
                data={this.placeHolder}
                labelLine={true}
                innerRadius={0}
                outerRadius={
                  this.state.width <= 900 ? 70 : this.state.width / 17
                }
                fill="#dddd"
                dataKey="value"
              ></Pie>
              <Legend
                verticalAlign="bottom"
                wrapperStyle={{ display: "block" }}
                content={this.CustomLegend}
              />
            </PieChart>
          </ResponsiveContainer>
        )}
        <div
          style={{ float: "right", position: "absolute", marginLeft: "15px", marginTop:'5px' }}
        >
          <i>{this.props.period} </i>
        </div>
      </div>
    );
  }
}
export default withTranslation()(PieChartCategorys);
