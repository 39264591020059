/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
import * as React from 'react';
import { StyledModal } from './StyledModal';
import { DataService } from '../../services/DataService';
import { InvoiceReport } from '../../services/API/InvoiceReports';
import { Button, Form, Spinner, Stack } from 'react-bootstrap';
import Datalist, { FooterOptions, ListColumn } from '../Datalist/Datalist';
import { Invoice } from '../../services/API/Invoices';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from '../Accordion/StyledAccordion';
import { Code, IsUserSinfra, ReadFriendlyNumber } from '../../lib/utils';
import { faTrash, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Logs } from '../../services/API/Logs';
import { Department, DepartmentFunctionArgs } from '../../services/API/Departments';
import { EditReport } from '../EditReports/EditReport';
import { EditHistory, EditReportType } from '../EditReports/editTypes';
import { DisplayHistory } from '../EditReports/DisplayHistory';
import { Customer } from '../../services/API/Customer';


export interface IInvoiceReportModalProps extends WithTranslation {
  onDismiss():void;
  invoiceReportId:number;
  batchId?:string;
}

export interface IInvoiceReportModalState {
  isLoading: boolean;
  report: InvoiceReport |undefined;
  columns: ListColumn<Invoice>[];
  errorMessage:string;
  showRemoveModal:boolean;
  isSaving:boolean;
  logs:Logs[];
  logColumns:ListColumn<Logs>[];
  department?:Department;
  editReportToggle:boolean;
  editHistory?:EditHistory[];
  customers?:Customer[];
}

class InvoiceReportModal extends React.Component<IInvoiceReportModalProps, IInvoiceReportModalState> {
  constructor(props: IInvoiceReportModalProps) {
    super(props);

    this.state = {
      isLoading: true,
      report: undefined,
      errorMessage: "",
      isSaving: false,
      showRemoveModal: false,
      logs:[],
      columns: [
        {
          fieldName: "invoiceNumber",
          isActive: true,
          key: "invoiceNumber",
          minWidth: 100,
          maxWidth: 140,
          sorting: true,
          name: this.props.t("invoiceReportList.invoiceReportModal.table.invoiceNumber"),
          footer: FooterOptions.COUNT
        },
        {
          fieldName: "contractId",
          isActive: true,
          key: "contractId",
          minWidth: 50,
          maxWidth: 140,
          name: this.props.t("invoiceReportList.invoiceReportModal.table.contractId"),
          sorting: true
        },
        {
          fieldName: "customerOrgId",
          isActive: true,
          key: "customerOrgId",
          sorting: true,
          minWidth: 100,
          name: this.props.t("invoiceReportList.invoiceReportModal.table.customer"),
          onRender: (item) => {
            return `${item.customerOrgId} - ${item.customerName}`;
          } 
        },
        {
          fieldName: "billingDate",
          isActive: true,
          key: "billingDate",
          minWidth: 150,
          maxWidth: 200,
          sorting: true,
          name: this.props.t("invoiceReportList.invoiceReportModal.table.billingDate"),
          onRender: (item) => item.billingDate.toLocaleDateString()
        },
        {
          fieldName: "dueDate",
          isActive: true,
          key: "dueDate",
          minWidth: 150,
          maxWidth: 200,
          sorting: true,
          name: this.props.t("invoiceReportList.invoiceReportModal.table.dueDate"),
          onRender: (item) => item.dueDate.toLocaleDateString()
        },
        {
          fieldName:"priceExcMoms",
          isActive: true,
          key: "priceExcMoms",
          minWidth: 20,
          maxWidth: 200,
          sorting: true,
          name: this.props.t("invoiceReportList.invoiceReportModal.table.priceExcMoms"),
          footer: FooterOptions.SUM,
          onRender: (item) => ReadFriendlyNumber(item.priceExcMoms)
        },
        {
          fieldName:"priceIncMoms",
          isActive: true,
          key: "priceIncMoms",
          minWidth: 20,
          maxWidth: 200,
          sorting: true,
          name: this.props.t("invoiceReportList.invoiceReportModal.table.priceIncMoms"),
          footer: FooterOptions.SUM,
          onRender: (item) => ReadFriendlyNumber(item.priceIncMoms)
        },
        {
          fieldName:"type",
          isActive: true,
          key: "type",
          minWidth: 20,
          maxWidth: 100,
          name: this.props.t("invoiceReportList.invoiceReportModal.table.type"),
          onRender: (item) => item.type == 1 ? this.props.t("general.debit").toString() : this.props.t("general.credit").toString()
        }
      ],
      logColumns:[
        
        {
          fieldName: "timeReported",
          isActive: true,
          key: "timeReported",
          minWidth: '',
          maxWidth: 150,
          sorting: true,
          name: 'timeReported',
          onRender:(item,col) => {
            return <p style={{width:'50px',margin:'0'}}>{new Date(item.timeReported).toLocaleDateString()}</p> 
                    
          }
          
        },
        {
          fieldName: "logLevel",
          isActive: true,
          key: "logLevel",
          minWidth: '',
          maxWidth: 100,
          //width:'35px !important',
          sorting: true,
          name: 'logLevel',
          
        },
        {
          fieldName: "logText",
          isActive: true,
          key: "logText",
          minWidth: '',
          //maxWidth: undefined,
          //width:'400px !important',
          sorting: true,
          name:'logText',
          onRender:(item,col) => {
            return <p style={{margin:'0'}}>{item.logText}</p>
          }
          
        }
       
        
      ],editReportToggle:false
    }
    
  }

  async componentDidMount() {
   
    if(typeof this.props.batchId !== 'undefined' && IsUserSinfra()){
      const logs = await DataService.Logs.GetLogsByBatchId(this.props.batchId);
      const editHistory = await DataService.EditedReports.GetReportHistory(this.props.batchId)
      const customers = await DataService.Customers.Get({
        select:["isSupplier", "kndOrgnr", "kndNamn", "kndNamnKey", "kndLopnr"], 
        filter: "isSupplier eq false"
      })
      this.setState({logs:logs},async () => {
        const [invoiceReport] = await Promise.all([
          DataService.InvoiceReports.GetById(this.props.invoiceReportId),
          
        ]);
        const department:DepartmentFunctionArgs = {}
        department.id = invoiceReport.departmentId
        
        const fetchedDepartment:Department | undefined = await DataService.Departments.GetDepartment(department)
       
        this.setState({
          report: invoiceReport,
          isLoading: false,
          department:fetchedDepartment,
          editHistory,
          customers
        });
      })
    }
    else{
      const [invoiceReport] = await Promise.all([
        DataService.InvoiceReports.GetById(this.props.invoiceReportId),
        
      ]);
      const logs = await DataService.Logs.GetLogsByBatchId(invoiceReport.batchId)
      //const editHistory = await DataService.EditedReports.GetReportHistory(invoiceReport.batchId)
      this.setState({
        logs,
        report: invoiceReport,
        isLoading: false,
        //editHistory
      });
    }
    

   
  }

  
  private onClickRemove = async () => {
    const { report } = this.state;
    if(report){
      this.setState({ isSaving: true, errorMessage: "" });
      const res = await DataService.InvoiceReports.RemoveById(report.id);
      if(res.Code != Code.OK){
        this.setState({ errorMessage: res.Message, isSaving: false });
      }
      else{
        window.location.reload();
      }
    }
  }
 
  private renderLogsTable = () => {
    return   <div style={{maxHeight: "48vh",marginBottom:'10px'}}>
    <Datalist
        items={this.state.logs ?? []}
        uniqueKey="invoiceNumber"
        columns={this.state.logColumns}
        noItemsText={this.props.t("invoiceReportList.invoiceReportModal.table.empty")}
        initialSortKey="timeReported"
        descendingSort={false}
        setColumns={(cols) => this.setState({ logColumns: cols })}
      />
      </div>
  }

  private renderInfo = () => {
    const { report } = this.state;
    const cellStyle : React.CSSProperties = {
      paddingLeft:8
    }
    const isSinfra = IsUserSinfra();
    const statusObj = DataService.InvoiceReports.GetStatus(report as InvoiceReport);
      return  <Stack gap={3}>
        <Stack style={{ justifyContent: "", flexWrap: "wrap" }}>
          
          <div style={{ fontSize: 18}}>{report?.supplierName+`(${report?.supplierOrgId})`}{` - `}<FontAwesomeIcon icon={statusObj.icon} color={statusObj.color} style={{marginRight:"5px"}}/>{statusObj.statusText}
          </div>
          
         {report?.reporter.includes('@') ? <a href={`mailto:${report?.reporter}`}>{report?.reporter}</a>:<div style={{ fontSize: 14, fontStyle: "italic", color: "#878686"}}>{report?.reporter}</div>} 
          <div style={{ fontSize: 14, fontStyle: "italic", color: "#878686"}}>{report?.reportDate.toLocaleDateString()}</div>
        </Stack>
        <Accordion open title={this.props.t("invoiceReportList.invoiceReportModal.info").toString()} titleStyle={{fontSize: "1.1rem", margin: 0}} style={{padding: "0px 10px 0px"}} contentStyle={{paddingLeft: 4}}>
        <table 
        >
          <tbody>
            <tr>
              <td>{this.props.t("invoiceReportList.invoiceReportModal.comment")} </td>
              <td style={cellStyle}>{report?.comment}</td>
            </tr>
            <tr>
              <td>{this.props.t("invoiceReportList.invoiceReportModal.noOfInvoices")} </td>
              <td style={cellStyle}>{report?.noOfInvoices}</td>
            </tr>
            <tr>
              <td>{this.props.t("invoiceReportList.invoiceReportModal.noOfInvoicesImported")} </td>
              <td style={cellStyle}>{report?.noOfInvoicesImported}</td>
            </tr>
            <tr>
              <td>{this.props.t("invoiceReportList.invoiceReportModal.totalSumExclMoms")} </td>
              <td style={cellStyle}>{ReadFriendlyNumber(report?.totalSumExclMoms ?? 0)}</td>
            </tr>
            <tr>
              <td>{this.props.t("invoiceReportList.invoiceReportModal.totalSumExclMomsImported")} </td>
              <td style={cellStyle}>{ReadFriendlyNumber(report?.totalSumExclMomsImported ?? 0 )}</td>
            </tr>
            <tr>
              <td>{this.props.t("invoiceReportList.invoiceReportModal.noOfWarnings")} </td>
              <td style={cellStyle}>{report?.noOfWarnings}</td>
            </tr>
           {typeof this.state.department !== 'undefined' &&
           this.state.department.id !== null &&
            <tr>
              
            <td>
              {this.props.t('departmentList.singular')}
              <FontAwesomeIcon style={{marginLeft:'4px'}} icon='building'/>
            </td>
            

            <td style={cellStyle}>{this.state.department.department}</td>
          </tr>
           }
            
          </tbody>
        <p></p>
        </table>
          </Accordion>
          {
            report?.statusText &&
            <Accordion open title={this.props.t("invoiceReportList.invoiceReportModal.logs").toString()} titleStyle={{fontSize: "1.1rem", margin: 0}} style={{padding: "0px 10px 0px"}} contentStyle={{paddingLeft: 4}}>
            {isSinfra ? 
            (<>
            
            <p>
              {
                report.statusText
                  .split("\\n")
                  .filter(str => !str.includes("See log for details:") && !isSinfra || isSinfra)
                  .map((str, i) => <>{i > 0 && <br/>}{str}</>)
              }
            </p>
            
            {
              
              this.renderLogsTable()
              }</>)
            :
            (<p>
              {
                report.statusText
                  .split("\\n")
                  .filter(str => !str.includes("See log for details:") && !isSinfra || isSinfra)
                  .map((str, i) => <>{i > 0 && <br/>}{str}</>)
              }
            </p>) 
            } 
            
           
              </Accordion>
          }
      </Stack>
    
  }
  dataToSendToEdit = (key:Extract<EditReportType,'Invoicereport'|'InvoicereportWithCSRD'|'Projectreport'|'ProjectreportWithCSRD'>) => {
    const reportToPass = {
      'Invoicereport':{'Invoicereport':{report:this.state.report,invoiceKey:'invoices'},'Logs':this.state.logs },
      'InvoicereportWithCSRD': {'InvoicereportWithCSRD':{report:this.state.report,invoiceKey:'csrdGroup'},'Logs':this.state.logs },
      'Projectreport':{'Projectreport':{report:this.state.report,invoiceKey:'invoices'},'Logs':this.state.logs},
      'ProjectreportWithCSRD':{'ProjectreportWithCSRD':{report:this.state.report,invoiceKey:'csrdGroup'},'Logs':this.state.logs }

    }[key]
    return reportToPass
  }
  public render() {

    return (
      <>
      <StyledModal 
        title={this.props.t("invoiceReportList.invoiceReportModal.title").toString()} 
        className={this.state.showRemoveModal ? "full-width backdropped" : "full-width"}
        onDismiss={this.props.onDismiss} 
        key={"InvoiceReportModal"}
        body={
          <Stack gap={3}>
            
            {
              this.state.isLoading &&
              <Spinner/>
            }
            {
              !this.state.isLoading && !this.state.editReportToggle ? 
              <>
              <Form.Check className="customSwitch" type="switch" label="Aktivera editering" reverse onClick={() => this.setState({editReportToggle:!this.state.editReportToggle})}/>
               {typeof this.state?.editHistory !== 'undefined' && this.state?.editHistory.length > 0  && this.state?.customers && 
                 <DisplayHistory data={this.state?.editHistory} customers={this.state?.customers}/>
               }
               
                {this.renderInfo()}
                <Accordion open={true} title={this.props.t("invoiceReportList.invoiceReportModal.invoices").toString()} titleStyle={{fontSize: "1.1rem", margin: 0}} style={{padding: "0px 10px 0px 10px"}}>
                  <div style={{maxHeight: "48vh"}}>
                <Datalist
                    items={this.state.report?.invoices ?? []}
                    uniqueKey="invoiceNumber"
                    columns={this.state.columns}
                    noItemsText={this.props.t("invoiceReportList.invoiceReportModal.table.empty")}
                    initialSortKey="billingDate"
                    descendingSort={false}
                    setColumns={(cols) => this.setState({ columns: cols })}
                  />
                  </div>
                </Accordion>
            </>:<>
                <Form.Check className="customSwitch" type="switch" label="Aktivera editering" reverse onClick={() => this.setState({editReportToggle:!this.state.editReportToggle})}/>
                
               
               {!this.state.isLoading && 
                <EditReport 
                reportType={this.state.report?.csrd
                  ? (this.state.report?.isProjectReport ? 'ProjectreportWithCSRD' : 'InvoicereportWithCSRD')
                  : (this.state.report?.isProjectReport ? 'Projectreport' : 'Invoicereport')}
                data={/*this.dataToSendToEdit(this.state.report?.csrd ? 'InvoicereportWithCSRD' : 'Invoicereport')*/
                  this.dataToSendToEdit(this.state.report?.csrd
                    ? (this.state.report?.isProjectReport ? 'ProjectreportWithCSRD' : 'InvoicereportWithCSRD')
                    : (this.state.report?.isProjectReport ? 'Projectreport' : 'Invoicereport'))
                }
                
              />
               }
                
               
               
            </>
            }
            
          </Stack>
        }
        footer={<Stack direction='horizontal' gap={2}>
          {
            this.state.report?.filePath &&
            <>
              <Button onClick={() => { window.open(AppSettings.invoiceReports.originalUrl+this.state.report?.filePath, "_blank");  }}>
                {
                  <>
                    <FontAwesomeIcon icon={faUpRightFromSquare} style={{marginRight: 4}}/> 
                    {this.props.t("invoiceReportList.invoiceReportModal.reportedFile")}
                  </>
                }
              </Button>
              <Button onClick={() => { window.open(AppSettings.invoiceReports.importedUrl+this.state.report?.filePath, "_blank");  }}>
              {
                <>
                  <FontAwesomeIcon icon={faUpRightFromSquare} style={{marginRight: 4}}/> 
                  {this.props.t("invoiceReportList.invoiceReportModal.importedFile")}
                </>
              }
              </Button>
            </>
          }
          {/* <Button onClick={() => {this.setState({ showRemoveModal: true })}} className="button-danger">
            {this.props.t("general.remove")}
          </Button> */}
          <Button onClick={() => this.props.onDismiss()} className='button-cancel'>
          {
            <>{this.props.t("general.close")}</>
          }
        </Button>
        </Stack>}
      />
      {
          this.state.showRemoveModal  &&
        <StyledModal 
          title={this.props.t("invoiceReportList.removeModalTitle").toString()} 
          onDismiss={() => { if(!this.state.isSaving)this.setState({ showRemoveModal: false}) }}
          body={<Stack className='submit-modal-body' gap={3}>
          
          <Trans i18nKey={"invoiceReportList.removeModalText"}/>
          {
            this.state.errorMessage &&
            <div className='error-message'>
              <span>{this.props.t('general.errorMsg')}:</span>
              {this.state.errorMessage}
            </div>
          }
        </Stack>}
      footer={
        <Stack direction="horizontal" gap={2}>
          <Button disabled={this.state.isSaving} className="button button-danger" onClick={this.onClickRemove}>
            {
              this.state.isSaving ?
              <Spinner animation="border" size={'sm'}/>
              :
              <><FontAwesomeIcon icon={faTrash} style={{marginRight: 4}}/>{this.props.t('general.confirm')}</>
            }
          </Button>
          <Button disabled={this.state.isSaving} className="button button-cancel" onClick={() => { this.setState({ showRemoveModal: false}) }}>{this.props.t('general.cancel')}</Button>
        </Stack>}
          />
        }
      </>
      
      
    );
  }
}

export default withTranslation()(InvoiceReportModal)
