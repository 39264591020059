/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { InvoiceForPrint, GetDataForPrint } from "../../services/API/Invoices";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { StyledModal } from "../modal/StyledModal";
import { Form, Stack } from "react-bootstrap";
import Button from "../RTE/Button";
import Print from "./Printer";
import CsvExport from "./Csvexport";
import { NumberFormatter, ReadFriendlyNumber } from "../../lib/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
type Option ={
  label:string;
  value:string;
}
const fileOptions: string[] = ["pdf", "csv"];
interface IPrintProps {
  show: boolean;
  date: { start: Date; end: Date };
  contractIds: Option[];
  handleClose: () => void;
  handleShow: () => void;
  printModalLoading: (value: boolean) => void;
}
interface IPrintState {
  loading: boolean;
  format: string;
  data: InvoiceForPrint[] | any;
  opt: {
    from: string | undefined;
    to: string | undefined;
    contractIds: string[] 
  };
}

class PrintModal extends React.Component<IPrintProps, IPrintState> {
  private formatedDataForBarChart: any[] = [];
  private rows: any[] = [];
  constructor(props: IPrintProps) {
    super(props);
    const {date} = this.props;
    this.state = {
      loading: true,
      format: i18n.t("print.chooseFormat"),
      data: null,
      opt: {
        from: date.start.toLocaleDateString("sv-SE"),
        to: new Date(date.end.getFullYear(), date.end.getMonth()+1, 0).toLocaleDateString("sv-SE"),
        contractIds: this.props.contractIds.map(cId => cId.value),
      },
    };
  }
  async componentDidMount(): Promise<void> {
    this.props.printModalLoading(true);
    const data = await GetDataForPrint(this.state.opt);

    this.props.printModalLoading(false);
    if (data.length > 0) {
      this.setState({
        data: data,
        loading: false,
      });
    } else {
      this.props.handleShow();
    }
  }
  async componentDidUpdate(
    prevProps: Readonly<IPrintProps>,
    prevState: Readonly<IPrintState>,
    snapshot?: any
  ) {
    if (prevState.data !== this.state.data) {
      this.formatedDataForBarChart = await this.buildDataForBarChart();
      this.rows = await this.buildRows();
    }
  }
  private async buildRows(): Promise<any[]> {
    const res: any[] = [];
    for (let index = 0; index < this.state.data.length; index++) {
      const x = this.state.data[index];
      //const width = 100 / 7
      const billingDate = x.billingDate.split("T");
      const dueDate = x.dueDate.split("T");

      res.push(
        <tr className="tableRow" key={index}>
          <span>
            <td style={{ width: "43%" }}>
              {x.customerOrgId} - {x.customerName}
            </td>
          </span>
          <td style={{ width: "12%" }}>{x.invoiceNumber}</td>
          <td style={{ width: "12%" }}>{billingDate[0]}</td>
          <td style={{ width: "14%" }}>{dueDate[0]}</td>
          <td style={{ width: "13%" }}>{ReadFriendlyNumber(x.priceExcMoms)}</td>
          {/* <td style={{width:`${width}px`}}>{ReadFriendlyNumber(x.priceIncMoms)}</td> */}
          <td style={{ width: "6%" }}>
            {x.type === 1 ? "Debit" : i18n.t("print.credit")}
          </td>
        </tr>
      );
    }
    return res;
  }

  private async buildDataForBarChart(): Promise<any[]> {
    const arr: any[] = [];
    this.state.data.sort(function (a: any, b: any) {
      return Date.parse(a.billingDate) - Date.parse(b.billingDate);
    });
    let previusMonth = this.state.data[0].billingDate.split("-");
    let slowPointer = previusMonth[0] + "-" + previusMonth[1];
    let cash = 0;
    let totalBills = 0;
    for (let index = 0; index < this.state.data.length; index++) {
      let a = this.state.data[index].billingDate.split("-");
      let currentPointer = a[0] + "-" + a[1];
      if (!slowPointer.includes(currentPointer)) {
        const cashResult: string = NumberFormatter(cash, 2);
        arr.push({
          date: slowPointer,
          month: cash,
          totalBills,
          yXasis: cashResult,
        });
        previusMonth = this.state.data[index].billingDate.split("-");
        slowPointer = previusMonth[0] + "-" + previusMonth[1];
        cash = 0;
        totalBills = 0;
      }
      cash += this.state.data[index].priceExcMoms;
      totalBills++;
    }
    const cashResult: string = NumberFormatter(cash, 2);
    arr.push({
      date: slowPointer,
      month: cash,
      totalBills,
      yXasis: cashResult,
    });

    return arr;
  }
  
  private handleFormatSelection(value: string) {
    this.setState({ format: value });
  }
  private generateDataFormats() {
    const res: any[] = [];
    fileOptions.map((x, index) => {
      res.push(
        <option key={index} value={x}>
          {x}
        </option>
      );
    });
    return res;
  }

  public render() {
    return (
      <div>
        {this.state.loading === false && (
          <StyledModal
            title={i18n.t("print.exportStats").toString()}
            onDismiss={() => this.props.handleClose()}
            body={
              <div>
                <Stack gap={3} className="submit-modal-body">
                  {this.state.data.length > 1000 && (
                    <div className="alertPrintModal">
                      <FontAwesomeIcon
                        style={{ color: "red", height: "15px" }}
                        icon={faTriangleExclamation}
                      />{" "}
                      {i18n.t("print.exportWarning")}
                    </div>
                  )}
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Select
                      defaultValue={" "}
                      value={this.state.format}
                      onChange={(ev) => {
                        this.handleFormatSelection(ev.target.value);
                      }}
                    >
                      <option>{i18n.t("print.chooseFormat")}</option>
                      {this.generateDataFormats()}
                    </Form.Select>
                  </Form.Group>
                </Stack>
              </div>
            }
            footer={
              <Stack
                className="submit-modal-body"
                style={{ alignSelf: "center" }}
                gap={3}
                direction="horizontal"
              >
                {this.state.format === i18n.t("print.chooseFormat") && (
                  <Button
                    className="primary"
                    disabled={true}
                    onClick={() => console.log()}
                  >
                    {i18n.t("print.exportButton")}
                  </Button>
                )}
                {this.state.format === "pdf" && (
                  <Print
                    data={this.state.data}
                    options={this.state.opt}
                    format={this.state.format}
                    formattedDataForbarChart={this.formatedDataForBarChart}
                    rows={this.rows}
                  />
                )}
                {this.state.format === "csv" && (
                  <CsvExport
                    data={this.state.data}
                    format={this.state.format}
                  />
                )}
              </Stack>
            }
          />
        )}
      </div>
    );
  }
}
export default withTranslation()(PrintModal);
