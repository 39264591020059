/* eslint-disable no-useless-concat */
import Urls from "../../lib/Urls";
import { apiFetch, getActiveSupplier, kcFetch } from "../../lib/utils";


export interface TSupplier {
  supplierOrgNr:string;
  supplierName:string;
  keycloakId?:string;
  divided_reporting?:boolean;
  prevent_invoice_report?:boolean;
  prevent_kickback_report?:boolean;
  project_report?:boolean;
  testing_report?: boolean;
  rowEdit?:boolean;
}


const Get = async (active?:boolean) : Promise<TSupplier[]> => {
  let response:any = undefined;
  if(typeof active !== 'undefined'){
    response = await apiFetch(Urls.Supplier+'?'+`active=${active}`);
  }
  else{
    response = await apiFetch(Urls.Supplier);
  }
  const suppliers = await response.json();
  return suppliers.map((supp:any) => ({
    supplierOrgNr: supp.leverantorOrgNr,
    supplierName: supp.leverantorName?.trim() ?? ""
  }));
}

const GetByOrgNr = async (orgNr:string) : Promise<TSupplier> => {
  const response = await apiFetch(Urls.Supplier+`/${orgNr}`);
  const supplierByOrgNr = await response.json();
  return {
    supplierOrgNr: supplierByOrgNr.leverantorOrgNr,
    supplierName: supplierByOrgNr.leverantorName?.trim() ?? ""
  };
}



export {
  Get,
  GetByOrgNr
}