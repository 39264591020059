import Urls from "../../lib/Urls";
import { Code, ReturnFetchObj, apiFetch, convertToQuerystring } from "../../lib/utils";
import { t } from 'i18next'


export interface IDashboardCategorys{
    titles:string[];
}

export interface IDashboardExpectedReports{
    period: string;
    reportsExpected: number;
    reportsReceived: number;
    invoiceReports: number;
    nullReports: number;
    csrdReports:number;
}

// export interface IDashboardLEV_SuppliersMissingReports{
//     period: string;
//     supplierOrgId: string;
//     supplierName: string;
// }

// export interface IDashboardLEV_SuppliersReceivedReports{
//     period: string;
//     supplierOrgId: string;
//     supplierName: string;
// }

// export interface IDashboardLEV_AllImportedInvoices{
//     id: number;
//     invoiceNumber: string;
//     status: number;
//     supplierName: string;
//     supplierOrgNr: string;
//     customerName: string;
//     customerOrgNr: string;
//     contractId: string;
//     billingDate: Date;
//     dueDate: Date;
//     priceExcMoms: number;
//     priceIncMoms: number;
//     type: number;
//     batchId: string;
// // }
// export interface IDashboardLEV_MonthlyContractSummary{
//     supplierOrgId: string;
//     supplier: string;
//     contractId:string;
//     period: string;
//     noInvoices:number;
//     priceExcMoms: number;
// }
// export interface IDashboardLEV_MonthlyPerContract{
//     contractId: string;
//     contractName: string;
//     supplierOrgId: string;
//     supplierName: string;
//     period: string;
//     noInvoices: number;
//     priceExcMoms: number;
// }
// export interface IDashboardLEV_MonthlyPerCustomer{
//     customerOrgId: string;
//     customerName: string;
//     period: string;
//     noInvoices: number;
//     priceExcMoms: number;
// }
// export interface IDashboardLEV_MonthlyPerSupplier{
//     supplierOrgId: string;
//     supplierName: string;
//     period: string;
//     noInvoices: number;
//     priceExcMoms: number;
// }
// export interface IDashboardLEV_SinfraContractArea{
//     kategori: string;
//     benamning: string;
//     leverantor: string;
//     leverantorOrgNr: string;
//     avtalsId: string;
//     kickbackProcent: string;
//     startRamavtal: Date;
//     startRapportering: Date;
//     slutRapportering: Date;
//     avslutat: Date;
//     arkiverat: boolean;
//     ejKopplat: boolean;
// }
export interface IDashboardLEV_SuppliersSummary{
    supplierOrgId:  string;
    supplierName:  string;
    january:  string;
    febuary:  string;
    march:  string;
    april:  string;
    may:  string;
    june:  string;
    july:  string;
    august:  string;
    september: string;
    october: string;
    november: string;
    december: string;
}

export interface IDashboardLEV_ExpectedReports{
    period: string;
    reportsExpected: number;
    reportsReceived: number;
    invoiceReports: number;
    nullReports: number;
}
export interface IData_fordashboardCard{
 reportType:string;
 day:number;
 totalReported:number;
 week:string;
}
export interface IData_Invoicesfordashboard{
    id:number;
    invoiceNumber:string;
    status:number;
    supplierId:string;
    supplierOrgId:string;
    supplierInvoceNumber:string;
    customerId:string;
    customerOrgId:string;
    contractId:string;
    priceExcMoms:number;
    billingDate:Date;
    type:number;
   
}
export interface ICategoryForPieChart{
    id:string;
    contractId:string;
    name:string;
    //category:{it:number,category:string};
     categoryId:number;
    supplierOrgId:string;
    kickbackPercent:string;
    startingDate:Date;
    reportingFromDate:Date;
    reportingToDate:Date;
    terminationDate:Date;
    importLevNamn:string;
    archived:boolean;
    detached:boolean;
    sum:number;
    
}

export interface IInvoiceDataForPieChart{
    contractId:string;
    categoryId:number;
    category:string;
    contractName:string;
    supplierOrgId:string;
    supplierName:string;
    period:string;
    noInvoices:number;
    priceExcMoms:number;

}
export interface IDashboardLEV_Systemlog{
    id:number;
    timeReported:Date;
    logLevel:string;
    logType:string;
    logText:string;
    batchId:string;      
}






const GetCategorys = async () : Promise<IDashboardCategorys> => {
    //const queryString = convertToQuerystring(opt);
    //const url = queryString.length > 0 ? `${Urls.KickbackReport}?${queryString}` : `${Urls.KickbackReport}`;
    const url = Urls.Dashboard + '/category'
    const response = await apiFetch(url);
    const jsonCategorys = await response.json();
    return jsonCategorys as IDashboardCategorys
    // return jsonKickbacks.map((kickback:any) => {
    //   return {
    //     ...kickback,
    //     reportDate : new Date(kickback.reportDate)
    //   }
    // })
  }
const GetExpectedReports = async (): Promise<IDashboardExpectedReports[]> =>{
    const url = Urls.Dashboard + '/expectedReports'
    const response = await apiFetch(url);
    const jsonCategorys = await response.json();
    const result:IDashboardExpectedReports[] = jsonCategorys.map((x:any) => {
        return x as IDashboardExpectedReports
    }) 
    return result
} 
// const GetLEV_SupplierMissingPeriods = async (): Promise<IDashboardLEV_SuppliersMissingReports[]> => {
//     const url = Urls.Dashboard + '/LEV_SuppliersMissingReports'
//     const response = await apiFetch(url);
//     const jsonCategorys = await response.json();
//     const result:IDashboardLEV_SuppliersMissingReports[] = jsonCategorys.map((x:any) => {
//         return x as IDashboardLEV_SuppliersMissingReports
//     }) 
//     return result
// }
// const GetLEVSuppliersReceivedReports = async (): Promise<IDashboardLEV_SuppliersReceivedReports[]> =>{
//     const url = Urls.Dashboard + '/LEV_SuppliersReceivedReports'
//     const response = await apiFetch(url);
//     const jsonCategorys = await response.json();
//     const result:IDashboardLEV_SuppliersReceivedReports[] = jsonCategorys.map((x:any) => {
//         return x as IDashboardLEV_SuppliersReceivedReports
//     }) 
//     return result
// }  
// const GetLEV_AllImportedInvoices = async (): Promise<IDashboardLEV_AllImportedInvoices[]> =>{
//     const url = Urls.Dashboard + '/LEV_AllImportedInvoices'
   
//     const response = await apiFetch(url);
//     const jsonCategorys = await response.json();
//     const result:IDashboardLEV_AllImportedInvoices[] = jsonCategorys.map((x:any) => {
//         return x as IDashboardLEV_AllImportedInvoices
//     }) 
//     return result
// }
// const GetLEV_MonthlyContractSummary = async (): Promise<IDashboardLEV_MonthlyContractSummary[]> =>{
//     const url = Urls.Dashboard + '/LEV_MonthlyContractSummary'
   
//     const response = await apiFetch(url);
//     const jsonCategorys = await response.json();
//     const result:IDashboardLEV_MonthlyContractSummary[] = jsonCategorys.map((x:any) => {
//         return x as IDashboardLEV_MonthlyContractSummary
//     }) 
//     return result
// }
// const GetLEV_MonthlyPerContract = async (): Promise<IDashboardLEV_MonthlyPerContract[]> =>{
//     const url = Urls.Dashboard + '/LEV_MonthlyPerContract'
   
//     const response = await apiFetch(url);
//     const jsonCategorys = await response.json();
//     const result:IDashboardLEV_MonthlyPerContract[] = jsonCategorys.map((x:any) => {
//         return x as IDashboardLEV_MonthlyPerContract
//     }) 
//     return result
// }
// const GetLEV_MonthlyPerCustomer = async (): Promise<IDashboardLEV_MonthlyPerCustomer[]> =>{
//     const url = Urls.Dashboard + '/LEV_MonthlyPerCustomer'
   
//     const response = await apiFetch(url);
//     const jsonCategorys = await response.json();
//     const result:IDashboardLEV_MonthlyPerCustomer[] = jsonCategorys.map((x:any) => {
//         return x as IDashboardLEV_MonthlyPerCustomer
//     }) 
//     return result
// }
// const GetLEV_MonthlyPerSupplier = async (): Promise<IDashboardLEV_MonthlyPerSupplier[]> =>{
//     const url = Urls.Dashboard + '/LEV_MonthlyPerSupplier'
   
//     const response = await apiFetch(url);
//     const jsonCategorys = await response.json();
//     const result:IDashboardLEV_MonthlyPerSupplier[] = jsonCategorys.map((x:any) => {
//         return x as IDashboardLEV_MonthlyPerSupplier
//     }) 
//     return result
// }
// const GetLEV_SinfraContractArea = async (): Promise<IDashboardLEV_SinfraContractArea[]> =>{
//     const url = Urls.Dashboard + '/LEV_SinfraContractArea'
   
//     const response = await apiFetch(url);
//     const jsonCategorys = await response.json();
//     const result:IDashboardLEV_SinfraContractArea[] = jsonCategorys.map((x:any) => {
//         return x as IDashboardLEV_SinfraContractArea
//     }) 
//     return result
// }
// const GetLEV_SuppliersSummary2022 = async (): Promise<IDashboardLEV_SuppliersSummary[]> =>{
//     const url = Urls.Dashboard + '/LEV_SuppliersCompleteSummary2022'
   
//     const response = await apiFetch(url);
//     const jsonCategorys = await response.json();
//     const result:IDashboardLEV_SuppliersSummary[] = jsonCategorys.map((x:any) => {
//         return x as IDashboardLEV_SuppliersSummary
//     }) 
//     return result
// }
// const GetLEV_SuppliersSummary2023 = async (): Promise<IDashboardLEV_SuppliersSummary[]> =>{
//     const url = Urls.Dashboard + '/LEV_SuppliersCompleteSummary2023'
   
//     const response = await apiFetch(url);
//     const jsonCategorys = await response.json();
//     const result:IDashboardLEV_SuppliersSummary[] = jsonCategorys.map((x:any) => {
//         return x as IDashboardLEV_SuppliersSummary
//     }) 
//     return result
// }
// const GetLEV_SuppliersSummary2024 = async (): Promise<IDashboardLEV_SuppliersSummary[]> =>{
//     const url = Urls.Dashboard + '/LEV_SuppliersCompleteSummary2024'
   
//     const response = await apiFetch(url);
//     const jsonCategorys = await response.json();
//     const result:IDashboardLEV_SuppliersSummary[] = jsonCategorys.map((x:any) => {
//         return x as IDashboardLEV_SuppliersSummary
//     }) 
//     return result
// }
// const GetLEV_ExpectedReports = async (): Promise<IDashboardLEV_ExpectedReports[]> =>{
//     const url = Urls.Dashboard + '/LEV_SuppliersExpectedReportsSummary'
    
//     const response = await apiFetch(url);
//     const jsonCategorys = await response.json();
//     const result:IDashboardLEV_ExpectedReports[] = jsonCategorys.map((x:any) => {
//         return x as IDashboardLEV_ExpectedReports
//     }) 
//     return result
// }
const GetData_fordashboardCard = async (): Promise<IData_fordashboardCard[][]> =>{
    const url = Urls.Dashboard + '/Data_fordashboardCard'
    
    const response = await apiFetch(url);
    const jsonCategorys = await response.json();
    const result:IData_fordashboardCard[][] = jsonCategorys.map((x:any) => {
        return x as IData_fordashboardCard[]
    }) 
    return result
}
const GetData_Invoicesfordashboard = async (contractdIds:string[],period:string): Promise<IData_Invoicesfordashboard[]> =>{
    //const ids = JSON.stringify(contractdIds.map(x => {return x.contractTd})) 
    const url = Urls.Dashboard + `/Invoicesfordashboard?contractIds=${contractdIds}&period=${period}`
    //console.log(ids,period)
    const response = await apiFetch(url);
    const jsonCategorys = await response.json();
    const result:IData_Invoicesfordashboard[] = jsonCategorys.map((x:any) => {
        return x as IData_Invoicesfordashboard
    }) 
    return result
}
const GetData_ICategoryForPieChart = async (period:string): Promise<ICategoryForPieChart[]> =>{
    //const ids = JSON.stringify(contractdIds.map(x => {return x.avtalsId})) 
    const url = Urls.Dashboard + `/CategorysReportingOnPeriod?period=${period}`
    //console.log(ids,period)
    const response = await apiFetch(url);
    const jsonCategorys = await response.json();
    const result:ICategoryForPieChart[] = jsonCategorys.map((x:any) => {
        return x as ICategoryForPieChart
    }) 
    return result
}
const GetData_IInvoiceDataForPieChart = async ():Promise<IInvoiceDataForPieChart[]> =>{
    const url = Urls.Dashboard + `/TestEndpointforCategorySum`
    //console.log(ids,period)
    const response = await apiFetch(url);
    const jsonCategorys = await response.json();
    const result:IInvoiceDataForPieChart[] = jsonCategorys.map((x:any) => {
        return x as IInvoiceDataForPieChart
    }) 
    return result
}
// const GetIDashboardLEV_Systemlog = async ():Promise<IDashboardLEV_Systemlog[]> =>{
//     const url = Urls.Dashboard + `/LEV_Systemlog`
//     //console.log(ids,period)
//     const response = await apiFetch(url);
//     const jsonCategorys = await response.json();
//     const result:IDashboardLEV_Systemlog[] = jsonCategorys.map((x:any) => {
//         return x as IDashboardLEV_Systemlog
//     }) 
//     return result
// }

const GetListOfAvailableLEV_views = async ():Promise<any[]> =>{
    const url = Urls.Dashboard + '/GetListOfAvailableLEV_Views'
    const response = await apiFetch(url);
    const jsonViews = await response.json();
    const result:any[] = jsonViews.map((x:any) => {
        return x 
    }) 
    return result
}
const Get_LEV_Views = async (view:string):Promise<any[]> =>{
    
    const url = Urls.Dashboard + `/Get_LEV_Views?view=${view}`
    const response = await apiFetch(url);
    const jsonViews = await response.json();
    const result:any[] = jsonViews.map((x:any) => {
        return x 
    }) 
    return result
}
export {
    GetCategorys,
    GetExpectedReports,
    //GetLEV_SupplierMissingPeriods,
    //GetLEVSuppliersReceivedReports,
    //GetLEV_AllImportedInvoices,
    //GetLEV_MonthlyContractSummary,
    //GetLEV_MonthlyPerContract,
    //GetLEV_MonthlyPerCustomer,
    //GetLEV_MonthlyPerSupplier,
   // GetLEV_SinfraContractArea,
    //GetLEV_SuppliersSummary2022,
    //GetLEV_SuppliersSummary2023,
    //GetLEV_SuppliersSummary2024,
    //GetLEV_ExpectedReports,
    GetData_fordashboardCard,
    GetData_Invoicesfordashboard,
    GetData_ICategoryForPieChart,
    GetData_IInvoiceDataForPieChart,
    //GetIDashboardLEV_Systemlog,
    GetListOfAvailableLEV_views,
    Get_LEV_Views
}
