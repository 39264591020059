import * as React from 'react';
import { FaChevronDown } from 'react-icons/fa';

export interface IAccordionProps {
  title: string |JSX.Element;
  open?: boolean;
  icon?: JSX.Element;
  children: JSX.Element;
  titleStyle?: React.CSSProperties | undefined;
  style?: React.CSSProperties | undefined;
  titleWrapperStyle?: React.CSSProperties | undefined;
  contentStyle?: React.CSSProperties | undefined;
  alwaysOpen?:boolean;
}

interface AccordionState {
  isActive: boolean;
}

export default class StyledAccordion extends React.Component<IAccordionProps, AccordionState> {
  constructor(props : any){
    super(props);
    this.state={
      isActive: (this.props.open || this.props.alwaysOpen) ?? false
    }
  }
  
  private toggleIsActive = () => {
    if(this.props.alwaysOpen)
      return;
    this.setState({
      isActive: !this.state.isActive
    })
  }

  public render(): React.ReactElement<IAccordionProps> {
    const titleWrapperClassNames = this.props.alwaysOpen ?
      "titleWrapper" : "titleWrapper clickable";
    return (
      <div style={this.props.style} className={"custom-accordion"} >
        <div style={this.props.titleWrapperStyle} className={titleWrapperClassNames} onClick={() => this.toggleIsActive()}>
          <label style={this.props.titleStyle} className={"title"}>
            {
              this.props.icon &&
              this.props.icon
            }
            {this.props.title}
          </label>
          {
            !this.props.alwaysOpen &&
            <div className='icon' aria-expanded={this.state.isActive}>
              <FaChevronDown />
            </div>
          }
        </div>
        <div style={this.props.contentStyle} className={"contentWrapper"} aria-expanded={this.state.isActive}> 
        {
          this.props.children
        }
          
        </div>
      </div>
    );
  }
}
