import * as React from 'react';
import DatePicker, { registerLocale, ReactDatePickerProps,getDefaultLocale } from 'react-datepicker';
import * as dateFnsLocale from 'date-fns/locale'
import i18n from '../../i18n';

interface IDatePicker_reactProps extends ReactDatePickerProps  {
}

const DatePicker_react: React.FunctionComponent<IDatePicker_reactProps> = (props) => {
  React.useEffect(() => {
    //@ts-ignore
    const possibleLocale = dateFnsLocale[i18n.language];
    registerLocale(i18n.language, possibleLocale ? possibleLocale : dateFnsLocale.frCA);
    
  }, [])

  return <DatePicker 
            {...props}
            locale={i18n.language}
            dateFormat={props.dateFormat ?? "yyyy-MM-dd"}
            popperProps={{strategy:"fixed"}}
            onChangeRaw={(ev) => {
              console.log(ev)
              if(props.onChangeRaw)
                
                return props.onChangeRaw(ev);

              const value = ev.target.value;
              //loggar som skall tas bort efter att datumbuggen är fixad
              console.log(value)
              if(value){
                const result = value.match(/^\d{8}/);
                console.log(result)
                if(result){
                  const year = result[0].substring(0,4);
                  const month = result[0].substring(4,6);
                  const date = result[0].substring(6,8);
                  console.log(year,month,date)
                  props.onChange(new Date(`${year}-${month}-${date}`), ev);
                }
              }
            }}
            className='form-control'
            
          />;
};

export default DatePicker_react;
