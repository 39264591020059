import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Datalist, { ListColumn } from '../../Datalist/Datalist';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TUser } from '../../../services/API/Users';
import { DataService } from '../../../services/DataService';
import { Department } from '../../../services/API/Departments';
import MemberModal from './MemberModal';

interface IMemberListProps {
  members:TUser[];
  departments: Department[];
  onChange(member:TUser):void;
  onDelete(id:string):void;
}

const MemberList: React.FunctionComponent<IMemberListProps> = (props) => {
  const { t } = useTranslation();
  const [ isModalOpen, setIsModalOpen ] = React.useState(false);

  const supplierDepartments = React.useRef(props.departments);
  React.useEffect(() => {
    supplierDepartments.current = props.departments;
    setColumns([...columns]);
  }, [props.departments]);


  const [ columns, setColumns ] = React.useState<ListColumn<TUser>[]>([
    {
      name: t("general.email"),
      fieldName: "email",
      key: "email",
      minWidth: 100,
      isActive: true,
      sorting: true,
    },
    {
      name: t("general.firstname"),
      fieldName: "firstName",
      key: "firstName",
      minWidth: 100,
      isActive: true,
      sorting: true,
    },
    {
      name: t("general.lastname"),
      fieldName: "lastName",
      key: "lastName",
      minWidth: 100,
      isActive: true,
      sorting: true,
    },
    {
      name: t("general.department"),
      fieldName: "department",
      key: "department",
      isActive: true,
      minWidth: 100,
      sorting: true,
      onRender: (item) => {
        const department = supplierDepartments.current.find(dep => item.attributes.departments.find(d => d == dep.id))
        return department?.department ?? "";
      }
    },
    {
      name: t("general.status"),
      fieldName: "enabled",
      key: "enabled",
      minWidth: 40,
      isActive: true,
      sorting: true,
      onRender: (item, col) => {
        if(item.id == "")
          return "";
        return <div>{item.enabled ? t("general.active") : t("general.inactive")}</div>
      }
    },
    {
      fieldName: "link",
      isActive: true,
      key: "link",
      minWidth: 30,
      maxWidth: 30,
      customCellPadding: "0px",
      name: "",
      onRender: (item) => item.id != "" ? <Button 
        className='button-cancel tool-button'
        onClick={() => { 
          window.open(`/edituser/${item.id}`, "_blank")
        }}
      >
        <FontAwesomeIcon  icon={"up-right-from-square"}/>
      </Button> : <></>
    },
    {
      fieldName: "edit",
      isActive: true,
      key: "edit",
      minWidth: 30,
      maxWidth: 30,
      customCellPadding: "0px",
      name: "",
      onRender: (item) => <Button 
        className='tool-button'
        onClick={() => { 
          setPreselectedUser(item);
          setIsModalOpen(true);
        }}
      >
        <FontAwesomeIcon  icon={"edit"}/>
      </Button>
    },
    {
      fieldName: "remove",
      isActive: true,
      key: "remove",
      minWidth: 30,
      customCellPadding: "0px",
      maxWidth: 30,
      name: "",
      onRender: (item) => <Button 
          className='button-danger tool-button'
          onClick={() => { props.onDelete(item.id) }}
        >
          <FontAwesomeIcon icon={"trash"}/>
        </Button>
    }
  ]);

  //Modal
  const [preselectedUser, setPreselectedUser] = React.useState<TUser>();

  return <div className='member-list'>
  <div className="field-label">
    {t("general.user")}
  </div>
  <Datalist 
    items={props.members} 
    columns={columns}
    setColumns={setColumns} 
    noItemsText={t("memberList.noMembers")} 
    uniqueKey={"id"}
    initialSortKey="email"
    key={"supplierUsersTable"}
  />
  <Button 
    style={{float:"right"}}
    onClick={() => {
      setIsModalOpen(true);
    }}
  >
      <FontAwesomeIcon icon={"add"} style={{marginRight: 4}}/>
      {t("memberList.add")}
  </Button>
  {
    isModalOpen &&
    <MemberModal 
      onDismiss={() => { setIsModalOpen(false); setPreselectedUser(undefined); }} 
      departments={props.departments}
      members={props.members}
      onSubmit={(user) => { props.onChange(user); setIsModalOpen(false); setPreselectedUser(undefined); }}
      member={preselectedUser}
    />
  }
</div>;
};

export function useMembers(supplierOrgNr: string){
  const [isLoading, setIsLoading] = React.useState(true);
  const [items, setItems] = React.useState<TUser[]>([]);

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const fetchedMembers = await DataService.Suppliers.GetSupplierUsers(supplierOrgNr);
    setItems(fetchedMembers);
    setIsLoading(false);
  };

  const AddOrUpdate = (user: TUser) => {
    setItems((_users) => {
      const index = _users.findIndex(u => user.email == u.email);
      if(index == -1){
        return _users.concat(user);
      }
      const newUsers = [..._users];
      newUsers.splice(index, 1, user);
      return newUsers;
    });
  }

  const RemoveById = (id: string) => {
    setItems((_users) => {
      return _users.filter(user => user.id != id)
    })
  }

  return {
    isLoading,
    items,
    AddOrUpdate,
    RemoveById
  };
}

export default MemberList;
